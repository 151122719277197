import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';
import { Overlay, OverlayTrigger, Tooltip } from 'react-bootstrap';
import moment from 'moment';
import Dotdotdot from 'react-dotdotdot';

const style = {
  cellPositioning: {
    margin: 0,
    verticalAlign: 'middle',
    height: 'initial',
    fontSize: 11,
    paddingTop: 2,
    paddingRight: 2,
    paddingBottom: 2,
    paddingLeft: 8,
  },
  menuButton: {
    cursor: 'pointer',
  },
  placeholderStyle: { 
    opacity: 0.5,
    fontStyle: 'italic',
    cursor: 'text',
  },
};

class DataRow extends Component {
  static propTypes = {
    idx: PropTypes.number,
    data: PropTypes.object,
    keys: PropTypes.array,
    commentFieldEditable: PropTypes.bool,
    toggleCommentBox: PropTypes.func,
  };
  constructor(props) {
    super(props);
    this.state = {
      showActions: false,
    };
    this.toggleActions = this.toggleActions.bind(this);
  }

  toggleActions(e) {
    e.stopPropagation();
    this.setState({ showActions: !this.state.showActions });
  }

  render() {
    const {
      data,
      idx,
      keys,
      commentFieldEditable,
      toggleCommentBox,
    } = this.props,
      { showActions } = this.state,
      comment = data.Comment,
      commentArray = comment ? comment.split(/\n/ig) : [],
      commentJSX = commentArray.map((ca, index) => {
        if (ca) {
          return <div key={`${index}-comment-field`}>{ca}</div>;
        } else {
          return <br key={`${index}-comment-field`} />;
        }
      }),
      commentPreview = (
        <Dotdotdot
          clamp={2}
          style={{ 
            overflow: 'hidden',
            display: 'inline-block',
            width: '100%',
          }}>
          {commentArray[0]}
        </Dotdotdot>
      ),
      addCommentPlaceholder = (
        <span style={style.placeholderStyle}>
          add comment
        </span>
      ),
      popover = (
        <Tooltip key={`tooltip-dataRow-${idx}`} className="details-comments-tooltip" id={`deta-comments-comment-tooltip-${idx}`}>
          {commentArray.length ? commentJSX : 'There is no comment'}
        </Tooltip>
      );
    return (
      <tr key={`detail-comments-modal-row-${idx}`}>
        {keys.filter(x=> x !== 'Actions').map((key, index) => {
          let td;
          if (key === 'Comment' && commentFieldEditable) {
            td = (
              <td 
                style={{ 
                  cursor: 'pointer',
                  maxWidth: '40vw',
                }}
                key={`td-${idx}-${index}`}
                onClick={() => toggleCommentBox(data)}>
                <OverlayTrigger
                  placement="left"
                  overlay={popover}>
                  <span>{commentArray.length ? commentPreview : addCommentPlaceholder}</span>
                </OverlayTrigger>
              </td>
            );
          } else if (key.indexOf('Date') !== -1) {
            td = <td key={`td-${idx}-${index}`}>{moment.utc(data[key]).local().format('L LT')}</td>;
          } else if (key === 'Actions') {
            // TODO - no longer used??
            td = (
              <td key={`popover-dataRow-${idx}`}>
                <i 
                  className="material-icons more-menu"
                  style={style.menuButton}
                  ref="target"
                  onClick={e => this.toggleActions(e)}>
                  more_horiz
                </i>
                <Overlay
                  show={showActions}
                  onHide={() => this.setState({ showActions: false })}
                  placement="top"
                  target={() => ReactDOM.findDOMNode(this.refs.target)}
                  rootClose
                >
                  {data[key]}
                </Overlay>
              </td>
            );
          } else {
            td = <td key={`td-${idx}-${index}`}>{data[key]}</td>;
          }
          return td;
        })}         
      </tr>
    );
  }
}

export default DataRow;
