import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-bootstrap';

class MappingRow extends Component {
    static propTypes = {
        column: PropTypes.object,
        idx: PropTypes.number,
        dbColumns: PropTypes.array,
        validateDates: PropTypes.bool,
        dateFormats: PropTypes.array,
        dateDelimiters: PropTypes.array,
        changeDbColumn: PropTypes.func,
        changeDateFormat: PropTypes.func,
        changeDateFormatDelimiter: PropTypes.func,
        disabled: PropTypes.bool,
        canEditDateFormats: PropTypes.bool,
    };

    shouldComponentUpdate(nextProps) {
        const cCol = this.props.column;
        const nCol = nextProps.column;

        return this.props.idx !== nextProps.idx || this.props.validateDates !== nextProps.validateDates
        || nCol.externalColumnName !== cCol.externalColumnName
        || nCol.duplicate !== cCol.duplicate
        || nCol.factColumnGuid !== cCol.factColumnGuid
        || nCol.dateFormat !== cCol.dateFormat
        || nCol.dateFormatDelimiter !== cCol.dateFormatDelimiter;
    }

    render() {
        const {
            column,
            idx,
            dbColumns,
            validateDates,
            dateFormats,
            dateDelimiters,
            changeDbColumn,
            changeDateFormat,
            changeDateFormatDelimiter,
        } = this.props;

        let dateFormatError = null;
        let dateDelimiterError = null;

        if (validateDates && column.isDateType) {
            if (!column.dateFormat) {
                dateFormatError = 'Required';
            }
        }
        var colName = dbColumns.find(x=> x.factColumnGuid === column.factColumnGuid);
        colName = colName ? colName.factColumnDisplayName : 'NOT FOUND';

        let formatStyle = { flex: '0 0 11rem'};

        return (
        <tr style={{display: 'table'}} key={`newColumn-${idx}`} className="d-flex">
            <td className='table-cell-content' style={{flex: '4', overflow: 'hidden', fontSize: '1rem'}}>
                <div style={{  
                        height: '100%',
                        display: 'flex',
                        flexDirection: 'column', 
                        justifyContent: 'center',
                    }} 
                    title={column.externalColumnName}
                >
                    <div style={{ 
                        textOverflow: 'ellipsis', 
                        whiteSpace: 'nowrap', 
                        overflow: 'hidden', 
                    }} >
                        {column.externalColumnName} 
                    </div>
                </div> 
            </td>
            <td className='table-cell-content' style={{flex: '4'}}>
                <Form.Group>
                    <Form.Select 
                        isInvalid={column.duplicate}
                        placeholder=""
                        value={column.factColumnGuid}
                        title={colName}
                        onChange={(e) => changeDbColumn(idx, e)}
                        style={column.factColumnGuid === 'default' ? { fontStyle: 'italic' } : undefined }
                    >
                        <option value="default" style={{ fontStyle: 'italic' }}>-- Not Mapped --</option>
                        {dbColumns.map(dbColumn => {
                            if (dbColumn.columnType !== 'calc_measure') {
                            return (
                                <option key={dbColumn.factColumnGuid}
                                    style={{ fontStyle: 'normal' }}
                                    value={dbColumn.factColumnGuid}>
                                    {dbColumn.factColumnDisplayName}
                                </option>
                            );
                            } else {
                            return null;
                            }
                        })}
                    </Form.Select>
                    <Form.Control.Feedback type="invalid">Duplicate</Form.Control.Feedback>
                </Form.Group>
            </td>
            <td className='table-cell-content' style={formatStyle}>
                {column.isDateType ? (
                    <Form.Group>
                        <Form.Select
                            isInvalid={dateFormatError}
                            placeholder=""
                            value={column.dateFormat}
                            onChange={(e) => changeDateFormat(idx, e)}
                            disabled={this.props.canEditDateFormats}
                        >
                            {dateFormats.map((df, index) => (
                            <option key={`date-format-${index}`} value={df}>{df}</option>
                            ))}
                        </Form.Select>
                        <Form.Control.Feedback>{dateFormatError}</Form.Control.Feedback>
                    </Form.Group>
                ) : (<span></span>)}
            </td>
            <td className='table-cell-content' style={formatStyle}>
                {column.isDateType ? (
                    <Form.Group>
                        <Form.Select 
                            isInvalid={dateDelimiterError}
                            placeholder=""
                            value={column.dateFormatDelimiter}
                            onChange={(e) => changeDateFormatDelimiter(idx, e)} disabled={this.props.canEditDateFormats}
                        >
                            {dateDelimiters.map(dfd => (
                            <option key={dfd.value} value={dfd.value}>{dfd.label}</option>
                            ))}
                        </Form.Select>
                        <Form.Control.Feedback type="invalid">{dateDelimiterError}</Form.Control.Feedback>
                    </Form.Group>
                ) : (<span></span>)}
            </td>
        </tr>);
    }
}

export default MappingRow;
