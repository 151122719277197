import React, { Component } from 'react';
import { connect } from '@app/utilities/routing';
import { Grid, Button as ArButton } from '@arius';
import { Modal, Button, Form, Row, Col} from 'react-bootstrap';
import { RECEIVED_REPORTING_TABLE } from '@app/actions/analysis/analysis.actionTypes';
import * as Colors from '@app/utilities/colors';
import { showModal } from '@app/actions/modal.actions';
import { ModalEditFormula, DEFAULT_REPORT_FORMULA} from './modalEditFormula';
import { 
    retrieveReportingTable,
    retrieveAvailableTables,
    retrieveLeftColumns,
    retrieveRightColumns,
    isReportingRunning,
} from '@app/actions/analysis/reporting.actions';
import ReportMappingsTable from './reportMappingsTable';
import { validateName } from '@app/utilities/validators';
import Loader from '@app/shared/presentational/loader';
import { notifyError } from '@app/utilities/notifier'

export const DEFAULT_REPORT_DEFINITION = {
    reportDefinitionId: -1,
    name: '',
    description: '',
    includeDimensions: false,
    leftTableIdentifier: '',
    rightTableIdentifier: '',
    leftTableFriendlyName: '',
    rightTableFriendlyName: '',
    leftFileIdentifier: '',
    rightFileIdentifier: '',
    leftUseLatest: true,
    rightUseLatest: true,
    mappings: [],
    formulas: [],
};

class ModalEditReport extends Component {
    static propTypes = {
    };
    constructor(props) {
        super(props);
        this.state = {
            showModalEdit: false,
            currentFormula: DEFAULT_REPORT_FORMULA,
            record: null,
        };
        this.selectLeftTable = this.selectLeftTable.bind(this);
        this.selectRightTable = this.selectRightTable.bind(this);
        this.open = this.open.bind(this);
        this.handleNameChange = this.handleNameChange.bind(this);
        this.handleDescChange = this.handleDescChange.bind(this);
        this.handleIncludeDimensionsChange = this.handleIncludeDimensionsChange.bind(this);
        this.onChange = this.onChange.bind(this);
        this.getFormulasGrid = this.getFormulasGrid.bind(this);
        this.save = this.save.bind(this);
        this.close = this.close.bind(this);
        this.handleChangeParam = this.handleChangeParam.bind(this);
        this.createFormula = this.createFormula.bind(this);
        this.editFormula = this.editFormula.bind(this);
        this.saveFormula = this.saveFormula.bind(this);
        this.getFriendlyFormula = this.getFriendlyFormula.bind(this);
        this.deleteFormula = this.deleteFormula.bind(this);
        this.getTemplateFormulaString = this.getTemplateFormulaString.bind(this);
        this.getTemplateActions = this.getTemplateActions.bind(this);
    }

    get canMap(){
        const { record } = this.state;
        return record && record.leftTableIdentifier && record.rightTableIdentifier;
    }

    get existingNames(){
        const { record, currentFormula } = this.state;
        let data = record && record.formulas ? record.formulas : [];
        return data ? data.filter(x=> x.reportDefinitionFormulaId !== currentFormula.reportDefinitionFormulaId)
            .map(x=> x.columnName.toLowerCase().trim()) : [];
    }

    get leftTable() {
        const { tables } = this.props;
        let table = tables.find(x=> x.physicalTableName === this.state.record.leftTableIdentifier);
        return table ? table : {isExtract: true};
    }

    get rightTable() {
        const { tables} = this.props;
        let table = tables.find(x=> x.physicalTableName === this.state.record.rightTableIdentifier);
        return table ? table : {isExtract: true};
    }
    
    get leftPrefix() {
        let { record } = this.state;
        const { leftTableFriendlyName, rightTableFriendlyName } = record;
        return leftTableFriendlyName === rightTableFriendlyName ? 
            `L-${leftTableFriendlyName}` : leftTableFriendlyName;
    }

    get rightPrefix() {
        let { record } = this.state;
        const { leftTableFriendlyName, rightTableFriendlyName } = record;
        return leftTableFriendlyName === rightTableFriendlyName ? 
            `R-${rightTableFriendlyName}` : rightTableFriendlyName;
    }

    get hasErrors(){
        const { record:r } = this.state;
        return !r || r.errorName ||
            r.errorLeftTable ||
            r.errorRightTable ||
            r.errorMappings ||
            r.errorMeasures ||
            r.mappings.filter((x)=> x.errorMessage).length ||
            r.formulas.filter((x)=> x.errorFormula).length;
    }

    componentDidMount() {
        const { scheduledJobs } = this.props;
        this.setState({ scheduledJobs });
    }

    componentDidUpdate(prevProps) {
        const { leftColumns, tables, userKey, dispatch, databaseId, currentRecord, getTables, rightColumns } = this.props;
        let { record } = this.state;

        if ((prevProps.rightColumns !== rightColumns)) {
            record.errorMeasures = this.validateMeasures();
            this.setState({record});
        }


        if ((prevProps.leftColumns !== leftColumns) && (!record.mappings || record.mappings.length < 1)) {
            // reset the mappings if the LEFT columns changed and no mappings exist
            record.mappings = [];
            this.setState({record});
        }

        if (currentRecord && currentRecord.reportDefinitionId !== (record ? record.reportDefinitionId : null)) {
            let record = {...currentRecord};
            getTables({ userKey, databaseId });
            record.mappings = this.mapData(record.mappings);
            this.setState({record});
        }

        if ((prevProps.tables !== tables)){
            var leftTable = tables.find(x=> x.physicalTableName === record.leftTableIdentifier);
            var rightTable = tables.find(x=> x.physicalTableName === record.rightTableIdentifier);
            if (rightTable){
                dispatch(retrieveRightColumns({userKey, databaseId, req: rightTable}));
            }
            if (leftTable){
                dispatch(retrieveLeftColumns({userKey, databaseId, req: leftTable}));
            }
        }
    }

    handleNameChange(e){
        const { existingNames } = this.props;
        let { record } = this.state;
        let name = e.target.value;
        record.name = name;
        record.errorName = this.validateName(name, existingNames);
        this.setState({record});
    }

    handleIncludeDimensionsChange(){
        let { record } = this.state;
        record.includeDimensions = !record.includeDimensions;
        record.errorMeasures = this.validateMeasures(record);
        this.setState({record});
    }

    handleDescChange(e){
        let { record:r } = this.state;
        let record = {...r, description: e.target.value};
        this.setState({record});
    }

    selectLeftTable(e) {
        const { userKey, databaseId, dispatch, tables } = this.props;
        let { record } = this.state;
        let physicalTableName = e.target.value;
        var table = physicalTableName ? tables.find(x=> x.physicalTableName === physicalTableName) : {};
        record.leftTableIdentifier = table.physicalTableName;
        record.leftTableFriendlyName = table.name;
        record.mappings = [];
        record.formulas = [];
        record.leftUseLatest = true;
        record.leftFileIdentifier = '';
        record.errorLeftTable = '';
        this.setState({leftTable: table, record});
        dispatch(retrieveLeftColumns({userKey, databaseId, req: table}));
    }

    selectRightTable(e) {
        const { userKey, databaseId, dispatch, tables } = this.props;
        let { record } = this.state;
        let physicalTableName = e.target.value;
        var table = physicalTableName ? tables.find(x=> x.physicalTableName === physicalTableName) : {};
        dispatch(retrieveRightColumns({userKey, databaseId, req: table}));
        record.rightTableIdentifier = table.physicalTableName;
        record.rightTableFriendlyName = table.name;
        record.mappings = [];
        record.formulas = [];
        record.errorMeasures = '';
        record.rightUseLatest = true;
        record.rightFileIdentifier = '';
        record.errorRightTable = '';
        this.setState({record});
    }

    handleChangeParam(e) {
        const { leftFiles, rightFiles } = this.props;
        let { record } = this.state;
        let id = e.target.id;
        
        if (id === 'leftUseLatest'){
            record.leftUseLatest = leftFiles.length < 1 ? true : !record.leftUseLatest;
            record.leftFileIdentifier = (record.leftUseLatest || leftFiles.length < 1) ? '' : leftFiles[0].tableFileId;
            record.errorLeftTable = '';
        } else if (id === 'rightUseLatest'){
            record.rightUseLatest = rightFiles.length < 1 ? true : !record.rightUseLatest;
            record.rightFileIdentifier = (record.rightUseLatest || rightFiles.length < 1) ? '' : rightFiles[0].tableFileId;
            record.errorRightTable = '';
        } else if (id === 'leftFileIdentifier') {
            record.leftFileIdentifier = e.target.value;
            record.leftUseLatest = record.leftFileIdentifier ? false : true;
            record.errorLeftTable = '';
        } else if (id === 'rightFileIdentifier') {
            record.rightFileIdentifier = e.target.value;
            record.rightUseLatest = record.rightFileIdentifier ? false : true;
            record.errorRightTable = '';
        }

        this.setState({record});
    }

    open() {
        const { userKey, databaseId, record:r, getRecord, dispatch } = this.props;
        if (r.reportDefinitionId > 0){
            getRecord({ userKey, databaseId, reportingTableId: r.reportDefinitionId }); 
        } else{
            dispatch({type: RECEIVED_REPORTING_TABLE, data: r});
        }
        this.setState({ showModal: true });
    }

    save() {
        const { userKey, databaseId, saveHandler, existingNames, dispatch } = this.props;
        let { record } = this.state;
        if (this.gridRef && this.gridRef.isEditing){
            this.gridRef.endEdit();
            return;
        }

        record.leftFileIdentifier = record.leftUseLatest ? '' : record.leftFileIdentifier;
        record.rightFileIdentifier = record.rightUseLatest ? '' : record.rightFileIdentifier;

        record.leftFileIdentifier = this.leftTable.isExtract ? '' : record.leftFileIdentifier;
        record.rightFileIdentifier = this.rightTable.isExtract ? '' : record.rightFileIdentifier;
        record.runs = [];

        record.errorName = this.validateName(record.name, existingNames);
        record.errorLeftTable = this.validateLeftTable();
        record.errorRightTable = this.validateRightTable();
        record.errorMappings = this.validateMappings();
        record.errorMeasures = this.validateMeasures();

        if (record.errorName || record.errorLeftTable || record.errorRightTable 
            || record.errorMappings || record.errorMeasures){
            this.setState({record});
            return;
        }

        if (record.reportDefinitionId < 1){
            saveHandler(record);
            this.close();
        } else {
            dispatch(isReportingRunning(userKey, databaseId, record.reportDefinitionId,
                (data)=> {
                    if (!data || !data.isRunning) {
                let messageItems = [
                    <span key={`warn-1`} style={{ fontWeight: 800 }}>
                        Saving will clear any existing records from your current Reporting table.  Do you want to continue? 
                    </span>,
                ];

                const yesClickHandler = () => {
                    saveHandler(record);
                    this.close();
                }
                const noClickHandler = () => {};
                const action = showModal(
                    'confirmation',
                    messageItems,
                    yesClickHandler,
                    noClickHandler
                );
                dispatch(action);
                    } else {
                        notifyError('Cannot save while updates to Reporting Table are in progress.');
                        return;
                    }
            }));
        }
    }

    validateName(name, existingNames) {

        if (!name || /^\s*$/.test(name)) {
            // name must exist and not be whitespace
            return 'Required';
        }

        let nameError = existingNames.filter(x=> name.toLowerCase().trim() === x).length ? 'Name must be unique' : '';
        nameError = nameError ? nameError : validateName(name);
        return nameError;
    }

    validateLeftTable() {
        const { record } = this.state;
        return record.leftTableIdentifier ? '' : 'Need to select both Left and Right table';
    }
    
    validateRightTable() {
        const { record } = this.state;
        return record.rightTableIdentifier ? '' : 'Need to select both Left and Right table';
    }

    validateMappings(record) {
        const { record:temp } = this.state;
        record = record ? record : temp;

        if (record.mappings.length < 1) {
            return 'Need valid column mapping';
        }

        let nUniqueLeftMappings = record.mappings.map(x=> x.columnName)
            .filter(x=> x && x !== '')
            .filter((value, index, self) => self.indexOf(value) === index).length;

        let nUniqueRightMappings = record.mappings.map(x=> x.columnNameToMapTo)
            .filter(x=> x && x !== '')
            .filter((value, index, self) => self.indexOf(value) === index).length;

        let nMappings = record.mappings.length;

        if (nUniqueLeftMappings !== nMappings || nUniqueRightMappings !== nMappings){
            return 'Need valid column mapping';
        } 
    }

    validateMeasures() {
        const { rightColumns } = this.props;
        const { record } = this.state;
        var hasMeasures = rightColumns.filter(x=> x.dataType === "Measure").length
        if (!record.includeDimensions && rightColumns.length && !hasMeasures) {
            return 'Must include dimensions in output when no measures included in table on right side';
        }
    }

    close() {
        const { dispatch } = this.props;
        dispatch({type: RECEIVED_REPORTING_TABLE, data: null});
        this.setState({record: null});
        this.props.closeHandler();
    }

    onChange(data){
        let { record } = this.state;
        data = this.mapData(data);
        record.mappings = data;
        record.errorMappings = '';
        this.setState({record});
    }

    mapData(data) {
        return Array.isArray(data) ? data.map((x,i)=> {
            return {
                reportDefinitionMappingId: i,
                reportDefinitionId: x.reportDefinitionId,
                columnName: x.columnName,
                columnNameToMapTo: x.columnNameToMapTo ? x.columnNameToMapTo : '',
                isSelected: x.isSelected ? true : false,
                errorMessage: x.errorMessage
            }
        }) : [];
    }

    getFriendlyFormula(f) {
        return f.formulaString
            .split('[L].').join(`[${this.leftPrefix}].`)
            .split('[R].').join(`[${this.rightPrefix}].`);
    }

    getTemplateFormulaString(r){
        let s = this.getFriendlyFormula(r);
        return <span title={s}>{s}</span>;
    }

    getTemplateErrorMessage(r){
        return (
            <span style={{ color: r.errorFormula ? 'red' : 'green'}}>
                <i className="material-icons">{r.errorFormula  ? 'error' : 'check'}</i>
            </span>
        )
    }

    getTemplateActions(r){
        return <span>
            <ArButton toolTip="Edit" iconName="edit" onClick={(e)=> {this.editFormula(e, r)}}/>
            <ArButton toolTip="Delete" iconName="delete" onClick={(e)=> {this.deleteFormula(e, r)}}/>
        </span>
    }

    getFormulasGrid() {
        const { record } = this.state;
        let data = record.formulas ? record.formulas : [];

        let columns = [
            { field: 'reportDefinitionFormulaId', visible: false, isPrimaryKey: true},
            { field: 'reportDefinitionId', visible: false},
            { field: 'columnName', headerText: 'Column Name', width: 200,},
            { field: 'formulaString', headerText: 'Formula', template: this.getTemplateFormulaString},
            { field: 'errorMessage', headerText: 'Valid', width:100, template: this.getTemplateErrorMessage},
            { headerText: 'Actions', template: this.getTemplateActions},
        ];
        
        return <Grid 
            columns={columns}
            data={data}
            height='auto'
        />
    }

    createFormula() {
        this.setState({showModalEdit: true, currentFormula: {reportDefinitionFormulaId: -1, columnName: '', formulaString: ''}});
    }

    editFormula(e, row) {
        let record = {...row};
        record.formulaString = this.getFriendlyFormula(record);
        this.setState({showModalEdit: true, currentFormula: record});
    }

    deleteFormula(e, row) {
        let { record } = this.state;
        record.formulas = record.formulas.filter(x=> x.reportDefinitionFormulaId !== row.reportDefinitionFormulaId);
        this.setState({record});
    }

    saveFormula(f) {
        let { record } = this.state;
        
            // make the 'non-friendly' version of the formula
        f.formulaString = f.formulaString
            .split(`[${this.leftPrefix}].`).join('[L].')
            .split(`[${this.rightPrefix}].`).join('[R].');

        // set an id if necessary
        if (f.reportDefinitionFormulaId < 1){
            let ids = record.formulas.length > 0 ? record.formulas.map(x=> x.reportDefinitionFormulaId) : [0];
            f.reportDefinitionFormulaId = Math.max(...ids)+1;
        }

        // add/replace in collection
        record.formulas = record.formulas.filter(x => x.reportDefinitionFormulaId !== f.reportDefinitionFormulaId);
        record.formulas.push(f);
        record.errorMeasures = this.validateMeasures(record);
        this.setState({record});
    }

    getTextControls() {
        const { record } = this.state;
        if (!record) { return '';}

        return <Row>
            <Col md={6}>
                <Form.Group>
                    <Form.Label>Name</Form.Label>
                    <Form.Control
                        className="form-control"
                        as="input" maxLength="255"
                        value={record.name}
                        onChange={this.handleNameChange}
                        isInvalid={record.errorName}
                    />
                    <Form.Control.Feedback type='invalid'>{record.errorName}</Form.Control.Feedback>
                </Form.Group>
            </Col>
            <Col md={6}>
                <Form.Group>
                <Form.Label>Description</Form.Label>
                    <Form.Control
                        className="form-control"
                        as="textarea" maxLength="1500"
                        value={record.description}
                        onChange={this.handleDescChange}
                    />
                </Form.Group>
            </Col>
        </Row>;
    }

    getLeftTableControls(){
        const { record } = this.state;
        if (!record) { return '';}
        const { tables, leftFiles } = this.props;
        return <span>
        <Form.Group controlId="selectL">
            <Form.Label>Select Left</Form.Label>
            <Form.Select placeholder="Select a CSV File" 
                    value={record.leftTableIdentifier} onChange={this.selectLeftTable}
                    isInvalid={record.errorLeftTable}>
                <option value="">-- Select an extract or supporting table --</option>
                {tables.map(u => (
                <option key={`${u.physicalTableName}`} value={u.physicalTableName}>{u.displayName}</option>
                ))}
            </Form.Select>
            <Form.Control.Feedback type='invalid'>{record.errorLeftTable}</Form.Control.Feedback>
        </Form.Group>
        {this.leftTable.isExtract ? <div></div> : (
        <Form.Group>
            <Form.Check type="checkbox" id="leftUseLatest" checked={record.leftUseLatest}
                onChange={this.handleChangeParam} label="Use Latest" />
            <Form.Select id='leftFileIdentifier' disabled={record.leftUseLatest}
                    value={record.leftFileIdentifier} onChange={this.handleChangeParam}>
                <option value="">-- Select a file --</option>
                {leftFiles.sort((a,b)=> new Date(b.updateDate) - new Date(a.updateDate)).map(u => (
                    <option key={`L${u.tableFileId}`} value={u.tableFileId}>{u.fileName}</option>
                ))}
            </Form.Select>
        </Form.Group>)}
        </span>;
    }

    getRightTableControls(){
        const { record } = this.state;
        if (!record) { return '';}
        const { tables, rightFiles } = this.props;

        return <span>
        <Form.Group>
            <Form.Label column={true}>Select Right</Form.Label>
            <Form.Select placeholder="Select a CSV File" onChange={this.selectRightTable}
                value={record.rightTableIdentifier}
                isInvalid={record.errorRightTable}>
                <option value="">-- Select an extract or supporting table --</option>
                {tables.map(u => (
                <option key={`${u.physicalTableName}`} value={u.physicalTableName}>{u.displayName}</option>
                ))}
            </Form.Select>
            <Form.Control.Feedback type='invalid'>{record.errorRightTable}</Form.Control.Feedback>
    </Form.Group>
        { this.rightTable.isExtract ? <div></div> : (
            <Form.Group>
                <Form.Check type="checkbox" id="rightUseLatest" checked={record.rightUseLatest}
                    onChange={this.handleChangeParam} label="Use Latest" />
                <Form.Select id='rightFileIdentifier' disabled={record.rightUseLatest}
                        value={record.rightFileIdentifier} onChange={this.handleChangeParam}>
                    <option value="">-- Select a file --</option>
                    {rightFiles.sort((a,b)=> new Date(b.updateDate) - new Date(a.updateDate)).map(u => (
                        <option key={`R${u.tableFileId}`} value={u.tableFileId}>{u.fileName}</option>
                    ))}
                </Form.Select>
            </Form.Group>)
        }
        <Form.Group>
            <Form.Check type='checkbox'>
                <Form.Check.Input type='checkbox' 
                    id='includeDimensionsCBX'
                    style={{ marginLeft: 0 }}
                    checked={record.includeDimensions}
                    onChange={this.handleIncludeDimensionsChange}
                />
                <Form.Check.Label>&nbsp;Include Dimensions in Output</Form.Check.Label>
            </Form.Check>
            <Form.Control style={{display: 'none'}} isInvalid={record.errorMeasures}></Form.Control>
                <Form.Control.Feedback type='invalid'>{record.errorMeasures}</Form.Control.Feedback>
        </Form.Group>
        </span>;
    }

    getMappingControls(){
        const { record } = this.state;
        if (!record) {return '';}
        let mappings = Array.isArray(record.mappings) ? record.mappings : [];
        const { leftColumns, rightColumns } = this.props;
        return <ReportMappingsTable 
            leftColumns = {leftColumns.filter(x=> x.dataType !== "Measure")}
            rightColumns = {rightColumns.filter(x=> x.dataType !== "Measure")}
            mappings = {mappings}
            saveHandler = {this.onChange}
            canMap={this.canMap}
            error={record.errorMappings}
        />
    }

    getFormulaControls(){
        const { record } = this.state;
        if (!record) {return '';}
        return <Form.Group controlId="formulas">
            <div style={{display: 'flex',justifyContent: 'space-between'}}>
            <Form.Group controlId="formulas">
                <Form.Label>Formulas</Form.Label>
            </Form.Group>
                <ArButton
                    toolTip = "Create new formula"
                    mode="add"
                    onClick={this.createFormula}
                />
            </div>
            {this.getFormulasGrid()}
        </Form.Group>;
    }

    render() {
        const { record, showModalEdit, currentFormula} = this.state;
        const { show, userKey, leftColumns, rightColumns, isFetching, canEdit } = this.props;
        const { leftTableFriendlyName, rightTableFriendlyName } = record ? record : {};

        let content = isFetching ? <Loader loadingItem="Report" /> : (
            <span>
                <div>
                    {this.getTextControls()}
                    <div className="row form-group">
                        <div className="col-md-6">{this.getLeftTableControls()}</div>
                        <div className="col-md-6">{this.getRightTableControls()}</div>
                    </div>
                </div>
                <div className="row form-group">
                    <div className="col-md-12">{this.getMappingControls()}</div>
                </div>
                <div className="row form-group">
                    <div className="col-md-12">{this.getFormulaControls()}</div>
                </div>
            </span>
        )

        return (
        <Modal id={'modalLoadFile'} show={show} dialogClassName="wait-modal" size="sm"
            onHide={this.close}  onShow={this.open}  backdrop='static'>
            <Modal.Header style={{ backgroundColor: Colors.blue, color: '#FFFFFF' }} closeButton>
                {record && record.reportDefintionId < 1 ? 'Create' : 'Modify'} Report
            </Modal.Header>
            <Modal.Body className="modal-container">
                {content}
                <ModalEditFormula 
                    userKey={userKey}
                    closeHandler={()=> this.setState({showModalEdit: false})}
                    saveHandler={this.saveFormula}
                    show={showModalEdit} 
                    leftColumns={leftColumns.filter(x=> x.dataType === 'Measure').map(x=> x.name)}
                    rightColumns={rightColumns.filter(x=> x.dataType === 'Measure').map(x=> x.name)}
                    leftTableFriendlyName={leftTableFriendlyName}
                    rightTableFriendlyName={rightTableFriendlyName}
                    record={currentFormula}
                    existingNames={this.existingNames}
                />
            </Modal.Body>
            <Modal.Footer>
                <Button variant="arius" data-dismiss="modal" onClick={this.save} disabled={this.hasErrors || !canEdit}>Save</Button>
                <Button variant="arius" data-dismiss="modal" onClick={this.close}>Cancel</Button>
            </Modal.Footer>
        </Modal>
        );
    }
}

const mapStateToProps = state => ({
    userKey: state.user.userKey,
    tables: state.analysis.reporting.availableTables,
    leftColumns: state.analysis.reporting.leftColumns,
    rightColumns: state.analysis.reporting.rightColumns,
    leftFiles: state.analysis.reporting.leftFiles,
    rightFiles: state.analysis.reporting.rightFiles,
    currentRecord: state.analysis.reporting.reportingTable,
    isFetching: state.analysis.reporting.isFetchingReportingTable
});

const mapDispatchToProps = dispatch => ({
    dispatch,
    getTables: ({ userKey, databaseId }) => {
        dispatch(retrieveAvailableTables({ userKey, databaseId }));
    },
    getRecord: ({ userKey, databaseId, reportingTableId }) => {
        dispatch(retrieveReportingTable({ userKey, databaseId, reportingTableId }));
    }, 
});

let __OBJ =  connect(mapStateToProps, mapDispatchToProps)(ModalEditReport);
export {__OBJ as ModalEditReport}

