import React, { Component } from 'react';
import PropTypes from 'prop-types';
import AdxQueryWarning from '@app/shared/presentational/adxQueryWarning';
import * as GlobalStyles from '../../../utilities/globalStyles';
import * as Colors from '../../../utilities/colors';
import {
  OverlayTrigger,
  Tooltip,
  Row,
  Card,
  Form,
} from 'react-bootstrap';
import { Button } from '@arius';
import SegmentPreviewModal from './segmentPreviewModal';
import { notifySuccess, notifyError } from '../../../utilities/notifier';
import { DATA_MANAGEQUERIES } from '../../../utilities/permissions';
import SaveButtons from '../../shared/presentational/saveButtons';
import { validateName } from '../../../utilities/validators';
import Loader from '../../shared/presentational/loader';
import { showModal } from '../../../actions/modal.actions';
import { storeQuery } from '../../../actions/tod/queries.actions';
import CustomCheckbox from '../../shared/presentational/customCheckbox';
import QueryTopLevelForm from '../../tod/presentational/queryTopLevelForm';

const style = {
  checkboxRow: {
    display: 'flex',
    flexFlow: 'row nowrap',
    margin: '2px 10px',
    minHeight: '18px',
  },
  checkboxValue: {
    width: '100%',
    paddingLeft: 5,
  },
  reCalcRow: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: '0px 0px 0px 10px',
  },
  titleRow: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  cardShadow: {
    boxShadow: '0 3px 6px rgba(0,0,0,0.12), 0 3px 6px rgba(0,0,0,0.24)',
    transition: 'all 0.3s cubic-bezier(.25,.8,.25,1)',
  },
  dimensionContainer: {
    backgroundColor: 'rgb(249, 249, 249)',
    display: 'flex',
    flexFlow: 'row nowrap',
    width: '100%',
    overflowX: 'scroll',
    overflowY: 'hidden',
  },
};

function alphabetize(list) {
  return list.sort((a, b) => {
    const dimensionA = a.value.slice(1, a.value.length - 1).toUpperCase(),
      dimensionB = b.value.slice(1, b.value.length - 1).toUpperCase();
    if (dimensionA < dimensionB) {
      return -1;
    }
    if (dimensionA > dimensionB) {
      return 1;
    }
    return 0;
  });
}

class CreateQuery extends Component {
  static propTypes = {
    params: PropTypes.object,
    userKey: PropTypes.string,
    dispatch: PropTypes.func,
    allDatabases: PropTypes.array,
    queries: PropTypes.array,
    changeDatabase: PropTypes.func,
    isFetching: PropTypes.bool,
    isSaving: PropTypes.bool,
    currentDb: PropTypes.object,
    getQuery: PropTypes.func,
    getQueryDataSource: PropTypes.func,
    getSegmentPreview: PropTypes.func,
    newSaveHandler: PropTypes.func,
    updateSaveHandler: PropTypes.func,
    dataSource: PropTypes.object,
    isDataSourceFetching: PropTypes.bool,
    segmentPreview: PropTypes.object,
    currentQuery: PropTypes.object,
    errorMessage: PropTypes.string,
    application: PropTypes.object,
    getDatabaseColumns: PropTypes.func,
    getDatabase: PropTypes.func,
    isFetchingDatabases: PropTypes.bool,
    verifyPermission: PropTypes.func,
    clearErrorMessageHandler: PropTypes.func,
  };
  constructor(props) {
    super(props);
    this.state = {
      queryName: '',
      queryDesc: '',
      queryExpDate: '',
      holdingQueryName: '',
      holdingQueryDesc: '',
      holdingQueryExpDate: '',
      holdingFilters: [],
      useHoldingQueryName: false,
      useHoldingQueryDescription: false,
      useHoldingQueryExpDate: false,
      checkHoldingFilters: false,
      databaseExposureDates: [],
      defaultDimensions: [],
      dimensionHeaders: [],
      splits: [],
      filters: [],
      showSegmentPreviewModal: false,
      isEditing: false,
      softErrorMode: true,
      showLegend: false,
      dirty: false,
      init: false,
      automaticUpdateEnabled: false,
      dimensionsWithLimitsReached: [],
    };

    this.getSegmentPreview = this.getSegmentPreview.bind(this);
    this.closeSegmentPreviewModal = this.closeSegmentPreviewModal.bind(this);
    this.saveQuery = this.saveQuery.bind(this);
    this.toggleLegend = this.toggleLegend.bind(this);
    this.openCreateEditRollupDefinitions = this.openCreateEditRollupDefinitions.bind(
      this,
    );
    this.toggleAutomaticUpdateEnabled = this.toggleAutomaticUpdateEnabled.bind(
      this,
    );
    this.updateQueryData = this.updateQueryData.bind(this);
    this.prioritzeRollups = this.prioritzeRollups.bind(this);
    this.getNameValidationError = this.getNameValidationError.bind(this);
    this.updateQueryName = this.updateQueryName.bind(this);
    this.updateQueryDescription = this.updateQueryDescription.bind(this);
    this.updateExpDate = this.updateExpDate.bind(this);
    this.selectAllDimensionValues = this.selectAllDimensionValues.bind(this);
  }

  componentDidMount() {
    const { currentDb, params, userKey, getQueryDataSource } = this.props;

    if (currentDb && currentDb.id === parseInt(params.databaseId, 10 ) && !currentDb.useADX) {
      getQueryDataSource(userKey, currentDb.id);
      if (currentDb.withColumns) {
        const databaseExposureDates = currentDb.columns.filter(
          col => col.columnType === 'dateY',
        ),
          queryExpDate = databaseExposureDates.length === 1
            ? databaseExposureDates[0].factColumnDisplayName
            : '';

        this.setState({
          databaseExposureDates,
          queryExpDate,
        });
      }
    }
    this.setState({ isEditing: !!params.queryId });
  }

  shouldComponentUpdate(nextProps, nextState) {
    const { holdingQueryName,
      holdingQueryDesc,
      holdingQueryExpDate,
      holdingFilters,
      useHoldingQueryName,
      useHoldingQueryDescription,
      useHoldingQueryExpDate } = this.state;
    if (holdingQueryName !== nextState.holdingQueryName
      || holdingQueryDesc !== nextState.holdingQueryDesc
      || holdingQueryExpDate !== nextState.holdingQueryExpDate
      || holdingFilters !== nextState.holdingFilters
      || useHoldingQueryName !== nextState.useHoldingQueryName
      || useHoldingQueryDescription !== nextState.useHoldingQueryDescription
      || useHoldingQueryExpDate !== nextState.useHoldingQueryExpDate) {
      return false;
    }
    return true;
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      currentDb,
      isFetching,
      getQueryDataSource,
      userKey,
      dataSource,
      currentQuery,
      params,
      getQuery,
      errorMessage,
      isSaving,
      clearErrorMessageHandler,
      browserHistory,
    } = this.props,
      { showSegmentPreviewModal } = this.state;

    if (
      prevProps.currentDb !== currentDb && currentDb && !currentDb.isFetching && !currentDb.useADX
    ) {
      if (!isFetching) {
        getQueryDataSource(userKey, currentDb.id);
      }
      if (currentDb && currentDb.columns && currentDb.columns.length > 0) {
        const databaseExposureDates = currentDb.columns.filter(
          col => col.columnType === 'dateY',
        ),
          queryExpDate = databaseExposureDates.length === 1
            ? databaseExposureDates[0].factColumnDisplayName
            : '';
        this.setState({
          databaseExposureDates,
          queryExpDate,
        });
      }
    }

    if (errorMessage && errorMessage !== prevProps.errorMessage) {
      notifyError(errorMessage);
      clearErrorMessageHandler();
      if (showSegmentPreviewModal) {
        this.closeSegmentPreviewModal();
      }
    }

    if (prevProps.currentQuery !== currentQuery && currentDb) {
      const splits = [], filters = [];
      if (currentQuery.columns) {
        currentQuery.columns.forEach(col => {
          splits.push(col.name);
          if (col.filterBy.length > 0) {
            filters.push({
              name: col.name,
              values: col.filterBy.map(val => val),
            });
          } 
        });
      }
      this.setState({
        init: true,
        queryName: currentQuery.queryName,
        queryDesc: currentQuery.queryDescription,
        queryExpDate: currentQuery.defaultDateY,
        splits,
        filters,
      });
    }

    if (dataSource && prevProps.dataSource !== dataSource) {
      if (!prevState.init) {
        const filters = [];
        const splits = [];
        dataSource.values.forEach(dimension => {
          filters.push({ name: dimension.name, values: [] });
          splits.push(dimension.name);
          if (dimension.limitReached && this.state.dimensionsWithLimitsReached.indexOf(dimension.name) === -1)
          {
            this.state.dimensionsWithLimitsReached.push(dimension.name);
            this.setState({
              dimensionsWithLimitsReached : this.state.dimensionsWithLimitsReached
            });
          }
        });
        this.setState({ init: true, filters, splits });
      }
      if (this.state.defaultDimensions.length === 0) {
        this.setState({
          defaultDimensions: dataSource.values,
          dimensionHeaders: dataSource.values.map(dim => dim.name),
        });
        if (params.queryId) {
          getQuery(userKey, currentDb.id, params.queryId);
        }
      }
    }

    if (prevState.splits !== this.state.splits) {
      this.getNewDataSource();
    }

    if (
      !isSaving && prevProps.isSaving !== isSaving && !this.props.errorMessage
    ) {
      notifySuccess('Query Saved!');
      browserHistory.goBack();
    }
    if (this.state.filters !== prevState.filters) {
      this.setState({ holdingFilters: this.state.filters });
    }
  }

  componentWillUnmount() {
    const { userKey, getQueryDataSource, currentDb } = this.props;
    if (!currentDb.useADX){
       getQueryDataSource(userKey, currentDb.id); 
    }
  }

  getNameValidationError(soft) {
    const { isEditing } = this.state,
      queryName = this.state.useHoldingQueryName ? this.state.holdingQueryName : this.state.queryName;
    const { currentDb, queries, currentQuery } = this.props;
    let error = validateName(queryName, soft);
    if (!error && !soft) {
      const otherQueries = isEditing && currentDb
        ? queries.filter(q => q.queryId !== currentQuery.queryId)
        : queries;

      if (
        otherQueries.find(
          q =>
            q.queryName.toLowerCase().trim() === queryName.toLowerCase().trim(),
        )
      ) {
        error = `Query with the name '${queryName.trim()}' already exists!`;
      }
    }
    return error;
  }

  getSegmentPreview() {
    const { userKey, getSegmentPreview, currentDb } = this.props;
    const { splits, filters } = this.state;

    // const segmentFilters = this.handleFilters(filters, 'getSegmentPreview');

    getSegmentPreview(userKey, currentDb.id, filters, splits);
    this.setState({ showSegmentPreviewModal: true });
  }

  getNewDataSource(childFilteredFilters) {
    let { filters } = this.state;
    if (childFilteredFilters) {
      filters = childFilteredFilters;
    }
    const { userKey, getQueryDataSource, currentDb } = this.props;
    const { splits } = this.state;

    if (currentDb.useADX) { return;}

    const clonedFilters = JSON.parse(JSON.stringify(filters));
    clonedFilters.forEach(cf => {
      if (cf.rollupInfo) {
        delete cf.rollupInfo;
      }
    });
    getQueryDataSource(userKey, currentDb.id, clonedFilters, splits);
  }

  toggleAutomaticUpdateEnabled() {
    this.setState({
      automaticUpdateEnabled: !this.state.automaticUpdateEnabled,
    });
  }

  updateQueryData() {
    this.setState({ dirty: true });
    const filters = this.handleSelectValuesFromChildren();
    this.getNewDataSource(filters);
  }

  handleFilters(filters, method) {
    const { defaultDimensions } = this.state;
    const columns = filters.map(filter => {
      if (!filter.rollupInfo) {
        const defaultDimension = defaultDimensions.find(
          d => d.name === filter.name,
        );
        filter = {
          ...filter,
          rollupInfo: [],
        };
        const rollupDefinitions = defaultDimension.values.filter(
          rd => !!rd.rollupDefinitionId,
        );
        if (rollupDefinitions.length) {
          filter.values.forEach(v => {
            const found = rollupDefinitions.find(rd => rd.value === v);
            if (found) {
              filter.rollupInfo.push(found);
            }
          });
        }
      }

      if (filter.rollupInfo.length) {
        const adjFilter = [];
        filter.values.forEach(v => {
          // if not found then add in case of rollup
          const found = filter.rollupInfo.find(rd => rd.value === v);
          if (!found) {
            const nonRollupObject = {
              value: v,
              rollupDefinitionId: null,
            };
            adjFilter.push(nonRollupObject);
          }
        });

        filter.rollupInfo.forEach(ri => {
          const rollupObject = {
            value: ri.value,
            rollupDefinitionId: ri.rollupDefinitionId,
          };
          adjFilter.push(rollupObject);
        });

        // for rollups
        const rd = {
          name: filter.name,
          split: false,
          filterBy: adjFilter,
        };
        return rd;
      } else {
        // for non rollups
        const adjNonRollupFilter = [];
        filter.values.forEach(v => {
          const nonRollupObject = {
            value: v,
            rollupDefinitionId: null,
          };
          adjNonRollupFilter.push(nonRollupObject);
        });

        const f = {
          name: filter.name,
          split: false,
          filterBy: adjNonRollupFilter,
        };
        return f;
      }
    });

    if (method === 'getSegmentPreview') {
      const segmentFilters = [];
      columns.forEach(c => {
        const columnFilters = [];
        c.filterBy.forEach(f => {
          columnFilters.push(f.value);
        });
        const segment = {
          name: c.name,
          values: columnFilters,
        };
        segmentFilters.push(segment);
      });

      return segmentFilters;
    } else if (method === 'saveQuery') {
      return columns;
    } else {
      return null;
    }
  }

  handleSelectAll(dimension) {
    const { filters, automaticUpdateEnabled } = this.state;
    const filter = filters.find(f => f.name === dimension.name);
    if (filter) {
      filters.splice(filters.indexOf(filter), 1);
    }
    this.setState({ filters, dirty: true });
    if (automaticUpdateEnabled) {
      this.updateQueryData();
    }
  }

  handleSelectSpecific(dimension) {
    const { filters, automaticUpdateEnabled } = this.state;
    const filter = filters.find(f => f.name === dimension.name);
    if (!filter) {
      filters.push({ name: dimension.name, values: [], rollupInfo: [] });
    }
    this.setState({ filters, dirty: true });
    if (automaticUpdateEnabled) {
      this.updateQueryData();
    }
  }

  handleSelectValue(filterName, value, defaultDimension, useHoldingFilters) {
    let shouldUpdate = false;
    const { automaticUpdateEnabled } = this.state;
    let { filters } = this.state;
    if (useHoldingFilters) {
      filters = this.state.holdingFilters;
    }
    filters = filters.map(filter => {
      let clonedFilter;
      if (filter.name === filterName) {
        if (!filter.rollupInfo) {
          filter = {
            ...filter,
            rollupInfo: [],
          };

          const rollupDefinitions = defaultDimension.values.filter(
            rd => !!rd.rollupDefinitionId,
          );
          if (rollupDefinitions.length) {
            filter.values.forEach(v => {
              const found = rollupDefinitions.find(rd => rd.value === v);
              if (found) {
                filter.rollupInfo.push(found);
              }
            });
          }
        }
        const val = filter.values.find(v => v === value.value);
        const rollup = filter.rollupInfo.find(r => r.value === value.value);
        clonedFilter = JSON.parse(JSON.stringify(filter));

        if (val) {
          filter.values.splice(filter.values.indexOf(val), 1);
          if (rollup) {
            filter.rollupInfo.splice(filter.rollupInfo.indexOf(rollup), 1);
          }
        } else {
          if (value.rollupDefinitionId) {
            filter.rollupInfo.push(value);
          }
          filter.values.push(value.value);
        }
        if (filter.values.length === defaultDimension.values.length
          || (filter.values.length === defaultDimension.values.length - 1
          && clonedFilter.values.length === defaultDimension.values.length)) {
          shouldUpdate = true;
        }
      }
      return filter;
    });
    if (shouldUpdate) {
      this.setState({ filters, dirty: true });
    } else {
      this.setState({ holdingFilters: filters });
    }
    
    
    if (automaticUpdateEnabled) {
      this.getNewDataSource(filters);
    }
  }

  closeSegmentPreviewModal() {
    this.setState({ showSegmentPreviewModal: false });
  }

  selectAllDimensionValues(filterName, defaultDimension) {
    const { automaticUpdateEnabled } = this.state;
    let { filters } = this.state;
    filters = filters.map(filter => {
      if (filter.name === filterName) {
        if (!filter.rollupInfo) {
          filter = {
            ...filter,
            rollupInfo: [],
          };

          const rollupDefinitions = defaultDimension.values.filter(
            rd => !!rd.rollupDefinitionId,
          );
          if (rollupDefinitions.length) {
            filter.values.forEach(v => {
              const found = rollupDefinitions.find(rd => rd.value === v);
              if (found) {
                filter.rollupInfo.push(found);
              }
            });
          }
        }
        if (filter.values.length === defaultDimension.values.length) {
          filter.values = [];
        } else {
          filter.values = [];
          defaultDimension.values.forEach((value) => {
            filter.values.push(value.value);
          });
        }
      }
      return filter;
    });
    this.setState({ filters, dirty: true });
    if (automaticUpdateEnabled) {
      this.getNewDataSource(filters);
    }
  }

  handleSelectValuesFromChildren() {
    let { filters } = this.state;
    const { defaultDimensions } = this.state, state = this;
    filters = filters.map(filter => {
      const defaultDimension = defaultDimensions.find(
        d => d.name === filter.name,
      ),
        selectedValues = [];
      filter = {
        ...filter,
        rollupInfo: [],
      };
      filter.values = [];
      Object.keys(state).forEach(key => {
        if (
          key.indexOf(`${defaultDimension.name}--selectedValueName-->`) === 0 &&
          state[key] &&
          state[key].state &&
          state[key].state.checked
        ) {
          selectedValues.push(key);
        }
      });
      const rollupDefinitions = defaultDimension.values.filter(
        rd => !!rd.rollupDefinitionId,
      );
      if (rollupDefinitions.length) {
        filter.values.forEach(v => {
          const found = rollupDefinitions.find(rd => rd.value === v);
          if (found) {
            filter.rollupInfo.push(found);
          }
        });
      }
      selectedValues.forEach(sv => {
        const value = defaultDimension.values.find(
          v =>
            v.value ===
            sv.slice(sv.lastIndexOf('>') + 1, sv.lastIndexOf('<--')),
        );
        if (!value.rollupDefinitionId) {
          filter.values.push(value.value);
        } else {
          filter.rollupInfo.push(value);
        }
      });
      return filter;
    });
    this.setState({ filters });
    return filters;
  }

  validate(filters) {
    const queryExpDate = this.state.useHoldingQueryExpDate ? this.state.holdingQueryExpDate : this.state.queryExpDate;
    const nameError = this.getNameValidationError(false),
      foundSpecificFilterWithNoSelectedValues = filters.find(f => f.values.length === 0);
    if (nameError) {
      notifyError(`Query name error: ${nameError}`);
      return false;
    }

    if (!queryExpDate) {
      notifyError('You must select an exposure date.');
      return false;
    }
  
    if (foundSpecificFilterWithNoSelectedValues) {
      notifyError(`Query Dimension error: ${foundSpecificFilterWithNoSelectedValues.name} requires selection if "specific" is selected`);
      return false;
    }

    return true;
  }

  saveQuery() {
    const {
      splits,
      isEditing,
    } = this.state,
      queryName = this.state.useHoldingQueryName ? this.state.holdingQueryName : this.state.queryName,
      queryExpDate = this.state.useHoldingQueryExpDate ? this.state.holdingQueryExpDate : this.state.queryExpDate,
      queryDesc = this.state.useHoldingQueryDescription ? this.state.holdingQueryDesc : this.state.queryDesc;
    const {
      userKey,
      newSaveHandler,
      updateSaveHandler,
      currentDb,
      currentQuery,
    } = this.props;
    const filters = this.handleSelectValuesFromChildren();
    if (!this.validate(filters)) {
      this.setState({ softErrorMode: false });
      return;
    }

    const columns = this.handleFilters(filters, 'saveQuery');

    splits.forEach(split => {
      if (!columns.find(c => c.name === split)) {
        columns.push({
          name: split,
          // split: true,
          split: false,
          filterBy: [],
        });
      }
    });

    this.setState({ dirty: false });

    if (isEditing && currentQuery) {
      updateSaveHandler({
        userKey,
        queryId: currentQuery.queryId,
        databaseId: currentDb.id,
        queryName,
        queryDescription: queryDesc,
        columns,
        defaultDateY: queryExpDate,
      });
    } else {
      newSaveHandler({
        userKey,
        databaseId: currentDb.id,
        queryName,
        queryDescription: queryDesc,
        columns,
        defaultDateY: queryExpDate,
      });
    }
  }

  toggleLegend() {
    this.setState({ showLegend: !this.state.showLegend });
  }

  openCreateEditRollupDefinitions(dimensionName) {
    const { currentDb, dispatch, browserHistory, } = this.props,
      { dirty } = this.state,
      queryName = this.state.useHoldingQueryName ? this.state.holdingQueryName : this.state.queryName,
      deleteMessageItems = [],
      nav = () =>
        browserHistory.push(
          `/tod/queries/rollupDefinitionsList/${currentDb.id}/${dimensionName}`,
        );

    if (dirty) {
      deleteMessageItems.unshift(
        'You have unsaved changes.  Navigating away from this page will cause you to lose any unsaved data.  Do you wish to continue?',
      );
      const yesClickHandler = () => {
          nav();
        },
        noClickHandler = () => {},
        action = showModal(
          'confirmation',
          deleteMessageItems,
          yesClickHandler,
          noClickHandler,
        );
      dispatch(action);
    } else {
      dispatch(
        storeQuery({
          databaseId: currentDb.id,
          queryName,
        }),
      );
      nav();
    }
  }

  updateQueryName(queryName) {
    this.setState({ holdingQueryName: queryName, useHoldingQueryName: true });
  }

  updateQueryDescription(queryDesc) {
    this.setState({ holdingQueryDesc: queryDesc, useHoldingQueryDescription: true });
  }

  updateExpDate(queryExpDate) {
    this.setState({ holdingQueryExpDate: queryExpDate, useHoldingQueryExpDate: true });
  }

  prioritzeRollups(dimensions) {
    dimensions.forEach(d => {
      const sortedRollups = alphabetize(
        d.values.filter(v => v.rollupDefinitionId !== null),
      ),
        remainingDimensionValues = d.values.filter(
          v => v.rollupDefinitionId === null,
        );
      d.values = [...sortedRollups, ...remainingDimensionValues];
    });
    return dimensions;
  }

  modString(string) {
    return string;
    // return string.indexOf('@') === 0 ? string.substring(1) : string;
  }

  render() {
    const {
      isDataSourceFetching,
      dataSource,
      segmentPreview,
      isFetching,
      errorMessage,
      verifyPermission,
      browserHistory,
      currentDb
    } = this.props;
    const {
      databaseExposureDates,
      filters,
      dimensionHeaders,
      isEditing,
      softErrorMode,
      showLegend,
      dirty,
      automaticUpdateEnabled,
      queryDesc,
      queryName,
    } = this.state;
    const defaultDimensions = this.prioritzeRollups(
      this.state.defaultDimensions,
    );
    const isWritable = verifyPermission(DATA_MANAGEQUERIES),
      editable = true;

    let queryBuilderMarkup = <div />;
    let showLegendMarkup = <div />;
    let inputStyle = {};
    if (!editable) {
      inputStyle = {
        pointerEvents: 'none',
      };
    }

    if (showLegend) {
      showLegendMarkup = (
        <Row>
            <Card className="offset-md-3 col-md-6 bg-whiteSmoke">
              <Card.Body>
                  <h5 className="text-center"><u>Legend for column values</u></h5>
                  <dl className="row spacer small">
                    <dt className="col-sm-3">
                      <div className="valueSample">
                        <span className="bold">Auto</span>
                      </div>
                    </dt>
                    <dd className="col-sm-9">
                      <span className="bold">Bold</span>
                      {' '}
                      indicates value will contribute to query and will contribute to resulting segments
                    </dd>
                    <dt className="col-sm-3">
                      <div className="valueSample">
                        <span className="excludedValue">Property</span>
                      </div>
                    </dt>
                    <dd className="col-sm-9">
                      <span className="excludedValue">StrikeOut&nbsp;</span>
                      indicates value will not contribute to the query because no data exists for resulting segments
                    </dd>
                    <dt className="col-sm-3">
                      <div className="valueSample">
                        <span className="availableValue">Liability</span>
                      </div>
                    </dt>
                    <dd className="col-sm-9">
                      <span className="availableValue">Italics&nbsp;</span>
                      indicates value is not selected and will not contribute to query even though there are values in the database
                    </dd>
                  </dl>
              </Card.Body>
            </Card>
        </Row>
      );
    }

    if (dataSource && defaultDimensions.length > 0) {
      queryBuilderMarkup = (
        <div style={{ marginBottom: 150 }}>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div>
              <div style={{ display: 'flex' }}>
                This configuration will produce
                <span onClick={() => this.getSegmentPreview()}>
                  <OverlayTrigger
                    placement="left"
                    focus
                    overlay={
                      <Tooltip id="previewSegmentsTooltip" style={GlobalStyles.toolTipFix}>
                        Preview Segments
                      </Tooltip>
                    }
                  >
                    <span
                      style={{
                          textDecoration: 'underline',
                          fontWeight: 'bold',
                          marginLeft: 5,
                          cursor: 'pointer',
                        }}
                    >
                      {dataSource.splitCount} segments.
                    </span>
                  </OverlayTrigger>
                </span>
                {
                editable
                  ? <div style={style.reCalcRow}>
                <div style={{ display: 'flex', margin: '0 0 0 20px' }}>
                  <div>
                    {isDataSourceFetching && dirty
                      ? <i
                        className="fa fa-cog fa-spin fa-fw"
                        style={{
                          fontSize: '2.5vmin',
                          cursor: 'pointer',
                          color: Colors.purple,
                        }}
                        />
                      : 
                      <Button 
                        iconName='fa-cog' 
                        style={{ fontSize: '2.5vmin'}}
                        onClick={() => this.updateQueryData()}
                        toolTip='Refresh Configuration'/>
                      }
                  </div>
                </div>
              </div>
                  : <span></span>
              }
              </div>
            </div>
            <div style={{ float: 'left' }}>
              {(dataSource.isSegmentLimitReached)
                ? <span style={{ color: Colors.red, fontWeight: 'bold', fontSize: '15px' }}>Segment limit reached! (Capped to {dataSource.splitCount}).</span>
                : ''}
            </div>
            <div style={{ float: 'right' }}>
              {
                editable
                  ? <div style={style.reCalcRow}>
                <div style={{ display: 'flex' }}>
                  {!automaticUpdateEnabled
                    ? <OverlayTrigger
                      placement="left"
                      overlay={
                          <Tooltip id={'Enable Automatic Update'} style={GlobalStyles.toolTipFix}>
                            Automatic Refresh Disabled
                          </Tooltip>
                        }
                      >
                        <i
                          className="fa fa-toggle-off"
                          aria-hidden="true"
                          style={{
                            fontSize: '2.5vmin',
                            cursor: 'pointer',
                          }}
                          onClick={() => this.toggleAutomaticUpdateEnabled()}
                        />
                      </OverlayTrigger>
                    : <OverlayTrigger
                      placement="left"
                      overlay={
                          <Tooltip id={'Disable Automatic Update'} style={GlobalStyles.toolTipFix}>
                            Automatic Refresh Enabled
                          </Tooltip>
                        }
                      >
                        <i
                          className="fa fa-toggle-on"
                          aria-hidden="true"
                          style={{
                            fontSize: '2.5vmin',
                            cursor: 'pointer',
                          }}
                          onClick={() => this.toggleAutomaticUpdateEnabled()}
                        />
                      </OverlayTrigger>}
                  <div
                    style={{
                      cursor: 'pointer',
                      margin: '0 10px',
                    }}
                    onClick={() => this.toggleAutomaticUpdateEnabled()}
                  >
                    Auto refresh
                  </div>
                </div>
                <span>
                  <i
                    className="fa fa-question-circle"
                    style={{
                      fontSize: '4.5vmin',
                      color: Colors.purple,
                      cursor: 'pointer',
                    }}
                    onClick={this.toggleLegend}
                  />
                </span>
              </div>
                  : <span><i
                    className="fa fa-question-circle"
                    style={{
                      fontSize: '4.5vmin',
                      color: Colors.purple,
                      cursor: 'pointer',
                    }}
                    onClick={this.toggleLegend}
              /></span>
              }
              <span>
              </span>
            </div>
          </div>
          {showLegendMarkup}
          <div id="dimension-container" style={style.dimensionContainer}>
            {dataSource.values.map((dimension, idx) => {
              const filter = filters.find(f => f.name === dimension.name) ||
                false;
              const defaultDimension = defaultDimensions.find(
                d => d.name === dimension.name,
              );
              const provision = dataSource.provisional.find(
                d => d.name === dimension.name,
              );
              
              const dimensionNameStyle = (dimension.name || {}).length < 40 ? { whiteSpace: 'nowrap' } : {};
              const allChecked = filter && filter.values && filter.values.length === defaultDimension.values.length;

                return (
                <div
                  key={`dimension-${dimension.name}-${idx}`}
                  style={{
                    ...GlobalStyles.card,
                    backgroundColor: Colors.grey, display: 'flex', flexFlow: 'column nowrap',
                    ...style.cardShadow,
                  }}
                > 
                  <div>
                    <div style={style.titleRow}>
                      <h4 style={dimensionNameStyle}>{dimension.name}</h4>
                    </div>
                    <div>
                      {this.state.dimensionsWithLimitsReached.indexOf(dimension.name) !== -1
                        ? <span style={{color: Colors.red, fontWeight: 'bold', fontSize: '12px'}}>
                          Limit of { dataSource.maxDimensionValues } has been reached
                        </span>
                        : '' }
                    </div>
                    <div className='form-check-container' style={{ display: 'flex', marginBottom: '10px' }}>
                      <div style={{ whiteSpace: 'nowrap' }}>
                        <Form.Check 
                            id={`dimension-${dimension.name}-${idx}ALL`}
                            type="radio"
                            checked={!filter}
                            onChange={() => this.handleSelectAll(dimension)}
                            inline
                            label="All(*)" />
                      </div>
                      <div style={{ whiteSpace: 'nowrap' }}>
                      <Form.Check type="radio"
                            id={`dimension-${dimension.name}-${idx}SPECIFIC`}
                            checked={filter}
                            onChange={() => this.handleSelectSpecific(dimension)}
                            inline
                            label="Specific" />
                      </div>
                    </div>
                  </div>
                  <div
                    style={{
                      overflowY: 'scroll',
                      backgroundColor: 'white',
                      maxHeight: '60vh',
                      height: '100%'
                    }}
                  >{filter ?
                    <Form.Check 
                        style={{marginLeft: '10px', marginTop: '10px'}}
                        key={`selectAllValuesSelection-${dimension.name}`}
                        label='(Select All)'
                        checked={allChecked}
                        onChange={() => this.selectAllDimensionValues(filter.name, defaultDimension)}
                    />
                    : ''}
                    {defaultDimension.values.map((val, index) => {
                      let checkClick = () => this.handleSelectValue(
                        filter.name,
                        val,
                        defaultDimension,
                        true,
                      ); 
                      const hasValue = !!dimension.values.find(
                        v => v.value === val.value,
                      );
                      let itemStyle = hasValue
                        ? { fontWeight: 'bold' }
                        : { textDecoration: 'line-through' };
                      if (
                        provision &&
                        provision.values.find(v => v.value === val.value)
                      ) {
                        itemStyle = { fontStyle: 'italic' };
                      }
                      if (val.value && val.value.length < 40) {
                        itemStyle.whiteSpace = 'noWrap';
                      }
                      
                      if (automaticUpdateEnabled) {
                        checkClick = () => this.handleSelectValue(
                          filter.name,
                          val,
                          defaultDimension,
                        );                         
                      }
                      
                      return (
                        <div
                          key={`${dimension.name}-${val.value}-${index}`}
                          style={{...itemStyle, ...style.checkboxRow}}
                        >
                          {filter
                            ? <CustomCheckbox
                              ref={name => {
                                this[
                                    `${dimension.name}--selectedValueName-->${val.value}<--${index}`
                                  ] = name;
                              }}
                              checked={
                                  !!filter.values.find(v => v === val.value) ||
                                    false
                                }
                              onClick={
                                  checkClick
                                }
                              inputStyle={inputStyle}
                              />
                            : <span />}
                          <div style={style.checkboxValue}>
                            {val.value}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      );
    }

    var form = currentDb && currentDb.useADX ? <AdxQueryWarning/> : (
             !queryName && !dirty && isEditing
            ? <Loader loadingItem="Query Info" />
            : <div>
                <QueryTopLevelForm
                isWritable={editable}
                databaseExposureDates={databaseExposureDates}
                queryDesc={queryDesc}
                queryName={queryName}
                queryExpDate={this.state.queryExpDate}
                getNameValidationError={this.getNameValidationError}
                updateQueryName={this.updateQueryName}
                updateQueryDescription={this.updateQueryDescription}
                updateExpDate={this.updateExpDate}
                softErrorMode={softErrorMode}
                />
                {isDataSourceFetching && !dirty
                ? <Loader loadingItem="Columns" />
                : <div />}
                {queryBuilderMarkup}
                
                <SegmentPreviewModal
                modalId="segmentPreviewModal"
                isFetching={isFetching}
                dimensions={dimensionHeaders}
                segmentPreview={segmentPreview}
                showModal={this.state.showSegmentPreviewModal}
                closeHandler={this.closeSegmentPreviewModal}
                />
            </div>);

    return (
      <div style={{ width: '100vw', padding: '0vh 10vw', overflowY: 'auto', maxHeight: 'calc(100vh - 52px)' }}>
        <div
          style={{
            borderBottom: '2px solid #BDBDBD',
            margin: 'initial',
            height: 75,
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          {isEditing
            ? <h2 style={{ margin: 'initial', paddingLeft: 15 }}>Edit Query</h2>
            : <h2 style={{ margin: 'initial', paddingLeft: 15 }}>
                Add New Query
              </h2>}
        </div>

        {form}
        <SaveButtons
                saveHandler={this.saveQuery}
                isSaveButtonDisabled={
                    !isWritable ||
                    (errorMessage && errorMessage.indexOf('No data') !== -1)
                }
                backButtonHander={() =>
                    browserHistory.push('/tod/queries')}
                backButtonText="Back to Queries"
                />
      </div>
    );
  }
}

export default CreateQuery;