import React from 'react';
import PropTypes from 'prop-types';
import * as GlobalStyles from '../../../utilities/globalStyles';
import * as Colors from '../../../utilities/colors';
import { Form } from 'react-bootstrap';

const style = {
  card: {
    boxShadow: '0 3px 6px rgba(0,0,0,0.12), 0 3px 6px rgba(0,0,0,0.24)',
    transition: 'all 0.3s cubic-bezier(.25,.8,.25,1)',
    height: '45vh',
    overflowY: 'scroll',
    backgroundColor: 'white',
    margin: 'auto',
    padding: '10px',
  },
  table: {
    paddingTop: '10px',
    paddingLeft: '15px',
    paddingRight: '15px',
    paddingBottom: '10px',
    backgroundColor: 'white',
    overflow: 'scroll',
  },
  tableCell: {
    border: '1px solid black',
    padding: '10px',
  },
  tableHeader: {
    fontWeight: 'bold',
    textAlign: 'center',
  },
  checkboxRow: {
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'center',
    margin: '1px',
    cursor: 'pointer',
  },
  checkboxValue: {
    width: '100%',
    paddingLeft: '10px',
    whiteSpace: 'nowrap',
  },
  checkboxValueCustom: {
    width: '100%',
    paddingLeft: '10px',
    whiteSpace: 'nowrap',
  },
  checkboxInput: {
    padding: '20px',
    marginRight: '5px',
    marginTop: '0px',
  },
};

const TotalsSelections = ({
  filters,
  columns,
  rows,
  measures,
  selectedFilter,
  tableMarkup,
  dataSource,
  addRemoveRow,
  addRemoveColumn,
  selectAllMeasures,
  addRemoveMeasure,
  selectAllForSelectedFilter,
  addRemoveFilter,
  selectFilter,
}) => (
  <div>
    <div
      style={{...GlobalStyles.card, display: 'flex', overflowX: 'scroll', margin: '20px 0 0 0' }}
    >
      <div style={{...GlobalStyles.card, backgroundColor: Colors.grey }}>
        <h4 style={{ whiteSpace: 'nowrap' }}>Dimensions (Row)</h4>
        <div style={style.card}>
          {dataSource.classFields.map(dim => (
            <div
              onClick={e => addRemoveRow(dim, e)}
              style={style.checkboxRow}
              key={`row-${dim}`}
            >
              <input
                type="checkbox"
                checked={rows.includes(dim)}
                onChange={() => {}}
                style={style.checkboxInput}
              />
              <div style={style.checkboxValue}>
                {dim}
              </div>
            </div>
          ))}
        </div>
      </div>
      <div style={{...GlobalStyles.card, backgroundColor: Colors.grey }}>
        <h4 style={{ whiteSpace: 'nowrap' }}>Dimensions (Column)</h4>
        <div style={style.card}>
          {dataSource.classFields.map(dim => (
            <div
              onClick={e => addRemoveColumn(dim, e)}
              style={style.checkboxRow}
              key={`column-${dim}`}
            >
              <input
                type="checkbox"
                checked={columns.includes(dim)}
                onChange={() => {}}
                style={style.checkboxInput}
              />
              <div style={style.checkboxValue}>
                {dim}
              </div>
            </div>
          ))}
        </div>
      </div>
      <div style={{...GlobalStyles.card, backgroundColor: Colors.grey }}>
        <h4 style={{ whiteSpace: 'nowrap' }}>Measures (Column)</h4>
        <div style={style.card}>
          <div onClick={selectAllMeasures} style={style.checkboxRow}>
            <input
              type="checkbox"
              checked={measures.length === dataSource.dataFields.length}
              onChange={() => {}}
              style={style.checkboxInput}
            />
            <div style={style.checkboxValue}>
              (All)
            </div>
          </div>
          {dataSource.dataFields.map(measure => (
            <div
              style={style.checkboxRow}
              key={`measure-${measure}`}
              onClick={e => addRemoveMeasure(measure, e)}
            >
              <input
                type="checkbox"
                checked={measures.includes(measure)}
                onChange={() => {}}
                style={style.checkboxInput}
              />
              <div style={style.checkboxValue}>
                {measure}
              </div>
            </div>
          ))}
        </div>
      </div>
      <div style={{...GlobalStyles.card, backgroundColor: Colors.grey }}>
        <div style={{ display: 'flex' }}>
          Filter By:
          {selectedFilter ?
          <Form.Select
            placeholder="select"
            value={selectedFilter}
            onChange={selectFilter}
            style={{ width: 'initial' }}
          >
            {filters.map(filter => (
              <option key={`${filter.name}-filter`} value={filter.name}>
                {filter.name}
              </option>
            ))}
          </Form.Select>
          : <span />
          }
        </div>
        <div style={style.card}>
          {(() => {
            const filter = filters.find(f => f.name === selectedFilter);
            if (filter) {
              const allFilterChecked =
                filter.available.length === filter.values.length;
              return (
                <div>
                  <div
                    onClick={e =>
                      selectAllForSelectedFilter(allFilterChecked, e)}
                    style={style.checkboxRow}
                  >
                    <input
                      type="checkbox"
                      checked={allFilterChecked}
                      onChange={() => {}}
                      style={style.checkboxInput}
                    />
                    <div style={style.checkboxValueCustom}>
                      (All)
                    </div>
                  </div>
                  {filter.available.map(val => (
                    <div
                      onClick={e => addRemoveFilter(val, e)}
                      style={style.checkboxRow}
                      key={`filter-${val}`}
                    >
                      <input
                        type="checkbox"
                        checked={filter.values.includes(val)}
                        onChange={() => {}}
                        style={style.checkboxInput}
                      />
                      <div style={style.checkboxValueCustom}>
                        {val}
                      </div>
                    </div>
                  ))}
                </div>
              );
            } else {
              return '';
            }
          })()}
        </div>
      </div>
    </div>
    {tableMarkup}
  </div>
);

TotalsSelections.propTypes = {
  filters: PropTypes.array,
  columns: PropTypes.array,
  rows: PropTypes.array,
  measures: PropTypes.array,
  selectedFilter: PropTypes.string,
  tableMarkup: PropTypes.func,
  dataSource: PropTypes.object,
  addRemoveRow: PropTypes.func,
  addRemoveColumn: PropTypes.func,
  selectAllMeasures: PropTypes.func,
  addRemoveMeasure: PropTypes.func,
  selectAllForSelectedFilter: PropTypes.func,
  addRemoveFilter: PropTypes.func,
  selectFilter: PropTypes.func,
};

export default TotalsSelections;
