import React, { Component } from 'react';
import { MultiSelectComponent,CheckBoxSelection, Inject  } from '@syncfusion/ej2-react-dropdowns';

export const universalFilter = (arrayToFilter, filterValues) => {
    return arrayToFilter.filter((r) => {
        let match = true;

        const fields = Object.keys(filterValues);
        for (const key of fields) {
            if (!match) break;
            let value = r[key];
            let filterVal = filterValues[key];
            
            if (key === 'categories'){
                if (!filterVal || !filterVal.length){
                    match = true;
                } else {
                    let categoryIds = r.categories.map(c=> c.categoryId);
                    let intersection = categoryIds.filter(i => filterVal.includes(i));
                    match = intersection.length > 0;
                }
            } else {
                if (typeof value === 'number') {
                    value = String(value);
                }
                filterVal = filterVal ? filterVal.toLowerCase() : '';
                value = value ? value.toLowerCase() : '';
                match = !filterVal || value.includes(filterVal);
            }
        }
        return match;
    })
};

class ProjectsFilter extends Component {
    static propTypes = {};
    constructor(props) {
        super(props);
        this.state = {
            categories: []
        };
        this.filterInputs = this.filterInputs.bind(this);
        this.getInputControl = this.getInputControl.bind(this);
        this.itemTemplate = this.itemTemplate.bind(this);
    }

    componentDidMount(){
        if (this.props.categories){
            this.setState({categories: this.props.categories});
        }
    }

    shouldComponentUpdate(nextProps, nextState){
        let filterChanged = nextProps.filter !== this.props.filter;
        let catsChanged = nextState.categories !== this.state.categories;
        return filterChanged || catsChanged;
    }

    UNSAFE_componentWillReceiveProps(newProps){
        if (newProps.categories !== this.props.categories){
            this.setState({categories: newProps.categories});
        }
    }

    get filterDetailsConfig() { return {
        projectId: {
            displayName: 'Project ID',
            placeholder: 'enter project id',
        },
        checkedOutUser: {
            displayName: 'Checkout Out By',
            placeholder: 'enter name',
        },
        projectName: {
            displayName: 'Project Name',
            placeholder: 'enter project name',
        },
        checkedOutTimestamp: {
            displayName: 'Checked Out Date',
            placeholder: 'enter date',
        },
        valuationDate: {
            displayName: 'Valuation Date',
            placeholder: 'enter valuation date',
        },
        updatedBy: {
            displayName: 'Last Updated By',
            placeholder: 'enter name',
        },
        dataShape: {
            displayName: 'Data Structure',
            placeholder: 'enter data structure',
        },
        updatedDate: {
            displayName: 'Last Updated Date',
            placeholder: 'enter date',
        },
        categories: {
            displayName: 'Project Categories',
            placeholder: 'filter by categories'
        },
        status: {
            displayName: 'Status',
            placeholder: 'enter status',
        },
    }
    };

    filterInputs(e, field) {
        const input = e || '';
        const { filter, arrayToFilter, setParentState } = this.props;
        let newState = {};
        newState.filter = { ...filter };
        newState.filter[field] = input;
        const filteredMatches = universalFilter(arrayToFilter, newState.filter);
        
        const filterValues = Object.values(newState.filter);
        const foundFilterInput = filterValues.find(fv => fv.length);
        newState.useFilteredMatches = !!foundFilterInput || false;
        newState.filteredMatches = filteredMatches;
        newState.noFilteredMatches = !filteredMatches.length || false;
        setParentState(newState);
    }

    getFilter(key, i, placeholder, displayName, value)
    {
        if (key) {
            return ([
                <label htmlFor={`filter-field-${i}`} key={`filter-field-${i}`} 
                    style={{textAlign: 'right', fontWeight: 'bold', marginBottom: 6}}
                    className="col-sm-2 col-form-label">
                    {displayName}
                </label>,
                <div className="col-sm-4" key={`filter-field-div${i}`}>
                    {this.getInputControl(key, i, placeholder, displayName, value)}
                </div>
            ]);
        }
        else {
            return <div key={`filter-field-${i}`} className="col-sm-6"></div>
        }
    }

    getInputControl(key, i, placeholder, displayName, value){
        if (displayName === 'Project Categories') {
            const { categories } = this.props;
            // if (!categories || !categories.length){
            //     //console.error('using fake picker');
            //     return <span></span> // for some reason, wait until you have the categories to render the picker
            // }
            return  <MultiSelectComponent
                dataSource={categories}
                change={(e) => this.filterInputs(e.value, key)} 
                fields= {{ text: 'name', value: 'projectCategoryId' }}
                placeholder={placeholder} 
                value={value}
                mode="CheckBox" 
                showSelectAll={true}
                showDropDownIcon={true} 
                filterBarPlaceholder="Search categories" 
                itemTemplate={this.itemTemplate}
                delayUpdate={true}
                //popupHeight="350px"
                //width='360'
                height='38'
                ref={(ref) => this.dref = ref}
                >
                <Inject services={[CheckBoxSelection]} />
            </MultiSelectComponent>


        } else {
            return <input id={`filter-field-${i}`} type="text"
                className="form-control"
                placeholder={placeholder}
                onChange={(e) => this.filterInputs(e.target.value, key)} 
            />
        }
    }

    itemTemplate(c) {
        return <div className="ms-ename">
            <span style={{backgroundColor:`${c.color}`, marginRight: 5,
                borderRadius: 10, marginTop: 5, fontSize: '10px'}}>&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
            {c.name}
        </div>;
    }

    render() {
        const { filter } = this.props;
        let filterDetailsConfig = this.filterDetailsConfig;
        let searchFilterJSX = (
                <div className="row" style={{marginTop: '20px', marginBottom: '20px'}}>
                {
                    Object.keys(filterDetailsConfig).map((key, i) => {
                        let displayName = filterDetailsConfig[key].displayName;
                        let placeholder = filterDetailsConfig[key].placeholder;
                        let value = filter[key];
                        return this.getFilter(key, i, placeholder, displayName, value);
                    })
                }
                </div>
        )
        return (
        <div className='container'>
            {searchFilterJSX}
        </div>
        );
    }
}

export default ProjectsFilter;
