import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from '@app/utilities/routing';
import { ContentRoutes } from '@app/routes';
import { fetchCurrentUser } from '../actions/user.actions';
import { fetchApiVersion, fetchAriusApplicationVersion, fetchAeReleaseVersion, fetchIntegrationSettings } from '../actions/application.actions';
import { toggleSettingsMenu } from '../actions/settings.actions';
import ConfirmationModal from './shared/presentational/confirmationModal';
import * as Colors from '../utilities/colors';
import Loader from './shared/presentational/loader';
import Settings from './settings/containers/settings';
import Navbar from '@app/shared/presentational/app/navbar';

const style = {
  settingsBox: {
    position: 'absolute',
    right: 'calc(50vw - 400px)',
    top: 'calc(50vh - 300px)',
    zIndex: 1000,
  },
};

class App extends Component {
    static propTypes = {
        dispatch: PropTypes.func.isRequired,
        modal: PropTypes.object,
        user: PropTypes.object,
        content: PropTypes.object,
        sidebar: PropTypes.object,
        nav: PropTypes.object,
        currentApplication: PropTypes.string,
        apiVersion: PropTypes.string,
        ariusVersion: PropTypes.string,
        lockDataFromTOD: PropTypes.bool,
        currentTodDbVersion: PropTypes.number,
        currentAEDbScriptVersion: PropTypes.number,
        aeReleaseVersion: PropTypes.string,
        settingsMenuOpen: PropTypes.bool,
    };
    constructor(props) {
        super(props);
        this.state = {
        activeDrags: 0,
        };
        this.showSettings = this.showSettings.bind(this);
        this.onStart = this.onStart.bind(this);
        this.onStop = this.onStop.bind(this);
        this.stopProp = this.stopProp.bind(this);
        this.closeMenuAndRemoveListener = this.closeMenuAndRemoveListener.bind(this);
    }

    UNSAFE_componentWillMount() {
        const { dispatch, user } = this.props;
        if (!user || user.isAuthorized === null) {
            dispatch(fetchApiVersion());
            dispatch(fetchIntegrationSettings());
            dispatch(fetchCurrentUser());
            dispatch(fetchAriusApplicationVersion());
            dispatch(fetchAeReleaseVersion());
        }
    }

    componentDidUpdate() {
        const { user, history } = this.props;
        
        if (user && !user.isFetching && user.isAuthorized === false) {
            history.push('/unauthorized');
            return;
        }
    }

    onStart() {
        this.setState({ activeDrags: this.state.activeDrags + 1 });
    }

    onStop() {
        this.setState({ activeDrags: this.state.activeDrags -1 });
    }

    closeMenuAndRemoveListener(e) {
        const { settingsMenuOpen } = this.props;
        if (!settingsMenuOpen) {
            if (document.getElementById('settings-parent')) {
                document.getElementById('settings-parent').removeEventListener('click', this.stopProp, true);
            }
            document.body.removeEventListener('click', this.closeMenuAndRemoveListener, false);
            return;
        }
        if (e.target.classList.contains('settings-menu-icon')) return;
        if (document.getElementById('settings-parent')) {
            document.getElementById('settings-parent').removeEventListener('click', this.stopProp, true);
        }
        document.body.removeEventListener('click', this.closeMenuAndRemoveListener, false);
        this.showSettings(null, 'close');
    }

    showSettings(e, optionalParam) {
        if (e) e.stopPropagation();
        const { dispatch, settingsMenuOpen } = this.props;
        if ((!settingsMenuOpen && !optionalParam) || optionalParam === 'open') {
            if (optionalParam) {
                dispatch(toggleSettingsMenu(optionalParam));
            } else {
                dispatch(toggleSettingsMenu());
            }
            setTimeout(() => {
                document.getElementById('settings-parent').addEventListener('click', this.stopProp, true);
                document.body.addEventListener('click', this.closeMenuAndRemoveListener, false);
            }, 15);
        } else if (e) {
                if (document.getElementById('settings-parent')) {
                    document.getElementById('settings-parent').removeEventListener('click', this.stopProp, true);
                }
                document.body.removeEventListener('click', this.closeMenuAndRemoveListener, false);
                if (optionalParam) dispatch(toggleSettingsMenu(optionalParam));
                else dispatch(toggleSettingsMenu());
        } else {
            if (optionalParam) dispatch(toggleSettingsMenu(optionalParam));
            else dispatch(toggleSettingsMenu());
        }
    }

    stopProp(e) {
        e.stopPropagation();
    }

    render() {
        const {
            modal,
            history,
            dispatch,
            user,
            apiVersion,
            ariusVersion,
            lockDataFromTOD,
            currentTodDbVersion,
            currentAEDbScriptVersion,
            aeReleaseVersion,
            settingsMenuOpen,
        } = this.props;

        let versionData = {
            user,
            apiVersion,
            ariusVersion,
            lockDataFromTOD,
            currentTodDbVersion,
            currentAEDbScriptVersion,
            aeReleaseVersion,
        };

        let settingsMenu;
        if (!user || !user.isAuthorized) {
            return null;
        }

        if (settingsMenuOpen) {
            settingsMenu = (
                <div id="settings-parent" style={style.settingsBox}>
                    <div>
                        <div className="box settingsTree">
                            <Settings />
                        </div>
                    </div>
                </div>
            );
        }

        return (
        <div className="body-child" style={{ minWidth: '100vw', maxWidth: '100vw' }}>
            <Navbar 
                showSettingsHandler={this.showSettings}
                history={history}
                versionData={versionData}
            />
            {settingsMenu}
            {user 
                        ? 
                        <ContentRoutes/>
                        : <div
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                            margin: '25vh',
                            color: Colors.purple,
                        }}
                        >
                        <Loader size={100} />
                        </div>}
            <ConfirmationModal
                text={modal.message}
                yesClickHandler={modal.yesClickHandler}
                noClickHandler={modal.noClickHandler}
                dispatch={dispatch}
                show={modal.modalType ? true : false}
            />
        </div>
        );
    }
}

const mapStateToProps = state => ({
    user: state.user,
    modal: state.modal,
    currentApplication: state.application.name,
    apiVersion: state.application.apiVersion,
    ariusVersion: state.application.ariusVersion,
    lockDataFromTOD: state.application.lockDataFromTOD,
    currentTodDbVersion: state.tod.databases.currentVersion,
    currentAEDbScriptVersion: state.analysis.workspaces.currentAEVersion,
    aeReleaseVersion: state.application.aeReleaseVersion,
    settingsMenuOpen: state.settingsMenu.settingsMenuOpen,
});

const mapDispatchToProps = dispatch => ({
    dispatch,
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
