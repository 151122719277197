import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'react-bootstrap';
import * as Colors from '@app/utilities/colors';
import * as api from '@app/api/projects.serviceApi';
import Loader from '@app/shared/presentational/loader';
import PlaceHolderMessage from '@app/shared/presentational/placeHolderMessage';
import { retrieveMeasureMappers } from '@app/actions/analysis/project.actions';

import { Grid, Button } from '@arius';
import { WORKSPACE_MANAGEMAPPERS, MESSAGE_NOT_AUTHORIZED_TO_ACCESS } from '@app/utilities/permissions';
import { notifyError } from '../../../utilities/notifier';

const DEFAULT_MODAL = {
    title: 'Delete Measure Mapper',
    message: '',
    mode: ''
};

class MeasureMappersList extends Component {
    static propTypes = {
        dispatch: PropTypes.func,
        userKey: PropTypes.string,
        uploads: PropTypes.array.isRequired,
        isSaving: PropTypes.bool,
        isFetching: PropTypes.bool,
        getMeasureMappers: PropTypes.func,
        deleteFile: PropTypes.func,
        uploadNewFile: PropTypes.func,
        errorMessage: PropTypes.string,
        clearError: PropTypes.func,
        uploadNewFile2: PropTypes.func,
        hasWriteAccess: PropTypes.bool,
    };
    static defaultProps = {
        uploads: [],
    };
    constructor(props) {
        super(props);
        this.state = {
            prevDbId: 0, 
            modal: DEFAULT_MODAL
        };
        this.getGrid = this.getGrid.bind(this);
        this.getActionItems = this.getActionItems.bind(this);
        this.promptToDelete = this.promptToDelete.bind(this);
    }

    componentDidMount() {
        this.refresh();
    }

    componentDidUpdate(prevProps) {
        const { currentWorkspace } = this.props;
        if (currentWorkspace && prevProps.currentWorkspace !== currentWorkspace) {
            this.refresh();
        }
    }

    refresh() {
        const { dispatch, userKey, currentWorkspace, isFetching } = this.props;
        if (currentWorkspace && !isFetching){
            dispatch(retrieveMeasureMappers(userKey, currentWorkspace.id));
            this.setState({prevDbId: currentWorkspace.id});
        }
    }

    promptToDelete(mapper, e) {
        const { userKey, currentWorkspace, verifyPermission } = this.props;
        let canDelete = verifyPermission(WORKSPACE_MANAGEMAPPERS);
        if (!canDelete){
            notifyError(MESSAGE_NOT_AUTHORIZED_TO_ACCESS);
            return;
        }
        this.setState({modal: {...DEFAULT_MODAL, mode: 'checking'}});
        api.retrieveBatchScriptsContainingMapper(userKey, currentWorkspace.id, mapper.mapperId).then((data)=> {
            let names = data ? data.map(x=> x.scriptName) : null;
            if (names && Array.isArray(names)){
                if (names.length > 0) {
                    this.setState({
                        modal: { ...DEFAULT_MODAL, mode: 'warn',
                            title: 'Delete Measure Mapper',
                            message: <div>
                            Cannot delete this Measure Mapper that is referenced in the following Batch Scripts:  
                            <ul>
                                {names.map(n => <li key={`li-${n}`} style={{ fontWeight: 800 }}>{n}</li>)}
                            </ul>
                        </div>
                        }
                    });
                } else {
                    this.setState({
                        modal: { ...DEFAULT_MODAL, mode: 'prompt',
                            title: 'Are you sure?',
                            message: <div>
                                Are you sure you want to delete the following mapper?  
                                <ul>
                                    <li style={{ fontWeight: 800 }}>{mapper.name}</li>
                                </ul>
                            </div>,
                            yesHandler: ()=> {
                                api.deleteMeasureMapper(userKey, currentWorkspace.id, mapper.mapperId).then(()=>{
                                    this.refresh();
                                })
                                this.setState({modal: {...DEFAULT_MODAL, mode: ''}});
                            }
                        }
                    });
                }
            } else {
                this.setState({modal: {...DEFAULT_MODAL, mode: 'fail'}})
            }
        })
    }

    getGrid() {
        const { measureMappers } = this.props;
        let data = measureMappers ? measureMappers : [];
        let columns = [
            { field: 'mapperId', headerText: 'Id', visible: false},
            { field: 'name', headerText: 'Name'},
            { field: 'description', headerText: 'Description'},
            { field: 'lastModifyBy', headerText: 'Last Modified By'},
            { field: 'lastModifyDate', headerText: 'Last Modified'},
            { field: 'mapperId', headerText: 'Actions', template: this.getActionItems},
        ];
        
        return <Grid 
            columns={columns}
            data={data}
            height='calc(100vh - 240px)' 
            allowPaging={false}/>
    }

    getActionItems(row) {
        return  (
            <Button 
                iconName="delete" 
                onClick={(e) => this.promptToDelete(row, e)}
                toolTip='Delete'/>
        )
    }

    getDialogModal() {
        const { modal } = this.state;
        let { mode, title, message, yesHandler } = modal;
        let show = mode !== '';
        let handleClose = () => this.setState({modal: {...modal, mode: ''}});
        let buttons = <Button variant="arius" onClick={handleClose}>Close</Button>;
        
        let containerStyle = { display: 'flex', flexDirection: 'column', justifyContent: 'center', margin: '10px' };
        let iconStyle = {fontSize: 50,display: 'flex', justifyContent: 'center'};
        let textStyle = {width: '100%',textAlign: 'center', marginTop: 10};

        if (mode === 'checking') {
            message = <span style={containerStyle}>
                <div><i style={{...iconStyle, color: Colors.purple}} className="fa fa-spinner fa-spin"></i></div>
                <div style={textStyle}>Checking dependencies...</div>
            </span>;
        }

        if (mode === 'fail') {
            message = <span style={containerStyle}>
                <div><i style={{...iconStyle, color: Colors.red}} className="fa fa-times"></i></div>
                <div style={textStyle}>Error checking dependencies</div>
            </span>;
        }

        if (mode === 'prompt') {
            buttons = [
                <Button variant="arius" key='y' onClick={yesHandler}>Yes</Button>,
                <Button variant="arius" key='n' onClick={handleClose}>No</Button>
            ];
        }

        return (
        <Modal show={show} dialogClassName="confirmModal" onHide={handleClose} style={{ fontSize: 'smaller' }}>
            <Modal.Header closeButton style={{ backgroundColor: Colors.blue, color: '#FFFFFF'}}>
                <Modal.Title><small>{title}</small></Modal.Title>
            </Modal.Header>
            <Modal.Body>{message}</Modal.Body>
            <Modal.Footer>{buttons}</Modal.Footer>
        </Modal>
        );
    }


    render() {
        const { currentWorkspace, isFetching } = this.props;
        const { prevDbId } = this.state;
        let body = <div></div>;

        if (isFetching) {
            body = (
                <div style={{ width: '85vw', margin: '5vh auto' }}>
                    <Loader loadingItem='Measure Mappers' />
                </div>
            );
        } else if (!currentWorkspace && !prevDbId) {
            body = (
                <div
                style={{ width: '85vw', margin: '5vh auto' }}>
                <PlaceHolderMessage message={'Please select a database'} />
                </div>
            );
        }

        return (
        <div className="list-container-arius">
            <div className="list-header-arius">
                <h4>Measure Mappers</h4>
            </div>
            {body}
            <div style={currentWorkspace && !isFetching ? {} : {display: 'none'}}>
                {this.getGrid()}
            </div>
            {this.getDialogModal()}
        </div>
        )
    }
}

export default MeasureMappersList;
