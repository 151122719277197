//import 'babel-polyfill';
// import '../../node_modules/syncfusion-javascript/Scripts/jsrender.min';
// import '../../node_modules/jquery/dist/jquery.min';
// import '../../node_modules/jquery-easing/jquery.easing.1.3';
// import '../../node_modules/syncfusion-javascript/Scripts/ej/web/ej.web.all.min.js';
// import '../../node_modules/syncfusion-javascript/Scripts/ej/common/ej.unobtrusive.min.js';
// import '../../node_modules/syncfusion-javascript/Scripts/ej/ej.widgets.all.min';
// import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/scss/bootstrap.scss';
import "@syncfusion/ej2-base/styles/bootstrap4.css";
import "@syncfusion/ej2-buttons/styles/bootstrap4.css";
import '@syncfusion/ej2-calendars/styles/bootstrap4.css';  
import '@syncfusion/ej2-dropdowns/styles/bootstrap4.css';  
import "@syncfusion/ej2-lists/styles/bootstrap4.css";
import "@syncfusion/ej2-inputs/styles/bootstrap4.css";
import '@syncfusion/ej2-navigations/styles/bootstrap4.css';
import "@syncfusion/ej2-popups/styles/bootstrap4.css";
import "@syncfusion/ej2-react-calendars/styles/bootstrap4.css";
import '@syncfusion/ej2-splitbuttons/styles/bootstrap4.css';
import '@syncfusion/ej2-react-grids/styles/bootstrap4.css';
import '@syncfusion/ej2-react-dropdowns/styles/bootstrap4.css';
import 'alertifyjs/build/css/alertify.min.css';
import 'alertifyjs/build/css/themes/default.min.css';
import 'font-awesome/css/font-awesome.min.css';
// import 'react-rangeslider/umd/rangeslider.min.css';

import '@/css/styles.scss';

import '@/css/material-icons.css';

import React from 'react';
import { createRoot } from 'react-dom/client';
import Root from './js/components/shared/containers/root';
import { processTokenResponse } from './js/utilities/adalAdapter';
import { store } from './js/store';
import moment from 'moment';

import { registerLicense } from '@syncfusion/ej2-base';

// for libraries that need jquery
import $ from 'jquery';
window.$ = window.jQuery = $;

if (window.location.protocol !== 'https:') {
  window.location.href = `https://${window.location.host}${window.location.pathname}`;
}


registerLicense('ORg4AjUWIQA/Gnt2VVhhQlFaclhJXGFWfVJpTGpQdk5xdV9DaVZUTWY/P1ZhSXxRdkNjWH9WcH1VRGJfVEA=')
// set moment locale
moment.locale(window.navigator.userLanguage || window.navigator.language);
// load Syncfusion libraries
const scripts = [
  //'https://maxcdn.bootstrapcdn.com/bootstrap/3.3.7/js/bootstrap.min.js',
  // 'https://cdn.syncfusion.com/js/assets/external/jquery.easing.1.3.min.js',
  // 'https://cdn.syncfusion.com/js/assets/external/jsrender.min.js',
  // 'https://cdn.syncfusion.com/14.2.0.26/js/web/ej.web.all.min.js',
];
scripts.forEach((script) => {
  const scriptEl = document.createElement('script');
  scriptEl.src = script;
  document.body.appendChild(scriptEl);
});

if (window.parent === window) {
    const container = document.getElementById('root');
    const root = createRoot(container); // createRoot(container!) if you use TypeScript
    root.render(<Root store={store} />);
} else {
  processTokenResponse();
}

