import { combineReducers } from 'redux';
import user from './user.reducer';
import modal from './modal.reducer';
import config from './config.reducer';
import application from './application.reducer';
import serverNotification from './serverNotifications.reducer';
import analysis from './analysis.reducer';
import analytics from './analytics.reducer';
import portalManagement from './portalManagement.reducer';
import tod from './tod.reducer';
import utils from './utils.reducer';
import settingsMenu from './settingsMenu.reducer';
import jobStatus from './jobStatus.reducer';

const rootReducer = combineReducers({
  user,
  modal,
  config,
  application,
  serverNotification,
  portalManagement,
  analysis,
  analytics,
  tod,
  utils,
  settingsMenu,
  jobStatus,
  settings: (state = null) => state,
});

export {
  rootReducer,
};
