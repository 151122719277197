import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
    Form,
    ListGroup,
    ListGroupItem,
} from 'react-bootstrap';
import { Grid, Button} from '@arius';
import { showModal } from '../../../actions/modal.actions';
import SaveButtons from '../../shared/presentational/saveButtons';
import { notifyError, alertMessage } from '../../../utilities/notifier';
import Loader from '../../shared/presentational/loader';
import NoResults from '../../shared/presentational/noResults';
import PlaceHolderMessage from '../../shared/presentational/placeHolderMessage';
import { DATA_MANAGEQUERIES } from '@app/utilities/permissions';
import AdxQueryWarning from '@app/shared/presentational/adxQueryWarning';

function alphabetize(list) {
    return list.sort((a, b) => {
        const dimensionA = a.rollupName
            .slice(1, a.rollupName.length - 1)
            .toUpperCase(),
            dimensionB = b.rollupName.slice(1, b.rollupName.length - 1).toUpperCase();
        if (dimensionA < dimensionB) {
            return -1;
        }
        if (dimensionA > dimensionB) {
            return 1;
        }
            return 0;
    });
}

function sortAllRollupDefinitions(rollupDefinitions, dimensions) {
    return dimensions.reduce((prev, curr) => {
        const definitions = prev,
            filteredAndOrdered = alphabetize(
                rollupDefinitions.filter(rd => rd.attributeName === curr)
            );
        return [...definitions, ...filteredAndOrdered];
    }, []);
}

class RollupDefinitionsList extends Component {
    static propTypes = {
        queries: PropTypes.array,
        dispatch: PropTypes.func,
        userKey: PropTypes.string,
        currentDb: PropTypes.object,
        isFetching: PropTypes.bool,
        params: PropTypes.object,
        deleteRollupDefinitionHandler: PropTypes.func,
        databases: PropTypes.array,
        changeDatabase: PropTypes.func,
        isProcessingNewRollupDefinition: PropTypes.bool,
        getAllRollupDefinitions: PropTypes.func,
        allRollupDefinitions: PropTypes.array,
        queriesUsingRollup: PropTypes.array,
        verifyPermission: PropTypes.func,
    };
    static defaultProps = {};
    constructor(props) {
        super(props);
        this.state = {};
        this.openCreateRollupDefinitions = this.openCreateRollupDefinitions.bind(this);
        this.backNav = this.backNav.bind(this);
        this.deleteRollupDefinition = this.deleteRollupDefinition.bind(this);
        this.changeDatabase = this.changeDatabase.bind(this);
        this.getGrid = this.getGrid.bind(this);
        this.getActionItems = this.getActionItems.bind(this);
        this.getDetailTemplate = this.getDetailTemplate.bind(this);
    }

    get dimensions() {
        const { dataSource } = this.props;
        return dataSource && dataSource.values ? dataSource.values.map(x=> x.name) : [];
    }

    componentDidMount() {
        const {
            currentDb,
            userKey,
            getAllRollupDefinitions,
            getQueryDataSource,
            isProcessingNewRollupDefinition,
        } = this.props;

        if (currentDb && !currentDb.useADX && !isProcessingNewRollupDefinition) {
            getAllRollupDefinitions(userKey, currentDb.id);
            getQueryDataSource(userKey, currentDb.id);
            this.setState({ currentDb, databaseId: currentDb.id });
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        const { queriesUsingRollup } = this.props,
        { queriesUsingRollup: nextQueriesUsingRollup } = nextProps;
        if (queriesUsingRollup !== nextQueriesUsingRollup && nextQueriesUsingRollup.length) {
            let queryNames = nextQueriesUsingRollup.map(x=> x.name).sort();
            queryNames = queryNames.map(name => 
                `<span>${name}</span>`
            );
            const displayString = queryNames.join('<br>');
            alertMessage('Cannot delete this rollup definition while in use by the following queries:', displayString);
        }
    }

    componentDidUpdate(prevProps, prevState) {
        const {currentDb, isFetching, getAllRollupDefinitions, getQueryDataSource,userKey} = this.props;
        if (currentDb  && !currentDb.useADX && prevProps.currentDb !== currentDb && !isFetching) {
            getAllRollupDefinitions(userKey, currentDb.id);
            getQueryDataSource(userKey, currentDb.id);
        }
    }

    openCreateRollupDefinitions() {
        const { currentDb, browserHistory, verifyPermission } = this.props;
        const hasPermissions = verifyPermission(DATA_MANAGEQUERIES);

        if (!hasPermissions){
            notifyError('You are not authorized to access this functionality');
            return;
        }

        if (!currentDb) {
            notifyError('You must first select a database');
        } else {
            browserHistory.push(`/tod/queries/${currentDb.id}/rollups/new`);
        }
    }

    backNav() {
        this.props.browserHistory.push(`/tod/queries`);
    }

    changeDatabase(e) {
        const {
            databases,
            changeDatabase,
            browserHistory
        } = this.props,
        database = databases.find(d => d.workspaceId.toString() === e.target.value);
        changeDatabase(database);
        browserHistory.push(`/tod/queries/${database.workspaceId}/rollups`);
    }


    deleteRollupDefinition(e, rollupDefinitionId, rollupName) {
        e.stopPropagation();
        this.setState({ dataSourceReceived: true });
        const { userKey, dispatch, deleteRollupDefinitionHandler, currentDb } = this.props;

        const deleteMessageItems = [
            <li key={`${rollupName}-delete`} style={{ fontWeight: 800 }}>
                {rollupName}
            </li>,
        ];

        deleteMessageItems.unshift(
            'Are you sure you want to delete the following rollup definition?'
        );

        const yesClickHandler = () => {
            deleteRollupDefinitionHandler({
                userKey,
                factDatabaseId: currentDb.id,
                rollupDefinitionId,
            });
        },
        noClickHandler = () => {},
        action = showModal(
            'confirmation',
            deleteMessageItems,
            yesClickHandler,
            noClickHandler
        );
        dispatch(action);
    }

    getGrid() {
        const { allRollupDefinitions } = this.props;
        let data = sortAllRollupDefinitions(allRollupDefinitions, this.dimensions);
        let columns = [
            { field: 'attributeName', headerText: 'Dimension'},
            { field: 'rollupName', headerText: 'Name'},
            { field: 'rollupDescription', headerText: 'Description'},
            { field: 'auditUser', headerText: 'Last Modified By'},
            { field: 'auditDatetime', headerText: 'Last Modified'},
            { field: 'rollupDefinitionId', headerText: 'Actions', template: this.getActionItems}
        ];
        return <Grid columns={columns} data={data} height='calc(100vh - 330px)' 
            detailTemplate={this.getDetailTemplate}/>
    }

    getDetailTemplate(r) {
        let rData = [];
        if (r.rollupData) {
            rData = JSON.parse(r.rollupData);
        }
        if (!rData.length) {
            rData.push('All');
        }
        return (
        <small>
            <div>
                <span style={{ fontWeight: 'bold' }}>Description: </span>
                {r.rollupDescription}
            </div>
            <ListGroup style={{border: '0px'}}>
                {rData.map((data, i) => (
                <ListGroupItem style={{ border: '0px', padding: '2px 20px 2px 20px' }} key={`${data}-${i}`}>
                    {data}
                </ListGroupItem>
                ))}
            </ListGroup>
        </small>
        )
    }

    getActionItems(r) {
        const { currentDb, verifyPermission, browserHistory } = this.props;
        const hasPermissions = verifyPermission(DATA_MANAGEQUERIES);
        const hasWriteAccess = currentDb && hasPermissions;

        return (
            <span>
                <Button
                    id={`edit-${r.queryId}`}
                    onClick={() => {
                        browserHistory.push(`/tod/queries/${currentDb.id}/rollups/${r.rollupDefinitionId}`);
                    }}
                    toolTip={hasWriteAccess ? 'View/Edit' : 'View'}
                    iconName='edit'
                />
                <Button
                    id={`delete-${r.queryId}`}
                    onClick={(e) =>{
                        if (hasWriteAccess){
                            this.deleteRollupDefinition(e,r.rollupDefinitionId,r.rollupName)
                        } else {
                            e.stopPropagation();
                            notifyError('You are not authorized to access this functionality');
                        }
                    }}
                    toolTip='Delete Query'
                    iconName='delete'
                />
            </span>
        )
    }

    render() {
        const { isFetching, databases, isDataSourceFetching, currentDb, allRollupDefinitions} = this.props;
        
        let tableMarkup = <div />;

        if (allRollupDefinitions && allRollupDefinitions.length) {
            tableMarkup = this.getGrid();
        } else if (!currentDb) {
        tableMarkup = (
            <div style={{ margin: '5vh auto' }}>
            <PlaceHolderMessage message="Please select a database" />
            </div>
        );
        } else if (currentDb.useADX){
            tableMarkup = <AdxQueryWarning/>
        }
        else if (isFetching || isDataSourceFetching || !databases.length) {
            tableMarkup = <div style={{ margin: '5vh auto' }}><Loader loadingItem="Rollup Definitions" /></div>;
        } else {
            tableMarkup = (
                <div style={{ margin: '5vh auto' }}>
                <NoResults />
                </div>
            );
        }

        return (
        <div className="list-container-arius" style={{ overflowY: 'auto', height: 'calc(100vh - 107px)' }}>
            <div className="list-header-arius">
            <h4>Rollup Definitions</h4>
            <span>
            <Button 
                mode='add' 
                onClick={this.openCreateRollupDefinitions}
                toolTip='Create New Rollup Definition'/>
            </span>
            </div>
            <Form.Group controlId="selectDatabaseControl" className='select-database'>
                <Form.Label>Select Database</Form.Label>
                <Form.Select
                    placeholder="select"
                    value={currentDb ? currentDb.id : 'default'}
                    onChange={this.changeDatabase}
                >
                    <option value="default">Select a database</option>
                    {databases.map(db => (
                        <option key={`${db.workspaceId}`} value={db.workspaceId}>
                        {db.workspaceName}
                        </option>
                    ))}
                </Form.Select>
            </Form.Group>
            {tableMarkup}
            <SaveButtons
            backButtonHander={() => this.backNav()}
            backButtonText="Back"
            />
        </div>
        );
    }
}

export default RollupDefinitionsList;
