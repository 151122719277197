import React, { Component } from 'react';
import { Form, Row, Col, Card } from 'react-bootstrap';
import * as style from '@app/utilities/globalStyles';
import { Table, Button } from '@arius';

class ReportMappingsTable extends Component {
    constructor(props) {
        super(props);
        this.state = {};
        this.add = this.add.bind(this);
        this.delete = this.delete.bind(this);
        this.selectAll = this.selectAll.bind(this);
        this.deleteSelected = this.deleteSelected.bind(this);
        this.getGrid = this.getGrid.bind(this);
        this.getRow = this.getRow.bind(this);
        this.select = this.select.bind(this);
        this.selectRow = this.selectRow.bind(this);
    }

    add() {
        const { mappings, saveHandler } = this.props;
        let newMappings = [...mappings];
        newMappings.push({columnName: '', columnNameToMapTo: ''});
        saveHandler(newMappings);
    }

    delete(idx) {
        const { mappings, saveHandler } = this.props;
        saveHandler(mappings.filter((x,i) => i !== idx));
    }

    deleteSelected() {
        const { mappings, saveHandler } = this.props;
        saveHandler(mappings.filter((x,i) => !x.isSelected));
    }

    selectAll() {
        const { mappings, saveHandler } = this.props;
        const { allSelected:all } = this.state;
        let allSelected = !all;
        saveHandler(mappings.map(x=> {return {...x, isSelected: allSelected}}));
        this.setState({allSelected});
    }

    select(e, idx) {
        const { mappings, saveHandler } = this.props;
        let newMappings = [...mappings];
        newMappings[idx][e.target.id] = e.target.value;
        saveHandler(newMappings);
    }

    selectRow(idx){
        const { mappings, saveHandler } = this.props;
        let newMappings = [...mappings];
        newMappings[idx].isSelected = !newMappings[idx].isSelected;
        saveHandler(newMappings);
    }

    getRow(row, idx) {
        const { leftColumns, rightColumns } = this.props;
        let {Tr, Td} = Table;
        return (
          <Tr key={`newMapping-${idx}`}>
            <Td style={{ width: '5%' }}>
                <Form.Check type="checkbox"
                    onChange={ () => this.selectRow(idx) }
                    checked={row.isSelected}
                />
            </Td>
            <Td style={{ width: '20%' }}>
                <Form.Select
                    id="columnName"
                    value={row.columnName}
                    onChange={(e) => this.select(e, idx)}>
                        <option value=''>---- Select Column to Map ----</option>
                        {leftColumns.map((x, index) => (
                            <option key={`${index}-${x.name}`} value={x.name}>{x.name}</option>
                        ))}
                </Form.Select>
            </Td>
            <Td style={{ width: '20%' }}>
                <Form.Select
                    id="columnNameToMapTo"
                    value={row.columnNameToMapTo}
                    onChange={(e) => this.select(e, idx)}>
                        <option value=''>---- Select Column to Map ----</option>
                        {rightColumns.map((x, index) => (
                            <option key={`${index}-${x.name}`} value={x.name}>{x.name}</option>
                        ))}
                </Form.Select>
            </Td>
            <Td style={{ width: '20%' }}>
                <span style={{color: 'red'}}>{row.errorMessage}</span>
            </Td>
            <Td style={{ width: '5%' }}>
                <Button toolTip="Remove" mode="delete" onClick={()=> this.delete(idx)}/>
            </Td>
          </Tr>
        );
    }

    getGrid() {
        const { allSelected } = this.state;
        const { mappings } = this.props;
        let {Tr, Th, Tbody, Thead, ResponsiveTable:Tbl} = Table;
        return (
            <Tbl>
                <Thead>
                    <Tr>
                        <Th style={{ width: '5%' }}>
                            <input
                                type="checkbox"
                                onChange={this.selectAll}
                                checked={allSelected}
                            />
                        </Th>
                        <Th style={{ width: '20%' }}>Column</Th>
                        <Th style={{ width: '20%' }}>Column To Map To</Th>
                        <Th style={{ width: '20%' }}> </Th>
                        <Th style={{ width: '5%' }}/>
                    </Tr>
                </Thead>
                <Tbody style={style.tableBody} scroll="false" id="mappingsTable">
                    {mappings.map((x, idx) => this.getRow(x, idx))}
                </Tbody>
            </Tbl>
        )
    }
    
    render() {
        const { canMap, error } = this.props;
        return (
            <Card style={{backgroundColor: '#F9F9F9', border: 'none', margin: '20px'}}>
                <Card.Body>
                    <Row>
                        <Form.Group controlId="none">
                            <Form.Label>Column Mappings</Form.Label>
                            <Form.Control style={{display: 'none'}} isInvalid={error}></Form.Control>
                            <Form.Control.Feedback type='invalid'>{error}</Form.Control.Feedback>
                        </Form.Group>
                    </Row>
                    <Row>
                        <Col style={{display: 'flex'}}>
                            <Button variant="primary" onClick={this.add} disabled={!canMap} style={{ marginLeft: 10}}>
                                <i className="material-icons">add</i>Add Mapping
                            </Button>
                            <Button variant="danger" onClick={this.deleteSelected} disabled={!canMap} style={{ marginLeft: 10}}>
                                <i className="material-icons" >remove</i>
                                <span>Remove Mapping</span>
                            </Button>
                        </Col>
                    </Row>
                    <Row style={{padding: 10}}>
                        {this.getGrid()}
                    </Row>
                </Card.Body>
            </Card>
        );
    }
}

export default ReportMappingsTable;
