import React, { Component } from 'react';
import { Modal, Form, Button, ButtonGroup, Row, Container, Col  } from 'react-bootstrap';
import _debounce from 'lodash/debounce';
import * as reportingApi from '@app/api/reporting.serviceApi';
import * as Colors from '@app/utilities/colors';
import { validateName } from '@app/utilities/validators';

export const DEFAULT_REPORT_FORMULA = {columnName: '', formulaString: '', errorName: '', errorFormula: ''};

class ModalEditFormula extends Component {
    constructor(props) {
        super(props);
        this.state = {
            record: DEFAULT_REPORT_FORMULA,
            isValidating: false
        };
        this.open = this.open.bind(this);
        this.save = this.save.bind(this);
        this.close = this.close.bind(this);
        this.handleNameChange = this.handleNameChange.bind(this);
        this.handleFormulaTextChange = this.handleFormulaTextChange.bind(this);
        this.validateFormula = _debounce(this.validateFormula.bind(this), 1000);
    }

    get hasErrors(){
        const { record } = this.state;
        return record.errorName || record.errorFormula;
    }

    componentDidMount() {

    }

    componentDidUpdate(prevProps, prevState) {
        const { record } = this.state;
        //console.error('componentDidUpdate: ', prevState.record, record);
        if (record.formulaString && prevState.record.formulaString !== record.formulaString) {
            this.validateFormula();
        }
    }

    open() {
        const { record } = this.props;
        this.setState({ record });
    }

    validateFormula() {
        const { userKey } = this.props;
        const { record } = this.state;
        this.setState({isValidating: true});
        let columns = this.leftFriendlyMeasures.concat(this.rightFriendlyMeasures).map(x=> x.value);
        reportingApi.validateFormula({ userKey, columns, formula: record.formulaString }).then((result) => {
            this.setState({record: {...record, errorFormula: result.errorMessage}, isValidating: false});
        });
    }

    save() {
        const { saveHandler, existingNames } = this.props;
        let { record } = this.state;

        let errorName = this.validateName(record.columnName, existingNames);
        let errorFormula = record.errorFormula;

        if (!errorFormula && !record.formulaString){
            errorFormula = 'Formula cannot be blank';
        }

        if (errorName || errorFormula){
            this.setState({record: {...record, errorName, errorFormula}});
            return;
        }

        saveHandler(record);
        this.close();
    }

    validateName(name, existingNames) {

        if (!name || /^\s*$/.test(name)) {
            // name must exist and not be whitespace
            return 'Required';
        }

        let nameError = existingNames.filter(x=> name.toLowerCase().trim() === x).length ? 'Name must be unique' : '';
        nameError = nameError ? nameError : validateName(name);
        return nameError;
    }

    close() {
        this.setState({record: DEFAULT_REPORT_FORMULA});
        this.props.closeHandler();
    }

    insertIntoFormula(text, operand) {
        if (text.includes(' ') && !operand) {
            text = `${text}`;
        }
        const { record:s } = this.state;
        let record = {...s};
        let formula = record.formulaString;
        const formulaTextArea = document.getElementById('formulaTextArea');
        const scrollPos = formulaTextArea.scrollTop;
        let caretPos = formulaTextArea.selectionStart;

        const
        front = (formulaTextArea.value).substring(0, caretPos),
        back = (formulaTextArea.value).substring(formulaTextArea.selectionEnd, formulaTextArea.value.length);

        formula = front + text + back;
        caretPos = caretPos + text.length;

        formulaTextArea.value = formula;
        formulaTextArea.selectionStart = caretPos;
        formulaTextArea.selectionEnd = caretPos;
        formulaTextArea.focus();
        formulaTextArea.scrollTop = scrollPos;

        record.formulaString = formula;
        this.setState({ record });
    }

    handleNameChange(e){
        const { existingNames } = this.props;
        let { record } = this.state;
        let name = e.target.value;
        let errorName = this.validateName(name, existingNames);
        record.columnName = name;
        this.setState({record: {...record, errorName}});
    }

    handleFormulaTextChange(e){
        const { record:s, isValidating } = this.state;
        if (isValidating) {return;}
        let record = {...s};
        let f = e.target.value;

        // force an open parentheses after a division symbol
        if (f.slice(-1) === '/'){
            f += ' (';
        }
        if (f.slice(-2) === '/ '){
            f += '(';
        }

        record.formulaString = f;

        this.setState({record});
    }

    getMathButtons() {
        let s = {fontWeight: '800'};
        return <ButtonGroup role="group" aria-label="...">
                <Button variant="outline-dark fa fa-plus"
                    onClick={() => this.insertIntoFormula(' + ', true)}>
                </Button>
                <Button variant="outline-dark fa fa-minus"
                    onClick={() => this.insertIntoFormula(' - ', true)}>
                </Button>
                <Button variant="outline-dark fa fa-times"
                    onClick={() => this.insertIntoFormula(' * ', true)}>
                </Button>
                <Button variant="outline-dark" style={s}
                    onClick={() => this.insertIntoFormula(' / (', true)}>/
                </Button>
                <Button variant="outline-dark" style={s}
                    onClick={() => this.insertIntoFormula(' (', true)}>(
                </Button>
                <Button variant="outline-dark" style={s}
                    onClick={() => this.insertIntoFormula(') ', true)}>)
                </Button>
            </ButtonGroup>
    }

    getFriendlyColumns(columns, tableFriendlyName) {
        return columns.map(x=> {return {label: x, value: `[${tableFriendlyName}].[${x}]`}});
    }

    get leftFriendlyMeasures() {
        const { leftColumns, leftTableFriendlyName, rightTableFriendlyName } = this.props;
        let prefix = leftTableFriendlyName === rightTableFriendlyName ? 
            `L-${leftTableFriendlyName}` : leftTableFriendlyName;
        return leftColumns.map(x=> {return {label: x, value: `[${prefix}].[${x}]`}});
    }

    get rightFriendlyMeasures() {
        const { rightColumns, leftTableFriendlyName, rightTableFriendlyName } = this.props;
        let prefix = leftTableFriendlyName === rightTableFriendlyName ? 
            `R-${rightTableFriendlyName}` : rightTableFriendlyName;
        return rightColumns.map(x=> {return {label: x, value: `[${prefix}].[${x}]`}});
    }

    render() {
        const { show, leftTableFriendlyName, rightTableFriendlyName } = this.props;
        const { record } = this.state;
        return (
        <Modal id={this.props.modalId} show={show} onHide={this.close} enforceFocus
            dialogClassName="formula-modal" onShow={this.open} backdrop='static'>
            <Modal.Header style={{ backgroundColor: Colors.blue, color: '#FFFFFF' }} closeButton>
            <Modal.Title>
                <div>Calculated Measure</div>
                <h6>
                    Enter formula for the Calculated Measure using available Measures and Operators listed below.
                </h6>
            </Modal.Title>
            
 
            {/* <h6>For example: (Salvage + Subrogation) * .5</h6> */}
            </Modal.Header>
            <Modal.Body>
            <Container>
                <Row>
                    <Col xs={12}>
                        <Form.Group controlId="name_col">
                            <Form.Label>Name</Form.Label>
                            <Form.Control placeholder="column name" value={record.columnName} 
                                onChange={this.handleNameChange}    
                                isInvalid={record.errorName}
                                maxLength="128"
                                />
                                <Form.Control.Feedback type='invalid'>{record.errorName}</Form.Control.Feedback>
                        </Form.Group>                   
                    </Col>
                </Row>
                <Row>
                    <Col xs={12}>
                        <Form.Group controlId="titlelabel" style={{marginBottom: 0}}>
                            <Form.Label>Formula</Form.Label>
                        </Form.Group>                  
                    </Col>
                </Row>
                <Row>
                    <Col xs={6}>
                        <Form.Group controlId="measuresSelect1">
                            <Form.Label style={{fontWeight: 'bolder', width: '100%', textAlign: 'center'}}>{leftTableFriendlyName}</Form.Label>
                            <Form.Select
                                onDoubleClick={(e) => this.insertIntoFormula(e.target.value)}
                                multiple style={{ height: 150, width: '100%', overflowX: 'auto' }}>
                                    {this.leftFriendlyMeasures.map((m, idx) => (
                                    <option key={`measure-${idx}`}
                                        value={m.value} title={m.label}>{m.label}</option>
                                    ))}
                            </Form.Select>
                        </Form.Group>
                    </Col>
                    <Col xs={6}>
                        <Form.Group controlId="measuresSelect2">
                            <Form.Label style={{fontWeight: 'bolder', width: '100%', textAlign: 'center'}}>{rightTableFriendlyName}</Form.Label>
                            <Form.Select
                                onDoubleClick={(e) => this.insertIntoFormula(e.target.value)}
                                multiple style={{ height: 150, width: '100%', overflowX: 'auto' }}>
                                    {this.rightFriendlyMeasures.map((m, idx) => (
                                    <option key={`measure-${idx}`}
                                        value={m.value} title={m.label}>{m.label}</option>
                                    ))}
                            </Form.Select>
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} style={{ display: 'flex', justifyContent: 'center', padding: 10}}>
                        {this.getMathButtons()}
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Form.Group controlId="formulaTextArea">
                            <Form.Control
                                spellCheck={false}
                                as="textarea"
                                placeholder="Enter Formula Here"
                                value={record.formulaString}
                                style={{height: 100}}
                                onChange={this.handleFormulaTextChange} />
                        </Form.Group>
                        <h5 style={{ color: Colors.red }}>{record.errorFormula}</h5>
                    </Col>
                </Row>
            </Container>
            </Modal.Body>
            <Modal.Footer>
            <Button variant="arius" data-dismiss="modal" disabled={this.hasErrors} onClick={this.save}>Save</Button>
            <Button variant="arius" data-dismiss="modal" onClick={this.close}>Cancel</Button>
            </Modal.Footer>
        </Modal>
        );
    }
}

let __OBJ = ModalEditFormula;
export {__OBJ as ModalEditFormula}
