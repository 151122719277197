import React, { Component } from 'react';
import { connect } from '@app/utilities/routing';
import { Modal } from 'react-bootstrap';
import {
    validateSyntax,
    fetchWhereStatement
} from '@app/actions/tod/advancedQueries.actions';
import * as Colors from '@app/utilities/colors';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import Loader from '@app/shared/presentational/loader';
import * as GlobalStyles from '@app/utilities/globalStyles';

class QuerySqlPreviewModal extends Component {
    constructor(props) {
        super(props);
        this.state = { 
        };
        this.validate= this.validate.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.handleOpen = this.handleOpen.bind(this);
        this.launchModal = this.launchModal.bind(this);
        this.getWhere = this.getWhere.bind(this);
        this.handleKey = this.handleKey.bind(this);
        this.buffer = [];
    }

    componentWillUnmount() {
        document.removeEventListener('keydown', this.handleKey);
    }

    handleKey(e){
        // look for the key sequence 1==11...
        const key = e.key.toLowerCase();
        this.buffer.push(key);
        let s = this.buffer.toString();
        if (s.includes('1,=,=,1,1')){
            this.getWhere();
            this.buffer = [];
        }
    }

    validate() {
        const {databaseId, userKey, query, dispatch}=this.props;
        dispatch(validateSyntax(userKey, databaseId, query));
    }

    getWhere() {
        const {databaseId, userKey, query, dispatch}=this.props;
        dispatch(fetchWhereStatement(userKey, databaseId, query));
    }

    handleOpen(){
        document.addEventListener('keydown', this.handleKey);
    }

    handleClose(){
        this.setState({show: false});
        document.removeEventListener('keydown', this.handleKey);
    }

    launchModal(){
        this.setState({comments: '', message: 'test', show: true});
    }

    componentDidUpdate() {
    }

    getValidationElements() {
        const {queryValidationResponse} = this.props;
        let valid = queryValidationResponse ? queryValidationResponse.isValid : true;
        let msg = queryValidationResponse ? queryValidationResponse.validationMessage : "";
        if (valid){
            return (
                <div style={{color: 'green'}}>
                    VALID
                </div>
            )
        } else {
            return (
                <div >
                   <span style={{color: 'red'}}>INVALID or INCOMPLETE</span>  <span>{msg}</span>
                </div>
            ) 
        }
    }
  
    render() {

        const {isWaiting, queryValidationResponse}=this.props;
        const {show} = this.state;
        let sql = queryValidationResponse ? queryValidationResponse.sqlPreview : 'not defined';
        //let kql = queryValidationResponse ? queryValidationResponse.kqlPreview : 'not defined';

        let btnStyle = {
            color: Colors.darkGrey,
            cursor: 'pointer',
        };

        let body = (
            <div className="row" style={{}}>
                <div className="col-sm-12" style={{}}>
                {
                    isWaiting ? <Loader loadingItem="Validating query" /> :
                    <pre className="preQueryPreview" style={{height: '500px', overflowY: 'auto'}}>
                        {sql}  
                    </pre>
                }
                </div>
            </div>
        );

        let buttons = <span>
            <button key="nobutton"
                className="btn btn-default dialog-button"
                onClick={this.handleClose}>
                Close
            </button>
        </span>;

        return (
            <span className="pull-right">
                 <OverlayTrigger
                    placement="left"
                    focus
                    overlay={<Tooltip id='tootipId_sqlPreview' style={GlobalStyles.toolTipFix}>Sql Preview</Tooltip>}
                    >
                    <div style={{position:'relative'}}>
                        <i
                            id="modifyProjectStatuses" 
                            style={btnStyle}
                            className="material-icons pull-right hover-purple-negative"
                            onClick={this.launchModal}
                        >
                            event_available
                        </i>       
                    </div>
                </OverlayTrigger> 
                <Modal show={show} 
                    onHide={this.handleClose} onEnter={this.handleOpen} 
                    dialogClassName="wait-modal">
                    <Modal.Header closeButton style={{ backgroundColor: Colors.blue, color: '#FFFFFF' }}>
                        <Modal.Title>Query Preview</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>{body}</Modal.Body>
                    <Modal.Footer>
                        <div className="col-sm-12" style={{paddingRight: '0px', paddingLeft: '0px'}}>
                                <div className="float-left" style={{display:'flex',justifyContent: 'flex-start'}}>
                                    {this.getValidationElements()}
                                </div>
                                <div className="pull-right" style={{}}> 
                                    {buttons}
                                </div>
                        </div>
                    </Modal.Footer>
                </Modal>
            </span>
            
        );
        }
    }

    const mapStateToProps = state => ({
        userKey: state.user.userKey,
        queryValidationResponse: state.tod.queries.queryValidationResponse,
        isWaiting: state.tod.queries.isFetchingQueryValidation
      });
      
    const mapDispatchToProps = dispatch => ({
        dispatch,
    });
    
    export default connect(mapStateToProps, mapDispatchToProps)(QuerySqlPreviewModal);


