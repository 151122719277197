import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Grid, Button } from '@arius';
import { showModal } from '@app/actions/modal.actions';
import Loader from '@app/shared/presentational/loader';
import NoResults from '@app/shared/presentational/noResults';
import { notifyError } from '@app/utilities/notifier';
import DetailRow from './mappingsListDetailRow';

class MappingsList extends Component {
    static propTypes = {
        mappings: PropTypes.object,
        currentDb: PropTypes.object,
        dataMappers: PropTypes.array,
        isFetching: PropTypes.bool,
        params: PropTypes.object,
        isUserDbAdmin: PropTypes.bool,
        dispatch: PropTypes.func,
        userKey: PropTypes.string,
        deleteMapper: PropTypes.func,
        getMapper: PropTypes.func,
        currentMapper: PropTypes.object,
        dateFormatDelimiters: PropTypes.array,
    };
    static defaultProps = {
        dataMappers: [],
        currentDb: {},
    };
    constructor(props) {
        super(props);
        this.state = {
            currentMappings: [],
            alreadyFetched: false,
        };

        this.selectMapper = this.selectMapper.bind(this);
        this.deleteMapper = this.deleteMapper.bind(this);
        this.getActionItems = this.getActionItems.bind(this);
        this.getGrid = this.getGrid.bind(this);
    }

    deleteMapper(id) {
        const { dispatch, dataMappers } = this.props;

        if (id && dataMappers.length > 0) {
        const selectedMapper = dataMappers.find(u => u.mapperId === id);
        const deleteMessageItems = [
            <li key={selectedMapper.mapperId} style={{ fontWeight: 800 }}>
            {selectedMapper.mapperName}
            </li>,
        ];

        deleteMessageItems.unshift(
            'Are you sure you want to delete the following mapper?'
        );

        const yesClickHandler = () => {
            const { userKey, currentDb, deleteMapper } = this.props;
            deleteMapper(userKey, currentDb.id, id);
        };
        const noClickHandler = () => { };
        const action = showModal(
            'confirmation',
            deleteMessageItems,
            yesClickHandler,
            noClickHandler
        );
        dispatch(action);
        }
    }

    selectMapper(id) {
        const { selectedMapper } = this.state,
        { dataMappers, getMapper, userKey, currentDb } = this.props,
        foundMapper = dataMappers.find(m => m.mapperId === id);
        if (foundMapper && selectedMapper && foundMapper.mapperId === selectedMapper.mapperId) {
            this.setState({ selectedMapper: null });
        } else {
            this.setState({ selectedMapper: foundMapper });
            getMapper(userKey, currentDb.id, id);
        }
    }

    getActionItems(row) {
        const { browserHistory, isUserDbAdmin, params } = this.props;
        return  (
        <span>
            <Button
                id={`edit-${row.mapperId}`}
                onClick={e => {
                    if (isUserDbAdmin){
                        browserHistory.push(`/tod/databases/${params.databaseId}/mappings/${row.mapperId}`);
                    } else {
                        notifyError('You do not have permissions to proceed');
                    }
                }}
                toolTip='View/Edit'
                iconName='edit'
            />
            <Button
                id={`delete-${row.mapperId}`}
                toolTip='Delete Mapper'
                iconName='delete'
                onClick={e => {
                    if (isUserDbAdmin){
                        this.deleteMapper(row.mapperId);
                    } else {
                        notifyError('You do not have permissions to proceed');
                    }
                }}
            />
        </span>
        )
    }

    getGrid() {
        const {
            dataMappers:data,
            userKey,
            dateFormatDelimiters,
            params
        } = this.props;
        let columns = [
            { headerText: 'Name', field: 'mapperName'},
            { headerText: 'Description', field: 'mapperDescription'},
            { headerText: 'Last Modified By', field: 'auditUser'},
            { headerText: 'Last Modified', field: 'auditDateTime'},
            { headerText: 'Actions', template: this.getActionItems, field: 'mapperName'},
        ];
        return <Grid 
            columns={columns}
            data={data}
            height='calc(100vh - 240px)' 
            detailTemplate={(row) => <DetailRow 
                row={row} 
                userKey={userKey} 
                databaseId={params.databaseId}
                dateFormatDelimiters={dateFormatDelimiters}
            />} 
        />
    }

    render() {
        const {
            dataMappers,
            isFetching,
            params,
            isUserDbAdmin,
            browserHistory
        } = this.props;

        let body = <div></div>;

        if (isFetching) {
            body = (
                <div style={{ width: '85vw', margin: '5vh auto' }}>
                    <Loader loadingItem='Database Mappers' />
                </div>
            );
        } else if (!isFetching && dataMappers.length < 1) {
            body = <div style={{ width: '85vw', margin: '5vh auto' }}><NoResults /></div>;
        }

        return (
        <div className="list-container-arius">
            <div className="list-header-arius">
                <h4>Mapper</h4>
                {isUserDbAdmin
                    ? 
                    <Button 
                        mode='add' 
                        onClick={() => browserHistory.push(`/tod/databases/${params.databaseId}/mappings/new`)}
                        toolTip='Create Mapping'/>
                    : ''}
            </div>
            {body}
            <div style={!isFetching && dataMappers.length > 0 ? {} : {display: 'none'}}>
                {this.getGrid()}
            </div>
        </div>
        );
    }
}

export default MappingsList
