import React, { Component } from 'react';
import { connect } from '@app/utilities/routing';
import { Button } from 'react-bootstrap';
import _debounce from 'lodash/debounce'

import {
    fetchValues, fetchFactValues
} from '@app/actions/tod/advancedQueries.actions';

const style = {
    listBox: {
        height: 'calc(100vh - 450px)',
        listStyle: 'none',
        backgroundColor: 'white',
        padding: 20,
        overflowY: 'scroll',
    },
    button: {
        marginBottom: 15,
        height: 34,
        //width: 100,
        textAlign: 'center'
    },
};

class ValuesPicker extends Component {
    constructor(props) {
        super(props);
        this.state = {
            rawData: [],
            assigned: [],
            available: [],
            pageSize: 30000,  //one single giant page for now
            currentPage: 1,
            totalPages: 1,
            totalCount: 0,
            desc: false,
            search: '',
            selectedAvailable: [],
            selectedSelected: []
        };
        this.addItems = this.addItems.bind(this);
        this.removeItems = this.removeItems.bind(this);
        this.addAllItems = this.addAllItems.bind(this);
        this.removeAllItems = this.removeAllItems.bind(this);
        this.getData = _debounce(this.getData.bind(this), 750);
        this.handleChange = this.handleChange.bind(this);
        this.updateSelectedValues = this.updateSelectedValues.bind(this);
        this.handleSearchStringChange = this.handleSearchStringChange.bind(this);
    }

    componentDidMount() {
        const {values} = this.props;
        let data = values ? JSON.parse(values) : [];
        this.getData();
        this.setState({assigned: data});
    }

    getData(){
        const {pageSize, currentPage, desc, search} = this.state;
        const {dispatch, userKey, currentDb, column, includeOtherColumns} = this.props;
        let factColumnName = column ? column.name : '';
        let columnType = column ? column.columnType : '';
        if (!currentDb || !factColumnName || (!includeOtherColumns && columnType && columnType.toLowerCase() !== "dimension")){
            return;
        }

        let skip = pageSize*(currentPage-1);
        if (includeOtherColumns)
        {
            // look up values in the fact table (this will hit ADX).  
            dispatch(fetchFactValues(userKey, currentDb.id, factColumnName, search, skip, pageSize, desc));
            return;
        }
        dispatch(fetchValues(userKey, currentDb.id, factColumnName, search, skip, pageSize, desc));
    }

    UNSAFE_componentWillReceiveProps(nextProps) 
    {
        const { pagingResult } = this.props;
        let {currentPage, pageSize} = this.state;
        if (pagingResult !== nextProps.pagingResult){
            let rawData = nextProps.pagingResult ? nextProps.pagingResult.data : [];
            let totalCount = nextProps.pagingResult ? nextProps.pagingResult.totalCount : 0;
            let totalPages = nextProps.pagingResult ?  Math.round(totalCount/pageSize) : 1;
            totalPages = totalPages > 0 ? totalPages : 1;
            currentPage = currentPage > totalPages ? totalPages : currentPage;
            rawData = rawData.map((x)=> x.value);
            let data = rawData.filter((x) => !this.state.assigned.includes(x));
            this.setState({
                available: data,
                totalCount,
                totalPages,
                currentPage,
                rawData
            });            
        }
    }

    componentDidUpdate(prevProps, prevState) {
        const {currentPage, search} = this.state;
        if (search !== prevState.search || currentPage !== prevState.currentPage || prevProps.column !== this.props.column){
            this.getData();
        }
    }

    addItems(items) {
        let available = this.state.available.slice();
        let assigned = this.state.assigned.slice();
        items = Array.isArray(items) ? items : [items];

        for (let i = 0, l = items.length; i < l; i++) {
            let selectedItem = items[i];
            if (!assigned.includes(selectedItem) && available.includes(selectedItem)) {
                assigned.push(selectedItem);
                available.splice(available.indexOf(selectedItem), 1);
            }
        }
        this.setState({ 
            assigned: this.alphabetizeItems(assigned), 
            available: this.alphabetizeItems(available),
            selectedAvailable: [],
            selectedSelected: [] });
        this.handleChange(assigned);
    }

    removeItems(items) {
        let available = this.state.available.slice();
        let assigned = this.state.assigned.slice();
        items = Array.isArray(items) ? items : [items];

        for (let i = 0, l = items.length; i < l; i++) {
            let selectedItem = items[i];
            if (!available.includes(selectedItem) && assigned.includes(selectedItem)) {
                if (this.state.rawData.includes(selectedItem)){
                    available.push(selectedItem);
                }
                assigned.splice(assigned.indexOf(selectedItem), 1);
            }
        }
        this.setState({ 
            assigned: this.alphabetizeItems(assigned), 
            available: this.alphabetizeItems(available),
            selectedAvailable: [],
            selectedSelected: [] });
        this.handleChange(assigned);
    }

    addAllItems() {
        let { available, assigned } = this.state;
        assigned = this.alphabetizeItems([...assigned, ...available]);
        this.setState({ assigned, available:[] });
        this.handleChange(assigned);
    }

    removeAllItems() {
        let { assigned } = this.state;
        assigned = [];
        this.setState({ assigned, available: this.alphabetizeItems(this.state.rawData) });
        this.handleChange(assigned);
    }

    selectItem(item, e) {
        e.stopPropagation();
        let { selectedItem } = this.state;
        selectedItem = selectedItem === item ? null : item;
        this.setState({ selectedItem });
    }

    alphabetizeItems(items) {
        return items && Array.isArray(items) ? items.sort((a, b) => {
            if (a.toString().toLowerCase() < b.toString().toLowerCase()) {
                return -1;
            } else {
                return 1;
            }
        }) : [];
    }

    updateSelectedValues = (e) => {
        let el = e.target;
        let selectedValues = []
        for (let i = 0, l = el.options.length; i < l; i++) {
            if (el.options[i].selected) {
                selectedValues.push(el.options[i].value);
            }
        }
        let newState = {};
        newState[el.id] = selectedValues;
        this.setState(newState);
    }

    handleChange(assigned){
        const {handleChange} = this.props;
        handleChange({target: {id: this.props.id, value: JSON.stringify(assigned)}});
    }

    handleSearchStringChange(e) {
        const { isFetchingValues } = this.props;
        if (isFetchingValues) { return; }
        this.setState({search: e.target.value});
    }

    render() {
        const { isFetchingValues } = this.props;
        let disabled = this.props.disabled;
        const { available, assigned, selectedAvailable, selectedSelected } = this.state;
        let listBox= (
            
    <fieldset>
    <div className="row">
        <div className="col-sm-5" style={{}}>
            <div className="form-group" style={{marginBottom:0}}>
                <label>Available Values</label>
                <input
                    id="search"
                    style={{marginBottom: 15}}
                    value={isFetchingValues ? 'loading...' : this.state.search}
                    onChange={this.handleSearchStringChange}
                    className= {"form-control" + (isFetchingValues ? " input-loading" : "")}
                    type="text"
                    placeholder='search...'
                />
                <select multiple
                    id='selectedAvailable'
                    ref={this._selectRef}
                    style={{...style.listBox, height: 'calc(100vh - 445px)'}} 
                    className="form-control ttc"
                    value={selectedAvailable}
                    onChange={this.updateSelectedValues}
                    disabled={disabled}>
                        {available.map(item => {
                        return <option 
                            key={item+'o'} 
                            value={item}
                            onDoubleClick={(e) => {this.addItems(item)}}
                            >{item}</option>
                        })}
                </select>
                
                {/* <div className="" style={{textAlign: 'center'}}>
                    <UltimatePagination 
                        currentPage={currentPage} 
                        totalPages={totalPages} 
                        onChange={(page) => this.setState({currentPage: page})}
                    />
                </div> */}
                <div className="" style={{marginTop: 15}}>
                    Records found: {this.state.totalCount > 999 ? '1000+' : this.state.totalCount}
                </div>
            </div>
        </div>
        <div className="col-sm-2" style={{textAlign: 'center', display: 'flex', 
            justifyContent: 'center', flexFlow: 'column', height: 'calc(100vh-393px)'}}>
            <label>&nbsp;&nbsp;</label>
            <div>
                <Button variant="outline-secondary" className="col-sm-12" style={style.button} onClick={this.addAllItems}>
                    <i className="material-icons">list</i>
                    <i className="material-icons">keyboard_arrow_right</i>
                </Button>
                <Button variant="outline-secondary" className="col-sm-12" style={style.button} onClick={
                    () => this.addItems(this.state.selectedAvailable)}>
                    <i className="material-icons">keyboard_arrow_right</i>
                </Button>
                <Button variant="outline-secondary" className="col-sm-12" style={style.button} onClick={
                    () => this.removeItems(this.state.selectedSelected)}>
                    <i className="material-icons">keyboard_arrow_left</i>
                </Button>
                <Button variant="outline-secondary" className="col-sm-12" style={style.button} onClick={this.removeAllItems}>
                    <i className="material-icons">keyboard_arrow_left</i>
                    <i className="material-icons">list</i>
                </Button>
            </div>
        </div>
        <div className="col-sm-5" style={{}}>
            <div className="form-group">
                <label>Selected</label>
                <select multiple
                    id='selectedSelected'
                    ref={this._selectRef}
                    style={{...style.listBox, height: 'calc(100vh - 393px)'}} 
                    className="form-control ttc"
                    value={selectedSelected}
                    onChange={this.updateSelectedValues}
                    disabled={disabled}>
                        {assigned.map(item => {
                        return <option 
                            key={item+'o'} 
                            value={item}
                            onDoubleClick={(e) => {this.removeItems(item)}}
                            >{item}</option>
                        })}
                </select>
            </div>
        </div>
    </div>
    </fieldset> 
        )
    
    return listBox;
  }
}

const mapStateToProps = state => ({
    userKey: state.user.userKey,
    currentDb: state.tod.databases.current,
    isFetchingValues: state.tod.queries.isFetchingValues,
    pagingResult: state.tod.queries.valuesPagingResult,
});
  
const mapDispatchToProps = dispatch => ({
    dispatch,
});
  
export default connect(mapStateToProps, mapDispatchToProps)(ValuesPicker);
