import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Loader from '@app/shared/presentational/loader';
import { Grid, Button } from '@arius';
import { notAuthorized } from '@app/shared/containers/conditionalLink';
import { DATA_MODIFY } from '@app/utilities/permissions';
import { createPermissionChecker } from '@app/utilities/permissions';
import { 
    fetchLinkedDatabases,
    deleteLinkedDatabaseMapping,
    clearLinkedDatabases 
} from '@app/actions/tod/todDatabases.actions';
import SaveButtons from '@app/shared/presentational/saveButtons';
import { notifySuccess } from '@app/utilities/notifier';
import { showModal } from '@app/actions/modal.actions';
import { notifyError } from '../../../utilities/notifier';


class LnkDatabase extends Component {
    static propTypes = {
        dispatch: PropTypes.func,
        params: PropTypes.object.isRequired,
        userKey: PropTypes.string,
    };

    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            databaseId: null,
        };
        this.getAddButton = this.getAddButton.bind(this);
    };

    componentDidMount() {
        const {
            params,
            selectDatabaseHandler,
        } = this.props;

        const databaseId = parseInt(params.databaseId, 10);
        if (databaseId) {
            this.setState({
                isLoading: true,
                databaseId: databaseId,
            });

            this.loadLinkedDatabases(databaseId);

        } else {
            selectDatabaseHandler(null);
        }

    }

    componentDidUpdate(prevProps, prevState) {
        const {
            isFetchingLinkedDatabases,
            isDeletingLinkedDatabaseMapping,
        } = this.props;

        const {
            isLoading,
        } = this.state;

        if (!isFetchingLinkedDatabases && isLoading){
            this.setState({
                isLoading: false,
            });
        }

        if (prevProps.isDeletingLinkedDatabaseMapping !== isDeletingLinkedDatabaseMapping) {
            if (!isDeletingLinkedDatabaseMapping) {
                notifySuccess("Database link is deleted");
            } else if (isDeletingLinkedDatabaseMapping.length > 0) {
                // error message, user already notified
                console.log('Database link deletion completed with error');
                console.log(isDeletingLinkedDatabaseMapping);
            }
        }
        
    }

    loadLinkedDatabases = (id) => {
        const { userKey, dispatch } = this.props;
        dispatch(fetchLinkedDatabases(userKey, id));
    };

    getActionItems = (row) => {
        //const { userKey, dispatch } = this.props;
        const { databaseId } = this.state;

        const secondaryDatabaseId = row.databaseId;
        return  (
            <div>
                {this.getActionLink(
                    `/tod/databases/${databaseId}/link/${secondaryDatabaseId}/edit/`, 
                    true, // verifyPermission(DATA_MODIFY)
                    true,
                    "Edit", 
                    "edit",
                )}  

            <Button 
                iconName="delete" 
                onClick={() => this.promptToDelete(row)}
                toolTip='Delete database link'/>

            </div>
        )
    }

    
    getSecondaryGrid = () => {
        const { linkedDatabases } = this.props;
        const data = linkedDatabases.secondaryDatabases;
        
        let columns = [
            { field: 'databaseName', headerText: 'Name'},
            { field: 'description', headerText: 'Description'},
            { field: 'linkUpdatedBy', headerText: 'Last Modified By'},
            { field: 'linkUpdatedDateTime', headerText: 'Last Modified'},
            { field: 'databaseName', headerText: 'Actions', template: this.getActionItems},
        ];

        if (!data || data.length === 0) {
            return null;
        }

        return (
        <div>
            <h5>Secondary Databases</h5>
            
            <Grid 
                columns={columns}
                data={data}
                //height='calc(100vh - 200px)' 
                allowPaging={false}
            />
        </div>
        )
    };

    getPrimaryGrid = () => {
        const { linkedDatabases } = this.props;

        const data = linkedDatabases.primaryDatabases;
        
        let columns = [
            { field: 'databaseName', headerText: 'Name'},
            { field: 'description', headerText: 'Description'},
            { field: 'linkUpdatedBy', headerText: 'Last Modified By'},
            { field: 'linkUpdatedDateTime', headerText: 'Last Modified'},
        ];

        if (!data || data.length === 0) {
            return null;
        }

        return (
            <div>
                <h5>Primary Databases</h5>
                
                <Grid 
                    columns={columns}
                    data={data}
                    //height='calc(100vh - 200px)' 
                    allowPaging={false}
                />
            </div>
            )
    };

    getActionLink = (path, isAllowed, enabled, toolTip, iconName) => {
        const { browserHistory } = this.props;
        const { databaseId } = this.state;

        return (
            <Button
                id={`${toolTip}-${databaseId}`}
                onClick={() => {
                    //this.closeActions();
                    if (isAllowed) {
                        if (enabled) {
                            browserHistory.push(path);
                        }
                    } else {
                        notAuthorized();
                    }
                }}
                toolTip={toolTip}
                iconName={iconName}
                disabled={!enabled}
            />
        )
    }

    getAddButton = () => {
        
        const { linkedDatabases, browserHistory } = this.props;
        const { databaseId } = this.state;
        const secondary = linkedDatabases ? linkedDatabases.secondaryDatabases : null;
        const canAdd = !secondary || secondary.length === 0;
        const primary = linkedDatabases ? linkedDatabases.primaryDatabases : null;
        const verifyPermission = this.getPermissionChecker();

        if (!canAdd){
            return <Button
                onClick={() => {notifyError('Already linked')}}
                toolTip={'Already linked'}
                iconName={'add'}
                disabled={false}
            />
        }

        if (!verifyPermission(DATA_MODIFY)){
            return <Button
                onClick={() => {notifyError('You are not authorized to access this functionality');}}
                toolTip={'Add Secondary Database'}
                iconName={'add'}
                disabled={false}
            />
        } 

        if (primary && primary.length > 0) {
            return <span></span>;
        } else {
            return <Button
                onClick={() => {browserHistory.push(`/tod/databases/${databaseId}/link/edit/`)}}
                toolTip={'Add Secondary Database'}
                iconName={'add'}
                disabled={false}
            />
        }

    }

    promptToDelete = (row) => {
        const { dispatch, currentDatabase } = this.props;
        const { skuName } = this.state;

        const verifyPermission = this.getPermissionChecker();

        if (!verifyPermission(DATA_MODIFY)) {
            notAuthorized();
        } else {
            const desc = `Database link from ${currentDatabase.name} to ${row.databaseName}`
            let messageItems = [
            <li key={`${skuName}-1`} style={{ fontWeight: 800 }}>{desc}</li>,
            ];

            messageItems.unshift(<div key={`${skuName}-2`}>Are you sure you want to delete database link?</div>);
            const yesClickHandler = () => {this.deleteLink(row)};
            const noClickHandler = () => {};
            const action = showModal(
                'confirmation',
                messageItems,
                yesClickHandler,
                noClickHandler
            );
            dispatch(action);
        }
    }

    deleteLink = (row) => {
        const { userKey, dispatch } = this.props;
        const { databaseId } = this.state;
        const db2 = row.databaseId;
        dispatch(deleteLinkedDatabaseMapping(userKey, databaseId, db2));

    };

    getPermissionChecker = () => {
        const { currentDatabase, userPermissions } = this.props;

        return createPermissionChecker(
            currentDatabase ? [...userPermissions, ...currentDatabase.permissions] : userPermissions);
    }

    render() {
        const {
            linkedDatabases,
            browserHistory,
            dispatch,
        } = this.props;
        const {
            isLoading,
        } = this.state;

        const db = linkedDatabases ? linkedDatabases.database : null;

        return (
            <div className="list-container-arius" style={{ padding: 10 }}>
                <div className="list-header-arius" style={{ marginBottom: 10 }}>
                    <h4>Linked Databases
                        {db ? 
                            ' for ' + db.databaseName
                            : ('')
                        }

                    </h4>
                    {this.getAddButton()}
                </div>

                { isLoading ? 
                    <Loader loadingItem='Linked Databases Info' />
                    : ('')
                }

                {!isLoading && linkedDatabases ?  
                    <div>
                        {/* {db ? 
                            <h5>Database: {db.databaseName}</h5>
                            : ('')
                        } */}
                        {this.getSecondaryGrid()}
                        {this.getPrimaryGrid()}
                    </div>
                    : ('')
                }

                <SaveButtons
                    backButtonHander={() =>{
                        dispatch(clearLinkedDatabases());
                        browserHistory.push('/tod/databases')}}
                    backButtonText="Back to Databases"
                />

            </div>
        );
    };
}

export default LnkDatabase;