import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Form, Col, Row } from 'react-bootstrap';
import DualListBox from '@app/shared/presentational/dualListBox';
import SaveButtons from '@app/shared/presentational/saveButtons';
import { notifyError } from '@app/utilities/notifier';
import { SITE_MANAGEROLES } from '@app/utilities/permissions';
import { getAppSettings } from '@app/config';

function splitPermissions(permissionIdsToSelect, availablePermissions) {
    const rc = {
        available: [],
        selected: [],
    };

    for (let i = 0; i < availablePermissions.length; i++) {
        const ap = availablePermissions[i];
        if (permissionIdsToSelect.indexOf(ap.permissionId) !== -1) {
            rc.selected.push(ap);
        } else {
            rc.available.push(ap);
        }
    }
    return rc;
}

class DefineRoles extends Component {
    static propTypes = {
        userKey: PropTypes.string,
        dispatch: PropTypes.func,
        roleDescriptions: PropTypes.array,
        assignedRoles: PropTypes.array,
        name: PropTypes.string,
        description: PropTypes.string,
        availablePermissionsHandler: PropTypes.func,
        clearPermissionsHandler: PropTypes.func,
        createRoleHandler: PropTypes.func,
        updateRoleHandler: PropTypes.func,
        fetchRolePermissions: PropTypes.func,
        selectedRole: PropTypes.object,
        params: PropTypes.object,
        availablePermissions: PropTypes.array,
    };
    static defaultProps = {
        roleDescriptions: [],
        availablePermissions: [],
        name: '',
        description: '',
    };
    constructor(props) {
        super(props);
        let modules = [
            { label: 'Portal Management', value: 'site' },
            { label: 'Arius Analysis Tool', value: 'AA' },
            { label: 'Triangles On Demand', value: 'ToD' }
        ];
        if (props.features.advancedAnalytics === true){
            modules.push({ label: 'Advanced Analytics', value: 'Advanced Analytics' });
        }
        this.state = {
            helpUrl: '',
            roleDescriptions: [],
            availablePermissions: [],
            availableResources: [],
            selectedPermissions: [],
            selectedResource: '',
            roleName: '',
            isSystemRole: false,
            roleDescription: '',
            editing: false,
            defaultAvailablePermissions: [],
            defaultSelectedPermissions: [],
            modules,
            softErrorMode: true,
        };
        getAppSettings().then(settings => {
            this.setState({helpUrl: settings.helpUrls_permissions});
        });
        if (props.params && props.params.roleId) {
            this.state.roleId = props.params.roleId;
            this.state.editing = true;
        }
        this.saveRole = this.saveRole.bind(this);
        this.handleRoleDescriptionChange = this.handleRoleDescriptionChange.bind(this);
        this.handleRoleNameChange = this.handleRoleNameChange.bind(this);
        this.permissionsListChangeBoxHandler = this.permissionsListChangeBoxHandler.bind(this);
        this.selectResource = this.selectResource.bind(this);
        this.validateRoleName = this.validateRoleName.bind(this);
        this.helpCallback = this.helpCallback.bind(this);
    }

    componentDidMount() {
        const {
            availablePermissionsHandler,
            fetchRolePermissions,
            roleDescriptions,
            roleDescriptionHandler
        } = this.props;
        const { roleId } = this.state;

        availablePermissionsHandler();
        if (roleId && roleDescriptions.length < 1){
            roleDescriptionHandler();
        }
        else if (roleId) {
            fetchRolePermissions(this.state.roleId);
        }
    }

    componentDidUpdate(prevProps) {        
        const { availablePermissions, selectedRole, isFetching, fetchRolePermissions, roleDescriptions } = this.props;
        const { editing, modules, selectedResource, roleId } = this.state;
        if (roleId && !selectedRole && roleDescriptions.length > 0 && !isFetching) {
            fetchRolePermissions(roleId);
            return;
        }

        if ( editing && selectedRole &&
            selectedRole.roleId === this.state.roleId &&
            (selectedRole !== prevProps.selectedRole ||
                availablePermissions.length !== prevProps.availablePermissions.length)
        ) {
            let max = -1, mostCommonResource;

            const newState = {
                roleName: selectedRole.name,
                isSystemRole: selectedRole.isSystemRole,
                roleDescription: selectedRole.description,
            };
            if (availablePermissions.length) {
                const split = splitPermissions(selectedRole.permissions, availablePermissions);
                // newState.defaultAvailablePermissions = split.available;
                // newState.defaultSelectedPermissions = split.selected;
                const sortedPermissions = split.selected.reduce(
                    (prev, curr) => {
                        const newArray = prev;
                        if (curr.permissionId.indexOf('Site') === 0) {
                            newArray[0].push(curr);
                        } else if (curr.permissionId.indexOf('Workspace') === 0) {
                            newArray[1].push(curr);
                        } else if (curr.permissionId.indexOf('Data') === 0) {
                            newArray[2].push(curr);
                        } else if (curr.permissionId.indexOf('AdvancedAnalytics') === 0) {
                            newArray[3].push(curr);
                        }
                        return newArray;
                    },
                    [[], [], [],[]],
                );

                sortedPermissions.forEach((sp, idx) => {
                    if (sp.length > max) {
                        max = sp.length;
                        mostCommonResource = modules[idx];
                    }
                });
                this.selectResource(
                    mostCommonResource.value,
                    split.available,
                    split.selected,
                );
            }
            this.setState(newState);
        }

        if (
            availablePermissions &&
            availablePermissions !== prevProps.availablePermissions &&
            !editing &&
            !selectedResource
        ) {
            this.selectResource('site');
        }
    }

    componentWillUnmount() {
        const { clearPermissionsHandler } = this.props;
        clearPermissionsHandler();
    }

    selectResource(e, preAvailable, preSelected) {
        const value = e,
        availablePermissions = preAvailable || this.props.availablePermissions,
        selectedPermissions = preSelected
            ? preSelected.map(ps => ({
            name: ps.permissionDesc,
            value: ps.permissionId,
            }))
            : [];

        let {
            defaultAvailablePermissions,
            defaultSelectedPermissions,
        } = this.state;

        if (value === 'site') {
            defaultAvailablePermissions = availablePermissions
                .filter(ap => ap.permissionId.indexOf('Site') === 0)
                .map(fp => ({
                name: fp.permissionDesc,
                value: fp.permissionId,
                }));
            defaultSelectedPermissions = selectedPermissions;
            } else if (value === 'AA') {
            defaultAvailablePermissions = availablePermissions
                .filter(ap => ap.permissionId.indexOf('Workspace') === 0)
                .map(fp => ({
                name: fp.permissionDesc,
                value: fp.permissionId,
                }));
            defaultSelectedPermissions = selectedPermissions;
            } else if (value === 'ToD') {
            defaultAvailablePermissions = availablePermissions
                .filter(ap => ap.permissionId.indexOf('Data') === 0)
                .map(fp => ({
                name: fp.permissionDesc,
                value: fp.permissionId,
                }));
            defaultSelectedPermissions = selectedPermissions;
            } else if (value === 'Advanced Analytics') {
                defaultAvailablePermissions = availablePermissions
                .filter(ap => ap.permissionId.indexOf('AdvancedAnalytics') === 0)
                .map(fp => ({
                    name: fp.permissionDesc,
                    value: fp.permissionId,
                }));
                defaultSelectedPermissions = selectedPermissions;
            } else {
            defaultAvailablePermissions = [];
            defaultSelectedPermissions = [];
        }
        this.setState({
            selectedResource: value,
            defaultSelectedPermissions,
            defaultAvailablePermissions,
        });
    }

    handleRoleNameChange(e) {
        this.setState({ roleName: e.target.value, isUpdated: true });
    }

    handleRoleDescriptionChange(e) {
        this.setState({ roleDescription: e.target.value, isUpdated: true });
    }

    saveRole() {
        const { userKey, createRoleHandler, browserHistory } = this.props,
        {
            isUpdated,
            roleName,
            roleDescription,
            selectedPermissions,
            roleId,
        } = this.state;

        let isValid = Array.isArray(selectedPermissions) && selectedPermissions.length > 0;
        if (!isValid) {
            notifyError('Must have at least 1 permission.');
            return;
        }
            

        if (!this.validate(false)) {
            this.setSoftErrorMode(false);
            return;
        }

        
        if (isUpdated) {
            const permissions = selectedPermissions.map(sp => sp.value),
                name = roleName;

            createRoleHandler({
                userKey,
                roleId,
                name,
                permissions,
                description: roleDescription,
                browserHistory
            });
        } else {
            browserHistory.push('/portalManagement/roles');
        }
    }

    validateRoleName(name, soft){
        if (!name && !soft) {
            return 'Required';
        }
    
        if (typeof (name) !== 'string') {
            return 'Invalid value';
        }
    
        const trimmed = name.trim();
            if (!trimmed.match(/^[\w\W\s-]*$/) || trimmed.match(/^_/)) {
            return 'Use Letters, Numbers, Underscores, Hyphens, Special characters and Spaces. First character cannot be underscore';
        }

        return '';
    }

    validate(soft) {
        const roleNameError = this.getNameValidationError(soft);
        if (roleNameError) {
            notifyError(`Role name: ${roleNameError}`);
            return false;
        }
        return !roleNameError;
    }

    getNameValidationError(soft) {
        const { roleName } = this.state,
        { roleDescriptions } = this.props;
    
        let trimmed = roleName.trim();
        // const { current } = this.props; this needs to be added in order to ensure that if we have a current working database in edit mode
        // it doesn't mess up the validation by saying this already exists, change after adding an edit mode
    
        const error = this.validateRoleName(trimmed, soft);
    
        if (error !== '') {
            return error;
        }

        trimmed = trimmed.toLowerCase();
        const found = roleDescriptions.find(
            rd => rd.name.toLowerCase() === trimmed 
        );
        
        if (found && found.roleId != this.state.roleId) { // eslint-disable-line eqeqeq
            return 'Already exists';
        }
    
        return '';
    }

    setSoftErrorMode(mode) {
        const { softErrorMode } = this.state;
        if (softErrorMode !== mode) {
            this.setState({ softErrorMode: mode });
        }
    }

    permissionsListChangeBoxHandler(selectedPermissions, availablePermissions) {
        const isUpdated = !(selectedPermissions ===
        this.state.defaultSelectedPermissions &&
        availablePermissions === this.state.defaultAvailablePermissions);
        this.setState({
            isUpdated,
            selectedPermissions,
            availablePermissions,
        });
    }

    helpCallback() {
        //alertMessageMaximizable('Application Permissions', renderToString(<HelpRolesPermissions></HelpRolesPermissions>));
        window.open(this.state.helpUrl);
    }

    render() {
        const { verifyPermission, browserHistory } = this.props
        const {
            selectedResource,
            roleDescription,
            roleName,
            isSystemRole,
            softErrorMode,
            editing,
            modules,
        } = this.state,
        roleNameValidationError = this.getNameValidationError(softErrorMode),
        options = modules.map(m => (<option
                key={`resources-option-${m.value}`}
                value={m.value}
            >
                {m.label}
            </option>)
        );
        let canEdit = !isSystemRole && verifyPermission(SITE_MANAGEROLES);
        let {
            defaultAvailablePermissions,
            defaultSelectedPermissions,
        } = this.state;

        return (
        <div style={{}}>
            <div className="list-container-arius">
                <div className="list-header-arius">
                    <h4>{editing ? 'Edit Role' : 'Add Role'}</h4>
                </div>

                <fieldset disabled={!canEdit}>
                    <Row>
                        <Form.Group as={Col} controlId="roleName" className='mb-3'>
                            <Form.Label>Role Name</Form.Label>
                            <Form.Control 
                                value={roleName}
                                onChange={this.handleRoleNameChange}
                                type="text"
                                style={{ width: '100%' }}
                                isInvalid={roleNameValidationError} 
                            />
                            <Form.Control.Feedback type='invalid'>{roleNameValidationError}</Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group as={Col} controlId="formControlsSelect">
                            <Form.Label>Application</Form.Label>
                            <Form.Select
                                placeholder="select"
                                disabled={editing}
                                onChange={e => this.selectResource(e.target.value)}
                                value={selectedResource}
                            >
                                <option
                                    key={'resources-option-default'}
                                    value="select"
                                >
                                    select
                                </option>
                                {options}
                            </Form.Select>
                        </Form.Group>
                    </Row>
                    <Row>
                        <Form.Group as={Col} controlId="roleDescription" className='mb-3'>
                            <Form.Label>Description</Form.Label>
                            <Form.Control 
                                as="textarea"
                                value={roleDescription}
                                onChange={this.handleRoleDescriptionChange}
                            />
                            <Form.Control.Feedback>{roleNameValidationError}</Form.Control.Feedback>
                        </Form.Group>
                    </Row>
                    <DualListBox
                        style={{height: 'calc(100vh - 385px)', padding: 0}}
                        canEdit={canEdit}
                        availableItems={defaultAvailablePermissions}
                        assignedItems={defaultSelectedPermissions}
                        availableGroup="Permissions Available"
                        assignedGroup="Selected Permissions"
                        helpCallback={this.helpCallback}
                        onChanges={this.permissionsListChangeBoxHandler}
                    />
                </fieldset>
            </div>
            <SaveButtons
                isSaveButtonDisabled={!canEdit}
                saveHandler={this.saveRole}
                backButtonHander={() => browserHistory.push('/portalManagement/roles')}
                backButtonText="Cancel"
            />
        </div>);
    }
}
 

export default DefineRoles;
