import React, { Component } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { connect } from '@app/utilities/routing';
import { 
    fetchPerformanceInfo,
    fetchPerformanceJobStatus,
} from '@app/actions/utils/performance.actions';
import ElasticPools from '@app/components/utils/presentational/performance/elasticpools';
import Databases from '@app/components/utils/presentational/performance/databases';
import PowerBi from '@app/components/utils/presentational/performance/powerbi';
import SideBar from '@app/components/utils/presentational/performance/sidebar';
import { SITE_MANAGEPERFORMANCE } from '@app/utilities/permissions';

class PerformanceLevelsTab extends Component {
    constructor(props) {
        super(props);
        this.state = {
            timerId: null,
            lastCompletion: null,
        };
        this.refreshData = this.refreshData.bind(this);
        this.levelsWarningMessage = "Settings under 100 DTU’s not recommended for production use (only for hibernation purposes)"; 
    }

    UNSAFE_componentWillMount() {
        this.refreshData();
    }

    refreshData() {
        const { userKey, dispatch } = this.props;
        this.setState({ lastCompletion: null})
        dispatch(fetchPerformanceInfo(userKey));
    }

    componentDidUpdate(prevProps) {
        console.log('PerformanceLevelsTab componentDidUpdate');
        const { userKey, dispatch, jobStatus } = this.props;
        console.log(jobStatus);
        console.log(this.state.lastCompletion);

        if (prevProps.jobStatus && prevProps.jobStatus.isBusy === false && 
            jobStatus && jobStatus.isBusy === true) {
            console.log('start on change');
            this.setState({ lastCompletion: null });
            this.startInterval();
        }
        else if (prevProps.jobStatus && prevProps.jobStatus.isBusy === true && 
            jobStatus && jobStatus.isBusy === false) {
            console.log('stop interval');
            clearInterval(this.state.timerId);
            this.setState({timerId: null, lastCompletion: jobStatus.success});
            dispatch(fetchPerformanceInfo(userKey));
        }
        else if (jobStatus && jobStatus.isBusy === true && !this.state.timerId) {
            console.log('start on start');
            this.startInterval();
        }
    }

    startInterval = () => {
        console.log('start interval');
        const timerId = setInterval(() => {
            this.checkJobStatus();
        }, 5000);
        this.setState({timerId: timerId});
    }

    componentWillUnmount() {

        if (this.state.timerId) {
            clearInterval(this.state.timerId);
        }
    }

    checkJobStatus = () => {
        const {userKey, dispatch, jobStatus} = this.props;
        console.log('checkJobStatus');
        const jobId = jobStatus ? jobStatus.jobId || '' : '';

        dispatch(fetchPerformanceJobStatus(userKey, jobId));
    }

    render() {
        const {
            dispatch,
            userKey,
            permissions,
            fetchingPerformanceInfo,
            performanceInfo,
            fetchingOptions,
            options,
            jobStatus,
            fetchingPerformanceJobStatus,
            powerbiOptions,
        } = this.props;

        let childProps = {
            dispatch: dispatch,
            userKey: userKey,
            refreshHandler: this.refreshData,
            isFetching: fetchingPerformanceInfo,
            isFetchingOptions: fetchingOptions,
            options,
            info: performanceInfo,
            jobStatus: jobStatus,
            fetchingPerformanceJobStatus: fetchingPerformanceJobStatus,
            lastCompletion: this.state.lastCompletion,
            canEdit: permissions && permissions.includes(SITE_MANAGEPERFORMANCE),
            levelsWarningMessage: this.levelsWarningMessage,
            powerbiOptions
        };

        return (
        <div style={{display: 'flex'}}>
            <SideBar/>
            <Switch>
                {/* <Route path="/utilities/performance/groups" 
                    render={props => (<Groups {...childProps}/>)}/> */}
                <Route path="/utilities/performance/elasticpools" 
                    render={props => (<ElasticPools {...childProps}/>)}/>
                <Route path="/utilities/performance/databases" 
                    render={props => (<Databases {...childProps}/>)}/>
                <Route path="/utilities/performance/powerbi" 
                    render={props => (<PowerBi {...childProps}/>)}/>
                {/* <Route path="/utilities/performance/compute" 
                    render={props => (<Compute {...childProps}/>)}/>
                <Route path="/utilities/performance/logs" 
                    render={props => (<Logs {...childProps}/>)}/> */}
                <Route path="/utilities/performance" render={props => <Redirect to='performance/elasticpools'/>}/>
            </Switch>
        </div>
        );
    }
}

const mapStateToProps = state => ({
    userKey: state.user.userKey,
    permissions: state.user.userPermissions,
    fetchingPerformanceInfo: state.utils.performance.fetchingPerformanceInfo,
    fetchingOptions: state.utils.performance.fetchingPerformanceOptions,
    performanceInfo: state.utils.performance.performanceInfo,
    options: state.utils.performance.options,
    jobStatus: state.utils.performance.jobStatus,
    fetchingPerformanceJobStatus: state.utils.performance.fetchingPerformanceJobStatus,
    powerbiOptions: state.utils.performance.powerbiOptions,
});

const mapDispatchToProps = dispatch => ({
    dispatch,
});

export default connect(mapStateToProps, mapDispatchToProps)(PerformanceLevelsTab);
