import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { saveAs } from 'file-saver';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import { showModal } from '@app/actions/modal.actions';
import { 
    createReportingTable, 
    updateReportingTable, 
    deleteReportingTable,
    requestRunReport,
    downloadReport
} from '@app/actions/analysis/reporting.actions';
import { ModalEditReport, DEFAULT_REPORT_DEFINITION } from './modalEditReport';
import { notifyError } from '@app/utilities/notifier';
import { retrieveReportingTables, refreshReportingDatabase } from '@app/actions/analysis/reporting.actions';
import { Grid, Button } from '@arius';
import { 
    createPermissionChecker, 
    WORKSPACE_DELETEEXTRACTTABLES, 
    WORKSPACE_CREATEEDITEXTRACTTABLES,
    WORKSPACE_UPDATEEXTRACTTABLES,
    WORKSPACE_DOWNLOADEXTRACTTABLES
} from '@app/utilities/permissions';
import $ from 'jquery';
import { getAppSettings } from '../../../../config';
import { notAuthorized } from '@app/shared/containers/conditionalLink';
import { MESSAGE_NOT_AUTHORIZED_TO_ACCESS } from '@app/utilities/permissions';

const style = {
    nonEditableInput: {
      position: 'absolute',
      visibility: 'hidden',
      top: '-50px',
      // pointerEvents: 'none',
    },
  };

class ReportTablesList extends Component {
    static propTypes = {
        extractDefinitions: PropTypes.array,
        isFetching: PropTypes.bool,
        firstSearchPerformed: PropTypes.bool,
        extractKickingOff: PropTypes.bool,
        isFetchingWideCsv: PropTypes.bool,
        isFetchingNarrowCsv: PropTypes.bool,
        userKey: PropTypes.string,
        databaseId: PropTypes.number,
        databaseName: PropTypes.string,
        dispatch: PropTypes.func,
        editClickHandler: PropTypes.func,
        currentWorkspace: PropTypes.object,
        apiKey: PropTypes.string,
        exportCsv: PropTypes.func,
        csv: PropTypes.object,   
        canCreateEditExtractTable: PropTypes.bool,
        canDeleteExtractTable: PropTypes.bool,
    };
    constructor(props) {
        super(props);
        this.state = {
            showModalRun: false,
            showModalEdit: false,
            currentRecord: {mappings: [], formulas: []},
        };
        this.create = this.create.bind(this);
        this.edit = this.edit.bind(this);
        this.save = this.save.bind(this);
        this.getActionItems = this.getActionItems.bind(this);
        this.getGrid = this.getGrid.bind(this);
        this.getActionItems = this.getActionItems.bind(this);
        this.getDetailActionItems = this.getDetailActionItems.bind(this);
        this.getDetailTemplate = this.getDetailTemplate.bind(this);
        this.refreshReportingTables = this.refreshReportingTables.bind(this);
        this.refreshReportingTablesHandler = this.refreshReportingTablesHandler.bind(this);
        this.promptToRequestReportRun = this.promptToRequestReportRun.bind(this);
        this.download = this.download.bind(this);
        this.copyCsvUrlToClipboard = this.copyCsvUrlToClipboard.bind(this);
        this.delete = this.delete.bind(this);
    }

    get canDownloadExtractTable() {
        const { currentWorkspace, userPermissions } = this.props;
        if (currentWorkspace === null) return false;
        if (currentWorkspace.permissions.indexOf(WORKSPACE_DOWNLOADEXTRACTTABLES) === -1 &&
            userPermissions.indexOf(WORKSPACE_DOWNLOADEXTRACTTABLES) === -1) {
          return false;
        }
        return true;
    }

    get existingNames(){
        let { currentRecord } = this.state;
        const { reportingTables: data } = this.props;
        return data ? data.filter(x=> x.reportDefinitionId !== currentRecord.reportDefinitionId)
            .map(x=> x.name.toLowerCase().trim()) : [];
    }

    componentDidMount() {
        this.refresh();
    }

    refresh() {
        const { userKey, dispatch, currentWorkspace } = this.props;
        if (currentWorkspace){
            dispatch(retrieveReportingTables({ userKey, databaseId: currentWorkspace.id }));
        }
    }

    componentDidUpdate(prevProps) {
        const { csv, currentWorkspace } = this.props;
        const { csvId } = this.state;
     
        if (csv && csvId && csvId === csv.id) {
            $(`#action-overlay-${csvId}`).click(); 
            this.setState({ csvId: '' });
            saveAs(csv.file, csv.fileName);
        }

        if (currentWorkspace && prevProps.currentWorkspace !== currentWorkspace) {
            this.refresh();
        }
    }

    copyCsvUrlToClipboard(reportDefinition) {
        const  {apiKey, currentWorkspace } = this.props;

        if (!this.canDownloadExtractTable) {
            notifyError(MESSAGE_NOT_AUTHORIZED_TO_ACCESS);
            return;
        }
    
        getAppSettings().then(settings => {
          const { baseServiceUrl } = settings;
          const url = 'https://' + baseServiceUrl + '/reporting/wide/' + currentWorkspace.name + '/' + reportDefinition.name + '?apikey=' + apiKey;
          const copyText = document.getElementById('csv-url');
          copyText.value = encodeURI(url);
          copyText.style.visibility = '';
          copyText.select();
          document.execCommand('Copy');
          copyText.style.visibility = 'hidden';
        });
    }    

    refreshReportingTablesHandler(reportDef) {
        const { dispatch } = this.props;

        const messageItems = [
            <div key={11} style={{marginBottom: 15}}>Are you sure you want to update the following data model?</div>,
            <div key={10} style={{marginBottom: 15}}><b>{`${reportDef.name}`}</b></div>,
            <div key={12}>Note this process may take multiple hours to update.  View the “Status Reports” page to monitor progress.</div>,
        ];

        const yesClickHandler = () => this.refreshReportingTables(reportDef.id);
        const noClickHandler = () => {};
        const action = showModal(
            'confirmation',
            messageItems,
            yesClickHandler,
            noClickHandler
        );
        dispatch(action);
    }

    refreshReportingTables(id) {
        const { userKey, databaseId, dispatch } = this.props;
        dispatch(refreshReportingDatabase({ userKey, databaseId, definitionId:id }));
    }

    create(e, id) {
        e.stopPropagation();  // don't let the row expand fire...
        $(`#action-overlay-${id}`).click();  // make action menu close.  TODO find better solution...

        const { databaseId } = this.props;
        if (!databaseId){
            notifyError('Please select a database');
            return;
        }
        
        let verifyPermission = this.getPermissionChecker();
        if (!verifyPermission(WORKSPACE_CREATEEDITEXTRACTTABLES)) {
            notAuthorized();
            return;
        }

        var currentRecord = {...DEFAULT_REPORT_DEFINITION};
        this.setState({showModalEdit: true, currentRecord});
    }

    edit(e, currentRecord) {
        e.stopPropagation();  // don't let the row expand fire...
        $(`#action-overlay-${currentRecord.reportDefinitionId}`).click();  // make action menu close.  TODO find better solution...

        // users should be able to see definition
        // let verifyPermission = this.getPermissionChecker();
        // if (!verifyPermission(WORKSPACE_CREATEEDITEXTRACTTABLES)) {
        //     notAuthorized();
        //     return;
        // }

        const { databaseId } = this.props;
        if (!databaseId){
            notifyError('Please select a database');
            return;
        }
        this.setState({showModalEdit: true, currentRecord: JSON.parse(JSON.stringify(currentRecord))});
    }

    delete(e, currentRecord) {
        const { userKey, databaseId, dispatch } = this.props;
        var reportingTableId = currentRecord.reportDefinitionId;
         
        e.stopPropagation();  // don't let the row expand fire...
        $(`#action-overlay-${currentRecord.reportDefinitionId}`).click();  // make action menu close.  TODO find better solution...

        let verifyPermission = this.getPermissionChecker();
        if (!verifyPermission(WORKSPACE_DELETEEXTRACTTABLES)) {
            notAuthorized();
            return;
        }

        let deleteMessageItems = [
            'Are you sure you want to delete the following Reporting table:',
            <li  key='delpr' style={{ fontWeight: 800 }}>{currentRecord.name}</li>,
        ];

        const yesClickHandler = () => {
            dispatch(deleteReportingTable({userKey, databaseId, reportingTableId}));
        };
        const noClickHandler = () => {};
        const action = showModal(
            'confirmation',
            deleteMessageItems,
            yesClickHandler,
            noClickHandler
        );
        dispatch(action);
    }

    getPermissionChecker = () => {
        const { currentWorkspace, userPermissions } = this.props;
        return createPermissionChecker(
            currentWorkspace ? [...userPermissions, ...currentWorkspace.permissions] : userPermissions);
    }

    getActionItems(row) {
        const {apiKey, databaseName, isFetchingWideCsv}= this.props;
        const popover = (
            <Popover id="todListItemActions">
                <Button toolTip="Run Report" iconName="fa-table" onClick={(e) => this.promptToRequestReportRun(e, row)}/>
                <Button toolTip="Edit Report" iconName="edit" onClick={(e)=> {this.edit(e, row)}}/>
                <Button toolTip="Download CSV" iconName='fa-download' isWaiting={isFetchingWideCsv} onClick={(e) => apiKey === null || apiKey === "" ? notifyError("You must have API key assigned") : this.download(e, row, databaseName)}/>
                <Button toolTip="Copy URL to clipboard" iconName="content_copy" onClick={(e) => apiKey === null || apiKey === ""? notifyError("You must have API key assigned") :this.copyCsvUrlToClipboard(row)}/>
                <Button toolTip="Delete" iconName="delete" onClick={(e)=> {this.delete(e, row)}}/>
            </Popover>
        );

        return (
            <OverlayTrigger  trigger="click" placement="auto" overlay={popover} rootClose={true} >
                <i
                    id={`action-overlay-${row.reportDefinitionId}`}
                    key={`action-items-${row.reportDefinitionId}`}
                    onClick={(e) => e.stopPropagation()}
                    className="material-icons menu-icon"
                >
                    more_horiz
                </i>
            </OverlayTrigger>
        )
    }

    getDetailActionItems(row) {
        return <span>
            <Button toolTip="Download Report" iconName="fa-download" onClick={()=> {alert('not implemented')}}/>
            <Button toolTip="Delete" iconName="delete" onClick={()=> {alert('not implemented')}}/>
        </span>;
    }

    getDetailTemplate(row) {
        //const { reportingTables: data } = this.props;
        var data = [ {
            id: 1000,
            target: 'Allocations 1',
            source: 'Extract Table X',
            valDate: '2/1/2020',
            lastModifiedBy: 'Fake Person',
            lastModified: new Date('2/27/1975 11:13'),
        },
        {
            id: 1001,
            target: 'Allocations 1',
            source: 'Extract Table X',
            valDate: '3/1/2020',
            lastModifiedBy: 'Bethany Cass',
            lastModified: new Date('3/3/2019 19:02'),
        },]
        let columns = [
            { field: 'id', headerText: 'Id', visible: false},
            { field: 'target', headerText: 'Target Table', width: 300},
            { field: 'source', headerText: 'Source Table', width: 300},
            { field: 'valDate', headerText: 'Valuation Date'},
            { field: 'lastModifiedBy', headerText: 'Last Modified By'},
            { field: 'lastModified', headerText: 'Last Modified'},
            { headerText: 'Actions', template: this.getDetailActionItems},
        ];
        
        return <div>
            <div>History of report runs</div>
            <Grid 
                columns={columns}
                data={data}
                height='auto'/>
        </div>
    }

    getTemplateRecordCount(r){
        return <span>{r.recordCount.toLocaleString()}</span>;
    }

    getGrid() {
        const { reportingTables: data } = this.props;
        let columns = [
            { field: 'reportDefinitionId', headerText: 'Id', visible: false},
            { field: 'name', headerText: 'Name'},
            { field: 'description', headerText: 'Description'},
            // { field: 'leftTableFriendlyName', headerText: 'Left'},
            // { field: 'rightTableFriendlyName', headerText: 'Right'},
            { field: 'updateDate', headerText: 'Last Modified'},
            { field: 'updateBy', headerText: 'Last Modified By'},
            { field: 'lastSubmitted', headerText: 'Last Submitted'},
            { field: 'lastSubmittedBy', headerText: 'Last Submitted By'},
            { field: 'recordCount', headerText: 'Record Count', template: this.getTemplateRecordCount},
            { field: 'reportDefinitionId', headerText: 'Actions', template: this.getActionItems},
        ];
        
        return <Grid 
            columns={columns}
            data={data}
            height='calc(100vh - 240px)' 
            //detailTemplate={this.getDetailTemplate}
        />
    }

    save(req) {
        const { userKey, databaseId, dispatch } = this.props;
        this.setState({showModalEdit: false});
        if (req.reportDefinitionId < 1){
            dispatch(createReportingTable({ userKey, databaseId, req }));
        } else {
            dispatch(updateReportingTable({ userKey, databaseId, req }));
        }
    }

    promptToRequestReportRun(e, record) {
        const { dispatch, userKey, databaseId } = this.props;

        let verifyPermission = this.getPermissionChecker();
        console.log('promptToRequestReportRun');
        if (!verifyPermission(WORKSPACE_UPDATEEXTRACTTABLES)) {
            notAuthorized();
            return;
        }

        const deleteMessageItems = [
            <li key={record.reportDefinitionId} style={{ fontWeight: 800 }}>
                {record.name}
            </li>,
        ];


        deleteMessageItems.unshift(
            'Run the following report?  Previous results will be overwritten.'
        );

        const yesClickHandler = () => {
            dispatch(requestRunReport({userKey, databaseId, reportDefinitionId: record.reportDefinitionId}));
        };
        const noClickHandler = () => {};
        const action = showModal(
            'confirmation',
            deleteMessageItems,
            yesClickHandler,
            noClickHandler
        );
        dispatch(action);
    }

    download(e, record) {
        const { dispatch, userKey, databaseId, databaseName, apiKey } = this.props;
        e.stopPropagation();  // don't let the row expand fire...
        $(`#action-overlay-${record.reportDefinitionId}`).click();  // make action menu close.  TODO find better solution...
        
        if (!this.canDownloadExtractTable) {
            notifyError(MESSAGE_NOT_AUTHORIZED_TO_ACCESS);
            return;
        }
        
        dispatch(downloadReport(
            {
                userKey, 
                databaseId, 
                reportDefinitionId: record.reportDefinitionId,
                reportDefinitionName: record.name, 
                databaseName, 
                apiKey
            }));
        this.setState({csvId: record.reportDefinitionId});
        setTimeout(()=> {
            $(`#action-overlay-${record.reportDefinitionId}`).click(); 
        }, 500);
    }


    render() {
        const { showModalEdit, currentRecord } = this.state;
        const { databaseId } = this.props;

        let verifyPermission = this.getPermissionChecker();
        const canEdit = verifyPermission(WORKSPACE_CREATEEDITEXTRACTTABLES);

        return (
            <div className="list-container-arius">
                <div className="list-header-arius">
                    <span style={{display:'flex'}}>
                        <h4>Reporting Tables</h4>
                    </span>
                    <input
                        style={style.nonEditableInput}
                        type="text"
                        id="csv-url">
                    </input>
                    <Button
                        toolTip = "Create new table definition"
                        mode="add"
                        onClick={(e) => this.create(e, 0)}
                    />
                </div>
                {this.getGrid()}
                <ModalEditReport 
                    show={showModalEdit} 
                    closeHandler={()=> this.setState({showModalEdit: false})}
                    saveHandler={this.save}
                    databaseId={databaseId}
                    record={currentRecord}
                    existingNames={this.existingNames}
                    canEdit={canEdit}
                />
            </div>
        )
    }
}

export default ReportTablesList;
