import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Modal, Form, Button, ButtonGroup  } from 'react-bootstrap';
import * as Colors from '../../../utilities/colors';

class FormulaModal extends Component {
  static propTypes = {
    modalId: PropTypes.string.isRequired,
    closeHandler: PropTypes.func.isRequired,
    columnIndex: PropTypes.number,
    column: PropTypes.object,
    columns: PropTypes.array,
    measures: PropTypes.array,
    showModal: PropTypes.bool,
    validateFormulaHandler: PropTypes.func,
    clearErrorMessageHandler: PropTypes.func,
    formulaChangeHandler: PropTypes.func,
    errorMessage: PropTypes.string,
  };
  constructor(props) {
    super(props);
    this.state = {
      formula: '',
    };
    this.saveHandler = this.saveHandler.bind(this);
    this.cancel = this.cancel.bind(this);
  }

  componentDidMount() {

  }

  componentDidUpdate(prevProps, prevState) {
    const { column, measures, showModal } = this.props;
    if (prevProps.column !== column || (prevProps.showModal !== showModal && showModal && column)) {
      this.setState({ formula: column.formula ? column.formula : '' });
      this.validateFormula(measures, column);
    }
    if (prevState.formula !== this.state.formula) {
      this.validateFormula(measures, column);
    }
  }

  validateFormula(measures, column) {
    const columnsToValidate = measures.slice();
    const newColumn = JSON.parse(JSON.stringify(column));
    newColumn.formula = this.state.formula;
    columnsToValidate.push(newColumn);
    this.props.validateFormulaHandler(columnsToValidate);
  }

  saveHandler() {
    this.props.formulaChangeHandler(this.props.columnIndex, this.state.formula);
    this.props.clearErrorMessageHandler();
    this.props.closeHandler();
  }

  cancel() {
    this.props.clearErrorMessageHandler();
    this.props.closeHandler();
    this.setState({ formula: '' });
  }

  insertIntoFormula(text, operand) {
    if (text.includes(' ') && !operand) {
      text = `"${text}"`;
    }
    let { formula } = this.state;
    const
      formulaTextArea = document.getElementById('formulaTextArea'),
      scrollPos = formulaTextArea.scrollTop;
    let caretPos = formulaTextArea.selectionStart;

    const
      front = (formulaTextArea.value).substring(0, caretPos),
      back = (formulaTextArea.value).substring(formulaTextArea.selectionEnd, formulaTextArea.value.length);

    formula = front + text + back;
    caretPos = caretPos + text.length;

    formulaTextArea.value = formula;
    formulaTextArea.selectionStart = caretPos;
    formulaTextArea.selectionEnd = caretPos;
    formulaTextArea.focus();
    formulaTextArea.scrollTop = scrollPos;

    this.setState({ formula });
  }

  render() {
    const { measures, errorMessage } = this.props;
    const { formula } = this.state;

    return (
      <Modal id={this.props.modalId} show={this.props.showModal} onHide={this.cancel} enforceFocus
        dialogClassName="calculated-modal"
      >
        <Modal.Header style={{ backgroundColor: Colors.blue, color: '#FFFFFF' }} closeButton>
          <Modal.Title>
              Calculated Measure
              <h6>
                Enter formula for the Calculated Measure using available Measures and Operators listed below.
            </h6>
            <h6>For example: (Salvage + Subrogation) * .5</h6>
        </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div style={{ display: 'flex', flexFlow: 'row nowrap', justifyContent: 'space-between' }}>
            <Form.Group controlId="measuresSelect">
              <Form.Label>Available Measures</Form.Label>
              <Form.Select
                onDoubleClick={(e) => this.insertIntoFormula(e.target.value)}
                multiple style={{ height: 150, width: 250 }}>
                {measures.map((measure, idx) => (
                  <option key={`measure-${idx}`}
                    value={measure.factColumnDisplayName}>{measure.factColumnDisplayName}</option>
                ))}
              </Form.Select>
            </Form.Group>
            <div style={{ display: 'flex', alignItems: 'center', margin: 'auto' }}>
              <ButtonGroup role="group" aria-label="...">
                <Button variant="outline-dark fa fa-plus"
                  onClick={() => this.insertIntoFormula(' + ', true)}>
                </Button>
                <Button variant="outline-dark fa fa-minus"
                  onClick={() => this.insertIntoFormula(' - ', true)}>
                </Button>
                <Button variant="outline-dark fa fa-times"
                  onClick={() => this.insertIntoFormula(' * ', true)}>
                </Button>
                <Button variant="outline-dark"
                  onClick={() => this.insertIntoFormula(' (', true)}>
                  (
                  </Button>
                <Button variant="outline-dark"
                  onClick={() => this.insertIntoFormula(') ', true)}>
                  )
                </Button>
              </ButtonGroup>
            </div>
          </div>
          <div>
            <Form.Group controlId="formulaTextArea">
              <Form.Label>Formula</Form.Label>
              <Form.Control
                spellCheck={false}
                as="textarea"
                placeholder="Enter Formula Here"
                value={formula}
                onChange={(e) => this.setState({ formula: e.target.value })} />
            </Form.Group>
            <h5 style={{ color: Colors.red }}>{errorMessage}</h5>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="arius"
            data-dismiss="modal"
            disabled={errorMessage || !formula} onClick={this.saveHandler}
          >Save</Button>
          <Button variant="arius" data-dismiss="modal" onClick={this.cancel}>Cancel</Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default FormulaModal;
