import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Container, Row, Col, Form, InputGroup, FormControl } from 'react-bootstrap';
import { DatePickerComponent } from '@syncfusion/ej2-react-calendars';

class SearchFilter extends Component {
    static propTypes = {
        submitQuery: PropTypes.func,
        handleChange: PropTypes.func,
        exportToCSV: PropTypes.func,
    };
    constructor(props) {
        super(props);
        this.handleDateChange = this.handleDateChange.bind(this);
    }

    getLabel(text) {
        return (
            <div style={{display: 'flex', alignItems: 'center', marginRight: 6}}>
                <span style={{minWidth: 100, fontWeight: 'bold', textAlign: 'right', verticalAlign: 'middle'}}>{text}</span>
            </div>
        )
    }

    handleDateChange(e) {
        const { handleChange } = this.props;
        const { id, value } = e.model ? e.model : e.target;
        if (value) {
            handleChange({target: {id, value}});
        }
    }

    render() {
        const { queryValue, resultCount, dateFrom, dateTo, handleChange, submitQuery } = this.props;
        const isTrue = true;

        let s = {paddingTop: 10};

        return <Container style={{paddingTop: 10, paddingBottom:10}}>
            <Row>
            <Col xs={3}>
                <Form.Group as={Row}>
                    <Form.Label column sm={3} style={{textAlign: 'right', paddingRight: 0}}>From</Form.Label>
                    <Col sm={9}>
                        <DatePickerComponent 
                            value={dateFrom}
                            id='dateFrom'
                            style={{height:38}}
                            className='form-control'
                            strictMode={true}
                            showClearButton={false}
                            onChange={this.handleDateChange}
                            blur={this.handleDateChange}
                            max={dateTo}/>
                    </Col>
                </Form.Group>
            </Col>
            <Col xs={3}>
                <Form.Group as={Row}>
                    <Form.Label column sm={3} style={{textAlign: 'right', paddingRight: 0}}>To</Form.Label>
                    <Col sm={9}>
                        <DatePickerComponent
                            id='dateTo'
                            style={{height:38}}
                            value={dateTo}
                            strictMode={true}
                            showClearButton={false}
                            onChange={this.handleDateChange}
                            blur={this.handleDateChange}
                            min={dateFrom}/>
                    </Col>
                </Form.Group>
            </Col>
            <Col xs={4}>
                <InputGroup>
                    <FormControl id="queryValue" value={queryValue} onChange={handleChange}/>
                    <InputGroup.Text onClick={() => { submitQuery({ isTrue }); } } style={{}}>Search</InputGroup.Text>
                </InputGroup>
            </Col>
            <Col xs={2}>
                <Form.Group as={Row}>
                    <Form.Label column sm={9} style={{textAlign: 'right'}}>Results/Page</Form.Label>
                    <Form.Control column sm={3} type="text"
                    id="resultCount"
                    value={resultCount}
                    onChange={handleChange}
                    style={{width: 45, paddingLeft: '.4rem', paddingRight: '.4rem'}}  />
                </Form.Group>
            </Col>
        </Row>
      </Container>
        let content = (
        <div className='row' style={{paddingBottom: 10, paddingLeft: 10, paddingRight: 10}}>
            <div className='col-12 col-sm-3' style={{...s, display: 'flex'}}>
                {this.getLabel('From')}
                <DatePickerComponent 
                    value={dateFrom}
                    id='dateFrom'
                    strictMode={true}
                    showClearButton={false}
                    onChange={this.handleDateChange}
                    blur={this.handleDateChange}
                    max={dateTo}/>
            </div>
            <div className='col-12 col-sm-3' style={{...s, display: 'flex'}}>
                {this.getLabel('To')}
                <DatePickerComponent
                    id='dateTo'
                    value={dateTo}
                    strictMode={true}
                    showClearButton={false}
                    onChange={this.handleDateChange}
                    blur={this.handleDateChange}
                    min={dateFrom}/>
            </div>
            <div className="input-group col-12 col-sm-3" style={{paddingTop: 10}}>
                <input type="text" id="queryValue" style={{maxHeight: 29}} className="form-control" value={queryValue} onChange={handleChange}/>
                <div className="input-group-append" style={{display: 'block'}}>
                    <span className="input-group-text" style={{maxHeight: 29}} onClick={() => { submitQuery({ isTrue }); } } >Search</span>
                </div>
            </div>
            <div className='col-12 col-sm-3' style={{...s, display: 'flex', justifyContent: 'center'}}>
                {this.getLabel('Results/Page')}
                <input
                    className="form-control"
                    type="text"
                    id="resultCount"
                    value={resultCount}
                    onChange={handleChange}
                    style={{width: 45, paddingLeft: '.4rem', maxHeight:29, paddingRight: '.4rem'}} 
                />
            </div>
        </div>
        )

        return <div>{content}</div>;
    }
}

export default SearchFilter;
