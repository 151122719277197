import React from 'react';
import PropTypes from 'prop-types';
import * as Colors from '@app/utilities/colors';

const SaveButtons = (
  { backButtonText, saveHandler, backButtonHander, isSaveButtonDisabled },
) => (
  <div
    style={{
      position: 'fixed',
      bottom: 0,
      left: 0,
      backgroundColor: Colors.grey,
      width: '100vw',
      padding: 10,
      height: '54px',
    }}
  >
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end',
      }}
    >
      {saveHandler
        ? <button
          key="save"
          type="button"
          onClick={saveHandler}
          disabled={isSaveButtonDisabled}
          className="btn purple-button-hover"
          >
            Save
          </button>
        : <div />}
      <button
        key="cancel"
        type="button"
        className="btn purple-button-hover"
        onClick={backButtonHander}
      >
        {backButtonText}
      </button>
    </div>
  </div>
);

SaveButtons.propTypes = {
  backButtonText: PropTypes.string,
  saveHandler: PropTypes.func,
  backButtonHander: PropTypes.func,
  isSaveButtonDisabled: PropTypes.bool,
};

export default SaveButtons;
