import React, { Component } from 'react';
import { OverlayTrigger, Popover, Tooltip} from 'react-bootstrap';
import { Grid, Button } from '@arius';
import ProjectInfo from './projectInfo';
import ProjectEditModal from './projectEditModal';
import $ from 'jquery';
import * as GlobalStyles from '@app/utilities/globalStyles';



class ProjectsGrid extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            showModal: false, 
            selectedProject: null
        };
        this.getProjectInfoTemplate = this.getProjectInfoTemplate.bind(this);
        this.getActionItems = this.getActionItems.bind(this);
        this.getUDOWarning = this.getUDOWarning.bind(this);
        this.editProjectName = this.editProjectName.bind(this);
        this.saveProjectName = this.saveProjectName.bind(this);
        this.getCategoryTemplate = this.getCategoryTemplate.bind(this);
        this.getTemplateProjectName = this.getTemplateProjectName.bind(this);
    }

    getProjectInfoTemplate(r) {
        const { currentWorkspace } = this.props;
        return <ProjectInfo project={r} currentWorkspace={currentWorkspace}/>
    }

    handleActionClick(handler, project){
        $(`#action-overlay-${project.projectId}`).click();  // make action menu close.  TODO find better solution...
        handler(project);
    }

    getActionItems(project) {
        const { 
            checkOutHandler,
            openReadOnlyHandler,
            deleteProjectHandler,
            copyProjectHandler,
            seeVersions,
            reviewProjectHandler,
            undoCheckoutHandler
        } = this.props;
        let id = project.projectId;
        let isCheckedOut = !!project.checkedOutUser;
        let h = this.handleActionClick;

        const popover = (
            <Popover>
                <Button 
                    toolTip={`Open${project.checkoutStatus !== 'Owned' ? ' & Check out' : ' local file'}`} 
                    iconName="fa-folder-open" 
                    onClick={()=> {h(checkOutHandler,project)}}/>
                <Button 
                    toolTip="Open & Read Only" 
                    iconName="remove_red_eye" 
                    onClick={()=> {h(openReadOnlyHandler,project)}}/>
                <Button 
                    toolTip="Delete" 
                    iconName="delete" 
                    onClick={()=> {h(deleteProjectHandler,project)}}/>
                <Button 
                    toolTip="Copy Project" 
                    iconName="content_copy" 
                    onClick={()=> {h(copyProjectHandler,project)}}/>
                <Button 
                    toolTip="Version History" 
                    iconName="fa-code-fork" 
                    onClick={()=> {h(seeVersions,project)}}/>
                <Button 
                    toolTip="Review" 
                    iconName="rate_review" 
                    onClick={()=> {h(reviewProjectHandler,project)}}/>
                {
                    isCheckedOut ? 
                    <Button 
                        toolTip="Undo Checkout" 
                        iconName="undo" 
                        onClick={()=> {h(undoCheckoutHandler,project)}}/> : null
                }
            </Popover>
        );

        return (
        <OverlayTrigger  trigger="click" placement="left" overlay={popover} rootClose={true} >
            <i
                id={`action-overlay-${id}`}
                key={`action-items-${id}`}
                onClick={(e) => e.stopPropagation()}
                className="material-icons menu-icon"
            >
                more_horiz
            </i>
        </OverlayTrigger>
        )
    }
    
    getUDOWarning(project) {
        const { currentUdoVersionNbr } = this.props;
        const warningTooltipMessage = `UDO Library version outdated...project's version: ${project.tableLibraryVersion}`;
        
        let outdated = currentUdoVersionNbr !== project.tableLibraryVersion && currentUdoVersionNbr !== 0;
        return outdated ? <Button toolTip={warningTooltipMessage} mode="error"/> : null;
    }

    shouldComponentUpdate(nextProps, nextState) {
        const { searchFilterOpen, data } = this.props;
        return nextProps.selectedProjectIds.length === 0 ||
            nextProps.data !== data || 
            nextProps.searchFilterOpen !== searchFilterOpen;
    }

    editProjectName(r) {
        this.setState({showModal: true, selectedProject: r});
    }

    saveProjectName(newName, projectId) {
        const { validateText, updateProjectName } = this.props;
        let isValid = validateText(newName, projectId);
        if (isValid) {
            updateProjectName(projectId, newName);
            this.setState({showModal: false});
        }
    }

    getCategoryTemplate(r){
        // return <div style={{display: 'flex'}}>
        //     {r.categories.map((c) => <div 
        //         key={`cat${c.projectId}-${c.categoryId}`}
        //         style={{backgroundColor:`${c.color}`, height: '20px', width: '6px', marginRight: '2px',
        //         borderRadius: 5}}>
        //         </div>)}
        // </div>


        return <div style={{display: 'flex'}}>
                    {r.categories.map((c) => <OverlayTrigger
                        key={`cat${c.projectId}-${c.categoryId}`}
                        placement="left"
                        focus
                        overlay={<Tooltip id={'tootipId_' + c.categoryId} 
                        style={GlobalStyles.toolTipFix}>{c.categoryName}
                        </Tooltip>}
                    >
                    <div 
                        
                        style={{backgroundColor:`${c.color}`, height: '20px', width: '8px', marginRight: '2px',
                        borderRadius: 0}}>
                        </div>
                    </OverlayTrigger>)}
        </div>
    }

    getTemplateLocked = (r) => r.locked ? (
        <div style={{ margin: '5px'}}><i className="fa fa-lock" aria-hidden="true"></i></div>
     ) : null;

    getTemplateProjectName(r){
        return <span className="edit-text" onDoubleClick={()=> {this.editProjectName(r)}}>{r.projectName}</span>;
    }

    getTemplateCheckedOutBy(r) {
        return <span>{!!r.checkedOutUser ? r.checkedOutUser : ''}</span>;
    }

    getTemplateCheckedOut(r){
        return <span>{!!r.checkedOutUser && r.checkedOutTimestamp ? r.checkedOutTimestamp: ''}</span>;
    }

    render() {
        const {
            selectHandler,
            searchFilterOpen,
            data,
        } = this.props;
        const {
            showModal,
            selectedProject
        } = this.state;

        let hasLockedRow = data.find(x=> x.locked) ? true : false;
        let columns = [
            { type:'checkbox', width:'30'}, // NOTE: make sure a primary key column is defined when using checkbox column
            { field: 'projectId', headerText: 'Lock', visible: hasLockedRow, enableTooltips: false, width: 45, template: this.getTemplateLocked},
            { field: 'projectId', headerText: 'ID',  width: '45', isPrimaryKey: true},
            { field: 'projectId', headerText: 'Category', template: this.getCategoryTemplate, width: 70, enableTooltips: false},
            { field: 'projectId',  headerText: ' ', template: this.getProjectInfoTemplate, width: '30', enableTooltips: false},
            { headerText: 'Project Name', field: 'projectName', width: 300, template: this.getTemplateProjectName},
            { headerText: 'Valuation Date', field: 'valuationDate', type:'dateUTC'},
            { headerText: 'Data Structure', field: 'dataShape', width: 200},
            { headerText: 'Checked Out By', field: 'checkedOutUser', template: this.getTemplateCheckedOutBy, width: '150'},
            { headerText: 'Checked Out', field: 'checkedOutTimestamp', type: 'datetime', template: this.getTemplateCheckedOut},
            { headerText: 'Last Updated By', field: 'updatedBy'},
            { headerText: 'Last Updated', field: 'updatedDate'},
            { field: 'projectId', headerText: 'UDO', enableTooltips: false, width: 50, template: this.getUDOWarning},
            { headerText: 'Status', field: 'status', width: 100},
            { field:'projectId', headerText: 'Actions', template: this.getActionItems}
        ];
        
        return (
            <div>
                <Grid 
                    columns={columns}
                    checkBoxHandler={(e) => selectHandler(e)}
                    data={data}
                    height={`calc(100vh - ${searchFilterOpen ? 535 : 275}px)`}
                    editOptions={this.editOptions}
                    getEditMessage={(row) => `<span style='font-weight:bold'>${row.projectName}</span>` }
                    beginEdit={(r) => {this.currentProject = r}}
                    onSave={this.saveProjectName}
                    showColumnChooser={true}
                    persistenceKey={'grid_projects'}
                />   
                <ProjectEditModal
                    show={showModal}
                    project={selectedProject}
                    closeHandler={()=> {this.setState({showModal: false})}}
                    saveHandler={this.saveProjectName}
                />             
            </div>
        )
    }
}


export default ProjectsGrid;