import React, { Component } from 'react';
import PropTypes from 'prop-types';
import * as Colors from '../../../utilities/colors';
import * as api from '@app/serviceApi/analytics.serviceApi';
import { FormLabel, OverlayTrigger, Tooltip } from 'react-bootstrap';

const style = {
  menuIcon: {
    cursor: 'pointer',
    fontSize: '20px',
    padding: '5px',
    color: Colors.darkGrey
  },
  nonEditableInput: {
    position: 'absolute',
    visibility: 'hidden',
    top: '-50px',
    // pointerEvents: 'none',
  },
  settingsContent: { 
    padding: '10px',
    display: 'flex',
    flexFlow: 'column nowrap',
  },
  settingsRow: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    padding: '5px 10px 20px 10px',
    border: '1px solid #ddd',
    backgroundColor: 'white',
  },
};

class User extends Component {
    static propTypes = {
        apiKey: PropTypes.string,
        requestKey: PropTypes.func,
        deleteApiKey: PropTypes.func,
    };

    constructor(props) {
        super(props);
        this.state = {};
        this.copyToClipBoard = this.copyToClipBoard.bind(this);
        this.getAdvAnalyticsValidationMessage = this.getAdvAnalyticsValidationMessage.bind(this);
        this.handleKey = this.handleKey.bind(this);
        this.buffer = [];
    }

    componentDidMount() {
        document.addEventListener('keydown', this.handleKey);
    }

    componentWillUnmount() {
        document.removeEventListener('keydown', this.handleKey);
    }

    handleKey(e){
        // look for the key sequence 1==11...
        const key = e.key.toLowerCase();
        this.buffer.push(key);
        let s = this.buffer.toString();
        if (s.includes('1,=,=,1,1')){
            this.getAdvAnalyticsValidationMessage();
            this.buffer = [];
        }
    }

    getAdvAnalyticsValidationMessage() {
        api.getConfigValidationMessage(this.props.userKey).then(r=> console.error(r))
    }

    copyToClipBoard() {
        const copyText = document.getElementById('api-key-input');
        copyText.style.visibility = '';
        copyText.select();
        document.execCommand('Copy');
        copyText.style.visibility = 'hidden';
    }

    render() {
        const { requestKey, deleteApiKey } = this.props;
        let { apiKey } = this.props;
        if (!apiKey) {
        apiKey = 'You currently have no API key assigned';
        }
        const copiableField = (
        <input
            style={style.nonEditableInput}
            type="text"
            value={apiKey}
            onChange={() => {}}
            id="api-key-input">
        </input>
        );

        return (
        <div style={style.settingsContent}>
            <div style={style.settingsRow}>
            <OverlayTrigger
                placement="left"
                overlay={
                <Tooltip id="tooltip">
                    Copy & Paste this text to be used in Excel & Power BI for Web API key when making use of Extract Table for the first time
                </Tooltip>
                }
            >
                <div style={{ display: 'flex', alignItems: 'flex-end' }}>
                    <span style={{ position: 'relative', top: '5px' }}>
                        <FormLabel>API Key:&nbsp;&nbsp;</FormLabel>
                    </span>
                    <span>
                        {apiKey}
                    </span>
                </div>
            </OverlayTrigger>
            <div style={{ display: 'flex', margin: '0px 0px 0px 20px', alignItems: 'flex-end' }}>
                <OverlayTrigger
                placement="left"
                overlay={
                    <Tooltip id="tooltip">
                    Request New API Key
                    </Tooltip>
                }
                >
                <i
                    style={style.menuIcon}
                    className="fa fa-key hover-purple-negative"
                    key="key-icon"
                    aria-hidden="true"
                    onMouseDown={() => requestKey()}></i>
                </OverlayTrigger>
                <OverlayTrigger
                placement="left"
                overlay={
                    <Tooltip id="tooltip">
                    Copy API Key to Clipboard
                    </Tooltip>
                }
                >
                <i
                    style={style.menuIcon}
                    className="fa fa-clipboard hover-purple-negative"
                    key="clipboard-icon"
                    aria-hidden="true"
                    onMouseDown={() => this.copyToClipBoard()}></i>
                </OverlayTrigger>
                <OverlayTrigger
                placement="left"
                overlay={
                    <Tooltip id="tooltip">
                    Delete API Key
                    </Tooltip>
                }
                >
                <i
                    key={'delete-api-key'}
                    style={style.menuIcon}
                    className="material-icons hover-purple-negative"
                    onMouseDown={() => deleteApiKey()}
                >
                    delete
                </i>
                </OverlayTrigger>
            </div>
            </div>
            {copiableField}
        </div>
        );
    }
}

export default User;
