import React, { Component } from 'react';
import { ColorPickerComponent } from '@syncfusion/ej2-react-inputs';
import { connect } from '@app/utilities/routing';
import { Modal, Button, Form, Row, Col} from 'react-bootstrap';
import * as Colors from '@app/utilities/colors';
import { fetchDateFormats, fetchDateFormatDelimiters } from '@app/actions/tod/todDatabases.actions';
import {
    fetchUploads,
    fetchColumnsForFile,
  } from '@app/actions/analysis/uploads.actions';
import { validateName } from '@app/utilities/validators';

class ModalEditCategory extends Component {
    static propTypes = {};
    constructor(props) {
        super(props);
        this.state = {
            errors: {},
            record: {name: '', description: '', color: ''},
            pickerOpen: false
        };
        this.handleNameChange = this.handleNameChange.bind(this);
        this.handleDescChange = this.handleDescChange.bind(this);
        this.handleColorChange = this.handleColorChange.bind(this);
        this.open = this.open.bind(this);
        this.onChange = this.onChange.bind(this);
        this.save = this.save.bind(this);
        this.close = this.close.bind(this);
    }

    get hasErrors(){
        const { errors } = this.state;
        return errors.name 
            || errors.color;
    }

    validateName(name, existingNames) {

        if (!name || /^\s*$/.test(name)) {
            // name must exist and not be whitespace
            return 'Required';
        }

        let nameError = existingNames.filter(x=> name.toLowerCase().trim() === x).length ? 'Name must be unique' : '';
        nameError = nameError ? nameError : validateName(name);
        return nameError;
    }

    handleNameChange(e){
        const { existingNames } = this.props;
        let { record, errors } = this.state;
        let name = e.target.value;
        record.name = name;
        this.setState({record, errors: {...errors, name: this.validateName(name, existingNames)}});
    }

    handleDescChange(e){
        let { record } = this.state;
        record.description = e.target.value;
        this.setState({record});
    }

    handleColorChange(e){
        let { record } = this.state;
        record.color = e.value;
        this.setState({record});
    }

    onChange(data){
        let { record, errors } = this.state;
        data = data.map((x, i)=> {
            return {
                tableDefinitionColumnId: i,
                tableDefinitionId: x.tableDefinitionId,
                name: x.name,
                dataType: x.dataType,
                format: x.dataType === 'Date' ? x.format : '',
                delimiter: x.dataType === 'Date' ? x.delimiter : ''
            }
        })
        record.columns = data;
        this.setState({record, errors: {...errors, columns: this.validateColumns(data)}});
    }

    close() {
        this.setState({selectedFile: {}, errors: {}});
        this.props.closeHandler();
    }

    modalHandler(modalState) {
        this.setState(modalState);
    }

    open() {
        this.setState({ showModal: true, record: this.props.record });
    }

    save() {
        const { saveHandler, existingNames } = this.props;
        const { record } = this.state;
        let nameError = this.validateName(record.name, existingNames);

        if (nameError){
            this.setState({errors: {name: nameError}});
            return;
        }
        
        saveHandler(record);
        this.close();
    }

    render() {
        const { show, canEdit} = this.props;
        const { record, errors, pickerOpen } = this.state;

    return (
      <Modal id={'modalEditCategory'} show={show} dialogClassName="wait-modal" size="sm"
        onHide={this.close} onShow={this.open} backdrop='static'>
        <Modal.Header style={{ backgroundColor: Colors.blue, color: '#FFFFFF' }} closeButton>
            Project Category
        </Modal.Header>
        <Modal.Body className="modal-container">
        {/* <div style={{ fontWeight: 800, display: 'flex', flexDirection: 'row' }}>Name:</div> */}
        <div>
            <Row>
                <Col md={12}>
                    <Form.Group>
                    <Form.Label>Name</Form.Label>
                        <Form.Control
                            className="form-control"
                            as="input" maxLength="255"
                            value={record.name}
                            onChange={this.handleNameChange}
                            isInvalid={errors.name}
                        />
                        <Form.Control.Feedback type='invalid'>{errors.name}</Form.Control.Feedback>
                    </Form.Group>
                </Col>
                <Col md={12}>
                    <Form.Group>
                    <Form.Label>Description</Form.Label>
                        <Form.Control
                            className="form-control"
                            as="textarea" maxLength="1500"
                            value={record.description}
                            onChange={this.handleDescChange}
                        />
                        <Form.Control.Feedback type='invalid'>Too long!</Form.Control.Feedback>
                    </Form.Group>
                </Col>
                <Col md={12}>
                    <Form.Group>
                    <Form.Label>Color</Form.Label>
                    <div className='col-12' style={pickerOpen ? {height:300} : {}}>
                        <ColorPickerComponent 
                            id='color-picker'
                            change={this.handleColorChange}
                            //enableOpacity={false}
                            showButtons={false}
                            mode='Palette'
                            value={record.color}
                            beforeOpen={()=> this.setState({pickerOpen: true})}
                            beforeClose={()=> this.setState({pickerOpen: false})}
                        />

                    </div>
                    </Form.Group>
                </Col>
            </Row>
            </div>
        </Modal.Body>
        <Modal.Footer>
            <Button variant="arius" data-dismiss="modal" onClick={this.save} disabled={this.hasErrors || !canEdit}>Save</Button>
            <Button variant="arius" data-dismiss="modal" onClick={this.close}>Cancel</Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

const mapStateToProps = state => ({
    userKey: state.user.userKey,
    columns: state.analysis.uploads.columns,
    uploads: state.analysis.uploads.items,
    formats: state.tod.databases.dateFormats,
    delimiters: state.tod.databases.dateFormatDelimiters,
});

const mapDispatchToProps = dispatch => ({
    getUploads: () => {
        dispatch(fetchUploads());
    },
    getColumns: (userKey, fileId) => {
        dispatch(fetchColumnsForFile(userKey, fileId));
    },
    getColumnsForCsvFile: (userKey, fileId) => {
        dispatch(fetchColumnsForFile(userKey, fileId));
      },
      getDateFormats: userKey => {
        dispatch(fetchDateFormats(userKey));
      },
      getDateFormatDelimiters: userKey => {
        dispatch(fetchDateFormatDelimiters(userKey));
      },
    dispatch,
  });
  
export default connect(mapStateToProps, mapDispatchToProps)(ModalEditCategory);
