import React, { Component } from 'react';
import { connect } from '@app/utilities/routing';
import Utils from './queryUtils';

class Operator extends Component {
    static propTypes = {};
    constructor(props) {
        super(props);
        this.state = {
            deleteMode: null,
        };
        this.handleClick = this.handleClick.bind(this);
    }

    onDrop = (ev) => {
        const {parentSerialNumber, order} = this.props.data;
        let o = Utils.parseDragObject(ev);
        Utils.handleOnDragLeave.call(this, ev);

        // add to parent
        this.props.insert(o, parentSerialNumber, order);
    }

    handleClick = (e) => {
        const {operators, data, insert} = this.props;
        let items = operators.filter((item, index) => item.type === 'and' || item.type === 'or');
        let ndx = items.findIndex((item)=> item.queryFragmentOperatorId === data.queryFragmentOperatorId) +1;
        ndx = ndx === items.length ? 0 : ndx;
        let obj = {
            ...data, 
            queryFragmentOperatorId: items[ndx].queryFragmentOperatorId,
            type: items[ndx].type,
            sql: items[ndx].sql 
        };
        insert(obj, data.parentSerialNumber, data.order-1);
    }

    removerContent(type) {
        const {data, remove} = this.props;
        const { deleteMode } = this.state;
        let blank = <span>&nbsp;</span>;
        return <span
            onMouseEnter={() => this.setState({deleteMode: type})}
            onMouseLeave={() => this.setState({deleteMode: null})}
        >
            {
                deleteMode === type ? <span 
                    onClick={(ev)=> {
                    ev.stopPropagation();
                    remove(data.serialNumber)}}>
                        &nbsp;
                        <i className="fa fa-times delete" aria-hidden="true" title={`remove ${type}`}></i>
                        &nbsp;
                </span> : blank
            }
        </span>;
    }

    render() {
        const {key, data} = this.props;
        const { deleteMode } = this.state;
        let cm  = deleteMode === 'operator' ? 'operator delete' : 'operator';
        return (
        <span 
            className={cm}
            key={key}
            onDragStart={(e)=> Utils.handleOnDragStart(e, data)} 
            onDrop={this.onDrop}    
            onDragOver={Utils.handleOnDragOver}
            onDragLeave={Utils.handleOnDragLeave} 
            draggable
        >
            <span onClick={this.handleClick}>{data.sql}</span>
            {this.removerContent('operator')}
        </span>
        
        );
    } 
}

const mapStateToProps = state => ({
    operators: state.tod.queries.operators,
});

const mapDispatchToProps = dispatch => ({
    dispatch,
});

export default connect(mapStateToProps, mapDispatchToProps)(Operator);

