import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from '@app/utilities/routing';
import { fetchCurrentUser } from '../actions/user.actions';
import { fetchApiVersion, fetchAriusApplicationVersion, fetchAeReleaseVersion } from '../actions/application.actions';
import * as Colors from '../utilities/colors';
import { requireAuth } from '../utilities/adalAdapter';

class ProcessLogin extends Component {
    static propTypes = {
        dispatch: PropTypes.func.isRequired,
        user: PropTypes.object,
    };

    UNSAFE_componentWillMount() {
        const { dispatch, user } = this.props;

        if (!user || (typeof user.isAuthorized !== 'boolean' && !user.isFetching)) {
        requireAuth().then(rc => {
            if (rc) {
            dispatch(fetchCurrentUser());
            dispatch(fetchApiVersion());
            dispatch(fetchAriusApplicationVersion());
            dispatch(fetchAeReleaseVersion());
            }
        });
        }
    }

    componentDidUpdate() {
        const { user, history, location } = this.props;
        if (user && user.isFetching === false && typeof user.isAuthorized === 'boolean') {
            if (user.isAuthorized) {
                let newLocation = location && location.state && location.state.from ? location.state.from : '/';
                history.push(newLocation);
            } else {
                history.push('/unauthorized');
            }
        }
    }

    render() {
        return (
        <div style={{ display: 'flex', justifyContent: 'center', margin: '25vh', color: Colors.purple }}>
            <i style={{ fontSize: '10vmin', display: 'block' }} className="fa fa-spinner fa-spin"></i>
        </div>
        );
    }
}

const mapStateToProps = (state) => ({
  ...state,
});

const mapDispatchToProps = (dispatch) => ({
  dispatch,
});

export default connect(mapStateToProps,mapDispatchToProps)(ProcessLogin);
