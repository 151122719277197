import React, { Component } from 'react';
import { connect } from '@app/utilities/routing';
import { Form, Modal } from 'react-bootstrap';
import * as Colors from '@app/utilities/colors';
import { fetchDataGroups } from '@app/actions/tod/advancedQueries.actions';
import ManualEntryControl from '@app/tod/advancedQueries/editors/manualEntryControl';
import ValuesPicker from './valuesPicker';
import { notifyError } from '../../../../../utilities/notifier';

class ClauseObjectEditModal extends Component {
    static propTypes = {
    };
    constructor(props) {
        super(props);
        this.state = {
            mode: 'enter' 
        };
   
        this.save= this.save.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.handleOpen = this.handleOpen.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    componentDidMount() {
        
    }

    componentDidUpdate(prevProps) {
        const {columnType, values, includeOtherColumns }=this.props;
        const {mode} = this.state;

        if (mode === 'select' && !includeOtherColumns && columnType && columnType.toLowerCase() !== "dimension") {
            this.setState({mode: 'enter'});
        }
        if (prevProps.values !== values){
            this.setState({values});
        }
    }

    handleChange(e) {
        let states = {};
        states[e.target.id] = e.target.value === 'default' ? 'default' : e.target.value;
        states.isDirty = true;
        this.setState(states);  
    }

    save() {
        const {handleValuesEdit, handleGroupEdit, dataGroups}=this.props;
        const {mode} = this.state;
        if (mode === 'group'){
            let gs = dataGroups ? dataGroups : [];
            let g = gs.find((o) => o.id.toString() === this.state.dataGroupId);
            if (!g) {
                notifyError("Invalid data group");
                return;
            }
            handleGroupEdit(g);
        } else {
            let values = JSON.parse(this.state.values);
            values = values.filter(x=> x);  // drop blank values
            values = values.filter((value, index, self) => self.indexOf(value) === index); // only unique values
            handleValuesEdit(JSON.stringify(values));
        }
        this.handleClose();
    }

    handleOpen(){
        const {values, dataGroupId, dispatch, userKey, currentDb} = this.props;
        dispatch(fetchDataGroups(userKey,  currentDb.id));
        let mode = 'enter';
        if (null != dataGroupId
            ) { mode = 'group'}
        let state = {mode, values, dataGroupId};
        this.setState(state);
    }

    isValid() {
        const {values}=this.state;
        let cleaned = Array.isArray(values) ? values.filter(x=> x) : [];  // remove empty lines
        return cleaned.length > 0 && this.state.name;
    }

    handleClose(){
        this.props.closeCallback();
    }

    getFormControls(mode){
        const { includeOtherColumns }=this.props;
        switch(mode) {
            case 'select':
                return (
                    <div className="col-sm-12" style={{}}>
                        <div className="form-group" style={{marginBottom: 0}}>
                            <ValuesPicker
                                id="values"
                                values={this.state.values}
                                handleChange={this.handleChange}
                                column={{name: this.props.subject, columnType: this.props.columnType}}
                                includeOtherColumns={includeOtherColumns}
                            ></ValuesPicker>
                        </div>
                    </div>
                );
            case 'enter':
                return (
                    <ManualEntryControl
                        id="values"
                        values={this.state.values}
                        handleChange={this.handleChange}
                        column={{name: this.props.subject, columnType: this.props.columnType}}
                    />
                );
            case 'csv':
                let fname = 'Crazy Claim Ids.csv';
                return (
                    <div className="col-sm-12" style={{}}>
                        <div className="form-group">
                            <label htmlFor="name">Select uploaded CSV file:</label>
                            <select className="form-control" id="file" value={fname} onChange={this.handleChange} >
                                <option value={fname}>{fname}</option>
                                <option value="ExposureDate">Other.csv</option>
                            </select>
                        </div>
                    </div>
                );
            case 'group':
                    let groups = this.props.dataGroups ? this.props.dataGroups : [];
                    groups = groups.filter((o) => o.factColumnGuid === this.props.factColumnGuid);
                    let dataGroupId = this.state.dataGroupId ? this.state.dataGroupId.toString() : null;
                    let group = groups.find((o) => o.id.toString() === dataGroupId);
                    let options = groups.length > 0 ? 
                        groups.map((o) => <option value ={o.id} key={o.id}>
                                            {o.name}</option>) :
                    <option value="">-- no groups defined --</option>;
                    let value = group ? group.id : 'default';
                return (
                    <div className="col-sm-12" style={{}}>
                        <Form.Group>
                            <Form.Label>Pre-Defined Group:</Form.Label>
                            <Form.Select id="dataGroupId" onChange={this.handleChange} value={value}>
                                {groups.length > 0 ? <option value="default">-- select a group --</option> : ''}
                                {options}
                            </Form.Select> 
                        </Form.Group>
                    </div>
                );
            default:
                return (<div>error with selection mode</div>);
            }
    }

    render() {

        const {show, columnType, includeOtherColumns }=this.props;
        let modes = [
            {id:'select', label: ' Select values'},
            {id:'enter', label: ' Enter values'},
            {id:'group', label: ' Select group'},
            //{id:'csv', label: 'From CSV'}
        ];
        const {mode } = this.state;

        return (
            
            <Modal show={show} onHide={this.handleClose} onEnter={this.handleOpen}
                   dialogClassName="clauseObjectEditorModalContainer">
            <Modal.Header closeButton style={{ backgroundColor: Colors.blue, color: '#FFFFFF' }}>
            <Modal.Title>{this.props.subject} {this.props.sql} ...</Modal.Title>
            </Modal.Header>
            <Modal.Body>
            <div className="container form" style={{width:'100%'}}>
                <fieldset className="row">
                    <div className="row col-sm-12" style={{marginBottom:'20px'}}>
                            {modes.map((m, idx) => (
                                <div className='col-sm-4' key={`mode-${idx}`}  style={{textAlign: 'center'}}>
                                    <Form.Check type="radio"
                                        id='mode'
                                        value={m.id}
                                        checked={mode === m.id} 
                                        disabled = {m.id === "select" && (!includeOtherColumns && columnType && columnType.toLowerCase() !== "dimension")}
                                        onChange={this.handleChange}
                                        inline
                                        label={m.label} />
                                </div>
                            ))}
                    </div>
                    {/* Values: {this.state.values} */}
                    {this.getFormControls(mode)}
                </fieldset> 
            </div>
            </Modal.Body>
            <Modal.Footer>
                <button key="confirmButton"
                    className="btn btn-info dialog-button"
                    onClick={this.save}
                    // disabled={!this.isValid() || !this.state.isDirty}
                >Save</button>
                <button key="nobutton"
                    className="btn btn-info dialog-button"
                    onClick={this.handleClose}>Cancel</button>
            </Modal.Footer>
        </Modal>
        );
        }
    }
  
    const mapStateToProps = state => ({
        userKey: state.user.userKey,
        dataGroups: state.tod.queries.dataGroups,
        currentDb: state.tod.databases.current,
      });
      
      const mapDispatchToProps = dispatch => ({
          dispatch,
      });
      
      export default connect(mapStateToProps, mapDispatchToProps)(ClauseObjectEditModal);

