import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Modal, Form } from 'react-bootstrap';
import * as api from '@app/api/categories.serviceApi';
import * as Colors from '@app/utilities/colors';
import { Button } from '@arius';
import PurpleButton from '@app/shared/presentational/purpleButton';
import Loader from '@app/shared/presentational/loader';
import {
    //WORKSPACE_DELETEPROJECT, 
    MESSAGE_NOT_AUTHORIZED_TO_ACCESS
} from '../../../utilities/permissions';
import { notifyError } from '../../../utilities/notifier';

class ProjectCategoriesEditor extends Component {
    static propTypes = {
        databaseId: PropTypes.number,
        projectIds: PropTypes.array,
    };

    constructor(props) {
        super(props);
        this.state = { 
            isWaiting: false,
            processingRequest: {},
            selectionItems: []
        };
   
        this.save= this.save.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.handleOpen = this.handleOpen.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.launchModal = this.launchModal.bind(this);
        this.setSelectionItems = this.setSelectionItems.bind(this);
    }
    
    save() {
        const { currentWorkspace, userKey, selectedProjectIds, refreshProjectsHandler }=this.props;
        const { selectionItems } = this.state;

        // categories that were common to all projects and UNCHECKED by user will be removed from all selected projects
        let categoryIdsToRemove = selectionItems.filter(x=> x.commonToAll && !x.checked).map(x=> x.categoryId);

        // categories the user CHECKED will be added to any projects that don't yet have that category
        let categoryIdsToAdd = selectionItems.filter(x=> !x.commonToAll && x.checked).map(x=> x.categoryId);

        let req = {projectIds: selectedProjectIds, categoryIdsToRemove, categoryIdsToAdd}
        api.categorizeProjects(userKey, currentWorkspace.id, req).then(
            () => {
                this.setState({ isWaiting: false });
                refreshProjectsHandler();
                this.handleClose();
            });
        this.setState({ isWaiting: true });
    }

    handleOpen(){
        const {currentWorkspace, selectedProjectIds, userKey}=this.props;
        // if (!verifyPermission(WORKSPACE_DELETEPROJECT)){
        //     notifyError(MESSAGE_NOT_AUTHORIZED_TO_ACCESS);
        //     return;
        // }

        if (selectedProjectIds.length < 1){
            this.setState({ isWaiting: false, processingRequest: {validationMessage: 'Please select at least one project.'} });
            return;
        }
        let databaseId = currentWorkspace ? currentWorkspace.id : -1;
        api.getCategories(userKey, databaseId).then(
            (data) => {
                this.setState({ isWaiting: false });
                this.setSelectionItems(data);
            }
        )
        this.setState({ isWaiting: true });
    }

    handleChange(e){
        const { selectionItems } = this.state;
        let newItems = selectionItems.map((s)=> { return {...s,
            checked: s.categoryId.toString() === e.target.id ? e.target.checked : s.checked
        }});
        this.setState({selectionItems: newItems});
    }

    handleClose(){
        this.setState({show: false, processingRequest: {}});
    }

    launchModal(){
        const { canDelete }=this.props;

        if (!canDelete){
            notifyError(MESSAGE_NOT_AUTHORIZED_TO_ACCESS);
            return;
        }
        
        this.setState({comments: '', message: 'test', show: true});
    }

    componentDidUpdate() {
        const {processingRequest}=this.props;

        if (processingRequest === 'success'){
            this.handleClose();
        }
    }

    getValidationMessageMarkup(validationMessage) {
        let parts = validationMessage.split("|");
        return parts.length > 1 ? (
            <span style={{fontWeight: 'bold'}}>
                { parts.map((x,i) => (
                    <div key={i} style={{marginBottom: 5, wordWrap: 'break-word'}}>{x}</div>
                ))}
            </span>
        ) : <span style={{fontWeight: 'bold'}}>{validationMessage}</span>
    }

    setSelectionItems(categories){
        // determine the categories that are common to all selected projects and 'pre-select' them
        const { selectedProjectIds, selectedProjects } = this.props;

        let projectCategories = [];
        selectedProjects.forEach(x=> projectCategories = projectCategories.concat(x.categories));
        let selectionItems = categories.map((c)=> {
            let commonToAll = projectCategories.filter((p)=> p.categoryId === c.projectCategoryId).length === selectedProjectIds.length;
            return {
                categoryId: c.projectCategoryId,
                name: c.name,
                commonToAll: commonToAll,
                checked: commonToAll,
                color: c.color
            }
        })
        this.setState({selectionItems})
    }

    getItems(){
        const { selectionItems } = this.state;
        
        if (!selectionItems || selectionItems.length < 1){
            return <span>No valid categories to select. Please create a category.</span>
        }

        return selectionItems.map((c, idx) => (
            <div style={{display: 'flex'}} key={`stCH${c.categoryId}`}>
                <Form.Check type={'checkbox'} id={`stCH${c.categoryId}`}>
                    <Form.Check.Input type={'checkbox'}
                        id={`${c.categoryId}`}
                        value={c.name}
                        checked={c.checked} 
                        onChange={this.handleChange}
                     />
                    <Form.Check.Label>
                        <div style={{display: 'flex'}}>
                            <div 
                                style={{backgroundColor:`${c.color}`, height: '10px', width: '10px', marginRight: 5,
                                borderRadius: 5, marginTop: 5}}>
                            </div>
                            <span>{c.name}</span>
                        </div>
                    </Form.Check.Label>
                </Form.Check>
            </div>
        ));
    }
  
    render() {
        const { show, isWaiting, processingRequest} = this.state;
        const { selectedProjectIds } = this.props;
        let validationMessage = processingRequest ? processingRequest.validationMessage : '';
        let isValid = !validationMessage || validationMessage === '';

        const msgStyle = {
            fontWeight: 'bold',
        };

        let n = selectedProjectIds.length;
        let form = !isValid ? (this.getValidationMessageMarkup(validationMessage)) : (
            <div className="container form" style={{width:'100%'}}>
                <fieldset className="row">
                    <div className="col-sm-12" style={{marginBottom: '10px'}}>
                        <span style={msgStyle}>{`${n} project${n > 1 ? 's' : ''} selected.`}</span>
                    </div>
                    <div className="col-sm-12" style={{marginBottom: '20px'}}>
                        {this.getItems()}
                        {/* <span style={msgStyle}>{`Are you sure you want to delete selected project${n > 1 ? 's' : ''}?`}</span> */}
                    </div>
                </fieldset> 
            </div>            
        );

        let body = isWaiting ? (<Loader loadingItem=" category information" />) : form;

        let buttons = isValid ? (              
            [
                <Button
                    key="confirmButton"
                    className={'dialog-button btn-danger'}
                    variant='arius'
                    onClick={this.save}>Save</Button>,
                <Button
                    key="nobutton"
                    className={'dialog-button'}
                    variant='arius'
                    onClick={this.handleClose}>Cancel</Button>
            ]) : (
                <Button
                    key="nobutton"
                    className={'dialog-button'}
                    variant='arius'
                    onClick={this.handleClose}>Ok</Button>
            );

        return (
            <span>
                <PurpleButton 
                    id="launch" 
                    clickHandler={this.launchModal}
                    message="Categorize Selected Projects" 
                    type="categorize">
                </PurpleButton>
                <Modal show={show} onHide={this.handleClose} onEnter={this.handleOpen} size='lg' dialogClassName="review-confirmation-modal">
                    <Modal.Header closeButton style={{ backgroundColor: Colors.blue, color: '#FFFFFF' }}>
                        <Modal.Title><small>Categorize selected projects</small></Modal.Title>
                    </Modal.Header>
                    <Modal.Body>{body}</Modal.Body>
                    <Modal.Footer>{buttons}</Modal.Footer>
                </Modal>
            </span>
            
        );
        }
    }
    
    export default ProjectCategoriesEditor;
