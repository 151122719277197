import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {Form, Row, Col, Card,} from 'react-bootstrap';
import { Button } from '@arius';
import { notifyError } from '../../../utilities/notifier';
import { validateName } from '../../../utilities/validators';
import ColumnRow from './columnRow';
import SaveButtons from '../../shared/presentational/saveButtons';
import CreateAEDatabaseModal from './createAEDatabaseModal';
import {
  createPermissionChecker,
  SITE_CREATEDATABASE,
} from '../../../utilities/permissions';
import { FORBIDDEN_COLUMN_NAMES } from '@app/utilities/constants';

class CreateAriusAnalysisDatabasePage extends Component {
    static propTypes = {
        workspaces: PropTypes.arrayOf(
            PropTypes.shape({
                workspaceId: PropTypes.number,
                workspaceName: PropTypes.string,
                workspaceDesc: PropTypes.string,
                workspaceDims: PropTypes.string,
            })
        ),
        userKey: PropTypes.string.isRequired,
        isFetching: PropTypes.bool,
        newSaveHandler: PropTypes.func,
        getWorkspaces: PropTypes.func,
        current: PropTypes.object,
        currentTodDatabase: PropTypes.object,
        clearNotificationHandler: PropTypes.func,
        serverNotification: PropTypes.object,
        getUploads: PropTypes.func,
        errorMessage: PropTypes.string,
        clearErrorMessageHandler: PropTypes.func,
        getDatabaseDimensions: PropTypes.func,
        todDatabases: PropTypes.array,
        userPermissions: PropTypes.array,
        params: PropTypes.object,
        updateSaveHandler: PropTypes.func,
        getWorkspace: PropTypes.func,
    };
    static defaultProps = {
        userKey: '',
    };
    constructor(props) {
        super(props);
        this.state = {
            databaseColumns: [],
            databaseDesc: '',
            databaseName: '',
            isEditing: false,
            databases: [],
            selectedDatabase: null,
            allColumnsSelected: false,
            isCreateModalOpen: false,
            selectedColumnIndex: null,
            selectedColumn: null,
            softErrorMode: true,
            uploads: [],
            serverNotification: null,
            userPermissions: [],
            workspaceId: null,
            workspace: null,
        };
        if (props.params && props.params.id) {
            this.state.isEditing = true;
            this.state.workspaceId = props.params.id;
        }
        this.descChangeHandler = this.descChangeHandler.bind(this);
        this.nameChangeHandler = this.nameChangeHandler.bind(this);
        this.addColumn = this.addColumn.bind(this);
        this.deleteColumn = this.deleteColumn.bind(this);
        this.saveDatabase = this.saveDatabase.bind(this);
        this.getDatabaseColumns = this.getDatabaseColumns.bind(this);
        this.changeColumnName = this.changeColumnName.bind(this);
        this.closeCreateModal = this.closeCreateModal.bind(this);
        this.openCreateModal = this.openCreateModal.bind(this);
        this.selectAllColumns = this.selectAllColumns.bind(this);
        this.deleteSelectedColumns = this.deleteSelectedColumns.bind(this);
        this.validateColumnName = this.validateColumnName.bind(this);
        this.getNameValidationError = this.getNameValidationError.bind(this);
    }

    componentDidMount() {
        const { todDatabases, userPermissions, getWorkspace, userKey, browserHistory } = this.props,
        { isEditing, workspaceId } = this.state;
        if (isEditing) {
            getWorkspace(userKey, workspaceId);
        }

        if (todDatabases) {
            this.setState({ databases: todDatabases });
        }

        if (userPermissions && userPermissions.length) {
            if (userPermissions.indexOf(SITE_CREATEDATABASE) === -1) {
                browserHistory.push('/unauthorized');
            }
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        const { current } = this.props, newState = this.state;

        if (current !== nextProps.current) {
            const newWorkspace = nextProps.current;
            newState.workspace = newWorkspace;
            if (newWorkspace) {
                newState.databaseName = newWorkspace.name;
                newState.databaseDesc = newWorkspace.description;

                // newWorkspace.dimensions
            }
            this.setState(newState);
        }
    }

    componentDidUpdate(prevProps) {
        const { todDatabases, currentTodDatabase, userPermissions, browserHistory } = this.props;
        if (
            todDatabases &&
            todDatabases.length > 0 &&
            !prevProps.todDatabases.length > 0
        ) {
            this.setState({ databases: this.props.todDatabases });
        }

        if (
            currentTodDatabase &&
            currentTodDatabase !== prevProps.currentTodDatabase &&
            this.state.selectedDatabase
        ) {
            this.populateColumnsFromTodDatabase(currentTodDatabase);
        }

        if (
            userPermissions &&
            userPermissions.length &&
            userPermissions !== prevProps.userPermissions
        ) {
            if (userPermissions.indexOf(SITE_CREATEDATABASE) === -1) {
                browserHistory.push('/unauthorized');
            }
        }
    }

    getDatabaseColumns() {
        const { selectedDatabase } = this.state,
        { getDatabaseDimensions, userKey } = this.props;

        if (selectedDatabase) {
            getDatabaseDimensions(userKey, selectedDatabase.workspaceId);
        } else {
            notifyError('No database selected');
        }
    }

    getNameValidationError(soft) {
        const { databaseName, workspaceId } = this.state,
        { workspaces } = this.props;

        let trimmed = databaseName.trim();
        // const { current } = this.props; this needs to be added in order to ensure that if we have a current working database in edit mode
        // it doesn't mess up the validation by saying this already exists, change after adding an edit mode

        const error = validateName(trimmed, soft, false, true);

        if (error !== '') {
            return error;
        }

        trimmed = trimmed.toLowerCase();
        const found = workspaces.find(
            ws => ws.workspaceName.toLowerCase() === trimmed
        );
        if (found && found.workspaceId != workspaceId) { // eslint-disable-line eqeqeq
            return 'Already exists';
        }

        return '';
    }

    setSoftErrorMode(mode) {
        const { softErrorMode } = this.state;
        if (softErrorMode !== mode) {
            this.setState({ softErrorMode: mode });
        }
    }

    nameChangeHandler(newName) {
        let databaseName = newName;
        if (databaseName && typeof databaseName !== 'string') {
            databaseName = '';
        }
        this.setState({ databaseName });
    }

    descChangeHandler(e) {
        this.setState({ databaseDesc: e.target.value });
    }

    addColumn() {
        const databaseColumns = this.state.databaseColumns.slice();
        const columnType = 'dimension';
        databaseColumns.push({ factColumnDisplayName: '', columnType, isSelected: false});
        this.setState({ databaseColumns });
    }

    deleteColumn(idx) {
        const { databaseColumns } = this.state;
        databaseColumns.splice(idx, 1);
        this.setState({ databaseColumns });
    }

    deleteSelectedColumns() {
        let { databaseColumns } = this.state;
        databaseColumns = databaseColumns.filter(col => !col.isSelected);
        this.setState({ databaseColumns, allColumnsSelected: false });
    }

    changeColumnName(index, newName) {
        let { databaseColumns } = this.state;
        const column = databaseColumns.find((c, idx) => idx === index);
        if (column) {
            databaseColumns = databaseColumns.map((col, idx) => {
                const c = col;
                if (idx === index) {
                    c.factColumnDisplayName = newName;
                }
                return c;
            });
            this.setState({ databaseColumns });
        }
    }

    changeColumnType(index, e) {
        let { databaseColumns } = this.state;
        const column = databaseColumns.find((c, idx) => idx === index);
        if (column) {
            databaseColumns = databaseColumns.map((col, idx) => {
                const c = col;
                if (idx === index) {
                    c.columnType = e.target.value;
                }
                return c;
            });
            this.setState({ databaseColumns });
        }
    }

    populateColumnsFromTodDatabase(currentTodDatabase) {
        const { databaseColumns } = this.state;

        if (currentTodDatabase) {
            currentTodDatabase.columns.forEach(dbc => {
                if (dbc.columnType === 'dimension') {
                    databaseColumns.push({
                        factColumnDisplayName: dbc.factColumnDisplayName,
                        isSelected: false
                    });
                }
            });
            this.setState({ databaseColumns });
        }
    }

    saveDatabase() {
        const {
            databaseColumns,
            databaseDesc,
            databaseName,
            isEditing,
        } = this.state,
        { newSaveHandler, userKey, updateSaveHandler } = this.props;
        if (!this.validate(false)) {
            this.setSoftErrorMode(false);
            return;
        }

        let workspaceId, createdBy, updatedBy;

        if (isEditing) {
            updateSaveHandler({
                workspaceName: databaseName,
                workspaceDescription: databaseDesc,
                workspaceDimensions: databaseColumns.map(
                dbc => dbc.factColumnDisplayName
                ),
                userKey,
                createdBy: this.state.workspace.createdBy,
                updatedBy: this.state.workspace.updatedBy,
                workspaceId: this.state.workspaceId,
            });
        } else {
            newSaveHandler({
                workspaceName: databaseName,
                workspaceDescription: databaseDesc,
                workspaceDimensions: databaseColumns.map(
                dbc => dbc.factColumnDisplayName
                ),
                userKey,
                createdBy,
                updatedBy,
                workspaceId,
            });
        this.openCreateModal();
        }
    }

    closeCreateModal() {
        this.setState({ isCreateModalOpen: false });
    }

    openCreateModal() {
        this.setState({ isCreateModalOpen: true });
    }

    selectAllColumns(e) {
        const { current } = this.props, { databaseColumns } = this.state;
        databaseColumns.forEach(col => {
        if (
            !current || (current && !current.hasDataLoaded) || !col.factColumnGuid
        ) {
            col.isSelected = e.target.checked;
        }
        });
        this.setState({ databaseColumns, allColumnsSelected: e.target.checked });
    }

    selectColumn(idx) {
        const { databaseColumns } = this.state;
        databaseColumns.forEach((col, index) => {
        if (index === idx) {
            col.isSelected = !col.isSelected;
        }
        });
        this.setState({ databaseColumns });
    }

    validateColumnName(col, soft, checkForbiddenNames) {
        if (checkForbiddenNames){
            let trimmed = col ? col.factColumnDisplayName.toLowerCase().trim() : '';
        
            if (FORBIDDEN_COLUMN_NAMES.includes(trimmed)){
                return 'This name is in conflict with a column name included in the Extract table.';
            }    
        }

        return col ? validateName(col.factColumnDisplayName || '', soft, false, true) : '';
    }

    validateColumnsComposition() {
        const { databaseColumns, isEditing } = this.state, nameMap = {};

        let dupName = false, nameError = '', columnError = null;

        databaseColumns.every((col, idx) => {
            nameError = this.validateColumnName(col, false, !isEditing);
        if (nameError) {
            columnError = `Column ${idx + 1} name: ${nameError}`;
            return false;
        }
            const lowerCaseName = col.factColumnDisplayName.toLowerCase();

            if (nameMap[lowerCaseName]) {
                dupName = true;
            } else {
                nameMap[lowerCaseName] = true;
            }

            return true;
        });

        if (nameError) {
            return columnError;
        }

        if (dupName) {
            columnError = 'Column names must be unique';
        }

        return columnError;
    }

    validate(soft) {
        const dbNameError = this.getNameValidationError(soft);
        if (dbNameError) {
            notifyError(`Database name: ${dbNameError}`);
            return false;
        }
        if (!soft) {
            const columnSetError = this.validateColumnsComposition();
            if (columnSetError) {
                notifyError(columnSetError);
                return false;
            }
        }
        return !dbNameError;
    }

    getTODSelector() {
        const { isEditing, databases,} = this.state;
        const { currentDatabase } = this.props;

        if ( isEditing && currentDatabase && currentDatabase.hasDataLoaded ) {
            return '';
        }
        
        return <Form.Group controlId="fileSelect" as={Col} md={6}>
              <Form.Label>Select TOD Database</Form.Label>
              <Form.Select
                placeholder="Select a database"
                onChange={e =>
                  this.setState({
                    selectedDatabase: databases.find(
                      db => db.factTableGuid === e.target.value
                    ) || null,
                  })}
              >
                <option value="select">-- Select a database --</option>
                {databases.map(db => (
                  <option key={`${db.factTableGuid}`} value={db.factTableGuid}>
                    {db.workspaceName}
                  </option>
                ))}
              </Form.Select>
        </Form.Group>
    }

    getPopulateButton() {
        const { isEditing } = this.state;
        const { currentDatabase } = this.props;

        if ( isEditing && currentDatabase && currentDatabase.hasDataLoaded ) {
            return '';
        }
        
        return <Form.Group controlId="fileSelect" as={Col} md={6}>
            <Form.Label>&nbsp;&nbsp;</Form.Label>
            <button
                className="btn btn-primary"
                style={{ display: 'flex', alignItems: 'center' }}
                onClick={this.getDatabaseColumns}
            >
                <i className="material-icons" key="add-column">add</i>
                <span>Populate Columns Below from CSV File</span>
            </button>
        </Form.Group>
    }

    getInfoControls() {
        const { softErrorMode } = this.state;
        const dbNameValidationError = this.getNameValidationError(softErrorMode);

        return <Row>
            <Form.Group as={Col} md={6}>
                <Form.Label>Name:</Form.Label>
                <Form.Control
                    type="text"
                    maxLength="100"
                    id="databaseDesc"
                    placeholder="Name"
                    value={this.state.databaseName}
                    isInvalid={dbNameValidationError}
                    onChange={e => this.nameChangeHandler(e.target.value)}
                    onBlur={e => this.nameChangeHandler(e.target.value.trim())}
                />
                <Form.Control.Feedback type="invalid">{dbNameValidationError}</Form.Control.Feedback>
            </Form.Group>
            <Form.Group as={Col} md={6}>
                <Form.Label>Description:</Form.Label>
                <Form.Control
                    type="text"
                    maxLength="1024"
                    id="databaseDesc"
                    placeholder="Description"
                    as="textarea"
                    value={this.state.databaseDesc}
                    onChange={this.descChangeHandler}
                />
            </Form.Group>
        {this.getTODSelector()}
        {this.getPopulateButton()}
        </Row>
    }

    getSchemaGrid() {
        const {
            databaseColumns,
            isEditing,
            allColumnsSelected,
            softErrorMode,
          } = this.state,
            {
              current,
            } = this.props;
        let columnStyles = [
            {flex: '0 0 32px'},
            {flex: '4'},
            {flex: '0 0 50px'},
        ]
        
        return (<div className="table-responsive">
            <table className="ar-table table table-striped" style={{ maxHeight: '70vh' }}>
                <thead>
                <tr>
                    <th style={columnStyles[0]}>
                        <div className='checkbox-container'>
                            <Form.Check type="checkbox"
                                onChange={this.selectAllColumns}
                                checked={allColumnsSelected}
                            />
                        </div>
                    </th>
                    <th style={columnStyles[1]}>Column Name</th>
                    <th style={columnStyles[2]}/>
                </tr>
                </thead>
                
                <tbody style={{display: 'block',  height: 500, overflowY: 'scroll'}}>
                {databaseColumns.map((column, idx) => {
                    const canEdit = !isEditing || false;
                    return (
                      <ColumnRow
                        columnStyles={columnStyles}
                        column={{ ...column }}
                        idx={idx}
                        key={`column-row-${idx}`}
                        canEdit={canEdit}
                        onSelectChanged={() => this.selectColumn(idx)}
                        onNameChanged={e => this.changeColumnName(idx, e)}
                        onTypeChanged={e => this.changeColumnType(idx, e)}
                        deleteColumn={() => this.deleteColumn(idx)}
                        validationError={this.validateColumnName(
                          column,
                          softErrorMode,
                          canEdit
                        )}
                        hasDataLoaded={current && current.hasDataLoaded}
                      />
                    );
                  })}
                </tbody>
            </table>
            </div>
        )
    }

    getSchemaControls() {
        return <Row>
            <Form.Group as={Col} md={12}>
            <Form.Label>Database Schema:</Form.Label>
                <Row>
                    <Col style={{display: 'flex'}}>
                        <Button variant="primary" onClick={this.addColumn} style={{ marginLeft: 10}}>
                            <i className="material-icons">add</i>Add Column
                        </Button>
                        <Button variant="danger" onClick={this.deleteSelectedColumns} style={{ marginLeft: 10}}>
                            <i className="material-icons" >remove</i>
                            <span>Remove Columns</span>
                        </Button>
                    </Col>
                </Row>
                <Row style={{padding: 10}}>
                    {this.getSchemaGrid()}
                </Row>
            </Form.Group>
        </Row>
    }

    render() {
        const { isEditing } = this.state;
        const {
              clearNotificationHandler,
              serverNotification,
              getWorkspaces,
              userKey,
              errorMessage,
              clearErrorMessageHandler,
              userPermissions,
              browserHistory,
              jobStatus,
            } = this.props;
        const verifyPermission = createPermissionChecker(userPermissions);

        return (
            <div id="create-tod-container" className="list-container-arius"
                style={{maxHeight: 'calc(100vh - 106px)', overflowY: 'auto'}}>
                <div className="list-header-arius">
                    <h4>{isEditing ? 'Edit Database' : 'Create Database'}</h4>
                    <Button mode='back' path='/tod/databases' toolTip='Back to Database List'/>
                </div>
                <Card style={{backgroundColor: '#F9F9F9', border: 'none', margin: '20px'}}>
                    <Card.Body> 
                        {this.getInfoControls()}
                        {this.getSchemaControls()}
                    </Card.Body>
                </Card>
                
                <SaveButtons
                    saveHandler={
                        verifyPermission(SITE_CREATEDATABASE)
                        ? this.saveDatabase
                        : () =>
                            notifyError(
                                'You are not authorized to access this functionality'
                            )
                    }
                    // isSaveButtonDisabled={!isCurrentUserAdmin}
                    backButtonHander={() => browserHistory.push('/arius/workspaces')}
                    backButtonText="Back to Databases"
                />
                <CreateAEDatabaseModal
                    modalId="createAEDatabaseModal"
                    showModal={this.state.isCreateModalOpen}
                    closeHandler={this.closeCreateModal}
                    clearNotificationHandler={clearNotificationHandler}
                    serverNotification={serverNotification}
                    getWorkspaces={getWorkspaces}
                    errorMessage={errorMessage}
                    clearErrorMessageHandler={clearErrorMessageHandler}
                    userKey={userKey}
                    browserHistory={browserHistory}
                    jobStatus={jobStatus}
                />
            </div>
        )
    }
}

export default CreateAriusAnalysisDatabasePage;
