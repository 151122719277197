import React, { Component } from 'react';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import moment from 'moment';
import { Modal } from 'react-bootstrap';
import * as Colors from '@app/utilities/colors';
import ModalLoadFile from './modalLoadFile';
import ModalEditTable from './modalEditTable';
import { 
    createTableDefinition, 
    updateTableDefinition, 
    fetchReportsForTableDefinition,
    retrieveTableDefinitions,
    deleteTableDefinition,
    deleteTableLoad
} from '@app/actions/analysis/reporting.actions';
import { Grid, Button } from '@arius';
import $ from 'jquery';
import { notifyError } from '@app/utilities/notifier';
import StatusIndicator from './statusIndicator';
import * as reportingApi from '@app/api/reporting.serviceApi';
import { showModal } from '@app/actions/modal.actions';
import { createPermissionChecker, WORKSPACE_DELETEEXTRACTTABLES, WORKSPACE_CREATEEDITEXTRACTTABLES } from '@app/utilities/permissions';
import { notAuthorized } from '@app/shared/containers/conditionalLink';

const DEFAULT_MODAL = {
    title: 'Delete Support table',
    message: '',
    mode: ''
};

class TableDefinitionsList extends Component {
    static propTypes = {
    };
    constructor(props) {
        super(props);
        this.state = {
            showModalLoadFile: false,
            showModalEdit: false,
            currentRecord: {columns: []},
            recordCounts: [],
            fetchingDeleteStatus: false,
            modal: DEFAULT_MODAL
        };
        this.load = this.load.bind(this);
        this.createTable = this.createTable.bind(this);
        this.editTable = this.editTable.bind(this);
        this.getActionItems = this.getActionItems.bind(this);
        this.getGrid = this.getGrid.bind(this);
        this.getActionItems = this.getActionItems.bind(this);
        this.getDetailActionItems = this.getDetailActionItems.bind(this);
        this.getDetailTemplate = this.getDetailTemplate.bind(this);
        this.save = this.save.bind(this);   
        this.refresh = this.refresh.bind(this);
        this.getStatusIndicator = this.getStatusIndicator.bind(this);
        this.promptToDelete = this.promptToDelete.bind(this);
        this.promptToDeleteData = this.promptToDeleteData.bind(this);
    }

    get existingNames(){
        let { currentRecord } = this.state;
        const { tableDefinitions:data } = this.props;
        return data ? data.filter(x=> x.tableDefinitionId !== currentRecord.tableDefinitionId)
            .map(x=> x.name.toLowerCase().trim()) : [];
    }

    componentDidMount() {
        this.refresh();
    }

    componentDidUpdate(prevProps) {
        const { currentWorkspace } = this.props;
        if (currentWorkspace && prevProps.currentWorkspace !== currentWorkspace) {
            this.refresh();
        }
    }

    refresh() {
        const { userKey, dispatch, currentWorkspace } = this.props;
        if (currentWorkspace){
            dispatch(retrieveTableDefinitions({ userKey, databaseId: currentWorkspace.id }));
        }
    }


    load(e, row) {
        e.stopPropagation();  // don't let the row expand fire...
        $(`#action-overlay-${row.tableDefinitionId}`).click();  // make action menu close.  TODO find better solution...

        let verifyPermission = this.getPermissionChecker();
        if (!verifyPermission(WORKSPACE_CREATEEDITEXTRACTTABLES)) {
            notAuthorized();
            return;
        }
        
        this.setState({showModalLoadFile: true, currentRecord: row})
    }

    createTable(e, id) {
        e.stopPropagation();  // don't let the row expand fire...
        $(`#action-overlay-${id}`).click();  // make action menu close.  TODO find better solution...

        let verifyPermission = this.getPermissionChecker();
        if (!verifyPermission(WORKSPACE_CREATEEDITEXTRACTTABLES)) {
            notAuthorized();
            return;
        }

        const { databaseId } = this.props;
        if (!databaseId){
            notifyError('Please select a database');
            return;
        }
        var record = {tableDefinitionId: -1, name: '', description: '', columns: []};
        this.setState({showModalEdit: true, currentRecord: record});
    }

    editTable(e, currentRecord) {
        e.stopPropagation();  // don't let the row expand fire...
        $(`#action-overlay-${currentRecord.tableDefinitionId}`).click();  // make action menu close.  TODO find better solution...

        // users should be able to see definition
        // let verifyPermission = this.getPermissionChecker();
        // if (!verifyPermission(WORKSPACE_CREATEEDITEXTRACTTABLES)) {
        //     notAuthorized();
        //     return;
        // }

        const { databaseId } = this.props;
        if (!databaseId){
            notifyError('Please select a database');
            return;
        }
        this.setState({showModalEdit: true, currentRecord: JSON.parse(JSON.stringify(currentRecord))});
    }

    save(req) {
        const { userKey, databaseId, dispatch } = this.props;
        this.setState({showModalEdit: false});
        if (req.tableDefinitionId < 1){
            dispatch(createTableDefinition({ userKey, databaseId, req }));
        } else {
            dispatch(updateTableDefinition({ userKey, databaseId, req }));
        }
    }

    getPermissionChecker = () => {
        const { currentWorkspace, userPermissions } = this.props;
        return createPermissionChecker(
            currentWorkspace ? [...userPermissions, ...currentWorkspace.permissions] : userPermissions);
    }

    getActionItems(row) {
        const { fetchingDeleteStatus } = this.state;
        const popover = (
            <Popover id="todListItemActions">
                <Button toolTip="Load CSV" iconName="file_upload" onClick={(e) => this.load(e, row)}/>
                <Button toolTip="Edit" iconName="edit" onClick={(e)=> this.editTable(e, row)}/>
                <Button toolTip="Delete" iconName={fetchingDeleteStatus ? "fa-refresh fa-spin" : "delete"} onClick={(e)=> {
                    e.stopPropagation();
                    $(`#action-overlay-${row.tableDefinitionId}`).click();  // make action menu close.  TODO find better solution...

                    let verifyPermission = this.getPermissionChecker();
                    if (!verifyPermission(WORKSPACE_DELETEEXTRACTTABLES)) {
                        notAuthorized();
                        return;
                    }

                    this.promptToDelete(row)
                }}/>
            </Popover>
        );

        return (
            <OverlayTrigger  trigger="click" placement="auto" overlay={popover} rootClose={true} >
                <i
                    id={`action-overlay-${row.tableDefinitionId}`}
                    key={`action-items-${row.tableDefinitionId}`}
                    onClick={(e) => e.stopPropagation()}
                    className="material-icons menu-icon"
                >
                    more_horiz
                </i>
            </OverlayTrigger>
        )
    }

    getDetailActionItems(row) {
        return <Button toolTip="Delete" iconName="delete" onClick={(e)=> {
            e.stopPropagation(); 
            this.promptToDeleteData(row)
        }}/>;
    }

    getDetailTemplate(row) {
        var data = row.files;
        let columns = [
            { field: 'tableFileId', headerText: 'Id', visible: false},
            { field: 'fileName', headerText: 'Name'},
            { field: 'recordCount', headerText: `Record Count`, template: this.getStatusIndicator},
            { field: 'updateBy', headerText: 'Loaded By', width:'130px'},
            { field: 'updateDate', headerText: 'Loaded Date', type: 'datetime'},
            { field: 'tableFileId', headerText: 'Actions', template: this.getDetailActionItems},
        ];
        
        return <div>
            <div>Files loaded to this table</div>
            <Grid 
                columns={columns}
                data={data}
                height='auto'/>
        </div>
    }

    getStatusIndicator(row) {
        const { userKey, databaseId } = this.props;
        var statusCallback = () => reportingApi.getLoadStatus({userKey, databaseId, tableFileId: row.tableFileId});
        return <StatusIndicator statusCallback={statusCallback} initialStatus={row.status} initialCount={row.recordCount}/>;
    }

    getGrid() {
        const { tableDefinitions: data } = this.props;
        //const { data } = this.state;
        let columns = [
            { field: 'tableDefinitionId', headerText: 'Id', visible: false},
            { field: 'name', headerText: 'Name'},
            { field: 'description', headerText: 'Description'},
            { field: 'updateBy', headerText: 'Last Modified By'},
            { field: 'updateDate', headerText: 'Last Modified'},
            { field: 'tableDefinitionId', headerText: 'Actions', template: this.getActionItems},
        ];
        
        return <Grid 
            columns={columns}
            data={data}
            height='calc(100vh - 240px)' 
            detailTemplate={this.getDetailTemplate}/>
    }

    promptToDelete(o) {
        const { dispatch, userKey, databaseId } = this.props;
        this.setState({modal: {...DEFAULT_MODAL, mode: 'checking'}});
        dispatch(fetchReportsForTableDefinition(userKey, databaseId, o.tableDefinitionId,
            (data)=> {
                let names = data ? data.names : null;
                if (names && Array.isArray(names)){
                    if (names.length > 0) {
                        this.setState({
                            modal: { ...DEFAULT_MODAL, mode: 'warn',
                                title: 'Delete Support table',
                                message: <div>
                                Cannot delete this Support table that is referenced in the following Reporting tables:  
                                <ul>
                                    {names.map(n => <li key={`li-${n}`} style={{ fontWeight: 800 }}>{n}</li>)}
                                </ul>
                            </div>
                            }
                        });
                    } else {
                        this.setState({
                            modal: { ...DEFAULT_MODAL, mode: 'prompt',
                                title: 'Are you sure?',
                                message: <div>
                                    Are you sure you want to delete the following table?  
                                    <ul>
                                        <li style={{ fontWeight: 800 }}>{o.name}</li>
                                    </ul>
                                </div>,
                                yesHandler: ()=> {
                                    dispatch(deleteTableDefinition({userKey, databaseId, tableDefinitionId: o.tableDefinitionId}));
                                    this.setState({modal: {...DEFAULT_MODAL, mode: ''}});
                                }
                            }
                        });
                    }
                } else {
                    this.setState({modal: {...DEFAULT_MODAL, mode: 'fail'}})
                }
            }));
    }

    promptToDeleteData(record) {
        const { dispatch, userKey, databaseId } = this.props;

        let verifyPermission = this.getPermissionChecker();
        if (!verifyPermission(WORKSPACE_CREATEEDITEXTRACTTABLES)) {
            notAuthorized();
            return;
        }

        let s = { fontWeight: 800 };
        const deleteMessageItems = [
            <li key={record.tableFileId} style={s}>
                {record.fileName} - {record.updateBy} - {moment.utc(record.updateDate).local().format('L LT')}
            </li>,
        ];

        deleteMessageItems.unshift(
            'Are you sure you want to delete data that was loaded for the selected file?  This cannot be undone.'
        );

        const yesClickHandler = () => {
            let req = {userKey, databaseId, tableDefinitionId: record.tableDefinitionId, 
                tableFileId: record.tableFileId};
            dispatch(deleteTableLoad(req));
        };
        const noClickHandler = () => {};
        const action = showModal(
            'confirmation',
            deleteMessageItems,
            yesClickHandler,
            noClickHandler
        );
        dispatch(action);
    }

    getDialogModal() {
        const { modal } = this.state;
        let { mode, title, message, yesHandler } = modal;
        let show = mode !== '';
        let handleClose = () => this.setState({modal: {...modal, mode: ''}});
        let buttons = <Button variant="arius" onClick={handleClose}>Close</Button>;
        
        let containerStyle = { display: 'flex', flexDirection: 'column', justifyContent: 'center', margin: '10px' };
        let iconStyle = {fontSize: 50,display: 'flex', justifyContent: 'center'};
        let textStyle = {width: '100%',textAlign: 'center', marginTop: 10};

        if (mode === 'checking') {
            message = <span style={containerStyle}>
                <div><i style={{...iconStyle, color: Colors.purple}} className="fa fa-spinner fa-spin"></i></div>
                <div style={textStyle}>Checking dependencies...</div>
            </span>;
        }

        if (mode === 'fail') {
            message = <span style={containerStyle}>
                <div><i style={{...iconStyle, color: Colors.red}} className="fa fa-times"></i></div>
                <div style={textStyle}>Error checking dependencies</div>
            </span>;
        }

        if (mode === 'prompt') {
            buttons = [
                <Button variant="arius" key='y' onClick={yesHandler}>Yes</Button>,
                <Button variant="arius" key='n' onClick={handleClose}>No</Button>
            ];
        }

        return (
        <Modal show={show} dialogClassName="confirmModal" onHide={handleClose} style={{ fontSize: 'smaller' }}>
            <Modal.Header closeButton style={{ backgroundColor: Colors.blue, color: '#FFFFFF'}}>
                <Modal.Title><small>{title}</small></Modal.Title>
            </Modal.Header>
            <Modal.Body>{message}</Modal.Body>
            <Modal.Footer>{buttons}</Modal.Footer>
        </Modal>
        );
    }

    render() {
        const { showModalLoadFile, showModalEdit, currentRecord } = this.state;
        const { databaseId } = this.props;

        let verifyPermission = this.getPermissionChecker();
        const canEdit = verifyPermission(WORKSPACE_CREATEEDITEXTRACTTABLES);

        return (
            <div className="list-container-arius">
                <div className="list-header-arius">
                    <span style={{display:'flex'}}>
                        <h4>Support Tables</h4>
                    </span>
                    {/* <Button
                        toolTip = "Refresh "
                        mode="add"
                        onClick={this.refresh}
                    /> */}
                    <Button
                        toolTip = "Create new table definition"
                        mode="add"
                        onClick={(e) => this.createTable(e, 0)}
                    />
                </div>
                {this.getGrid()}
                {this.getDialogModal()}
                <ModalEditTable 
                    show={showModalEdit} 
                    record={currentRecord} 
                    closeHandler={()=> {
                        this.setState({showModalEdit: false});
                    }}
                    saveHandler={this.save}
                    existingNames={this.existingNames}
                    canEdit={canEdit}
                    />
                <ModalLoadFile 
                    show={showModalLoadFile} 
                    closeHandler={()=> {
                        this.setState({showModalLoadFile: false});
                        this.refresh();
                    }}
                    tableDefinition={currentRecord}
                    databaseId={databaseId}
                />
            </div>
        )
    }
}

export default TableDefinitionsList;
