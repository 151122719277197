import React, { Component } from 'react';
import { ProgressBar, Form, InputGroup, Button } from 'react-bootstrap';

class BackupTab extends Component {
	static propTypes = {};
	static defaultProps = {};
	constructor(props) {
		super(props);
		this.state = {};
	} 

	render() {
		const {
            backupDescription,
            descChangeHandler,
            createBackupHandler,
            isCreatingBackup,
            backupMessage,
        } = this.props;

        //let waitbar = testMode=='backup2';

        // isCreatingBackup should change in parent when message with complete comes
        // but it doesn't happen

        const isActive = isCreatingBackup;
        const isComplete = backupMessage.indexOf('Completed') > -1;

        const controlsDisabled = (isActive && !isComplete);

        return (
        <Form style={{padding: 15}}>
            <Form.Group controlId="backupDescription">
                <Form.Label>Description For Backup</Form.Label> 
                <InputGroup>
                    <Form.Control 
                        value={backupDescription} 
                        onChange={descChangeHandler}
                        disabled={controlsDisabled}
                        placeholder="Enter description here">
                    </Form.Control>
                    <Button variant="arius" disabled={controlsDisabled} onClick={createBackupHandler}>
                            Create Backup
                    </Button>
                </InputGroup>
            </Form.Group>
            {(isActive && !isComplete) ? (<ProgressBar animated now={100} />) : ('')}
            <Form.Text style={{color: (isActive && !isComplete) ? 'purple' : 'green', paddingLeft: 12, height: 19}}>{backupMessage}</Form.Text>    
        </Form>
        );
	}
}

export default BackupTab;
