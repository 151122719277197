import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Route, Switch } from 'react-router-dom';
import Feature from '@app/utilities/routing/routeSafeFeature';
import { connect } from '@app/utilities/routing';
import {
  fetchWorkspacesIfNeeded,
  createWorkspace,
  updateWorkspace,
  fetchWorkspace,
  fetchWorkspaces,
  //fetchArchivedForAllBackups,
  fetchArchived,
  fetchBackups,
  fetchPointInTimeRestoreRange,
  selectWorkspace,
  createBackup,
  deleteBackup,
  restoreBackup,
  restorePointInTime,
  undoPITR,
  archiveBackup,
  deleteArchivedBackup,
  restoreArchivedBackup,
  fetchDatabaseUdoVersions,
  fetchDatabaseUdoLibrarylockstatus,
  emptyDatabaseUdoVersions,
  fetchCurrentAEDbScriptVersion,
  exportExtractToCsv,
  fetchBackupRestoreLogFile,
  deleteBackupRestoreLogFile,
} from '../../../actions/analysis/workspace.actions';
import {
  fetchDatabases,
  fetchDatabase,
} from '../../../actions/tod/todDatabases.actions';
import {
  clearServerNotifications,
} from '../../../actions/serverNotification.actions';
import {
  clearErrorMessage,
} from '../../../actions/application.actions';
import { SERVER_NOTIFICATION_MESSAGES } from '../../../utilities/constants';
import {
  fetchCurrentUserPermissions,
} from '../../../actions/roles.actions';
import {
  fetchAvailableResources,
} from '../../../actions/portalManagement/roles.actions';
import WorkspaceList from '@app/analysis/workspaceList';
import CreateAriusAnalysisDatabasePage from '@app/analysis/createAriusAnalysisDatabasePage';

class Workspaces extends Component {
  static propTypes = {
    isFetching: PropTypes.bool.isRequired,
    workspaces: PropTypes.arrayOf(
      PropTypes.shape({
        workspaceId: PropTypes.number,
        workspaceName: PropTypes.string,
        workspaceDesc: PropTypes.string,
        workspaceDims: PropTypes.string,
      })
    ).isRequired,
    newSaveHandler: PropTypes.func.isRequired,
    updateSaveHandler: PropTypes.func.isRequired,
    systemDimensions: PropTypes.arrayOf(PropTypes.string),
    current: PropTypes.object,
    dispatch: PropTypes.func.isRequired,
    userKey: PropTypes.string.isRequired,
    serverNotification: PropTypes.object.isRequired,
    clearNotificationHandler: PropTypes.func.isRequired,
    getBackups: PropTypes.func.isRequired,
    getPointInTimeRestoreRange: PropTypes.func.isRequired,
    getAllArchived: PropTypes.func.isRequired,
    backups: PropTypes.array,
    application: PropTypes.object,
    archivedBackups: PropTypes.array,
    getWorkspaces: PropTypes.func,
    getTodDatabases: PropTypes.func,
    todDatabases: PropTypes.array,
    deleteArchivedBackupHandler: PropTypes.func,
    archiveBackupHandler: PropTypes.func,
    restoreArchivedBackupHandler: PropTypes.func,
    restoreBackupHandler: PropTypes.func,
    restorePointInTimeHandler: PropTypes.func,
    selectWorkspaceHandler: PropTypes.func,
    createBackupHandler: PropTypes.func,
    deleteBackupHandler: PropTypes.func,
    getDatabaseUdoVersions: PropTypes.func,
    getDatabaseUdoLibrarylockstatus:PropTypes.func,
    databaseUdoVersions: PropTypes.array,
    UdoLibrarylockstatus:PropTypes.bool.isRequired,
    clearDatabaseUdoVersions: PropTypes.func,
    getCurrentAEDbScriptVersion: PropTypes.func,
    getDatabaseDimensions: PropTypes.func,
    currentTodDatabase: PropTypes.object,
    errorMessage: PropTypes.string,
    clearErrorMessageHandler: PropTypes.func,
    currentAEDbScriptVersion: PropTypes.number,
    getCurrentUserPermissions: PropTypes.func,
    userPermissions: PropTypes.array,
    isExtractToCsvProcessing: PropTypes.bool,
    exportExtractToCsvHandler: PropTypes.func,
    csvExtractFile: PropTypes.string,
    roleDescriptions: PropTypes.array,
    roleDescriptionHandler: PropTypes.func,
    getWorkspace: PropTypes.func,

    retrieveLogFile: PropTypes.func,
    deleteLogFileFromServer: PropTypes.func,
    logFile: PropTypes.object,
  };

  UNSAFE_componentWillMount() {
    const {
      userKey,
      getCurrentAEDbScriptVersion,
      currentAEDbScriptVersion,
      getCurrentUserPermissions,
    } = this.props;
    if (!currentAEDbScriptVersion) {
      getCurrentAEDbScriptVersion(userKey);
    }
    getCurrentUserPermissions();
  }

  componentDidMount() {
    const { dispatch, userKey, getTodDatabases } = this.props;
    dispatch(fetchWorkspacesIfNeeded(userKey));
    getTodDatabases(userKey);
  }

  componentDidUpdate(prevProps) {
    const {
      dispatch,
      current,
      isFetching,
      userKey,
      match,
      serverNotification,
    } = this.props;

    let { params } = match;

    if (isFetching) {
      return;
    }

    if (params.workspaceId) {
      const workspaceId = parseInt(params.workspaceId, 10);
      if (workspaceId) {
        if (current && current.id && current.id === workspaceId) {
          return;
        }
        dispatch(fetchWorkspace(userKey, workspaceId));
      }
    }

    if (
      prevProps.serverNotification &&
      prevProps.serverNotification.message !==
        SERVER_NOTIFICATION_MESSAGES.DB_COMPLETE &&
      serverNotification &&
      serverNotification.message === SERVER_NOTIFICATION_MESSAGES.DB_COMPLETE
    ) {
      dispatch(fetchWorkspaces(userKey));
    }
  }

  render() {
    const {
      workspaces,
      isFetching,
      history,
      newSaveHandler,
      updateSaveHandler,
      systemDimensions,
      current,
      userKey,
      dispatch,
      serverNotification,
      clearNotificationHandler,
      getBackups,
      getPointInTimeRestoreRange,
      getAllArchived,
      selectWorkspaceHandler,
      createBackupHandler,
      deleteBackupHandler,
      restoreBackupHandler,
      undoPITRHandler,
      restorePointInTimeHandler,
      restoreArchivedBackupHandler,
      archiveBackupHandler,
      deleteArchivedBackupHandler,
      getWorkspaces,
      getTodDatabases,
      todDatabases,
      getDatabaseUdoVersions,
      getDatabaseUdoLibrarylockstatus,
      databaseUdoVersions,
      UdoLibrarylockstatus,
      clearDatabaseUdoVersions,
      getDatabaseDimensions,
      currentTodDatabase,
      errorMessage,
      clearErrorMessageHandler,
      getCurrentUserPermissions,
      roleDescriptions,
      roleDescriptionHandler,
      userPermissions,
      isExtractToCsvProcessing,
      exportExtractToCsvHandler,
      csvExtractFile,
      getWorkspace,

      retrieveLogFile,
      deleteLogFileFromServer,
      logFile,
      jobStatus,
    } = this.props;

    let childProps = {
        isFetching,
        workspaces,
        browserHistory: history,
        newSaveHandler,
        updateSaveHandler,
        current,
        currentTodDatabase,
        systemDimensions,
        userKey,
        dispatch,
        serverNotification,
        clearNotificationHandler,
        getBackups,
        getPointInTimeRestoreRange,
        getAllArchived,
        selectWorkspaceHandler,
        createBackupHandler,
        deleteBackupHandler,
        restoreBackupHandler,
        undoPITRHandler,
        restorePointInTimeHandler,
        restoreArchivedBackupHandler,
        archiveBackupHandler,
        deleteArchivedBackupHandler,
        getWorkspaces,
        getTodDatabases,
        todDatabases,
        getDatabaseUdoVersions,
        getDatabaseUdoLibrarylockstatus,
        databaseUdoVersions,
        UdoLibrarylockstatus,
        clearDatabaseUdoVersions,
        getDatabaseDimensions,
        errorMessage,
        clearErrorMessageHandler,
        getCurrentUserPermissions,
        roleDescriptions,
        roleDescriptionHandler,
        userPermissions,
        isExtractToCsvProcessing,
        exportExtractToCsvHandler,
        csvExtractFile,
        getWorkspace,

        retrieveLogFile,
        deleteLogFileFromServer,
        logFile,
        jobStatus,
    };

    return (
    <Feature flag="ariusAnalysis">
        <Switch>
            <Route path="/arius/workspaces/new" render={props => (<CreateAriusAnalysisDatabasePage {...childProps}/>)}/>
            <Route path="/arius/workspaces/:workspaceId" render={props => (<WorkspaceList {...{...childProps, params: props.match.params}}/>)}/>
            <Route path="/arius/workspaces" render={props => (<WorkspaceList {...childProps}/>)}/>
        </Switch>   
    </Feature>
    );
  }
}

const mapStateToProps = state => ({
  users: state.portalManagement.users,
  isFetching: state.analysis.workspaces.isFetching,
  workspaces: state.analysis.workspaces.items,
  current: state.analysis.workspaces.current,
  userKey: state.user.userKey,
  systemDimensions: state.analysis.workspaces.systemDimensions,
  application: state.application,
  serverNotification: state.serverNotification,
  databaseUdoVersions: state.analysis.workspaces.databaseUdoVersions,
  UdoLibrarylockstatus:state.analysis.workspaces.UdoLibrarylockstatus,
  currentAEDbScriptVersion: state.analysis.workspaces.currentAEVersion,
  todDatabases: state.tod.databases.items,
  currentTodDatabase: state.tod.databases.current,
  errorMessage: state.application.errorMessage,
  userPermissions: state.user.userPermissions,
  isExtractToCsvProcessing: state.analysis.workspaces.isExtractToCsvProcessing,
  csvExtractFile: state.analysis.workspaces.csvExtractFile,
  logFile: state.analysis.workspaces.txtFile,
  jobStatus : state.jobStatus,
});

const mapDispatchToProps = dispatch => ({
  newSaveHandler: ({
    userKey,
    workspaceId,
    workspaceName,
    workspaceDescription,
    workspaceDimensions,
    createdBy,
    updatedBy,
  }) => {
    dispatch(
      createWorkspace({
        userKey,
        workspaceId,
        workspaceName,
        workspaceDescription,
        workspaceDimensions,
        createdBy,
        updatedBy,
      })
    );
  },
  updateSaveHandler: ({
    userKey,
    workspaceId,
    workspaceName,
    workspaceDescription,
    workspaceDimensions,
    createdBy,
    updatedBy,
  }) => {
    dispatch(
      updateWorkspace({
        userKey,
        workspaceId,
        workspaceName,
        workspaceDescription,
        workspaceDimensions,
        createdBy,
        updatedBy,
      })
    );
  },
  // newSaveHandler: ({ workspaceName, workspaceDesc, userDefinedDims, userKey }) => {
  //   dispatch(createWorkspace({ userKey, workspaceName, workspaceDesc, userDefinedDims }));
  // },
  clearNotificationHandler: () => {
    dispatch(clearServerNotifications());
  },
  clearErrorMessageHandler: () => {
    dispatch(clearErrorMessage());
  },
  selectWorkspaceHandler: workspace => {
    dispatch(selectWorkspace(workspace));
  },
  getBackups: (userKey, workspaceId) => {
    dispatch(fetchBackups(userKey, workspaceId));
  },
  getPointInTimeRestoreRange: (userKey, workspaceId) => {
      dispatch(fetchPointInTimeRestoreRange(userKey, workspaceId));
  },
  // getAllArchived: (userKey, workspaceId, backups) => {
  //   dispatch(fetchArchivedForAllBackups(userKey, workspaceId, backups));
  // },

  getAllArchived: (userKey, workspaceId) => {
    dispatch(fetchArchived(userKey, workspaceId));
  },

  createBackupHandler: (userKey, workspaceId, description) => {
    dispatch(createBackup(userKey, workspaceId, description));
  },
  deleteBackupHandler: (userKey, workspaceId, backupId) => {
    dispatch(deleteBackup(userKey, workspaceId, backupId));
  },
  deleteArchivedBackupHandler: (userKey, workspaceId, backupId, archiveId) => {
    dispatch(deleteArchivedBackup(userKey, workspaceId, backupId, archiveId));
  },
  restoreBackupHandler: (userKey, workspaceId, backupId) => {
    dispatch(restoreBackup(userKey, workspaceId, backupId));
  },
  restorePointInTimeHandler: (userKey, workspaceId, timestamp) => {
      dispatch(restorePointInTime(userKey, workspaceId, timestamp));
  },
  undoPITRHandler: (userKey, workspaceId, backupId) => {
    dispatch(undoPITR(userKey, workspaceId, backupId));
  },
  restoreArchivedBackupHandler: (
    userKey,
    workspaceId,
    backupId,
    archivedId
  ) => {
    dispatch(restoreArchivedBackup(userKey, workspaceId, backupId, archivedId));
  },
  archiveBackupHandler: (userKey, workspaceId, backupId, description) => {
    dispatch(archiveBackup(userKey, workspaceId, backupId, description));
  },
  getWorkspaces: userKey => {
    dispatch(fetchWorkspaces(userKey));
  },
  getWorkspace: (userKey, workspaceId) => {
    dispatch(fetchWorkspace(userKey, workspaceId));
  },
  getTodDatabases: userKey => {
    dispatch(fetchDatabases(userKey));
  },
  getDatabaseDimensions: (userKey, databaseId) => {
    dispatch(fetchDatabase(userKey, databaseId));
  },
  getDatabaseUdoVersions: (userKey, workspaceId) => {
    dispatch(fetchDatabaseUdoVersions(userKey, workspaceId));
  },
  getDatabaseUdoLibrarylockstatus: (userKey, workspaceId) =>{
    dispatch(fetchDatabaseUdoLibrarylockstatus(userKey, workspaceId));
  },
  
  clearDatabaseUdoVersions: () => {
    dispatch(emptyDatabaseUdoVersions());
  },
  getCurrentAEDbScriptVersion: userKey => {
    dispatch(fetchCurrentAEDbScriptVersion(userKey));
  },
  getCurrentUserPermissions: () => {
    dispatch(fetchCurrentUserPermissions());
  },
  availableResourcesHandler: () => {
    dispatch(fetchAvailableResources());
  },
  exportExtractToCsvHandler: (userKey, workspaceId, layoutType) => {
    dispatch(exportExtractToCsv(userKey, workspaceId, layoutType));
  },

  retrieveLogFile: (userKey, databaseId) => {
    dispatch(fetchBackupRestoreLogFile(userKey, databaseId));
  },
  deleteLogFileFromServer: (userKey, databaseId) => {
    dispatch(deleteBackupRestoreLogFile(userKey, databaseId));
  },

  dispatch,
});

export default connect(mapStateToProps, mapDispatchToProps)(Workspaces);
