import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {Card, ListGroup } from 'react-bootstrap';

const style = {
    accordionRow: {
        display: 'flex',
        justifyContent: 'flex-start',
        height: 'auto',
        minHeight: '3em',
        marginLeft: '2em',
        marginRight: '2em',
        marginBottom: '1px'
    },
    label: {
        padding: '0px',
        fontWeight: 'bold',
    },
    listItem: {
        border: '0px',
        padding: '0px 10px 0px 0px',
    },
    panelCustom: {
        borderRadius: '0px',
        backgroundColor: 'white',
        margin: '0px',
        border: '0px',
        // width: '60vw',
        padding: '0px',
        boxShadow: '0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)',
        transition: 'all 0.3s cubic-bezier(.25,.8,.25,1)',
    },
    list: {
        border: '0px',
        display: 'flex',
        flexFlow: 'row wrap',
    },
};

class WorkspaceSubItem extends Component {
    static propTypes = {
        id: PropTypes.number,
        idx: PropTypes.number,
        name: PropTypes.string,
        description: PropTypes.string,
        dimensions: PropTypes.string,
        numOfProjects: PropTypes.number,
        selected: PropTypes.bool,
        deleteClickHandler: PropTypes.func,
        openBackupModalHandler: PropTypes.func,
        viewWorkSpaceHandler: PropTypes.func,
        openUdoLibraryModalHandler: PropTypes.func,
        permissions: PropTypes.array,
        verifyPermission: PropTypes.func,
        exportExtractNarrowToCsvHandler: PropTypes.func,
        exportExtractWideToCsvHandler: PropTypes.func,
        editClickHandler: PropTypes.func,
        databaseVersion: PropTypes.string,
        current: PropTypes.object,
        selectWorkspaceHandler: PropTypes.func,
        startFullExtraction: PropTypes.func,
        openDatabaseUpdateModal: PropTypes.func,
        workspaces: PropTypes.array,
        userPermissions: PropTypes.array,
    };

    render() {
        const { workspace } = this.props;
        let w = workspace ? workspace : {};
        const {
            workspaceId: id, 
            workspaceName: name,
            workspaceDescription: description,
            workspaceDimensions: dimensions,
            version
        } = w;

        let parsedDimensions = JSON.parse(dimensions);
        let systemDimensions = parsedDimensions ? parsedDimensions.system : null;
        let userDefinedDimensions = parsedDimensions ? parsedDimensions.userDefined : null;

        return (<Card
            key={`${id}-${name}-open-panel`}
            style={style.panelCustom}
        >
            <small style={{...style.accordionRow, marginTop: '1em', justifyContent: 'space-between' }}>
                <div>
                    <span style={{ fontWeight: 'bold' }}>Description:&nbsp;</span>
                    {description}
                </div>
                <div style={{ display: 'flex' }}>
                    <div style={style.label}>DB Version:&nbsp;</div>
                    <ListGroup.Item style={style.listItem} key={`${id}-${name}-version`}>
                        {version}
                    </ListGroup.Item>
                </div>
            </small>
            <small style={style.accordionRow}>
                <div style={style.label}>System:&nbsp;</div>
                <ListGroup style={style.list}>
                    {Array.isArray(systemDimensions)
                        ? systemDimensions.map((dimension, i) => (
                            <ListGroup.Item
                                style={style.listItem}
                                key={`${id}-${name}-list-${i}`}
                            >
                                {dimension}
                                {i < systemDimensions.length - 1 ? <span>,</span> : ''}
                            </ListGroup.Item>
                        ))
                        : 'n/a'}
                </ListGroup>
            </small>
            <small style={style.accordionRow}>
                <div style={{...style.label, whiteSpace: 'nowrap' }}>
                    User Defined:&nbsp;
                </div>
                <ListGroup style={style.list}>
                    {Array.isArray(userDefinedDimensions)
                        ? userDefinedDimensions.map((dimension, i) => (
                            <ListGroup.Item
                                style={style.listItem}
                                key={`${id}-${name}-userlist-list-${i}`}
                            >
                                {dimension}
                                {i < userDefinedDimensions.length - 1
                                    ? <span>,</span>
                                    : ''}
                            </ListGroup.Item>
                        ))
                        : 'n/a'}
                </ListGroup>
            </small>
        </Card>);
    }
}

export default WorkspaceSubItem;

