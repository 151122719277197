import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-bootstrap';
import { Button } from '@arius';

class ColumnRow extends Component {
  static propTypes = {
    onSelectChanged: PropTypes.func,
    onNameChanged: PropTypes.func,
    onTypeChanged: PropTypes.func,
    onCumulativeChanged: PropTypes.func,
    openFormulaModal: PropTypes.func,
    deleteColumn: PropTypes.func,
    column: PropTypes.object,
    validationError: PropTypes.string,
    idx: PropTypes.number,
    canEdit: PropTypes.bool,
    columnTypes: PropTypes.array,
    hasDataLoaded: PropTypes.bool,
  };
  constructor(props) {
    super(props);
    this.state = {};
  }

  shouldComponentUpdate(nextProps) {
    const cCol = this.props.column;
    const nCol = nextProps.column;

    return this.props.idx !== nextProps.idx || this.props.validationError !== nextProps.validationError
      || nCol.columnType !== cCol.columnType
      || nCol.factColumnDisplayName !== cCol.factColumnDisplayName
      || nCol.formula !== cCol.formula
      || nCol.isCumulative !== cCol.isCumulative
      || nCol.isSelected !== cCol.isSelected;
  }

    getCheckCell() {
        const { column, canEdit, columnStyles } = this.props;
        let content = canEdit ? 
            <div className='checkbox-container'>
                <Form.Check type="checkbox"
                    onChange={(e) => this.props.onSelectChanged(e)}
                    checked={ column.isSelected }
                />
            </div> : '';

        return <td style={columnStyles[0]}>
            {content}
        </td>
    }

    getColumnNameCell(){
        const { column, validationError, canEdit, idx, columnStyles } = this.props;
        return <td style={columnStyles[1]}>
            <Form.Group>
            <Form.Control
                id={`nameInput${idx}`}
                isInvalid={validationError}
                type="text" maxLength="255"
                disabled={!canEdit}
                title={column.factColumnDisplayName}
                value={column.factColumnDisplayName}
                placeholder="Enter column name"
                onChange={(e) => this.props.onNameChanged(e.target.value)}
                onBlur={(e) => this.props.onNameChanged(e.target.value.trim())}
            />
            <Form.Control.Feedback type='invalid' style={{ marginBottom: 0 }}>{validationError}</Form.Control.Feedback>
            </Form.Group>
        </td>
    }

    getColumnTypeCell(){
        const { column, canEdit, columnTypes, hasDataLoaded, columnStyles } = this.props;
        const isCalculated = column.columnType === 'calc_measure';
        return <td 
            style={{...columnStyles[2]}}>
            <Form.Select
            placeholder=""
            value={column.columnType}
            disabled={!(canEdit || isCalculated)}
            onChange={(e) => this.props.onTypeChanged(e)}>
            {(() => {
                if (hasDataLoaded) {
                if (column.factColumnGuid && !isCalculated) {
                    return columnTypes.map((col, index) => (
                    <option key={`${index}-${col.value}`} value={col.value}>{col.label}</option>
                    ));
                } else {
                    const filteredColumnTypes = columnTypes.filter(ct => ct.columnTypeId === 2 || ct.columnTypeId === 5);
                    return filteredColumnTypes.map((col, index) => (
                    <option key={`${index}-${col.value}`} value={col.value}>{col.label}</option>
                    ));
                }
                } else {
                return columnTypes.map((col, index) => (
                    <option key={`${index}-${col.value}`} value={col.value}>{col.label}</option>
                ));
                }
            })()}
            </Form.Select>
        </td>
    }

    getCumulativeCell(){
        const { column, canEdit, columnStyles } = this.props;
        const isCalculated = column.columnType === 'calc_measure';

        let content = column.columnType === 'measure' || column.columnType === 'calc_measure' ?
            <div className='checkbox-container'>
                <Form.Check type="checkbox"
                    checked={column.isCumulative}
                    disabled={!(canEdit || isCalculated)}
                    onChange={this.props.onCumulativeChanged}
                />
            </div> : '';
        return <td 
            style={{ ...columnStyles[3] , textAlign: 'center' }}
        >
            {content}
        </td>
    }

    getFormulaCell(){
        const { column, columnStyles, openFormulaModal } = this.props;
        const isCalculated = column.columnType === 'calc_measure';

        return <td style={columnStyles[4]}>
          {isCalculated ? (
            <div style={{display: 'flex'}} >
              <Form.Group>
                <Form.Control 
                    type="text" 
                    title={column.formula}
                    isInvalid={!column.formula} 
                    htmlSize={70}
                    value={column.formula} disabled />
                <Form.Control.Feedback type='invalid' style={{ marginBottom: 0 }}>Please enter a formula</Form.Control.Feedback>
              </Form.Group>
              <Button toolTip="Edit Formula" iconName="edit" onClick={openFormulaModal}/>
            </div>
          ): ''}
        </td>;
    }

    getDeleteCell(){
        const { canEdit, columnStyles, deleteColumn } = this.props;

        return <td style={columnStyles[6]}>
            {canEdit ? (<Button toolTip="Remove Column" mode="delete" onClick={deleteColumn}/>
                ) :
            <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>}
        </td>
    }

    render() {
        const { idx } = this.props;
        return (
        <tr key={`newColumn-${idx}`} style={{}} className="d-flex">
            {this.getCheckCell()}
            {this.getColumnNameCell()}
            {this.getColumnTypeCell()}
            {this.getCumulativeCell()}
            {this.getFormulaCell()}
            {this.getDeleteCell()}
        </tr>
        );
    }
}

export default ColumnRow;
