
import React from 'react';
import * as style from '@app/utilities/globalStyles';

let Table = {
    Thead: (props) => {
        let s = props.style ? {...props.style} : {};
        return (
        
        <thead className={''} style={{fontSize: style.fontSizes.tableHeader, ...s}}>
        {/* <thead className={''}> */}
            {props.children}
        </thead>)
    },
    Th: (props) => {
        let style = props.style ? {...props.style} : {};
        if (props.width){
            style.width = props.width;
        }
        return (
            <th
                {...props}
                className={'table-cell-content cell-positioning pointer'}
                style={style}
            ></th>
        )
    },
    Tr: (props) => {
        let additionalClasses = props.className ? props.className : '';
        let pointerClass = props.pointer==='false' ? '' : 'pointer';
        return (
            <tr
                {...props}
                className={`${pointerClass} ${additionalClasses}`}
                style={props.style}
            >
                {props.children}
            </tr>
        )
    },
    Td: (props) => {
        let style = props.style ? {...props.style} : {};
        if (props.width){
            style.width = props.width;
        }
        return (
            <td
                {...props}
                className={'table-cell-content cell-positioning'}
                style={style}
            >
                {props.children}
            </td>
        )
    },
    Tbody: (props) => {
        let scrollClass = props.scroll === false ? '' : 'scrolling'
        return (
            <tbody
                {...props}
                className={scrollClass}
                style={{borderTop: '2px rgb(220,220,220) solid', ...props.style}}
            >
                {props.children}
            </tbody>
        )
    },
    ResponsiveTable: (props) => {
        let striping = props.unstriped ? 'table-unstriped' : 'table-striped';
        return (
            <div className="table-responsive table-responsive-arius" 
                style={{padding: 0}}>
                <table className={`table ${striping}`}>
                    {props.children}
                </table>
            </div>
        )
    }
}

export default Table;