import React, { Component } from 'react';
import { connect } from '@app/utilities/routing';
import { Switch, Route, Redirect } from 'react-router-dom';
import { 
    fetchModels,
    fetchUploads, 
    fetchParams,
    fetchResults,
    deleteUploadedFile, 
    uploadFile,
    requestRun,
    requestUploadToTOD
} from '@app/actions/analytics/analytics.actions';
import { createPermissionChecker, ADVANCED_ANALYTICS_UTILIZE } from '@app/utilities/permissions';
import UploadsList from '@app/analytics/uploadsList';
import AdvancedAnalyticsMain from '@app/analytics/advancedAnalyticsMain';

class AnalyticsTab extends Component {
    constructor(props) {
        super(props);
        this.state = {}
        this.permissionChecker = createPermissionChecker(this.props.userPermissions);
        this.canUtilize = this.permissionChecker(ADVANCED_ANALYTICS_UTILIZE);
    }

    componentDidUpdate(prevProps) {
    }

    UNSAFE_componentWillMount() {
    }

    render() {
        const {
            userKey,
            dispatch,
            models,
            uploads,
            deleteFile,
            uploadNewFile,
            isSaving,
            isFetching,
            history,
            results,
            inputParams,
            errorMessage,
            clearError,
            hasWriteAccess,
            getModels,
            getUploads,
            getResults,
            getParams,
            requestModelRun,
            requestUploadToTOD,
            isRequestingUpload,
            match,
            selectedModel
        } = this.props;

        let childProps = {
            userKey,
            dispatch,
            models,
            uploads,
            deleteFile,
            uploadNewFile,
            isSaving,
            isFetching,
            history,
            results,
            inputParams,
            hasWriteAccess,
            errorMessage,
            clearError,
            getModels,
            getUploads,
            getResults,
            getParams,
            requestModelRun,
            requestUploadToTOD,
            isRequestingUpload,
            canUtilize: this.canUtilize,
            params: match.params,
            selectedModel
        };

        return <div style={{display: 'flex'}}>
            <div>
                <Switch>
                    <Route path="/analytics/uploads" render={p=> <UploadsList {...childProps}/>}/>
                    <Route path="/analytics/models/:modelId" render={p=> <AdvancedAnalyticsMain {...childProps}/>}/>
                    <Route path="/analytics/models" render={p=> <AdvancedAnalyticsMain {...childProps}/>}/>
                    <Route path="/analytics" render={props => <Redirect to="/analytics/models"/>}/>
                </Switch>
            </div>
        </div>;
    }
}


const mapStateToProps = (state) => ({
    userKey: state.user.userKey,
    userPermissions: state.user.userPermissions,
    application: state.application,
    models: state.analytics.models,
    selectedModel: state.analytics.selectedModel,
    uploads: state.analytics.uploads,
    isSaving: state.analytics.isSaving,
    isFetching: state.analytics.isFetching,
    results: state.analytics.results,
    inputParams: state.analytics.params,
    errorMessage: state.application.errorMessage,
    isRequestingUpload: state.analytics.isRequestingUpload,
});

const mapDispatchToProps = (dispatch) => ({
    getModels: (userKey) => {
        dispatch(fetchModels(userKey));
    },
    getUploads: (userKey) => {
        dispatch(fetchUploads(userKey));
    },
    getResults: (userKey, modelName) => {
        dispatch(fetchResults(userKey, modelName));
    },
    getParams: (userKey, modelName) => {
        dispatch(fetchParams(userKey, modelName));
    },
    deleteFile: (userKey, fileId) => {
        dispatch(deleteUploadedFile(userKey, fileId));
    },
    uploadNewFile: (userKey, file) => {
        dispatch(uploadFile(userKey, file));
    },
    requestModelRun: (userKey, request) => {
        dispatch(requestRun(userKey, request));
    },
    requestUploadToTOD: (userKey, req) => {
        dispatch(requestUploadToTOD(userKey, req))
    },
    dispatch,
});

export default connect(mapStateToProps, mapDispatchToProps)(AnalyticsTab);
