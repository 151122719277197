import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-bootstrap';
import { Button } from '@arius';

class ColumnRow extends Component {
    static propTypes = {
        onSelectChanged: PropTypes.func,
        onNameChanged: PropTypes.func,
        deleteColumn: PropTypes.func,
        column: PropTypes.object,
        validationError: PropTypes.string,
        idx: PropTypes.number,
        canEdit: PropTypes.bool,
        hasDataLoaded: PropTypes.bool,
    };
    constructor(props) {
        super(props);
        this.state = {};
    }

    shouldComponentUpdate(nextProps) {
        const cCol = this.props.column;
        const nCol = nextProps.column;

        return this.props.idx !== nextProps.idx || this.props.validationError !== nextProps.validationError
        || nCol.factColumnDisplayName !== cCol.factColumnDisplayName
        || nCol.isSelected !== cCol.isSelected;
    }

    getCheckCell() {
        const { column, canEdit, columnStyles } = this.props;
        let content = canEdit ? 
            <div className='checkbox-container'>
                <Form.Check type="checkbox"
                    onChange={(e) => this.props.onSelectChanged(e)}
                    checked={ column.isSelected }
                />
            </div> : '';

        return <td style={columnStyles[0]}>
            {content}
        </td>
    }

    getColumnNameCell(){
        const { column, validationError, canEdit, idx, columnStyles } = this.props;
        return <td style={columnStyles[1]}>
            <Form.Group>
            <Form.Control
                id={`nameInput${idx}`}
                isInvalid={validationError}
                type="text" maxLength="255"
                disabled={!canEdit}
                title={column.factColumnDisplayName}
                value={column.factColumnDisplayName}
                placeholder="Enter column name"
                onChange={(e) => this.props.onNameChanged(e.target.value)}
                onBlur={(e) => this.props.onNameChanged(e.target.value.trim())}
            />
            <Form.Control.Feedback type='invalid' style={{ marginBottom: 0 }}>{validationError}</Form.Control.Feedback>
            </Form.Group>
        </td>
    }

    getDeleteCell(){
        const { canEdit, columnStyles, deleteColumn } = this.props;

        return <td style={columnStyles[2]}>
            {canEdit ? (<Button toolTip="Remove Column" mode="delete" onClick={deleteColumn}/>
                ) :
            <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>}
        </td>
    }

    render() {
        const { idx } = this.props;
        return (
        <tr key={`newColumn-${idx}`} style={{}} className="d-flex">
            {this.getCheckCell()}
            {this.getColumnNameCell()}
            {this.getDeleteCell()}
        </tr>
        );
    }
}

export default ColumnRow;
