import React, { Component } from 'react';
import { connect } from '@app/utilities/routing';
import moment from 'moment';
import { Table, Button as AButton } from '@arius';

class PITRGrid extends Component {
	static propTypes = {};
	static defaultProps = {};
	constructor(props) {
		super(props);
		this.state = {};
	}

	componentDidMount() {
	}

	componentDidUpdate(prevProps) {
    }

	render() {
		const {
            backups,
            undoPointInTime,
            deleteBackupHandler
        } = this.props;
        console.log('PITR Grid Render');

        const {Tr, Td, Th, Tbody, Thead, ResponsiveTable:Tbl} = Table;

        let availableBackups = backups ? backups.filter(x=> x.isPitr) : [{description: 'No existing PITR'}];
        console.log(availableBackups);

        if (availableBackups && availableBackups.length > 0) {
          console.log(` count: ${availableBackups.length}`);
          availableBackups = availableBackups.sort(function(a,b){
            return new Date(b.createdDate) - new Date(a.createdDate);
          }).slice(0, 1);
        }


        return (
        <Tbl>
        <Thead>
          <Tr>
            {/* <th>IsPitr</th> */}
            <Th>Description</Th>
            <Th>Size</Th>
            <Th>Status</Th>
            <Th>Date Created</Th>
            <Th>Created By</Th>
            <Th></Th>
          </Tr>
        </Thead>
        <Tbody>
          {availableBackups.map(backup =>
            (<Tr key={`backup-${backup.id}`}>
                <Td>{backup.description}</Td>
                <Td>{backup.size}</Td>
                <Td>{backup.status}</Td>
                <Td>{moment.utc(backup.createdDate).local().format('L LT')}</Td>
                <Td>{backup.createdBy}</Td>
                <Td>
                    <AButton toolTip="Undo PITR" placement="left" iconName="undo" onClick={(e) => undoPointInTime(backup.id)} />
                    <AButton toolTip="Delete PITR" placement="left" iconName="delete" onClick={(e) => deleteBackupHandler(backup.id)} />
                </Td>
            </Tr>)
          )}
        </Tbody>
      </Tbl>
    );
	}
}

const mapStateToProps = state => ({
});

const mapDispatchToProps = dispatch => ({
	dispatch,
  });
  
export default connect(mapStateToProps, mapDispatchToProps)(PITRGrid);
