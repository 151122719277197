import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Table, Button } from '@arius';
import { notifyError } from '@app/utilities/notifier';
import { SITE_MANAGESECURITY } from '@app/utilities/permissions';

class UserRole extends Component {
    static propTypes = {
        id: PropTypes.string,
        name: PropTypes.string,
        resource: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        ]),
        role: PropTypes.string,
        deleteClickHandler: PropTypes.func,
        userPermissions: PropTypes.array,
        resourceSelectedMode: PropTypes.bool,
        verifyPermission: PropTypes.func,
    };
    constructor(props) {
        super(props);
        this.state = {
        popOvrOpen: false,
        };
    }

    render() {
        const {
            id,
            name,
            resource,
            role,
            application,
            allowDelete,
            deleteClickHandler,
            verifyPermission,
        } = this.props;
        let { Td, Tr } = Table;
        // permissions based variables
        let deleteRoleAssignmentJSX = allowDelete ? (
            <Button
                key={`delete-${id}`}
                toolTip='Delete'
                iconName='delete'
                onClick={e => {
                    if (verifyPermission(SITE_MANAGESECURITY)){
                        deleteClickHandler(id, e);
                    } else {
                        notifyError('You are not authorized to access this functionality');
                    }
                }}
            />
        ) : (<i key={`delete-${id}`} style={{fontSize: '20px'}}>&nbsp; &nbsp;</i>);

        return (
        <Tr>
            <Td>{name}</Td>
            <Td>{resource}</Td>
            <Td>{application}</Td>
            <Td>{role}</Td>
            <Td>{deleteRoleAssignmentJSX}</Td>
        </Tr>
        );
    }
}

export default UserRole;
