import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-bootstrap';
import { connect } from '@app/utilities/routing';
import { deleteQuery, fetchQueries, copyQuery} from '@app/actions/tod/advancedQueries.actions';
import { showModal } from '@app/actions/modal.actions';
import { DATA_MANAGEQUERIES } from '@app/utilities/permissions';
import { setBackRoute } from '@app/actions/application.actions';
import { notifyError } from '@app/utilities/notifier';
import Loader from '@app/shared/presentational/loader';
import { Grid, Button } from '@arius';

class QueriesList extends Component {
	static propTypes = {
		queries: PropTypes.array.isRequired,
		databases: PropTypes.array,
		changeDatabase: PropTypes.func,
		dispatch: PropTypes.func,
		userKey: PropTypes.string,
		currentDb: PropTypes.object,
		isFetching: PropTypes.bool,
		params: PropTypes.object,
		verifyPermission: PropTypes.func,
		getDatasetColumns: PropTypes.func,
	};
	static defaultProps = {
		queries: [],
	};
	constructor(props) {
		super(props);
		this.state = {
			columnSelected: '',
			isDescending: false,
            currentQueries: [],
            currentDbId: 0
		};
		this.changeDatabase = this.changeDatabase.bind(this);
        this.promptToDeleteQuery = this.promptToDeleteQuery.bind(this);
        this.promptToCopyQuery = this.promptToCopyQuery.bind(this);
        this.getGrid = this.getGrid.bind(this);
        this.getActionItems = this.getActionItems.bind(this);
        this.loadQueries = this.loadQueries.bind(this);
    }
    
    componentDidMount() {
        // assume navigation came from back button on createQuery...
        this.loadQueries();
    }

	componentDidUpdate(prevProps) {
        const { currentDb, isFetching } = this.props;
        const { currentDbId } = this.state;
        if (currentDb && currentDbId !== currentDb.id) {
            //console.error('queriesList did update: setting state...', currentDb, prevProps.currentDb, isFetching)
            this.setState({currentDbId: currentDb.id});
            return;
        }
        if (!prevProps.isFetching && !isFetching){
            this.loadQueries();
        }
    }
    
    loadQueries() {
        const {currentDb, dispatch, userKey} = this.props;
        if (currentDb){
            dispatch(fetchQueries(userKey,  currentDb.id));
        };
    }

    getTemplateQueryDescription(q){
        let description = q.isValid && !q.isAvailableForUse ? `[PREPARING] ${q.description}` : q.description;
        description = q.error ? <div style={{color: 'red'}}>{q.error}</div> : description;
        return <span>{description}</span>;
    }

    getTemplateIsValid(q){
        return (
            <span style={{ color: q.isValid ? 'green' : 'red'}}>
                <i className="material-icons">{q.isValid ? 'check' : 'error'}</i>
            </span>
        )
    }
    getGrid() {
        const { queries } = this.props;
        let columns = [
            { field: 'name', headerText: 'Name'},
            { field: 'description', headerText: 'Description', template: this.getTemplateQueryDescription},
            { field: 'lastModifiedBy', headerText: 'Last Modified By'},
            { field: 'lastModifiedOn', headerText: 'Last Modified'},
            { field: 'isValid', headerText: 'Valid/Complete',textAlign: 'center', template: this.getTemplateIsValid, width: 85, headerTextAlign: 'center'},
            { field: 'queryId', headerText: 'Actions', template: this.getActionItems}
        ];

        return <Grid 
            columns={columns} 
            data={queries} 
            height='calc(100vh - 280px)' 
            persistenceKey={'grid_advQueries'}
        />;
    }

    getActionItems(q) {
        const {
            browserHistory,
            currentDb,
            verifyPermission,
        } = this.props;
    
        const hasPermissions = verifyPermission(DATA_MANAGEQUERIES);
        const hasWriteAccess = currentDb && hasPermissions;

        return (
            <span>
                <Button
                    id={`edit-${q.queryId}`}
                    onClick={()=> {browserHistory.push(`/tod/advancedQueries/${currentDb.id}/${q.queryId}`);}}
                    toolTip={hasWriteAccess ? 'View/Edit' : 'View'}
                    iconName='edit'
                />
                 <Button
                    id={`copy-${q.queryId}`}
                    onClick={e => this.promptToCopyQuery(q)}
                    toolTip='Copy Query'
                    iconName='content_copy'
                />
                <Button
                    id={`delete-${q.queryId}`}
                    onClick={e => this.promptToDeleteQuery(q)}
                    toolTip='Delete Query'
                    iconName='delete'
                />
            </span>
        )
    }

	changeDatabase(e) {
		const {
			databases,
			changeDatabase,
		} = this.props,
		database = databases.find(
			d => d.workspaceId.toString() === e.target.value
		);
		changeDatabase(database);
	}

	promptToDeleteQuery(query) {
		const {
			userKey,
			currentDb,
			queries,
            dispatch,
            verifyPermission
        } = this.props;
        
        const hasPermissions = verifyPermission(DATA_MANAGEQUERIES);
        const hasWriteAccess = currentDb && hasPermissions;
                            
        if (!hasWriteAccess) {
            notifyError('You are not authorized to access this functionality');
            return;
        }


        let id = query.queryId;
		if (id && queries && queries.length > 0) {
			let deleteMessageItems = [
			<li
				key={query.queryId}
				style={{ fontWeight: 800, listStyle: 'none' }}
			>
				{query.name}
			</li>,
			];

			deleteMessageItems.unshift(
				`Are you sure you want to delete the following query:`
			);
			deleteMessageItems.push(
				'This could affect Arius projects for segments that reference this query.'
			);

			const yesClickHandler = () => {
				dispatch(deleteQuery(userKey, currentDb.id, id));
			};
			const noClickHandler = () => {};
			const action = showModal(
				'confirmation',
				deleteMessageItems,
				yesClickHandler,
				noClickHandler
			);
			dispatch(action);
		}
	}
    
	promptToCopyQuery(query) {
		const {
			userKey,
			currentDb,
			dispatch,
            browserHistory,
            verifyPermission
        } = this.props;
        
        const hasPermissions = verifyPermission(DATA_MANAGEQUERIES);
        const hasWriteAccess = currentDb && hasPermissions;
                            
        if (!hasWriteAccess) {
            notifyError('You are not authorized to access this functionality');
            return;
        }
        
		if (query) {
            let id = query.queryId;
			let messageItems = [
			<li
				key={query.queryId}
				style={{ fontWeight: 800, listStyle: 'none' }}
			>
				{query.name}
			</li>,
			];

			messageItems.unshift(
				`Copy the following query:`
			);

			const yesClickHandler = () => {
				dispatch(copyQuery(userKey, currentDb.id, id, (queryId)=> {
                    browserHistory.push(
                        `/tod/advancedQueries/${currentDb.id}/${queryId}/edit`,
                      );
                }));
			};
			const noClickHandler = () => {};
			const action = showModal(
				'confirmation',
				messageItems,
				yesClickHandler,
				noClickHandler
			);
			dispatch(action);
		}
    }
    
    dataGroupNav() {
        const { dispatch, browserHistory, currentDb } = this.props;
        var ext = currentDb ? `/${currentDb.id}` : '';
        dispatch(setBackRoute(`/tod/advancedQueries${ext}`));
        browserHistory.push(`/tod/advancedQueries${ext}/groups`);
    }

	render() {
		const {
			databases,
			isFetching,
			currentDb,
			verifyPermission,
			browserHistory,
        } = this.props;
		const hasPermissions = verifyPermission(DATA_MANAGEQUERIES);
		const hasWriteAccess = currentDb && hasPermissions;
		const accessErrorMessage = !currentDb ? 'Must first select a database' :
						'You are not authorized to access this functionality';

		let tableMarkup = <div />;

		if (currentDb) {
			if (isFetching) {
				tableMarkup = (
					<div style={{ margin: '5vh auto' }}>
						<Loader loadingItem="Queries" />
					</div>
				);
			} else {
				tableMarkup = (this.getGrid());
			}
		}

		return (
		<div  className="list-container-arius">
			<div className="list-header-arius">
				<h4>Advanced Queries</h4>
                <div style={{display: 'flex'}}>
                    <Button variant="arius" style={{marginRight: '20px'}} onClick={e => this.dataGroupNav()}>Groups</Button>
                    <Button
                        toolTip="Create New Query"
                        mode="add"
                        onClick={
                            hasWriteAccess
                            ? () => browserHistory.push(`/tod/advancedQueries/${currentDb.id}/new`)
                            : () => notifyError(accessErrorMessage)
                        }/>
                </div> 
			</div>
			<Form.Group controlId="selectDatabaseControl" className='select-database'>
				<Form.Label>Select Database</Form.Label>
				<Form.Select
					placeholder="select"
					value={currentDb ? currentDb.id : 'default'}
					onChange={this.changeDatabase}
				>
					<option value="default">Select a database</option>
					{databases.map(db => (
						<option key={`${db.workspaceId}`} value={db.workspaceId}>
						{db.workspaceName}
						</option>
					))}
				</Form.Select>
			</Form.Group>
			{tableMarkup}
		</div>
		);
	}
}

const mapStateToProps = state => ({
	queries: state.tod.queries.items,
	isFetching: state.tod.queries.isFetchingQueries,
});

const mapDispatchToProps = dispatch => ({
	dispatch,
});
  
export default connect(mapStateToProps, mapDispatchToProps)(QueriesList);
