import {
  RECEIVED_USER,
  FETCHING_USER_FROM_SERVER,
  UNAUTHORIZED_USER,
  SET_SECURE_DATA_MANAGER_FACTORY,
  RECEIVED_FEATURES
} from './actionTypes';
import * as userServiceApi from '../serviceApi/users.serviceApi';
import { SecureDataManagerFactory } from '@app/utilities/secureDataManager';

function fetchingFromServer() {
  return {
    type: FETCHING_USER_FROM_SERVER,
  };
}

function receivedUser(json) {
  return {
    type: RECEIVED_USER,
    userId: json.userId,
    userKey: json.userKey,
    userPermissions: json.sitePermissions,
    siteSettings: json.siteSettings,
    receivedAt: Date.now(),
  };
}

function unauthorized() {
  return {
    type: UNAUTHORIZED_USER,
    receivedAt: Date.now(),
  };
}

function fetchCurrentUser(accessToken) {
    
  return (dispatch, getState) => {
    dispatch(fetchingFromServer());
    return userServiceApi.retrieveCurrentUser(accessToken).then(json => {
        let userReceived = json && !json.error && json.userId && json.userKey;
        if (!userReceived){
            dispatch(unauthorized());
        } else {
            dispatch({
                type: RECEIVED_FEATURES,
                features: json.features
            })
            dispatch(receivedUser(json, getState()));
        }
    });
  };
}

function refreshSecureDataManagerFactory(userKey, accessToken) {
    return {
        type: SET_SECURE_DATA_MANAGER_FACTORY, 
        factory: new SecureDataManagerFactory(userKey, accessToken)
    };
}

export { fetchCurrentUser, unauthorized, refreshSecureDataManagerFactory };
