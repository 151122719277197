import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { alphabetize } from '../../../utilities/helpers';
import { retrieveExportsByListType, deleteExportListItem } from '../../../actions/analysis/project.actions';
import { showModal } from '../../../actions/modal.actions';
import { Grid, Button } from '@arius';
import Loader from '@app/shared/presentational/loader';
import PlaceHolderMessage from '@app/shared/presentational/placeHolderMessage';

const listType = 'ExtractTable';

class ExportLists extends Component {
    static propTypes = {
        exportList: PropTypes.array,
        userKey: PropTypes.string,
        dispatch: PropTypes.func,
        currentWorkspace: PropTypes.object,
        isFetchingExportList: PropTypes.bool,
    };
    constructor(props) {
        super(props);
        this.state = {
            sortedBy: 'listName',
            ascending: true,
            exportList: [],
        };
        this.deleteItem = this.deleteItem.bind(this);
        this.getGrid = this.getGrid.bind(this);
        this.getActionItems = this.getActionItems.bind(this);
    }

    UNSAFE_componentWillMount() {
        const { userKey, dispatch, currentWorkspace, exportList } = this.props,
        { ascending } = this.state;
        if (currentWorkspace) {
            dispatch(
                retrieveExportsByListType({ 
                userKey, 
                databaseId: currentWorkspace.id || currentWorkspace.workspaceId,
                listType,
                })
            );
        }
        if (exportList) {
            this.setState({ exportList: alphabetize(exportList, 'listName', ascending) });
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        const { userKey, dispatch, currentWorkspace, exportList } = this.props,
        { currentWorkspace: nextWorkspace, exportList: nextExportList } = nextProps,
        { ascending } = this.state;
        if (nextWorkspace && currentWorkspace !== nextWorkspace) {
            dispatch(
                retrieveExportsByListType({ 
                userKey, 
                databaseId: nextWorkspace.id || nextWorkspace.workspaceId,
                listType,
                })
            );
        }
        if (exportList !== nextExportList) {
            this.setState({ exportList: alphabetize(nextExportList, 'listName', ascending) });
        }
    }

    deleteItem(exportListId) {
        const { dispatch, userKey, currentWorkspace, exportList } = this.props;

        if (exportList.length !== 0) {
            const selectedExport = exportList.find(
                def => def.exportListId === exportListId
            ),
                deleteMessageItems = [
                <li key={exportListId} style={{ fontWeight: 800 }}>
                    {selectedExport.listName}
                </li>,
                ];

            deleteMessageItems.unshift(
                'Are you sure you want to delete the following item?'
            );

            const yesClickHandler = () => {
                dispatch(deleteExportListItem({ userKey, exportListId, databaseId: currentWorkspace.id || currentWorkspace.workspaceId, listType }));
            };
            const noClickHandler = () => {},
                action = showModal(
                'confirmation',
                deleteMessageItems,
                yesClickHandler,
                noClickHandler
                );
            dispatch(action);
        }
    }
  
    
    getActionItems(row) {
        return  (
            <Button 
                iconName="delete" 
                onClick={(e) => {this.deleteItem(row.exportListId)}}
                toolTip='Delete Export List'/>     
        );
    }

    getGrid(data) {
        let columns = [
            { field: 'listName', headerText: 'Name'},
            { field: 'lastModifiedBy', headerText: 'Last Modified By'},
            { field: 'lastModifiedDate', headerText: 'Last Modified'},
            { field: 'exportListId', headerText: 'Actions', template: this.getActionItems},
        ];

        return <Grid 
            columns={columns}
            data={data}
            height='calc(100vh - 240px)'/>
    }

    render() {
        const { exportList } = this.state,
        { isFetchingExportList, currentWorkspace } = this.props;
        let renderTable;
        if (exportList && exportList.length) {
            renderTable = this.getGrid(exportList);
        } else if (isFetchingExportList) {
            renderTable = (
            <div style={{ width: '85vw', margin: '5vh auto' }}>
                <Loader loadingItem="Export List" />
            </div>
            );
        } else if (!currentWorkspace) {
            renderTable = (
            <div
                style={{ width: '85vw', margin: '5vh auto' }}>
                <PlaceHolderMessage message={'Please select a database'} />
            </div>
            );
        }

        return (
            <div className="list-container-arius">
                <div className="list-header-arius">
                    <h4>Extract Table Lists</h4>
                </div>
                {renderTable}
            </div>
        )
    }
}

export default ExportLists;
