import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Grid, Button } from '@arius';
import { showModal } from '@app/actions/modal.actions';
import { deleteSubscriptionUser } from '@app/actions/portalManagement/subscriptionUser.actions';
import { notifyError } from '../../../utilities/notifier';
import { notAuthorized } from '@app/shared/containers/conditionalLink';

const style = {
  row: {
    display: 'flex',
    flexFlow: 'row nowrap',
  },
  inputLabel: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: 10,
  },
  input: {
    display: 'flex',
    alignItems: 'center',
    marginRight: 50,
    marginBottom: 10,
    marginLeft: 10,
    marginTop: 10,
  },
  userToolbar: {
    backgroundColor: '#EAEAEA',
  },
};

class UsersList extends Component {
    static propTypes = {
        organizationId: PropTypes.number.isRequired,
        userKey: PropTypes.string,
        isFetching: PropTypes.bool.isRequired,
        users: PropTypes.object,
        dispatch: PropTypes.func,
        readOnly: PropTypes.bool,
    };
    static defaultProps = { organizationId: 0, isFetching: false, userKey: '', users: {} };
    constructor(props) {
        super(props);
        this.state = {
            selectedUserId: null,
            currentUsers: [],
            isUpdated: false,
            searchText: ''
        };

        this.promptToDelete = this.promptToDelete.bind(this);
        this.getGrid = this.getGrid.bind(this);
        this.getActionItems = this.getActionItems.bind(this);
    }

    get filteredUsers() {
        const { users } = this.props;
        const { searchText } = this.state;
        let allUsers = users.allActiveAndInactiveUsers;
        return allUsers.filter(u => u.name.toLowerCase().includes(searchText) || 
            u.email.toLowerCase().includes(searchText));
    }

    componentDidUpdate(prevProps, prevState) {
    }

    promptToDelete(o) {
        const { dispatch, userKey } = this.props;
        const deleteMessageItems = [(
            <li key={`${o.id}-delete`} style={{ fontWeight: 800 }}>{o.name}</li>
        )];
        deleteMessageItems.unshift('Are you sure you want to delete the following user?');

        const yesClickHandler = () => {
            dispatch(deleteSubscriptionUser({ userKey, userId: o.userId }));
            this.setState({ selectedUserId: null });
        };
        const noClickHandler = () => {};
        const action = showModal('confirmation', deleteMessageItems, yesClickHandler, noClickHandler);
        dispatch(action);
    }

    getActionItems(row) {
        const { browserHistory, readOnly } = this.props;
        return  (
            <span>
            <Button 
                iconName="edit" 
                onClick={() => {
                    readOnly ? 
                    notifyError('You are not authorized to access this functionality') : 
                    browserHistory.push(`/portalManagement/users/edit/${row.userId}`)
                }}
                toolTip='View/Edit'/>
            <Button 
                iconName="delete" 
                onClick={(e) => (readOnly ? notAuthorized : this.promptToDelete)(row)}
                toolTip='Delete'/>                
            </span>
        )
    }

    getTemplate(r){
        return r.isActive ? <span>Active</span>: <span>Inactive</span>;
    }

    getGrid() {
        let data = this.filteredUsers;
        let columns = [
            { field: 'name', headerText: 'Name'},
            { field: 'email', headerText: 'Email'},
            { field: 'isActive', headerText: 'Status', template: this.getTemplate},
            { field: 'userId', headerText: 'Actions', template: this.getActionItems},
        ];

        return <Grid 
            columns={columns}
            data={data}
            height='calc(100vh - 240px)'/>
    }

    render() {
        const { browserHistory, readOnly } = this.props;
        
        return (
            <div className="list-container-arius" style={{maxHeight: 'calc(100vh - 106px)', overflowY: 'auto'}}>
                <div style={{...style.row, ...style.userToolbar}}>
                    <div style={style.row}>
                        <div style={style.inputLabel}>
                        Quick Search
                        </div>
                        <input style={style.input} type="text" onChange={(e) => this.setState({searchText: e.target.value.toLowerCase()})} />
                    </div>
                </div>
                <div className="list-header-arius">
                    <h4 style={{ margin: 'initial', paddingLeft: 15 }}>Users</h4>
                    <Button 
                    mode='add' 
                    onClick={() => {
                        readOnly ? 
                            notifyError('You are not authorized to access this functionality') :
                            browserHistory.push('/portalManagement/users/edit')
                    }}
                    toolTip='Add new user'/>
                </div>
                {this.getGrid()}
            </div>
        )
    }
}

export default UsersList;
