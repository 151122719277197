import React, { Component } from 'react';
import moment from 'moment';
import { getOperationDescription } from '@app/utilities/longRunningOperationsDescriptionHelper';
import { Grid } from '@arius';
import JobDetailsGrid from './jobDetailsGrid';


class JobSummariesGrid extends Component {
    constructor(props) {
        super(props);
        this.state = {
        };
        
        // NOTE:  for whatever reason, complex grid properties need to be set at the component level
        //          before they can be used in the grid...  (per syncfusion "guidance", put in quotes)
        this.pageOptions = {
            pageSize: 10, pageSizes: [5,10,50,100]
        };
        this.getDetailTemplate = this.getDetailTemplate.bind(this);
        this.getTemplateJob = this.getTemplateJob.bind(this);
    }

    getDetailTemplate(r) {
        const { userKey, currentWorkspace, secureDataManagerFactory } = this.props;
        return <JobDetailsGrid
            userKey={userKey}
            secureDataManagerFactory={secureDataManagerFactory}
            dbId={currentWorkspace.id}
            currentWorkspace={currentWorkspace}
            jobId={r.groupId}
            isExtract={r.isExtract}
            isBackendReport={r.isBackendReport}
        />
    }

    shouldComponentUpdate(nextProps){
        const {currentWorkspace, startDate, endDate, statuses, trigger} = this.props;

        if (null != nextProps.currentWorkspace && currentWorkspace !== nextProps.currentWorkspace){
            return true;
        }

        if (trigger !== nextProps.trigger){
            return true;
        }
                
        let filtersChanged = nextProps.startDate.toDateString() !== startDate.toDateString() || 
            nextProps.endDate.toDateString() !== endDate.toDateString() ||
            nextProps.statuses.toString() !== statuses.toString();

        if (filtersChanged) {
            return true;
        }

        return false;
    }

    getTemplateJob(r) {
        const { getCancelIcon } = this.props;
        return <div style={{margin: 8, justifyContent: 'space-between'}}>
            <span>
                {getOperationDescription(r.operationCode, r.operationParams, true)}
            </span>
            <span style={{ float: 'right' }}>{getCancelIcon(r)}</span>
        </div>
    }

    getTemplateProjectInfo(r){
        return <span>{r.nbrCompleted}/{r.nbrProjects}</span>;
    }

    getGrid() {
        const { currentWorkspace, startDate, endDate, statuses, secureDataManagerFactory } = this.props;
        let dm = [];
        if (currentWorkspace){
            dm = secureDataManagerFactory.getDataManager(`workspaces/${currentWorkspace.id}/jobs`,{
                startDate: moment(startDate).hours(0).minutes(0), 
                endDate: moment(endDate).hours(23).minutes(59).seconds(59), 
            statuses});
        }

        let columns = [
            { headerText: 'Job', template: this.getTemplateJob, minColWidth: 200},
            { headerText: 'Last Submitted By', field: 'requestor'},
            { headerText: 'Last Submitted', field: 'requestTime'},
            { headerText: 'Status', field: 'status', width: 80},
            { headerText: 'Started', field: 'startTime',  type:'datetime'},
            { headerText: 'Info', width: '80px', template: this.getTemplateProjectInfo},
            { headerText: 'Last Updated', field: 'lastReportTime'},
        ];

        return <Grid 
            columns={columns}
            data={dm}
            height='calc(100vh - 300px)' 
            detailTemplate={this.getDetailTemplate}
            pageSettings={this.pageSettings}
            allowPaging={true}
            allowResizing={false}
        />
    }

    render() {
        return this.getGrid();
    }
}

export default JobSummariesGrid;
