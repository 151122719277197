import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Table, Button } from '@arius';

import { notifyError } from '@app/utilities/notifier';
import UserRole from './userRole';
import { showModal } from '@app/actions/modal.actions';
import SaveButtons from '@app/shared/presentational/saveButtons';
import { TOD_DB, WORKSPACE } from '@app/utilities/projectDataHelper';
import Loader from '@app/shared/presentational/loader';
import NoResults from '@app/shared/presentational/noResults';
import { APPLICATION_NAMES } from '@app/utilities/constants';

const style = {
    hiddenIcon: {
        visibility: 'hidden',
        pointerEvents: 'none',
    },
};

function getComparer(accessor) {
    return (a, b) => {
        const va = accessor(a);
        const vb = accessor(b);
        if (typeof va === 'number' && typeof vb === 'number') {
        return va - vb;
        }
        const sva = (va || '').toString().toLowerCase();
        const svb = (vb || '').toString().toLowerCase();
        if (sva === svb) {
        return 0;
        }
        return sva < svb ? -1 : 1;
    };
}

function alphabetize(list, columnName) {
    return list.sort((a, b) => {
        const first = a[columnName].toUpperCase(),
        second = b[columnName].toUpperCase();
        if (first < second) {
        return -1;
        }
        if (first > second) {
        return 1;
        }
        return 0;
    });
}

class ShowRolesAndAssignments extends Component {
    static propTypes = {
        application: PropTypes.object,
        userKey: PropTypes.string,
        isFetching: PropTypes.bool.isRequired,
        users: PropTypes.array,
        dispatch: PropTypes.func,
        roleDescriptions: PropTypes.array,
        assignedRoles: PropTypes.array,
        workspaces: PropTypes.object,
        assignedRoleHandler: PropTypes.func,
        roleDescriptionHandler: PropTypes.func,
        assignRoleHandler: PropTypes.func,
        userPermissions: PropTypes.array,
        verifyPermission: PropTypes.func,
        currentWorkspace: PropTypes.object,
        currentDatabase: PropTypes.object,
        selectedResource: PropTypes.string,
        canEdit: PropTypes.bool,
        resourceSource: PropTypes.string,
        databases: PropTypes.array,
    };
    static defaultProps = {
        organizationId: 0,
        isFetching: false,
        userKey: '',
        users: [],
    };
    constructor(props) {
        super(props);
        this.state = {
            selectedUserId: null,
            assignedRoles: [],
            filteredRoles: [],
            currentUsers: [],
            isUpdated: false,
            isDescending: false,
            columnSelected: 'name',
            resourceSelectedMode: false,
            userFilter: '',
            resourceFilter: '',
            applicationFilter: '',
            roleFilter: '',
            sortedBy: 'user',
            sortedAs: 'ascending',
        };
        if (props.selectedResource) {
            this.state.resourceSelectedMode = true;
        }
        this.deleteClickHandler = this.deleteClickHandler.bind(this);
        this.lookupRelations = this.lookupRelations.bind(this);
        this.getResourceLink = this.getResourceLink.bind(this);
        this.sortColumn = this.sortColumn.bind(this);
    }

    get filteredRoles() {
        const { assignedRoles, userFilter, resourceFilter, applicationFilter, roleFilter } = this.state;
        return assignedRoles.filter(
            ar =>
            ar.resource.toLowerCase().indexOf(resourceFilter.toLowerCase()) !== -1 &&
            ar.role.toLowerCase().indexOf(roleFilter.toLowerCase()) !== -1 &&
            ar.application.toLowerCase().indexOf(applicationFilter.toLowerCase()) !== -1 &&
            ar.user.toLowerCase().indexOf(userFilter.toLowerCase()) !== -1
        );
    }

    componentDidMount() {
        const {
            assignedRoleHandler,
            roleDescriptionHandler,
            roleDescriptions,
            assignedRoles,
            users
        } = this.props;

        if (
            roleDescriptions && roleDescriptions.length &&
            assignedRoles && assignedRoles.length &&
            users && users.length
        ) {
            const mappedRoleDescriptions = (assignedRoles.map(role =>
                this.lookupRelations(role)
            ) || [])
                .slice()
                .sort(getComparer(r => r.user));
            this.setState({ assignedRoles: mappedRoleDescriptions });
        }
        roleDescriptionHandler();
        assignedRoleHandler();
    }

    componentDidUpdate(prevProps) {
        const {
            roleDescriptions,
            assignedRoles,
            databases,
            workspaces,
            users
        } = this.props;

        if (
            roleDescriptions && roleDescriptions.length &&
            assignedRoles && assignedRoles.length &&
            users && users.length &&
            (roleDescriptions !== prevProps.roleDescriptions ||
                assignedRoles !== prevProps.assignedRoles ||
                workspaces !== prevProps.workspaces ||
                databases !== prevProps.databases ||
                users !== prevProps.users)
        ) {
            const mappedRoleDescriptions = (assignedRoles.map(role =>
                this.lookupRelations(role)
            ) || [])
                .slice()
                .sort(getComparer(r => r.user));
            this.setState({
                assignedRoles: mappedRoleDescriptions,
            });
        }
    }

    getResourceLink() {
        const { resourceSource, currentWorkspace, currentDatabase } = this.props;
        if (resourceSource === WORKSPACE) {
            return `/arius/workspaces/${currentWorkspace ? currentWorkspace.id : 'none'}/security/assign`;
        } else if (resourceSource === TOD_DB) {
            return `/tod/databases/${currentDatabase ? currentDatabase.id : 'none'}/security/assign`;
        } else {
            return '/portalManagement/assignments/assign';
        }
    }

    deleteClickHandler(role, e) {
        e.stopPropagation();
        const { assignRoleHandler, userKey, dispatch, currentWorkspace } = this.props,
        assignment = role.assignment,
        request = {
            userKey,
            operation: 'revoke',
            users: [assignment.memberId],
            roles: [assignment.roleId],
            currentWorkspace: currentWorkspace
        };

        if (assignment.scopeId) {
        request.databases = [assignment.scopeId];
        } else {
        request.site = true;
        }

        const deleteMessageItems = [
        <li key={`${role.user}-delete`} style={{ fontWeight: 800 }}>
            {role.user}, {role.role}
        </li>,
        ];

        deleteMessageItems.unshift(
        'Are you sure you want to delete the following role assignment?'
        );

        const yesClickHandler = () => {
            assignRoleHandler(request);
        },
        noClickHandler = () => {},
        action = showModal(
            'confirmation',
            deleteMessageItems,
            yesClickHandler,
            noClickHandler
        );
        dispatch(action);
    }

    lookupRelations(roleAssignment) {
        const {
            roleDescriptions,
            workspaces,
            users,
            databases,
            resourceSource,
            currentWorkspace,
            currentDatabase,
        } = this.props;
        const mergedDatabases = [...workspaces.items, ...databases];
        let resource;

        if (!resourceSource && roleAssignment.scopeId !== null) {
            resource = (mergedDatabases.find(
                mdb => mdb.workspaceId === roleAssignment.scopeId
            ) || { workspaceName: roleAssignment.scopeId }).workspaceName;
        } else if (roleAssignment.scopeId !== null) {
            if (resourceSource === WORKSPACE && currentWorkspace) {
                resource = currentWorkspace.name;
            } else if (resourceSource === TOD_DB && currentDatabase) {
                resource = currentDatabase.name;
            }
        }

        if (typeof resource === 'number') {
            resource = resource.toString();
        }

        const user = (users.find(u => u.userId === roleAssignment.memberId) || {
            name: roleAssignment.memberId,
        }).name;

        return {
            assignment: roleAssignment,
            user: typeof user === 'string' ? user : user.toString(),
            resource: resource || 'Site',
            role: (roleDescriptions.find(r => r.roleId === roleAssignment.roleId) || {
                name: roleAssignment.roleId,
            }).name,
            application: (roleDescriptions.find(r => r.roleId === roleAssignment.roleId) || {
                application: '--- not found ---',
            }).application,
        };
    }

    generateColumnFilterMarkup(column) {
        const { sortedAs, sortedBy } = this.state;
        if (sortedBy === column) {
        if (sortedAs === 'descending') {
            return <i style={{ marginLeft: 10 }} className="fa fa-chevron-down" />;
        } else {
            return <i style={{ marginLeft: 10 }} className="fa fa-chevron-up" />;
        }
        } else {
        return <i />;
        }
    }

    sortColumn(columnName) {
        const {
            sortedAs,
            assignedRoles,
            filteredRoles,
        } = this.state,
        newSortedAs = sortedAs === 'ascending' ? 'descending' : 'ascending',
        listFromFiltered = filteredRoles.length || false,
        roles = alphabetize(
            listFromFiltered ? filteredRoles : assignedRoles,
            columnName
        ),
        newState = {},
        sortedRoles = newSortedAs === 'ascending' ? roles : roles.reverse();
        newState.sortedAs = newSortedAs;
        newState.sortedBy = columnName;
        if (listFromFiltered) {
        newState.filteredRoles = sortedRoles;
        } else {
        newState.assignedRoles = sortedRoles;
        }
        this.setState(newState);
    }

    render() {
        const {
        isFetching,
        browserHistory,
        userPermissions,
        currentWorkspace,
        canEdit,
        resourceSource,
        currentDatabase,
        verifyPermission,
        roleDescriptions,
        } = this.props,
        {
            resourceSelectedMode,
            userFilter,
            resourceFilter,
            applicationFilter,
            roleFilter,
        } = this.state;

        let assignedRoles = this.filteredRoles; 
        let resourceName = '';
        
        if (resourceSelectedMode) {
        if (currentWorkspace || currentDatabase) {
            if (resourceSource === WORKSPACE && currentWorkspace) {
            assignedRoles = assignedRoles.filter(
                ar => 
                    ar.assignment.scopeId === currentWorkspace.id || 
                    // include relevant permissions assigned at the site level
                    (!ar.assignment.scopeId && ar.application === 
                        APPLICATION_NAMES.ARIUS_ENTERPRISE)
            );
            resourceName = currentWorkspace.name;
            } else if (resourceSource === TOD_DB && currentDatabase) {
            assignedRoles = assignedRoles.filter(
                ar => 
                    ar.assignment.scopeId === currentDatabase.id ||
                    // include relevant permissions assigned at the site level
                    (!ar.assignment.scopeId && ar.application === 
                        APPLICATION_NAMES.TOD)
            );
            resourceName = currentDatabase.name;
            }
        } else {
            return <Loader loadingItem="Database" />;
        }
        }
        // permission based variables
        // Site.ManageSecurity

        let assignRolesButtonJSX = (
            <Button 
                mode='add' 
                onClick={() => {
                    if (canEdit || (userPermissions && userPermissions.includes('Site.ManageSecurity'))) {
                        browserHistory.push(this.getResourceLink());
                    } else {
                        notifyError('You are not authorized to access this functionality')
                    }
                }}
                toolTip='Assign roles'/>
        );

        let saveButtonsJSX = <div />;
        let headerText = resourceSelectedMode ? resourceName : 'Assigned Roles';

        if (resourceSource) {
            saveButtonsJSX = (
                <SaveButtons
                backButtonHander={() => browserHistory.goBack()}
                backButtonText="Back"
                />
            );
        }

        let contentMarkup = (
        <div style={{ margin: '5vh auto' }}>
            <Loader loadingItem="Users" />
        </div>
        );

        if (!isFetching) {
        contentMarkup = (
            <div style={{ margin: '5vh auto' }}>
            <NoResults />
            </div>
        );

        if (roleDescriptions && roleDescriptions.length !== 0 && assignedRoles) {
            let {Tr, Th, Tbody, Thead, ResponsiveTable:Tbl} = Table;
            contentMarkup = (
                <Tbl>
                    <Thead>
                    <Tr>
                        <Th>
                            <input
                                id="showRoleuserFilter"
                                placeholder="filter users"
                                value={userFilter}
                                onChange={e => this.setState({userFilter: e.target.value})}
                                className="form-control"
                                type="text"
                            />
                        </Th>
                        <Th>
                            <input
                                id="showRoleResourceFilter"
                                placeholder="filter resources"
                                value={resourceFilter}
                                onChange={e => this.setState({resourceFilter: e.target.value})}
                                className="form-control"
                                type="text"
                            />
                        </Th>
                        <Th>
                            <input
                                id="showRoleApplicationFilter"
                                placeholder="filter applications"
                                value={applicationFilter}
                                onChange={e => this.setState({applicationFilter: e.target.value})}
                                className="form-control"
                                type="text"
                            />
                        </Th>
                        <Th>
                            <input
                                id="showRoleRoleFilter"
                                placeholder="filter roles"
                                value={roleFilter}
                                onChange={e => this.setState({roleFilter: e.target.value})}
                                className="form-control"
                                type="text"
                            />
                        </Th>
                        <Th>
                            <i
                                key={"delete-placeholder-1"}
                                style={style.hiddenIcon}
                                className="material-icons"
                            >
                                delete
                            </i>
                        </Th>
                    </Tr>
                    <Tr>
                        <Th onClick={() => this.sortColumn('user')}>
                            User Name
                            {this.generateColumnFilterMarkup('user')}
                        </Th>
                        <Th onClick={() => this.sortColumn('resource')}>
                            Resource
                            {this.generateColumnFilterMarkup('resource')}
                        </Th>
                        <Th onClick={() => this.sortColumn('application')}>
                            Application
                            {this.generateColumnFilterMarkup('application')}
                        </Th>
                        <Th onClick={() => this.sortColumn('role')}>
                            Role
                            {this.generateColumnFilterMarkup('role')}
                        </Th>
                        <Th>Actions</Th>
                    </Tr>
                    </Thead>
                    <Tbody style={{maxHeight: 'calc(100vh - 250px)'}}>
                        {assignedRoles.map((role, idx) => (
                            <UserRole
                                key={`userRole-${idx}`}
                                id={role.assignment.roleId}
                                idx={idx}
                                userPermissions={userPermissions}
                                verifyPermission={verifyPermission}
                                name={role.user}
                                allowDelete={role.assignment.scopeId != null || !resourceSelectedMode}
                                resource={role.resource}
                                application={role.application}
                                role={role.role}
                                deleteClickHandler={(assignedRole, e) =>
                                this.deleteClickHandler(role, e)}
                            />
                        ))}
                    </Tbody>
                </Tbl>
            );
        }
        }

        return (
            <div className="list-container-arius">
                <div className="list-header-arius">
                    <h4>{headerText}</h4>
                    <div style={{ display: 'flex' }}>
                        {assignRolesButtonJSX}
                    </div>
                </div>
                {contentMarkup}
                {saveButtonsJSX}
            </div>
        )
    }
}

export default ShowRolesAndAssignments;
