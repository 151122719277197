import React, { Component } from 'react';
import { Grid, Button } from '@arius';
import { Form } from 'react-bootstrap';
import Loader from '@app/shared/presentational/loader';
import { showModal } from '@app/actions/modal.actions';
import { 
    fetchPowerBIOptions,
    updatePowerBI,
    powerBIAction,
} from '@app/actions/utils/performance.actions';
import { notifyError } from '@app/utilities/notifier';

class PowerBiView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showModal: false,
            selected: {},
            timerId: null,
            skuName: null,
        }

        this.monthFormatter = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
            maximumFractionDigits: 0,
            minimumFractionDigits: 0, 
        }).format;
        this.hourFormatter = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
            maximumFractionDigits: 2,
            minimumFractionDigits: 2, 
        }).format;

        this.getGrid = this.getGrid.bind(this);
        this.getActionItems = this.getActionItems.bind(this);
        this.handleClose = this.handleClose.bind(this);

        this.promptToAction = this.promptToAction.bind(this);
        this.startstop = this.startstop.bind(this);

        this.getTierControls = this.getTierControls.bind(this);
        this.changeTier = this.changeTier.bind(this);
        this.getOptions = this.getOptions.bind(this);
        this.closeTier = this.closeTier.bind(this);

        this.promptToSave = this.promptToSave.bind(this);
        this.saveTier = this.saveTier.bind(this);
    }

    getOptions (capacityName) {
        const {userKey, dispatch} = this.props;
        dispatch(fetchPowerBIOptions(userKey, capacityName));
    }

    getActionItems(row) {
        return  (
            <Button 
                iconName="edit" 
                onClick={() => this.setState({showModal: true, selected: row})}
                toolTip='Edit'/>
        )
    }

    startstop(capacityName, actionName) {
        const { dispatch, userKey } = this.props;
        const request = {capacityName: capacityName, action: actionName};
        dispatch(powerBIAction(userKey, request));
        this.handleClose();
    }

    promptToAction(capacityName, actionName) {
        const { refreshHandler, dispatch, canEdit } = this.props;

        if (actionName === "Refresh"){
            refreshHandler();
        } else {

            if (!canEdit) {
                notifyError('You are not authorized to access this functionality');
                return;
            }

            let messageItems = [
            ];

            messageItems.unshift(<div key={`${actionName}-1`}>Are you sure you want to {actionName} Power BI Service?</div>);

            const yesClickHandler = () => {this.startstop(capacityName, actionName)};
            const noClickHandler = () => {};
            const action = showModal(
                'confirmation',
                messageItems,
                yesClickHandler,
                noClickHandler
            );
            dispatch(action);
        }
    }

    getGrid() {
        const { info } = this.props;
        let data = info && info.databaseServer ? 
        info.databaseServer.databases : [];

        let columns = [
            { field: 'name', headerText: 'Name'},
            { field: 'type', headerText: 'Type'},
            { field: 'edition', headerText: 'Edition'},
            { field: 'serviceLevel', headerText: 'Service Level'},
            { headerText: 'Actions', template: this.getActionItems},
        ];
        
        return <Grid 
            columns={columns}
            data={data}
            height='calc(100vh - 200px)' 
            allowPaging={false}/>
    }

    getTierControls() {
        const { powerbiOptions, info } = this.props;
        const { skuName } = this.state;
        
        const powerbi = info ? info.powerBI : null;
        const tiers = powerbiOptions ? powerbiOptions : [];

        if (!powerbiOptions)
        {
            return (<div>Loading Power BI Options...</div>)
        } else{
        return (
        <div>
        <Form>
            <Form.Group controlId="tier">
                {tiers.map((o) => {
                    let hourly = o.hourlyCostEstimate ? this.hourFormatter(o.hourlyCostEstimate) : 'unknown';
                    let monthly = o.monthlyCostEstimate ? this.monthFormatter(o.monthlyCostEstimate): 'unknown';
                    return (
                    <Form.Check 
                        type="radio"
                        key={`checkboxtier${o.name}`}
                        checked={o.name === skuName}
                        onChange={(e) => {
                            this.setState({ skuName: o.name });
                        }}
                        label={`${o.name}: ${o.description} (est ${hourly}/hr, est ${monthly}/mo)`} />
                )})}
            </Form.Group>
        </Form>
        <button key="confirmButton"
            className="btn btn-info dialog-button"
            onClick={this.promptToSave}
            disabled={powerbi.skuName === skuName}
        >Save</button>
        <button key="nobutton"
            className="btn btn-info dialog-button"
            onClick={this.closeTier}>Cancel</button>
        </div>)
        }
    }

    changeTier() {
        const { info } = this.props;
        const powerbi = info ? info.powerBI : null;

        console.log('changeTier');

        if (powerbi){
            this.getOptions(powerbi.name);
            this.setState({ skuName: powerbi.skuName });
        }
    }

    closeTier() {
        this.setState({ skuName: null });
    }

    promptToSave() {
        const { dispatch, powerbiOptions, canEdit } = this.props;
        const { skuName } = this.state;
        
        if (!canEdit) {
            notifyError('You are not authorized to access this functionality');
            return;
        }

        let tiers = powerbiOptions ? powerbiOptions : [];
        let tier = skuName && tiers.length > 0 ? tiers.find((x) => x.name === skuName) : [];
        let hourly = tier.hourlyCostEstimate ? this.hourFormatter(tier.hourlyCostEstimate): 'unknown';
        let monthly = tier.monthlyCostEstimate ? this.monthFormatter(tier.monthlyCostEstimate) : 'unknown';
        let desc = `${tier.description} (est ${hourly}/hr, est ${monthly}/mo)`;

        let messageItems = [
          <li key={`${skuName}-1`} style={{ fontWeight: 800 }}>{desc}</li>,
        ];

        messageItems.unshift(<div key={`${skuName}-2`}>Are you sure you want to change tier?</div>);
        messageItems.unshift(<div key={`${skuName}-3`}>{`This tier has an estimated monthly cost of ${monthly}`}</div>);

        const yesClickHandler = () => {this.saveTier()};
        const noClickHandler = () => {};
        const action = showModal(
            'confirmation',
            messageItems,
            yesClickHandler,
            noClickHandler
        );
        dispatch(action);
    }

    saveTier() {
        const { dispatch, userKey, info } = this.props;
        const { skuName } = this.state;
        const powerbi = info ? info.powerBI : null;
        if (powerbi) {
            let request = { name: powerbi.name, skuName: skuName, state: powerbi.state };
            dispatch(updatePowerBI(userKey, request));
            this.setState({ skuName: null });
        }
        this.handleClose();
    }

    handleClose() {
        this.setState({showModal: false});
    }

    render() {
        const { isFetching, jobStatus, lastCompletion, info } = this.props;
        const { skuName } = this.state;

        const powerbi = info ? info.powerBI : null;
        let state = powerbi ? powerbi.state : null;
        let startStop = null;
        if (powerbi && powerbi.state) {
         
            switch(powerbi.state)
            {
                case "Paused":
                    startStop = "Start";
                    break;
                
                case "Active":
                case "Succeeded":
                    startStop = "Stop";
                    state = "Active";
                    break;
                default:
                    startStop = "Refresh";
            }
        }

        return (  
            <div className="list-container-arius" style={{ padding: 10 }}>
                <h4>Power BI Service</h4>

                {jobStatus && jobStatus.isBusy === true ? <div>Updating Power BI service...</div>: ('')}
                    {lastCompletion === false ? <div style={{color: 'red'}}>Update Failed</div>:
                     lastCompletion === true ? <div style={{color: 'green'}}>Update Completed Successfully</div>:
                     ('')
                }
                <span style={isFetching ? {}: {display: 'none'}}>
                    <Loader loadingItem='Power BI Service Info' />
                </span>

                { powerbi ? 
                    <div>
                        <div>Current state: {state}</div>
                        <button key="startStopButton"
                            className="btn btn-info dialog-button"
                            onClick={() => this.promptToAction(powerbi.name, startStop)}
                            disabled={!startStop}
                        >
                            {startStop ? startStop : "not available"}
                        </button>
                        <div>Current tier: {powerbi.skuName}</div>
                        
                        {skuName ? this.getTierControls() :
                            <button key="changeTierButton"
                            className="btn btn-info dialog-button"
                            onClick={() => this.changeTier()}
                            >Change Tier</button>
                        }
                    </div>
                : ('')
                }
            </div>
        );
    }
}


export default PowerBiView;
