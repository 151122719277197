import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from '@app/utilities/routing';
import { Modal, Tabs, Tab, Button } from 'react-bootstrap';
import * as Colors from '@app/utilities/colors';
import moment from 'moment';
import { SERVER_NOTIFICATION_SUBJECTS } from '@app/utilities/constants';
import { saveAs } from 'file-saver';
import PITRTab from './pitrTab';
import BackupTab from './backupTab';
import RestoreTab from './restoreTab';
import ArchivedTab from './archivedTab';

import { fetchJobStatus } from '../../../../actions/jobStatus.actions';
import { PITR, PITR_UNDO} from '../../jobStatusTags' 


class DatabaseBackupModal extends Component {
    static propTypes = {
        systemDimensions: PropTypes.arrayOf(PropTypes.string),
        saveHandler: PropTypes.func,
        userKey: PropTypes.string,
        modalId: PropTypes.string.isRequired,
        closeHandler: PropTypes.func,
        availableBackups: PropTypes.array,
        earliestRestorePoint: PropTypes.string,
        latestRestorePoint: PropTypes.string,
        archivedBackups: PropTypes.array,
        scheduledJobs: PropTypes.array,
        getBackupsHandler: PropTypes.func.isRequired,
        getRestorePointInTimeRangeHandler: PropTypes.func.isRequired,
        createBackupHandler: PropTypes.func.isRequired,
        database: PropTypes.object,
        showModal: PropTypes.bool,
        serverNotification: PropTypes.object,
        getDatabases: PropTypes.func,
        deleteBackupHandler: PropTypes.func,
        restorePointInTimeHandler: PropTypes.func,
        restoreBackupHandler: PropTypes.func,
        archiveBackupHandler: PropTypes.func,
        deleteArchivedHandler: PropTypes.func,
        restoreArchivedHandler: PropTypes.func,
        clearNotificationHandler: PropTypes.func,

        retrieveLogFile: PropTypes.func,
        deleteLogFileFromServer: PropTypes.func,
        logFile: PropTypes.object,
        jobStatusPrefix: PropTypes.string,
    };
    static defaultProps = {
        availableBackups: [],
        scheduledJobs: [],
        archivedBackups: [],
        earliestRestorePoint: '',
        latestRestorePoint: ''
    };
    constructor(props) {
        super(props);
        this.state = {
        backupDescription: '',
        areYouSureMessage: '',
        additionalDeleteMesssage: '',
        selectedTab: 'restorepointintime',// 'backup',
        isCreatingBackup: false,
        isRestoringBackup: false,
        isRestoringPointInTimeBackup: false,
        isRestoringArchived: false,
        isArchivingBackup: false,
        selectedBackup: null,
        selectedArchived: null,
        scheduledJobs: [],
        newJobs: [],
        deleteModalHandler: null,
        archivedBackups: [],
        refreshDatabase: false,
        timerId: null,
        };
        this.descChangeHandler = this.descChangeHandler.bind(this);
        this.saveHandler = this.saveHandler.bind(this);
        this.createBackup = this.createBackup.bind(this);
        this.restoreBackup = this.restoreBackup.bind(this);
        this.restoreArchivedBackup = this.restoreArchivedBackup.bind(this);
        this.close = this.close.bind(this);
        this.selectBackup = this.selectBackup.bind(this);
        this.selectArchived = this.selectArchived.bind(this);
        this.createNewJob = this.createNewJob.bind(this);
        this.deleteJob = this.deleteJob.bind(this);
        this.deleteBackup = this.deleteBackup.bind(this);
        this.cancelNewJobs = this.cancelNewJobs.bind(this);
        this.archiveBackup = this.archiveBackup.bind(this);
        this.closeDeleteModal = this.closeDeleteModal.bind(this);
        this.deleteArchived = this.deleteArchived.bind(this);
        this.closePITValidationModal = this.closePITValidationModal.bind(this);
        this.modalHandler = this.modalHandler.bind(this);

        this.TAG_PITR=`${props.jobStatusPrefix} ${PITR}`;
        this.TAG_PITR_UNDO=`${props.jobStatusPrefix} ${PITR_UNDO}`;

        this.TAG_BACKUP=`${props.jobStatusPrefix} BACKUP`;
        this.TAG_BACKUP_DELETE=`${props.jobStatusPrefix} BACKUP DELETE`;
        this.TAG_RESTORE=`${props.jobStatusPrefix} RESTORE`;
        //this.TAG_ARCHIVE=`${props.jobStatusPrefix} ARCHIVE`;
        this.TAG_ARCHIVE_RESTORE=`${props.jobStatusPrefix} ARCHIVE RESTORE`;
        
    }

    componentDidMount() {
        const { scheduledJobs } = this.props;
        this.setState({ scheduledJobs });
    }

    componentDidUpdate(prevProps) {
        const { database, userKey, showModal, serverNotification, archivedBackups, logFile, jobStatus } = this.props,
        databaseId = database && (database.id || database.workspaceId);

        console.log('componentDidUpdate');
        console.log(this.state);

        if (!this.state.isCreatingBackup &&
            serverNotification.message.indexOf('complete') === -1 &&
              (serverNotification.subject === SERVER_NOTIFICATION_SUBJECTS.AE_DB_BACKUP ||
               serverNotification.subject === SERVER_NOTIFICATION_SUBJECTS.TOD_DB_BACKUP)) {

          this.setState({ isCreatingBackup: true });
        }

        // messages coming in wrong order from functions, we can't rely on them at the moment
        // if (!this.state.isRestoringBackup && 
        //     serverNotification.message.indexOf('complete') === -1 &&
        //     (serverNotification.subject === SERVER_NOTIFICATION_SUBJECTS.AE_DB_RESTORE ||
        //      serverNotification.subject === SERVER_NOTIFICATION_SUBJECTS.TOD_DB_RESTORE) &&
        //     // there is a bug in db delete in Functions, we can receive notifications 
        //     // about db delete after operation is completed
        //     serverNotification.message.indexOf('delete') === -1) {
        //   this.setState({ isRestoringBackup: true });
        // }

        // if anything is in progress start timer
        if (( this.state.isRestoringPointInTimeBackup ||
              this.state.isCreatingBackup ||
              this.state.isRestoringBackup ||
              this.state.isArchivingBackup ||
              this.state.isRestoringArchived
            ) && !this.state.timerId) {
          const timerId = this.startInterval();
          this.setState({ timerId });
        }

        // if nothing is in progress stop timer
        if (!this.state.isRestoringPointInTimeBackup && 
            !this.state.isCreatingBackup && 
            !this.state.isRestoringBackup &&
            !this.state.isArchivingBackup &&
            !this.state.isRestoringArchived &&
            this.state.timerId) {
            console.log('stop timer');
            clearInterval(this.state.timerId);
            this.setState({ timerId: null });
        }

        if (jobStatus && jobStatus.jobStatuses) {
          let state = {};
          const tag = this.getJobTag();
          console.log(tag);
          if (tag) {
            const job = jobStatus.jobStatuses[tag];
            if (job) {
              console.log(job);

              if (this.state.timerId) {
                console.log('timer running');
                if (job.status === 'Completed' || job.status === 'Error') {
                  console.log('completed');
                  if (this.state.isRestoringPointInTimeBackup) {
                    state = { isRestoringPointInTimeBackup: false };
                  } else if (this.state.isCreatingBackup) {
                    state = { isCreatingBackup: false };
                  } else if (this.state.isRestoringBackup) {
                    state = { isRestoringBackup: false };
                  } else if (this.state.isArchivingBackup) {
                    state = { isArchivingBackup: false };
                  } else if (this.state.isRestoringArchived) {
                    state = { isRestoringArchived: false };
                  }

                  this.setState({ ...state, refreshDatabase: true });
                  this.props.getDatabases(userKey);
                  this.props.getBackupsHandler(userKey, databaseId);
                  this.props.getRestorePointInTimeRangeHandler(userKey, databaseId);
                }
                // else do nothing
              } else {
                console.log('timer not running');
                // start asking for job status if job is not completed
                // TODO: add validation for time, don't do it for old jobs
                // take the latest job from all available.

                if (job.status !== 'Completed' && job.status !== 'Error') {
                  
                  console.log('not completed');

                  if (tag.startsWith(this.TAG_PITR)) {
                    state = { isRestoringPointInTimeBackup: true };
                  } else if (tag.startsWith(this.TAG_BACKUP)) {
                    state = { isCreatingBackup: true };
                  } else if (tag.startsWith(this.TAG_RESTORE)) {
                    state = { isRestoringBackup: true };
                  } else if (tag.startsWith(this.TAG_ARCHIVE_RESTORE)) {
                    state = { isRestoringArchived: true };
                  }
                  
                  this.setState({ ...state});
                }
                // else do nothing
              }
            }
          }
        }


        if (database) { // if no database, no reason to refresh
          const turnedOn = showModal && !prevProps.showModal;
          const dbChanged = !prevProps.database || prevProps.database.id !== database.id;
          if (turnedOn || dbChanged) {
              this.setState({ refreshDatabase: false });
              this.props.getBackupsHandler(userKey, databaseId);
              this.props.getRestorePointInTimeRangeHandler(userKey, databaseId);
          }
        }

        if (prevProps.archivedBackups !== archivedBackups) {
          this.setState({ archivedBackups });
        }

        // if (prevProps.serverNotification !== serverNotification) {
        // if (serverNotification.message && serverNotification.message.indexOf('complete') > -1) {
        //     if (serverNotification.subject === SERVER_NOTIFICATION_SUBJECTS.AE_DB_RESTORE ||
        //         serverNotification.subject === SERVER_NOTIFICATION_SUBJECTS.TOD_DB_RESTORE) {
        //       this.setState({ isRestoringBackup: false });
        //     }

        //     if (serverNotification.subject === SERVER_NOTIFICATION_SUBJECTS.AE_DB_PIT_RESTORE || 
        //         serverNotification.subject === SERVER_NOTIFICATION_SUBJECTS.TOD_DB_PIT_RESTORE ||
        //         serverNotification.subject === SERVER_NOTIFICATION_SUBJECTS.DB_PITR_UNDO) {
        //       this.setState({ isRestoringPointInTimeBackup: false });
        //     }

        //     if (serverNotification.subject === SERVER_NOTIFICATION_SUBJECTS.AE_DB_ARCHIVE_RESTORE||
        //         serverNotification.subject === SERVER_NOTIFICATION_SUBJECTS.TOD_DB_ARCHIVE_RESTORE) {
        //       this.setState({ isRestoringArchived: false });
        //     }

        //     if (serverNotification.subject === SERVER_NOTIFICATION_SUBJECTS.AE_DB_BACKUP ||
        //         serverNotification.subject === SERVER_NOTIFICATION_SUBJECTS.TOD_DB_BACKUP) {
        //       this.setState({ isCreatingBackup: false, backupDescription: '' });
        //     }

        //     this.setState({ refreshDatabase: true });
        //     this.props.getDatabases(userKey);
        //     this.props.getBackupsHandler(userKey, databaseId);
        //     this.props.getRestorePointInTimeRangeHandler(userKey, databaseId);
        // }
        // }

        if (prevProps.logFile !== logFile) {
            saveAs(logFile, 'BackupRestoreLogFile.txt');
        }
    }

    startInterval = () => {
      console.log('start interval');
      const timerId = setInterval(() => {
          this.checkJobStatus();
      }, 10000);
      //this.setState({timerId: timerId});

      return timerId;
    }

    checkJobStatus = () => {
      let tag = this.getJobTag();

      if (tag){
        this.checkJobStatus2(tag);
      }
      

    }

    checkJobStatus2 = (tag) => {
      const {userKey, dispatch, jobStatus} = this.props;
      console.log(`checkJobStatus: ${tag}`);

      const job = jobStatus.jobStatuses[tag];
      console.log(job);

      const jobId = job ? job.jobId : '';

      dispatch(fetchJobStatus(userKey, jobId, tag));
    }

    getDatabaseId = () => {
      const {database} = this.props;
      const databaseId = database && (database.id || database.workspaceId);
      return databaseId;
    }

    getJobTag = () => {
      const {
        isRestoringPointInTimeBackup,
        isCreatingBackup,
        isRestoringBackup,
        isArchivingBackup,
        isRestoringArchived,
        key,
      } = this.state;

      const databaseId = this.getDatabaseId();
      let tag = '';
      
      if (databaseId) {
        if (isRestoringPointInTimeBackup) {
          tag = `${this.TAG_PITR},${databaseId}`;
        } else if (isCreatingBackup) {
          tag = `${this.TAG_BACKUP},${databaseId}`;
        } else if (isRestoringBackup || isArchivingBackup) {
          tag = `${this.TAG_RESTORE},${databaseId}`;
        } else if(isRestoringArchived) {
          tag = `${this.TAG_ARCHIVE_RESTORE},${databaseId}`
        }

        if (!tag) {
          if (!key || key ==='backup') {
            tag = `${this.TAG_BACKUP},${databaseId}`;
          } else if (key === 'pitr') {
            tag = `${this.TAG_PITR},${databaseId}`;
          } else if (key === 'restore') {
            tag = `${this.TAG_RESTORE},${databaseId}`;
          } else if (key === 'archived'){
            tag = `${this.TAG_ARCHIVE_RESTORE},${databaseId}`;
          }
        }
      }

      return tag;
    }

    descChangeHandler(e) {
        this.setState({ backupDescription: e.target.value });
    }

    createBackup() {
        // this.setState({isCreatingBackup: true});
        // let messages = [
        //     'Beginning process',
        //     'Turning on the machine',
        //     'Adding oil',
        //     'Advancing gears', 
        // ]
        // for (let i = 0; i < messages.length; i++) { 
        //     setTimeout(() => {
        //         this.setState({testMessage: `${messages[i]}`});
        //     }, i*2000);
        // }
        // setTimeout(() => {
        //     this.setState({isCreatingBackup: false, testMessage: 'Done'});
        // }, messages.length*2000);

        // return;

        const { userKey, database } = this.props,
        databaseId = database.id || database.workspaceId;

        this.props.createBackupHandler(userKey, databaseId, this.state.backupDescription);

        this.setState({ isCreatingBackup: true });
    }

    deleteBackup(id) {
        const { userKey, database } = this.props,
        databaseId = database.id || database.workspaceId;
        const deleteClickHandler = () => {
            this.props.deleteBackupHandler(userKey, databaseId, id);
            this.setState({ showDeleteModal: false });
        };
        this.setState({
        showDeleteModal: true,
        deleteModalHandler: deleteClickHandler,
        areYouSureMessage: 'Are you sure you wish to delete the selected backup?',
        });
    }

    closeDeleteModal() {
        this.setState({ showDeleteModal: false, additionalDeleteMesssage: '', areYouSureMessage: '' });
    }

    restoreBackup() {
        const { userKey, database } = this.props;
        const { selectedBackup } = this.state,
        databaseId = database.id || database.workspaceId;
        if (selectedBackup) {
        const deleteModalHandler = () => {
            this.props.restoreBackupHandler(userKey, databaseId, selectedBackup.id);
            this.setState({ isRestoringBackup: true, showDeleteModal: false, additionalDeleteMesssage: '', areYouSureMessage: '' });
        };
        const msg = !selectedBackup.isVersionCurrent
            ? 'This database version is out of date. Are you sure you want to continue with restoring this database?'
            : 'Are you sure you wish to restore this database?';
        this.setState({
            showDeleteModal: true,
            deleteModalHandler,
            areYouSureMessage: msg,
            additionalDeleteMesssage: 'Note that this will overwrite your existing database with all data from this backup. New database will not have Point-In-Time Restore history.',
        });
        }
    }

    closePITValidationModal() {
      this.setState({ showPITValidationModal: false, targetRestorePointOutOfRange: false });
    }
    
    archiveBackup() {
        const { userKey, database } = this.props;
        const { selectedBackup } = this.state,
        databaseId = database.id || database.workspaceId;
        if (selectedBackup) {
        this.props.archiveBackupHandler(userKey, databaseId, selectedBackup.id, `${selectedBackup.description}_Archived`);
        this.setState({ isArchivingBackup: true });
        }
    }

    deleteArchived(id) {
        const { userKey, database } = this.props;
        const { selectedArchived } = this.state,
        databaseId = database.id || database.workspaceId;
        if (selectedArchived) {
        const deleteModalHandler = () => {
            this.props.deleteArchivedHandler(userKey, databaseId, selectedArchived.backupId, id);
            this.setState({ showDeleteModal: false, backupDescription: '', additionalDeleteMesssage: '', areYouSureMessage: '' });
        };
        this.setState({ showDeleteModal: true,
            deleteModalHandler,
            areYouSureMessage: 'Are you sure you wish to delete this archived database?',
        });
        }
    }

    restoreArchivedBackup() {
        const { userKey, database } = this.props;
        const { selectedArchived } = this.state,
        databaseId = database.id || database.workspaceId;
        const dbName = database.name || database.workspaceName;

        if (selectedArchived) {
        const deleteModalHandler = () => {
            this.props.restoreArchivedHandler(userKey, databaseId, selectedArchived.backupId, selectedArchived.id);
            this.setState({ isRestoringArchived: true, showDeleteModal: false, additionalDeleteMesssage: '', areYouSureMessage: '' });
        };
        const msg = !selectedArchived.isVersionCurrent
            ? 'This database version is out of date. Are you sure you want to continue with restoring this archived database?'
            : 'Are you sure you wish to restore this archived database?';
        this.setState({
            showDeleteModal: true,
            deleteModalHandler,
            areYouSureMessage: msg,
            additionalDeleteMesssage: `Note this will create a new database Restored_${dbName} as a copy of this archived database`,
        });
        }
    }

    createNewJob(frequency) {
        const
        { newJobs } = this.state,
        newJob = {
            frequency,
            day: 'Sat',
            time: moment().format('hh:mm'),
            nextRunTime: '',
        };
        newJobs.push(newJob);
        this.setState({ newJobs });
    }

    selectBackup(id) {
        let selectedBackup = this.props.availableBackups.find(b => b.id === id);
        selectedBackup = !selectedBackup ? this.props.archivedBackups.find(b => b.id === id) : selectedBackup;
        this.setState({ selectedBackup });
    }

    selectArchived(id) {
        const selectedArchived = this.props.archivedBackups.find(b => b.id === id);
        this.setState({ selectedArchived });
    }

    saveHandler() {}

    cancelNewJobs() {
        this.setState({ newJobs: [] });
    }

    deleteJob(job) {
        const { newJobs, scheduledJobs } = this.state;
        if (newJobs.includes(job)) {
        newJobs.splice(newJobs.indexOf(job), 1);
        this.setState({ newJobs });
        } else {
        scheduledJobs.splice(scheduledJobs.indexOf(job), 1);
        this.setState({ scheduledJobs });
        }
    }

    close() {
        const { refreshDatabase } = this.state;

        clearInterval(this.state.timerId);

        this.setState({
        backupDescription: '',
        selectedTab: 'backup',
        isCreatingBackup: false,
        isRestoringBackup: false,
        isRestoringPointInTimeBackup: false,
        isArchivingBackup: false,
        isRestoringArchived: false,
        selectedBackup: null,
        scheduledJobs: [],
        newJobs: [],
        archivedBackups: [],
        timerId: null,
        });
        this.props.closeHandler(refreshDatabase);
        this.props.clearNotificationHandler();
    }

    modalHandler(modalState) {
        this.setState(modalState);
    }

    open() {
        this.setState({ showModal: true });
    }

    undoPointInTimeRestore = (userKey, databaseId, backupId) => {
      this.props.undoPITRHandler(userKey, databaseId, backupId);
    }

    getMessageIfSubject = (...subjects) => {
      const { serverNotification } = this.props;
      
      if (!serverNotification || !subjects) {
        return '';
      }

      if (subjects.indexOf(serverNotification.subject) > (-1)) {
        return serverNotification.message;
      }

      return '';
    }

    getMessageIfTag = (tag) => {
      const { jobStatus } = this.props;
      
      if (!jobStatus || !jobStatus.jobStatuses || !tag) {
        return '';
      }

      const job = jobStatus.jobStatuses[tag];

      if (!job) {
        return '';
      }

      const msg = `${job.status}: ${job.errorMessage ? job.errorMessage : job.message}`;

      return msg;
    }

    render() {
    const { 
        database, 
        availableBackups, 
        earliestRestorePoint, 
        latestRestorePoint, 
        retrieveLogFile,
        deleteLogFileFromServer,
        restorePointInTimeHandler,
        userKey,
        jobStatus
    } = this.props;

    const {
        isCreatingBackup,
        isRestoringBackup,
        isRestoringPointInTimeBackup,
        selectedBackup,
        isArchivingBackup,
        deleteModalHandler,
        areYouSureMessage,
        selectedArchived,
        isRestoringArchived,
        archivedBackups,
        additionalDeleteMesssage,
        key,
     } = this.state;

     const databaseId = this.getDatabaseId();

    //console.log('render');

    const backupMessage = this.getMessageIfTag(`${this.TAG_BACKUP},${databaseId}`);
    const restoreMessage = this.getMessageIfTag(`${this.TAG_RESTORE},${databaseId}`);
    const restorePointInTimeMessage = this.getMessageIfTag(`${this.TAG_PITR},${databaseId}`);
    const undoPointInTimeMessage = this.getMessageIfTag(this.TAG_PITR_UNDO);
    const restoreArchiveMessage = this.getMessageIfTag(`${this.TAG_ARCHIVE_RESTORE},${databaseId}`);
    
    let headerMarkup = (<Modal.Title>No database selected</Modal.Title>);
        
    if (database) {
        let dbName = database.name ? database.name : database.workspaceName;
        headerMarkup = (<Modal.Title>Backup or Restore: {dbName}</Modal.Title>);
    }
    
    let show = this.props.showModal;
    return (
      <Modal id={this.props.modalId} show={show} dialogClassName="wait-modal" size="lg"
        onHide={this.props.closeHandler}>
        <Modal.Header style={{ backgroundColor: Colors.blue, color: '#FFFFFF' }} closeButton>
          {headerMarkup}
        </Modal.Header>
        <Modal.Body className="modal-container">
            <Tabs
                id="controlled-tab-example"
                activeKey={this.state.key}
                onSelect={key => this.setState({ key })}
            >
                <Tab eventKey="backup" title="Backup">
                  {
                    !key || key === 'backup' ?
                    (<BackupTab
                        backupDescription={this.state.backupDescription}
                        descChangeHandler={this.descChangeHandler}
                        createBackupHandler={this.createBackup}
                        isCreatingBackup={isCreatingBackup}
                        backupMessage={backupMessage}
                        testMode={'backup'}
                    />):('')
                  }
                </Tab>
                {/* <Tab eventKey="backup2" title="Backup2">
                  {
                    key=='backup2' ?
                    (<BackupTab
                        current={key=='backup'}
                        backupDescription={this.state.backupDescription}
                        descChangeHandler={this.descChangeHandler}
                        createBackupHandler={this.createBackup}
                        isCreatingBackup={isCreatingBackup}
                        backupMessage={backupMessage}
                        testMode={'backup2'}
                    />):('')
                  }
                </Tab> */}
                <Tab eventKey="restore" title="Restore">
                  {
                    key === 'restore' ?
                    (<RestoreTab
                      restoreMessage={restoreMessage}
                      availableBackups={availableBackups}
                      selectedBackup={selectedBackup}
                      isRestoringBackup={isRestoringBackup}
                      isArchivingBackup={isArchivingBackup}
                      restoreBackup={this.restoreBackup}
                      archiveBackup={this.archiveBackup}
                      deleteBackup={this.deleteBackup}
                      selectBackup={this.selectBackup}
                  />):('')
                  }
                </Tab>
                <Tab eventKey="archived" title="Archived" >
                  {
                    key === 'archived'?
                    (<ArchivedTab
                      current={key === 'archived'}
                      restoreArchiveMessage={restoreArchiveMessage}
                      archivedBackups={archivedBackups}
                      selectedArchived={selectedArchived}
                      isRestoringArchived={isRestoringArchived}
                      restoreArchivedBackup={this.restoreArchivedBackup}
                      archiveBackup={this.archiveBackup}
                      deleteArchived={this.deleteArchived}
                      selectArchived={this.selectArchived}
                    />):('')
                  }
                </Tab>
                <Tab eventKey="pitr" title="Point-in-time Restore" >
                  {
                    key === 'pitr'?
                    (<PITRTab
                        current={key === 'pitr'}
                        userKey={userKey}
                        database={database}
                        earliestRestorePoint={earliestRestorePoint}
                        latestRestorePoint={latestRestorePoint}
                        restorePointInTimeHandler={restorePointInTimeHandler}
                        restorePointInTimeMessage={restorePointInTimeMessage}
                        undoPointInTimeMessage={undoPointInTimeMessage}
                        isRestoringPointInTimeBackup={isRestoringPointInTimeBackup}
                        retrieveLogFile={retrieveLogFile}
                        deleteLogFileFromServer={deleteLogFileFromServer}
                        undoPITRHandler={this.undoPointInTimeRestore}
                        deleteBackupHandler={this.deleteBackup}
                        isDisabled = {isRestoringPointInTimeBackup || isCreatingBackup || isArchivingBackup || isRestoringBackup || isRestoringArchived}
                        modalHandler = {this.modalHandler}
                        backups = {availableBackups}
                        jobStatus = {jobStatus}
                    />):('')
                  }
                </Tab>
            </Tabs>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="arius" data-dismiss="modal" onClick={this.close}>Close</Button>
        </Modal.Footer>
        <Modal style={{ marginTop: 50 }} show={this.state.showDeleteModal} onHide={this.closeDeleteModal}>
          <Modal.Header style={{ backgroundColor: '#b15555', color: '#FFFFFF' }}>Are you sure?</Modal.Header>
          <Modal.Body>
            <div>{areYouSureMessage}</div>
            <br />
            <div>{additionalDeleteMesssage}</div>
          </Modal.Body>
          <Modal.Footer>
              <Button variant="arius" data-dismiss="modal" onClick={deleteModalHandler}>Yes</Button>
              <Button variant="arius" data-dismiss="modal" onClick={this.closeDeleteModal}>Cancel</Button>
          </Modal.Footer>
        </Modal>
        <Modal style={{ marginTop: 50 }} show={this.state.showPITValidationModal} onHide={this.closePITValidationModal}>
          <Modal.Header style={{ backgroundColor: '#b15555', color: '#FFFFFF' }}></Modal.Header>
          <Modal.Body>
            { this.state.targetRestorePointOutOfRange ?
              ( <div>Specified restore point is not within the valid min/max restore point range for this database.</div> ):
              ( <div>Specified restore point is not a valid date/datetime.</div> ) }
          </Modal.Body>
          <Modal.Footer>
            <Button variant="arius" data-dismiss="modal" onClick={this.closePITValidationModal}>Ok</Button>
          </Modal.Footer>
        </Modal>
      </Modal>
    );
  }
}

const mapStateToProps = state => ({

});

const mapDispatchToProps = dispatch => ({
	dispatch,
});

export default connect(mapStateToProps, mapDispatchToProps)(DatabaseBackupModal);
