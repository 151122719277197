import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from '@app/utilities/routing';
import { Form } from 'react-bootstrap';
import { deleteDataGroup, fetchDataGroups, copyDataGroup, fetchQueriesForDataGroup} from '@app/actions/tod/advancedQueries.actions';
import { showModal } from '@app/actions/modal.actions';
import { DATA_MANAGEQUERIES } from '@app/utilities/permissions';
import { notifyError, alertMessage } from '@app/utilities/notifier';
import Loader from '../../../shared/presentational/loader';
import NoResults from '../../../shared/presentational/noResults';
import SaveButtons from '@app/shared/presentational/saveButtons';
import { Grid, Button } from '@arius';

class DataGroupsList extends Component {
	static propTypes = {
		databases: PropTypes.array,
		changeDatabase: PropTypes.func,
		deleteQueryHandler: PropTypes.func,
		dispatch: PropTypes.func,
		userKey: PropTypes.string,
		currentDb: PropTypes.object,
		isFetching: PropTypes.bool,
		params: PropTypes.object,
		verifyPermission: PropTypes.func,
	};
	static defaultProps = {
		groups: [],
	};
	constructor(props) {
		super(props);
		this.state = {};
		this.changeDatabase = this.changeDatabase.bind(this);
        this.promptToDelete = this.promptToDelete.bind(this);
        this.checkGroupStatus = this.checkGroupStatus.bind(this);
        this.promptToCopy = this.promptToCopy.bind(this);
        this.backNav = this.backNav.bind(this);
        this.getGrid = this.getGrid.bind(this);
        this.getActionItems = this.getActionItems.bind(this);
	}

	componentDidMount() {
        const {currentDb, dispatch, userKey, isFetchingDataGroups} = this.props;
        
		if (null != currentDb && !isFetchingDataGroups) {
			// assume navigation came from back button on createQuery...
			dispatch(fetchDataGroups(userKey,  currentDb.id));
		}
	}

	componentDidUpdate(prevProps) {
		const {currentDb, isFetchingDataGroups, dispatch, userKey} = this.props;
		if (currentDb && prevProps.currentDb !== currentDb && !isFetchingDataGroups) {
			dispatch(fetchDataGroups(userKey,  currentDb.id));
		}
	}

    getGrid() {
        const { dataGroups:data } = this.props;

        let columns = [
            { field: 'name', headerText: 'Name'},
            { field: 'description', headerText: 'Description'},
            { field: 'factColumnName', headerText: 'Dimension'},
            { field: 'lastModifiedBy', headerText: 'Last Modified By'},
            { field: 'lastModifiedDate', headerText: 'Last Modified'},
            { field: 'id', headerText: 'Actions', template: this.getActionItems}
        ];
        return <Grid columns={columns} data={data} height='calc(100vh - 330px)'/>
    }

    getActionItems(o) {
		const {
			currentDb,
            verifyPermission,
            browserHistory
		} = this.props;
        const hasPermissions = verifyPermission(DATA_MANAGEQUERIES);
		const hasWriteAccess = currentDb && hasPermissions;
		let tooltip = hasWriteAccess ? 'View/Edit' : 'View';
		
        return (
            <span>
						
            <Button toolTip={tooltip} placement="right" iconName="edit" 
                onClick={() => {
                    browserHistory.push(`/tod/advancedQueries/${currentDb.id}/groups/${o.id}`);
                }} />

            <Button toolTip={`Copy ${o.name}`} placement="left" iconName="content_copy" onClick={(e) =>{
                    if (hasWriteAccess){
                        this.promptToCopy(o, e)
                    } else {
                        notifyError('You are not authorized to access this functionality');
                    }
                }}/>

            <Button toolTip="Delete Group" placement="left" iconName="delete" onClick={(e) =>{
                    if (hasWriteAccess){
                        this.checkGroupStatus(o, e)
                    } else {
                        notifyError('You are not authorized to access this functionality');
                    }
                }}/>
        </span>
        )
    }

	changeDatabase(e) {
		const {
			databases,
            changeDatabase,
            browserHistory
		} = this.props,
		database = databases.find(d => d.workspaceId.toString() === e.target.value);
        changeDatabase(database);
        browserHistory.push(`/tod/advancedQueries/${database.workspaceId}/groups`);
    }

    backNav() {
        this.props.browserHistory.push(`/tod/advancedQueries`);
    }

    checkGroupStatus(o) {
        const { dispatch, userKey, currentDb } = this.props;

        dispatch(fetchQueriesForDataGroup(userKey, currentDb.id, o.id,
            (data)=> {
                let names = data ? data.names : null;
                if (names && Array.isArray(names)){
                    if (names.length > 0) {
                        // display warning message
                        let queryNames = names.map(n => 
                            `<span>${n}</span>`
                        );
                        const displayString = queryNames.join('<br>');
                        alertMessage('Cannot delete this group while in use by the following queries:', displayString);
                    } else {
                        this.promptToDelete(o);
                    }
                } else {
                    notifyError('Problem getting group data.');
                }
            }));
    }

	promptToDelete(o) {
		const {userKey, currentDb,dispatch} = this.props;

		if (o) {
			const deleteMessageItems = [
			<li
				key={o.id}
				style={{ fontWeight: 800, listStyle: 'none' }}
			>
				{o.name}
			</li>,
			];

			deleteMessageItems.unshift(
				'Are you sure you want to delete the following: '
			);
			deleteMessageItems.push(
				'This could affect Arius projects for segments that reference this group.'
			);

			const yesClickHandler = () => {
                dispatch(deleteDataGroup(userKey, currentDb.id, o.id));
			};
			const noClickHandler = () => {};
			const action = showModal(
				'confirmation',
				deleteMessageItems,
				yesClickHandler,
				noClickHandler
			);
			dispatch(action);
		}
    }
    
    promptToCopy(o) {
		const {
			userKey,
			currentDb,
			dispatch,
		} = this.props;
        
		if (o) {
			let messageItems = [
			<li
				key={o.id}
				style={{ fontWeight: 800, listStyle: 'none' }}
			>
				{o.name}
			</li>,
			];

			messageItems.unshift(
				`Copy the following:`
			);

			const yesClickHandler = () => {
				dispatch(copyDataGroup(userKey, currentDb.id, o.id));
			};
			const noClickHandler = () => {};
			const action = showModal(
				'confirmation',
				messageItems,
				yesClickHandler,
				noClickHandler
			);
			dispatch(action);
		}
    }

	render() {
		const {
			databases,
			dataGroups,
            isFetchingDataGroups,
			currentDb,
			verifyPermission,
			browserHistory,
		} = this.props;

		const hasPermissions = verifyPermission(DATA_MANAGEQUERIES);
		const hasWriteAccess = currentDb && hasPermissions;
		const accessErrorMessage = !currentDb ? 'Must first select a database' :
						'You are not authorized to access this functionality';

        let data = dataGroups ? dataGroups : [];
		let tableMarkup = <div />;
		if (currentDb) {
			tableMarkup = (
				<div style={{ margin: '5vh auto' }}>
					<NoResults />
				</div>
			);
			if (isFetchingDataGroups) {
				tableMarkup = (
					<div style={{ margin: '5vh auto' }}>
						<Loader loadingItem="Groups" />
					</div>
				);
			} else if (data && data.length > 0) {
				tableMarkup = this.getGrid();
			}
		}

		return (
        <div  className="list-container-arius">
			<div className="list-header-arius">
                <h4>Groups</h4>
				<div style={{ display: 'flex' }}>
					<Button 
						mode='add' 
						onClick={
							hasWriteAccess
							? () =>
								browserHistory.push(
								`/tod/advancedQueries/${currentDb.id}/groups/new`
							)
							: () => notifyError(accessErrorMessage)
						}
						toolTip='Create new group'/>
				</div>
			</div>
			<Form.Group controlId="selectDatabaseControl" className='select-database'>
				<Form.Label>Select Database</Form.Label>
				<Form.Select
					placeholder="select"
					value={currentDb ? currentDb.id : 'default'}
					onChange={this.changeDatabase}
				>
					<option value="default">Select a database</option>
					{databases.map(db => (
						<option key={`${db.workspaceId}`} value={db.workspaceId}>
						{db.workspaceName}
						</option>
					))}
				</Form.Select>
			</Form.Group>
            {tableMarkup}
            <SaveButtons
                backButtonHander={this.backNav}
                backButtonText="Back"
            />
		</div>
		);
	}
}

const mapStateToProps = state => ({
	dataGroups: state.tod.queries.dataGroups,
    isFetching: state.tod.queries.isFetching,
    isFetchingDataGroups: state.tod.queries.isFetchingDataGroups
});

const mapDispatchToProps = dispatch => ({
	dispatch,
});
  
 export default connect(mapStateToProps, mapDispatchToProps)(DataGroupsList);
