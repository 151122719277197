import React, { Component } from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import * as Colors from '@app/utilities/colors';
import * as GlobalStyles from '../../../utilities/globalStyles';

class PurpleButton extends Component {

    render() {

        const {message, id, type, clickHandler, waiting}=this.props;
        let fontSize = '20px';

        let isBusy = waiting === true;
        let iconType = 'add';
        let btnStyle = {
            color: Colors.darkGrey,
            borderRadius: '5px',
            cursor: 'pointer',
            fontSize,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            height: '30px',
            width: '30px',
            textAlign: 'center'
        };
        let busyStyle = {
            color: Colors.purple,
            borderRadius: '5px',
            cursor: 'pointer',
            fontSize,
            height: '30px',
            width: '30px',
            textAlign: 'center',
            position:'absolute', 
            top: '0', left: '0',
            display: isBusy ? '' : 'none'
        };

        switch(type) {
            case 'save':
                iconType = 'save';
                break;
            case 'download':
                iconType = 'get_app';
                break;
            case 'change':
                iconType = 'change_history';
                break;
            case 'import':
                iconType = 'create';
                break;
            case 'delete':
                iconType = 'delete';
                break;
            case 'categorize':
                iconType = 'view_module';
                break;
            default:
                
        } 

        return (
 <OverlayTrigger
    placement="left"
    focus
    overlay={<Tooltip id={'tootipId_' + id} style={GlobalStyles.toolTipFix}>{message}</Tooltip>}
    >
    <span style={{}}>
        <i
            style={btnStyle}
            className="material-icons hover-purple"
            onClick={clickHandler}
        >
            {iconType}
        </i>
        <i style={busyStyle} className="fa fa-refresh fa-spin fa-fw"></i>          
    </span>
</OverlayTrigger> 
        );
    }
}

export default PurpleButton;
