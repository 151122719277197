import React, { Component } from 'react';
import { connect } from '@app/utilities/routing';
import { Switch, Route } from 'react-router-dom';
import { clearErrorMessage } from '@app/actions/application.actions';
import {
  fetchDatabases,
  selectDatabase,
  fetchDatbaseColumns,
  fetchDatabase,
} from '@app/actions/tod/todDatabases.actions';
import {
  createQuery,
  updateQuery,
} from '@app/actions/tod/advancedQueries.actions';
import { createPermissionChecker } from '@app/utilities/permissions';

import AdvancedQueriesList from '@app/tod/advancedQueries/queriesList';
import CreateAdvancedQuery from '@app/tod/advancedQueries/createQuery';
import DataGroupsList from '@app/tod/advancedQueries/dataGroupsList';
import CreateDataGroup from '@app/tod/advancedQueries/createDataGroup';

class QueriesTab extends Component {
    static propTypes = {
    };
    constructor(props) {
        super(props);
        this.state = {
            databases: [],
        };
    }

    UNSAFE_componentWillMount() {
        const { 
            currentDb,
            getDatabase,
            userKey,
            match,
            history: browserHistory
        } = this.props;
        let params = match.params;
        const databaseId = params.databaseId ? parseInt(params.databaseId, 10) : null;

        if (currentDb){
            if (!databaseId){
                browserHistory.push(`/tod/advancedQueries/${currentDb.id}`);
            } else if (databaseId !== currentDb.id){
                getDatabase(userKey, databaseId);
            }
        } else if (databaseId){
            getDatabase(userKey, databaseId);
        }
    }

    componentDidMount() {
        const { userKey, getDatabases } = this.props;
        this.filterDatabases(this.props.allDatabases.slice());
        getDatabases(userKey);
    }

    componentDidUpdate(prevProps) {
        const {
            currentDb,
            userKey,
            match,
            getDatabase,
            isFetchingDatabases,
            allDatabases,
            history: browserHistory
        } = this.props;

        let params = match.params;
        
        if (isFetchingDatabases) {
            return;
        }

        if (currentDb && !params.databaseId){
            browserHistory.push(`/tod/advancedQueries/${currentDb.id}`);
            return;
        }

        if (params.databaseId) {
            
            const databaseId = parseInt(params.databaseId, 10);
            if (databaseId) {
                // TODO: Fix TOD Permissions
                // const isDbUser = true, isQueryAdmin = true;
                // if (!isDbUser && !isQueryAdmin) {
                //     // TODO - is this enforcing that only dbuser or admin can see anything other than the query list?
                //     browserHistory.push(`/tod/advancedQueries/${params.databaseId}`);
                // }

                if (currentDb && currentDb.id !== databaseId) {
                    browserHistory.push(`/tod/advancedQueries/${currentDb.id}`);
                }
                else if (!currentDb) {
                    getDatabase(userKey, databaseId);
                }
            }
        }
        if (prevProps.allDatabases !== allDatabases) {
            this.filterDatabases(allDatabases.slice());
        }
    }


    filterDatabases(allDatabases) {
        // TODO: Fix TOD Permissions
        let databases = allDatabases.filter(db => db.hasDataLoaded);
        databases = databases.sort((a, b) =>
        a.workspaceName.toLowerCase().localeCompare(b.workspaceName.toLowerCase())
        );
        this.setState({ databases });
    }

  render() {
    const {
      userKey,
      match,
      history,
      dispatch,
      changeDatabase,
      isFetching,
      isSaving,
      currentDb,
      newSaveHandler,
      updateSaveHandler,
      dataSource,
      isDataSourceFetching,
      segmentPreview,
      currentQuery,
      errorMessage,
    
      backRoute,
      queryInfo,
      clearErrorMessageHandler,
    } = this.props;
    const { databases } = this.state;

    let childProps = {
        userKey,
        browserHistory: history,
        params: match.params,
        dispatch,
        databases,
        changeDatabase,
        isFetching,
        isSaving,
        currentDb,
        newSaveHandler,
        updateSaveHandler,
        dataSource,
        isDataSourceFetching,
        segmentPreview,
        currentQuery,
        errorMessage,
        backRoute,
        queryInfo,
        
        clearErrorMessageHandler,
        verifyPermission: createPermissionChecker(
          currentDb ? currentDb.permissions : []
        ),
    };

    return (
    <Switch>
        <Route path="/tod/advancedQueries/:databaseId/groups/new" render={props => <CreateDataGroup {...{...childProps, params: props.match.params}}/>}/> 
        <Route path="/tod/advancedQueries/:databaseId/groups/:groupId" render={props => <CreateDataGroup {...{...childProps, params: props.match.params}}/>}/> 
        <Route path="/tod/advancedQueries/:databaseId/groups" render={props => <DataGroupsList {...{...childProps, params: props.match.params}}/>}/>
        <Route path="/tod/advancedQueries/groups" render={props => <DataGroupsList {...{...childProps, params: props.match.params}}/>}/>
        <Route path="/tod/advancedQueries/:databaseId/new" render={props => <CreateAdvancedQuery {...{...childProps, params: props.match.params}}/>}/>
        <Route path="/tod/advancedQueries/:databaseId/:queryId" render={props => <CreateAdvancedQuery {...{...childProps, params: props.match.params}}/>}/>
        <Route path="/tod/advancedQueries" render={props => <AdvancedQueriesList {...{...childProps, params: props.match.params}}/>}/>
    </Switch>
    );
  }
}

const mapStateToProps = state => ({
  userKey: state.user.userKey,
  application: state.application,
  allDatabases: state.tod.databases.items,
  isFetchingDatabases: state.tod.databases.isFetching,
  currentDb: state.tod.databases.current,
  currentQuery: state.tod.queries.current,
  isFetching: state.tod.queries.isFetching,
  isSaving: state.tod.queries.isSaving,
  dataSource: state.tod.queries.dataSource,
  isDataSourceFetching: state.tod.queries.isDataSourceFetching,
  segmentPreview: state.tod.queries.segmentPreview,
  isProcessingNewRollupDefinition: state.tod.queries
    .isProcessingNewRollupDefinition,
  backRoute: state.application.backRoute,
  datasetColumns: state.tod.queries.datasetColumns,
  queryInfo: state.tod.queries.queryInfo,
  errorMessage: state.application.errorMessage,
});

const mapDispatchToProps = dispatch => ({
  getDatabases: userKey => {
    dispatch(fetchDatabases(userKey));
  },
  getDatabaseColumns: (userKey, databaseId, factTableGuid) => {
    dispatch(fetchDatbaseColumns(userKey, databaseId, factTableGuid));
  },
  getDatabase: (userKey, databaseId) => {
    dispatch(fetchDatabase(userKey, databaseId));
  },
  newSaveHandler: ({
    userKey,
    databaseId,
    queryName,
    queryDescription,
    columns,
    defaultDateY,
  }) => {
    dispatch(
      createQuery({
        userKey,
        databaseId,
        queryName,
        queryDescription,
        columns,
        defaultDateY,
      })
    );
  },
  updateSaveHandler: ({
    userKey,
    databaseId,
    queryId,
    queryName,
    queryDescription,
    columns,
    defaultDateY,
  }) => {
    dispatch(
      updateQuery({
        userKey,
        databaseId,
        queryId,
        queryName,
        queryDescription,
        columns,
        defaultDateY,
      })
    );
  },
  changeDatabase: db => {
    dispatch(selectDatabase(db));
  },
  clearErrorMessageHandler: () => {
    dispatch(clearErrorMessage());
  },
  dispatch,
});

export default connect(mapStateToProps, mapDispatchToProps)(QueriesTab);
