import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { showModal } from '@app/actions/modal.actions';
import { refreshReportingDatabase } from '../../../actions/analysis/reporting.actions';
import { Grid, Button } from '@arius';
import { retrieveReportingDefinitions } from '@app/actions/analysis/reporting.actions';

class ReportingDefinitionsList extends Component {
    static propTypes = {
        extractDefinitions: PropTypes.array,
        isFetching: PropTypes.bool,
        firstSearchPerformed: PropTypes.bool,
        extractKickingOff: PropTypes.bool,
        isFetchingWideCsv: PropTypes.bool,
        isFetchingNarrowCsv: PropTypes.bool,
        userKey: PropTypes.string,
        databaseId: PropTypes.number,
        dispatch: PropTypes.func,
        editClickHandler: PropTypes.func,
        currentWorkspace: PropTypes.object,
        apiKey: PropTypes.string,
        exportCsv: PropTypes.func,
        csv: PropTypes.object,   
        canCreateEditExtractTable: PropTypes.bool,
        canDeleteExtractTable: PropTypes.bool,
    };
    constructor(props) {
        super(props);
        this.state = {
        sortedBy: 'name',
        extractDefinitions: [],
        ascending: true,
        };
        this.getActionItems = this.getActionItems.bind(this);
        this.getGrid = this.getGrid.bind(this);
        this.refreshReportingTables = this.refreshReportingTables.bind(this);
        this.refreshReportingTablesHandler = this.refreshReportingTablesHandler.bind(this);
    }

    componentDidMount() {
        this.refresh();
    }

    componentDidUpdate(prevProps) {
        const { currentWorkspace } = this.props;
        if (currentWorkspace && prevProps.currentWorkspace !== currentWorkspace) {
            this.refresh();
        }
    }

    refresh() {
        const { userKey, dispatch, currentWorkspace } = this.props;
        if (currentWorkspace){
            dispatch(retrieveReportingDefinitions({ userKey, databaseId: currentWorkspace.id }));
        }
    }

    refreshReportingTablesHandler(reportDef) {
        const { dispatch } = this.props;

        const messageItems = [
            <div key={11} style={{marginBottom: 15}}>Are you sure you want to update the following data model?</div>,
            <div key={10} style={{marginBottom: 15}}><b>{`${reportDef.name}`}</b></div>,
            <div key={12}>Note this process may take multiple hours to update.  View the “Status Reports” page to monitor progress.</div>,
        ];

        const yesClickHandler = () => this.refreshReportingTables(reportDef.id);
        const noClickHandler = () => {};
        const action = showModal(
            'confirmation',
            messageItems,
            yesClickHandler,
            noClickHandler
        );
        dispatch(action);
    }

    refreshReportingTables(id) {
        const { userKey, databaseId, dispatch } = this.props;
        dispatch(refreshReportingDatabase({ userKey, databaseId, definitionId:id }));
    }

    getActionItems(row) {
        const { extractKickingOff } = this.props;

        return  (
            <Button 
                iconName={extractKickingOff ? "fa-refresh fa-spin" : "fa-table"}
                onClick={() => this.refreshReportingTablesHandler(row)}
                toolTip='Update Data Model'/>
        )
    }

    getGrid() {
        const { reportingDefinitions: data } = this.props;
        // var data = [ {
        //     id: 1000,
        //     listName: 'PowerBI Data Model',
        //     lastModifiedBy: 'Fake Person',
        //     lastModifiedDate: new Date('2/27/1975'),

        // }]
        let columns = [
            { field: 'id', headerText: 'Id', visible: false},
            { field: 'listName', headerText: 'Name'},
            { field: 'description', headerText: 'Description'},
            { field: 'lastModifiedBy', headerText: 'Last Modified By'},
            { field: 'lastModified', headerText: 'Last Modified'},
            { field: 'lastSubmittedBy', headerText: 'Last Submitted By'},
            { field: 'lastSubmitted', headerText: 'Last Submitted'},
            { field: 'id', headerText: 'Actions', template: this.getActionItems},
        ];
        
        return <Grid 
            columns={columns}
            data={data}
            height='calc(100vh - 240px)' 
            allowPaging={false}/>
    }


    render() {
        return (
            <div className="list-container-arius">
                <div className="list-header-arius">
                    <span style={{display:'flex'}}>
                        <h4>Report Data Models</h4>
                    </span>
                </div>
                {this.getGrid()}
            </div>
        )
    }
}

export default ReportingDefinitionsList;
