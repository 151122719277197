import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import {  NavTab, NavSideTabs } from '@app/utilities/routing';
import * as GlobalStyles from '@app/utilities/globalStyles';
import Feature from '@app/utilities/routing/routeSafeFeature';

class Sidebar extends Component {
    static propTypes = {
        dispatch: PropTypes.func,
    };
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        return (
        <div className='sidebar' style={GlobalStyles.sidebar}>
            <div className="analysis-home-link" style={{marginTop: 20, padding: '0px 20px' }}>
                <Link to={"/utilities"}>
                    <i
                    className="fa fa-arrow-circle-o-left"
                    style={{ paddingRight: 2 }}
                    aria-hidden="true"
                    />
                    Utilities Home
                </Link>
            </div>
            <NavSideTabs>
                {/* <NavTab label='Groups' to='/utilities/performance/groups'/>     */}
                <NavTab label='Elastic Pools' to='/utilities/performance/elasticpools'/>
                <NavTab label='Databases' to='/utilities/performance/databases'/>
                {/* <NavTab label='Compute' to='/utilities/performance/compute'/>
                <NavTab label='Logs' to='/utilities/performance/logs'/> */}
                <Feature flag="powerBiReports">
                    <NavTab label='Power BI' to='/utilities/performance/powerbi'/>
                </Feature>
            </NavSideTabs> 
        </div>
        );
    }
}

export default Sidebar;
