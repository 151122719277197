import React from 'react';
import { connect } from '@app/utilities/routing';

// TODO - this component is an attempt to fix conflicts between react-redux-feature-flags and react-router-dom librarires
// should be removed for a proper solution
class Feature extends React.Component {
    render() {
        const {children, features, flag} = this.props;

        if (!features[flag]) { return null;}
        return React.Children.only(children);
    }
}

function mapStateToProps(state, ownProps) {
    const {features} = state.application;;
    return { features };
}

export default connect(mapStateToProps)(Feature);