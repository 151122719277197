import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Form, Col, Row, Button } from 'react-bootstrap';
import * as Colors from '@app/utilities/colors';

const style = {
    listBox: {
        listStyle: 'none',
        backgroundColor: 'white',
        padding: 20,
        overflowY: 'auto',
    },
    activeItem: {
        backgroundColor: Colors.lightGrey,
        cursor: 'pointer',
    },
    inactiveItem: {
        cursor: 'pointer',
    },
    button: {
        width: '100%', marginBottom: '10px'
    },
};

class DualListBox extends Component {
    static propTypes = {
        availableItems: PropTypes.array,
        assignedItems: PropTypes.array,
        availableGroup: PropTypes.string,
        assignedGroup: PropTypes.string,
        onChanges: PropTypes.func,
        style: PropTypes.object,
    };
    static defaultProps = {
        availableItems: [],
        assignedItems: [],
        availableGroup: 'Available',
        assignedGroup: 'Assigned',
    };
    constructor(props) {
        super(props);
        this.state = {
            availableItems: [],
            assignedItems: [],
            selectedItem: null,
        };
        this.removeItem = this.removeItem.bind(this);
        this.removeSelectedItem = this.removeSelectedItem.bind(this);
        this.addItem = this.addItem.bind(this);
        this.addSelectedItem = this.addSelectedItem.bind(this);
        this.addAllItems = this.addAllItems.bind(this);
        this.removeAllItems = this.removeAllItems.bind(this);
    }

    componentDidMount() {
        const { availableItems, assignedItems } = this.props;
        this.setState({
            availableItems: this.alphabetizeItems(availableItems),
            assignedItems: this.alphabetizeItems(assignedItems),
        });
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.availableItems !== this.state.availableItems || prevState.assignedItems !== this.state.assignedItems) {
            const { assignedItems, availableItems } = this.state;
            this.props.onChanges(assignedItems, availableItems);
        }

        if (prevProps.availableItems !== this.props.availableItems || prevProps.assignedItems !== this.props.assignedItems) {
        this.setState({
            availableItems: this.alphabetizeItems(this.props.availableItems),
            assignedItems: this.alphabetizeItems(this.props.assignedItems),
        });
        }
    }

    addItem(item) {
        if (!this.props.canEdit) { return;}
        const selectedItem = item,
        availableItems = this.state.availableItems.slice(),
        assignedItems = this.state.assignedItems.slice();
        if (selectedItem) {
            if (!assignedItems.includes(selectedItem) && availableItems.includes(selectedItem)) {
                assignedItems.push(selectedItem);
                availableItems.splice(availableItems.indexOf(selectedItem), 1);
                this.setState({ assignedItems: this.alphabetizeItems(assignedItems), availableItems: this.alphabetizeItems(availableItems) });
            }
        }
    }

    addSelectedItem() {
        if (!this.props.canEdit) { return;}
        const { selectedItem } = this.state;
        this.addItem(selectedItem);
    }

    removeItem(item) {
        if (!this.props.canEdit) { return;}
        const selectedItem = item,
        availableItems = this.state.availableItems.slice(),
        assignedItems = this.state.assignedItems.slice();
        if (selectedItem) {
            if (!availableItems.includes(selectedItem) && assignedItems.includes(selectedItem)) {
                availableItems.push(selectedItem);
                assignedItems.splice(assignedItems.indexOf(selectedItem), 1);
                this.setState({ assignedItems: this.alphabetizeItems(assignedItems), availableItems: this.alphabetizeItems(availableItems) });
            }
        }
    }

    removeSelectedItem() {
        if (!this.props.canEdit) { return;}
        const { selectedItem } = this.state;
        this.removeItem(selectedItem);
    }

    addAllItems() {
        if (!this.props.canEdit) { return;}
        let { availableItems, assignedItems } = this.state;
        assignedItems = [...assignedItems, ...availableItems];
        availableItems = [];
        this.setState({ assignedItems: this.alphabetizeItems(assignedItems), availableItems: this.alphabetizeItems(availableItems) });
    }

    removeAllItems() {
        if (!this.props.canEdit) { return;}
        let { availableItems, assignedItems } = this.state;
        availableItems = [...assignedItems, ...availableItems];
        assignedItems = [];
        this.setState({ assignedItems: this.alphabetizeItems(assignedItems), availableItems: this.alphabetizeItems(availableItems) });
    }

    selectItem(item, e) {
        if (!this.props.canEdit) { return;}
        e.stopPropagation();
        let { selectedItem } = this.state;
        selectedItem = selectedItem === item ? null : item;

        this.setState({ selectedItem });
    }

    alphabetizeItems(items) {
        return items.sort((a, b) => {
            if (a.name.toString().toLowerCase() < b.name.toString().toLowerCase()) {
                return -1;
            } else {
                return 1;
            }
        });
    }

    render() {
        const { availableItems, assignedItems, selectedItem } = this.state;
        const { availableGroup, assignedGroup, className, helpCallback} = this.props;
        let editStyle = this.props.canEdit ? {} : {backgroundColor: '#eee', cursor: 'not-allowed'};
        let activeItemStyle = {...style.activeItem, ...editStyle};
        let inactiveItemStyle = {...style.inactiveItem, ...editStyle};
        let listBoxStyle = {...style.listBox, ...editStyle, height: 'calc(100% - 40px)'};
        return (
            <Row className={className} style={this.props.style}>
                <Form.Group as={Col} xs={5} style={{height: '98%'}}>
                    <Form.Label>
                        {availableGroup}
                        {helpCallback ? (
                            <span>
                                &nbsp;&nbsp;
                                <i
                                    className="fa fa-question-circle"
                                    style={{
                                    fontSize: '2.0vmin',
                                    color: Colors.purple,
                                    cursor: 'pointer',
                                    }}
                                    onClick={() => helpCallback()}
                                />
                            </span>) : (<div></div>)}
                    </Form.Label>
                    <ul style={listBoxStyle} className="form-control">
                        {availableItems.map(item =>
                            <li key={item.value}
                            value={item.value}
                            style={selectedItem === item ? activeItemStyle : inactiveItemStyle }
                            onClick={(e) => this.selectItem(item, e)}
                            onDoubleClick={(e) => {this.addItem(item);}}
                        >{item.name}</li>
                        )}
                    </ul>
                </Form.Group>
                <Form.Group as={Col} xs={2} style={{height: '98%'}}>
                    <Form.Label htmlFor="">&nbsp; &nbsp;</Form.Label>
                    <Button variant="outline-dark" style={style.button} onClick={this.addAllItems}>
                        <i className="material-icons">list</i>
                        <i className="material-icons">keyboard_arrow_right</i>
                    </Button>
                    <Button variant="outline-dark" style={style.button} onClick={this.addSelectedItem} disabled={this.props.disabled}>
                        <i className="material-icons">keyboard_arrow_right</i>
                    </Button>
                    <Button variant="outline-dark" style={style.button} onClick={this.removeSelectedItem}>
                        <i className="material-icons">keyboard_arrow_left</i>
                    </Button>
                    <Button variant="outline-dark" style={style.button} onClick={this.removeAllItems}>
                        <i className="material-icons">keyboard_arrow_left</i>
                        <i className="material-icons">list</i>
                    </Button>
                </Form.Group>
                <Form.Group as={Col} xs={5} style={{height: '98%'}}>
                    <Form.Label>{assignedGroup}</Form.Label>
                    <ul style={listBoxStyle} className="form-control">
                        {assignedItems.map(item =>
                            <li key={item.value}
                                value={item.value}
                                style={selectedItem === item ? activeItemStyle : inactiveItemStyle }
                                onClick={(e) => this.selectItem(item, e)}
                                onDoubleClick={(e) => {this.removeItem(item);}}
                            >
                                {item.name}
                            </li>
                        )}
                        </ul>
                </Form.Group>
            </Row>
        );
    }
}

export default DualListBox;
