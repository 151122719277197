import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Form, Col, Row } from 'react-bootstrap';
import { connect } from '@app/utilities/routing';
import * as Colors from '@app/utilities/colors';
import { notifyError } from '@app/utilities/notifier';
import SaveButtons from '../../shared/presentational/saveButtons';
import { SITE_MANAGESECURITY } from '@app/utilities/permissions';
import { APPLICATION_NAMES } from '@app/utilities/constants';

const style = {
  workspace: {
    marginLeft: '10px',
  },
  selectBox: {
    width: '100%', //'20vw',
    margin: 10,
    height: '60vh',
    border: '1px solid #ccc',
    borderRadius: '4px',
    backgroundColor: 'white',
    padding: '1em',
    overflowY: 'scroll',
    overflowX: 'hidden',
  },
  filterControl: {
      width: '100%',
      margin: 10
  },
};

function loadUsers(users) {
  return (users || []).map(u => ({ name: u.name, id: u.userId }));
}

function loadResources(workspaces) {
  return (workspaces || [])
    .map(w => ({ name: w.workspaceName, id: w.workspaceId }));
}

class AssignRoles extends Component {
  static propTypes = {
    users: PropTypes.array,
    userKey: PropTypes.string,
    availableUsersHandler: PropTypes.func,
    availableResourcesHandler: PropTypes.func,
    roleDescriptionHandler: PropTypes.func,
    availableResources: PropTypes.array,
    availableUsers: PropTypes.array,
    roleDescriptions: PropTypes.array,
    retrieveWorkspacesHandler: PropTypes.func,
    workspaces: PropTypes.object,
    assignRoleHandler: PropTypes.func,
    databases: PropTypes.array,
    userPermissions: PropTypes.array,
  };
  constructor(props) {
    super(props);
    this.state = {
      users: [],
      workspaces: [],
      databases: [],
      selectedUsers: [],
      selectedRoles: [],
      selectedResourceIds: [],
      selectedResourceId: null,
      userFilter: '',
      roleFilter: '',
      resourceFilter: '',
      availableUsers: [],
      availableResources: [],
      roleDescriptions: [],
      operation: '',
      userPermissions: PropTypes.array,
    };
    this.selectUser = this.selectUser.bind(this);
    this.selectRole = this.selectRole.bind(this);
    this.selectResource = this.selectResource.bind(this);
    this.filterUsers = this.filterUsers.bind(this);
    this.filterRoles = this.filterRoles.bind(this);
    this.filterResources = this.filterResources.bind(this);
    this.assignRoles = this.assignRoles.bind(this);
  }

  componentDidMount() {
    const {
        browserHistory,
        userPermissions,
        databases,
        availableResourcesHandler,
        users,
        retrieveWorkspacesHandler,
        userKey,
        workspaces,
        roleDescriptions,
    } = this.props,
      usersMap = loadUsers(users);
    if (databases && databases.length) {
      this.setState({ databases });
    }

    availableResourcesHandler();
    retrieveWorkspacesHandler(userKey);
    this.setState({
      users: usersMap,
      workspaces: loadResources((workspaces || {}).items),
    });

    if (userPermissions && userPermissions.length) {
      if (userPermissions.indexOf(SITE_MANAGESECURITY) === -1) {
        browserHistory.push('/unauthorized');
      }
    }

    if (roleDescriptions && roleDescriptions.length) {
      this.setState({ roleDescriptions });
    }
  }

  componentDidUpdate(prevProps) {
    const {
        browserHistory,
        users,
        workspaces,
        databases,
        roleDescriptions,
        userPermissions,
    } = this.props,
      newState = {};

    if (prevProps.users !== users) {
      newState.users = users.map(su => ({ name: su.name, id: su.userId }));
    }

    if (
      userPermissions &&
      userPermissions.length &&
      userPermissions !== prevProps.userPermissions
    ) {
      if (userPermissions.indexOf(SITE_MANAGESECURITY) === -1) {
        browserHistory.push('/unauthorized');
      }
    }

    if (
      databases &&
      prevProps.databases &&
      databases.length &&
      databases !== prevProps.databases
    ) {
      this.setState({ databases });
    }

    if ((prevProps.workspaces || {}).items !== (workspaces || {}).items) {
      newState.workspaces = workspaces.items.map(w => ({
        name: w.workspaceName,
        id: w.workspaceId,
      }));
    }

    if (
      roleDescriptions &&
      roleDescriptions.length &&
      roleDescriptions !== prevProps.roleDescriptions
    ) {
      newState.roleDescriptions = roleDescriptions;
    }

    if (newState.users || newState.workspaces || newState.roleDescriptions) {
      this.setState(newState);
    }
  }

  selectUser(user) {
    const { selectedUsers } = this.state, idx = selectedUsers.indexOf(user);

    if (idx === -1) {
      selectedUsers.push(user);
    } else {
      selectedUsers.splice(idx, 1);
    }
    this.setState({ selectedUsers });
  }

  selectResource(resource) {
    const { roleDescriptionHandler } = this.props;
    let application = '';
    let resourceId = resource.id;
    if (resource.workspaceId) {
        application = APPLICATION_NAMES.TOD;
        resourceId = resource.workspaceId;
    } else if (resourceId === 'site') {
        application = APPLICATION_NAMES.PORTAL_MANAGEMENT;
    } else {
        application = APPLICATION_NAMES.ARIUS_ENTERPRISE;
    }

    roleDescriptionHandler(resourceId);
    this.setState({ selectedResourceId: resourceId, selectedApplication: application });
  }

    selectRole(role) {
        let { selectedRoles } = this.state;

        if (selectedRoles.findIndex((x) => x.roleId === role.roleId) === -1){
            selectedRoles.push(role);
        } else {
            selectedRoles = selectedRoles.filter((x) => x.roleId !== role.roleId);
        }
        this.setState({selectedRoles});
    }

  filterUsers(e) {
    let { users } = this.props;
    users = loadUsers(users);
    const filteredUsers = users.filter(
      u => u.name.toLowerCase().indexOf(e.target.value.toLowerCase()) !== -1,
    );
    this.setState({ userFilter: e.target.value, users: filteredUsers });
  }

  filterRoles(e) {
    const { roleDescriptions } = this.props,
      filteredRoles = roleDescriptions.filter(
        rd =>
          rd.name.toLowerCase().indexOf(e.target.value.toLowerCase()) !== -1,
      );
    this.setState({
      roleFilter: e.target.value,
      roleDescriptions: filteredRoles,
    });
  }

  filterResources(e) {
    let { workspaces } = this.props;
    workspaces = loadResources((workspaces || {}).items);

    const { databases } = this.props,
      filteredWorkspaces = workspaces.filter(
        ws =>
          ws.name.toLowerCase().indexOf(e.target.value.toLowerCase()) !== -1,
      ),
      filteredDatabases = databases.filter(
        db =>
          db.workspaceName
            .toLowerCase()
            .indexOf(e.target.value.toLowerCase()) !== -1,
      );
    this.setState({
      resourceFilter: e.target.value,
      workspaces: filteredWorkspaces,
      databases: filteredDatabases,
    });
  }

  assignRoles() {
    const {
        selectedResourceId,
        selectedApplication,
        selectedRoles,
        selectedUsers,
        operation,
    } = this.state,
      { assignRoleHandler, userKey, currentWorkspace, browserHistory } = this.props,
      site = selectedResourceId === 'site',
      databases = site ? [] : [selectedResourceId],
      users = selectedUsers;

    if (users.length < 1) { 
        notifyError('Please select at least one user.');
        return;
    }

    let roles = selectedApplication === APPLICATION_NAMES.PORTAL_MANAGEMENT ? 
        selectedRoles :
        selectedRoles.filter((rd) => rd.application === selectedApplication);

    let roleIds = roles.map((rd) => rd.roleId);

    if (roleIds.length < 1) { 
        notifyError('Please select at least one role.');
        return;
    }

    assignRoleHandler({ userKey, users, roles: roleIds, site, databases, operation, currentWorkspace });
    browserHistory.push('/portalManagement/assignments');
  }

  getApplicationRolesJX(applicationName) {
    const {roleDescriptions, selectedRoles} = this.state;
    var descJSX = roleDescriptions
        .filter((rd) => rd.application === applicationName)
        .sort((a,b) => a.name.localeCompare(b.name))
        .map((rd, idx) => (
            <div style={{marginLeft: 20}} key={`roleSelection-${applicationName}-${idx}`}>
                <Form.Check
                    id={`roleSelection-${applicationName}-${idx}`} // assign an id so label is clickable
                    checked={selectedRoles.findIndex((x) => x.roleId === rd.roleId) !== -1}
                    onChange={() => this.selectRole(rd)}
                    inline
                    label={rd.name}
                />
            </div>
        )); 
    
    return <div>
        <strong >{applicationName}</strong>
        {descJSX}
    </div>;
  }

  getRoleDescriptions() {
        const { features } = this.props;
        const { selectedApplication} = this.state;

    switch(selectedApplication) {
        case APPLICATION_NAMES.ARIUS_ENTERPRISE:
            return this.getApplicationRolesJX(selectedApplication);
        case APPLICATION_NAMES.TOD:
            return this.getApplicationRolesJX(selectedApplication);
        default:
            return (
                <div>
                    {this.getApplicationRolesJX(APPLICATION_NAMES.PORTAL_MANAGEMENT)}
                    {this.getApplicationRolesJX(APPLICATION_NAMES.ARIUS_ENTERPRISE)}
                    {this.getApplicationRolesJX(APPLICATION_NAMES.TOD)}
                    {features.advancedAnalytics === true ? this.getApplicationRolesJX(APPLICATION_NAMES.ADVANCED_ANALYTICS) : null}
                </div>
            );
      }
  }

  render() {
    const
    {
        roleDescriptions,
        users,
        databases,
        workspaces,
        userFilter,
        roleFilter,
        resourceFilter,
        selectedUsers,
        selectedResourceId,
    } = this.state;
    const { browserHistory } = this.props;

    let availableUsersJSX = <div />,
      roleDescriptionsJSX = <div />,
      availableResourcesJSX = <div />,
      availableDatabasesJSX = <div />;

    if (users && users.length) {
      availableUsersJSX = users
        .sort((a,b) => a.name.localeCompare(b.name))
        .map((u, idx) => (
            <div key={`userSelection-${idx}`}>
                <Form.Check
                    id={`userSelection-${idx}`} // assign an id so label is clickable
                    checked={selectedUsers.indexOf(u.id) !== -1}
                    onChange={() => this.selectUser(u.id)}
                    inline
                    label={u.name}
                />
            </div>
        ));
    }

    if (
      (roleDescriptions && roleDescriptions.length && selectedResourceId) ||
      selectedResourceId === 0
    ) {
      roleDescriptionsJSX = this.getRoleDescriptions();
    }
    if (workspaces && workspaces.length) {
        availableResourcesJSX = workspaces
            .sort((a,b) => a.name.localeCompare(b.name))
            .map((w, idx) => (
                <div style={style.workspace} key={`resourceSelection-${idx}`}>
                    <Form.Check
                        id={`resourceSelection-${idx}`} // assign an id so label is clickable
                        type="radio"
                        checked={this.state.selectedResourceId === w.id}
                        onChange={() => this.selectResource(w)}
                        inline
                        label={w.name}
                    />
                </div>
            ));
    }

    if (databases && databases.length) {
        availableDatabasesJSX = databases
            .sort((a,b) => a.workspaceName.localeCompare(b.workspaceName))
            .map((db, idx) => (
                <div
                    style={style.workspace}
                    key={`databaseSelection-${idx}`}
                >
                    <Form.Check
                        id={`databaseSelection-${idx}`} // assign an id so label is clickable
                        type="radio"
                        checked={this.state.selectedResourceId === db.workspaceId}
                        onChange={() => this.selectResource(db)}
                        inline
                        label={db.workspaceName}
                    />
                </div>
            ));
    }

    return (

    <div className="container form-check-container" style={{width:'100%'}}>
        <div className="col-sm-12" style={{color: Colors.purple}}>
            <h1>Assign Roles</h1>
        </div>
        <Row>
            <Col xs={4}>
                <Form.Control
                    id="userFilter"
                    placeholder="filter users"
                    value={userFilter}
                    onChange={this.filterUsers}
                    className="form-control"
                    type="text"
                    style={style.filterControl}
                />
                <div style={style.selectBox}>
                    {availableUsersJSX}
                </div>
            </Col>
            <Col xs={4}>
                <Form.Control
                    id="resourceFilter"
                    placeholder="filter resources"
                    value={resourceFilter}
                    onChange={this.filterResources}
                    className="form-control"
                    type="text"
                    style={style.filterControl}
                />
                <div style={style.selectBox}>
                    <div key="resourceSite">
                        <Form.Check
                            id={`alldb`} // assign an id so label is clickable
                            type="radio"
                            checked={this.state.selectedResourceId === 'site'}
                            onChange={() => this.selectResource({id: 'site'})}
                            inline
                            label="Site (all databases)"
                        />
                    </div>
                    <strong>Arius Analysis Databases</strong>
                    {availableResourcesJSX}
                    <strong>ToD Databases</strong>
                    {availableDatabasesJSX}
                </div>
            </Col>
            <Col xs={4}>
                <Form.Control
                    id="roleFilter"
                    placeholder="filter roles"
                    value={roleFilter}
                    onChange={this.filterRoles}
                    style={style.filterControl}
                />
                <div style={style.selectBox}>
                    {roleDescriptionsJSX}
                </div>
            </Col>
        </Row>
        <div className="col-sm-12" style={{}}>
            <SaveButtons
                saveHandler={this.assignRoles}
                backButtonHander={() => browserHistory.goBack()}
                backButtonText="Cancel"
            />
        </div>
    </div>

    );
  }
}

const mapStateToProps = state => ({
    currentWorkspace: state.analysis.workspaces.current,
    userKey: state.user.userKey,
});

const mapDispatchToProps = dispatch => ({
    dispatch,
});

export default connect(mapStateToProps, mapDispatchToProps)(AssignRoles);
