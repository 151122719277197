import React, { Component } from 'react';
import PropTypes from 'prop-types';

const style = {
  mainContainer: {
    display: 'flex',
    flexFlow: 'row nowrap',
  },
  master: {
    display: 'flex',
    flexFlow: 'column nowrap',
    flex: '1 1',
    borderRight: '1px solid #BDBDBD',
    minHeight: '20vh',
  },
  view: {
    display: 'flex',
    flexFlow: 'column nowrap',
    flex: '3 1',
    padding: '0px 5px',
    minHeight: '20vh',
  },
  viewLabels: {
    borderBottom: '1px solid #BDBDBD',
    padding: '5px',
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'space-between',
  },
};

class MasterViewComponent extends Component {
  static propTypes = {
    views: PropTypes.array,
    customStyle: PropTypes.object,
  };
  constructor(props) {
    super(props);
    this.state = {
      selectedView: 'Valuation_Dates',
    };
    this.selectView = this.selectView.bind(this);
  }

  selectView(name) {
    const { selectedView } = this.state;

    if (name !== selectedView) {
      this.setState({ selectedView: name });
    }
  }

  render() {
    const { views, customStyle } = this.props,
      { selectedView } = this.state,
      viewLabels = views.map((v, i) => {
        let selectedStyle = {};
        if (v.name === selectedView) {
          selectedStyle = {
            background: 'rgba(0, 0, 0, 0.1)',
          };
        }
        return (
          <div
            key={`label-${i}-${v.displayName}`}
            onClick={() => this.selectView(v.name)}
            style={{ ...style.viewLabels, ...selectedStyle }}>
            <span>{v.displayName}</span>
            <span>{v.viewState && v.viewState.label}</span>
          </div>
        );
      }),
      viewComponent = views.find(v => v.name === selectedView).component;

    return (
      <div style={{ ...style.mainContainer, ...customStyle }}>
        <div style={style.master}>
          {viewLabels}
        </div>
        <div style={style.view}>
          {viewComponent}
        </div>
      </div>
    );
  }
}

export default MasterViewComponent;
