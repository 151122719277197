import React, { Component } from 'react';
import { DateRangePickerComponent } from '@syncfusion/ej2-react-calendars';
import { MultiSelectComponent,CheckBoxSelection, Inject  } from '@syncfusion/ej2-react-dropdowns';
import PropTypes from 'prop-types';
import { Button } from '@arius';
import AnalysisToolbar from './analysisToolbar';
import { fetchWorkspace } from '../../../actions/analysis/workspace.actions';
import PlaceHolderMessage from '../../shared/presentational/placeHolderMessage';
import { clearScripts } from '../../../actions/analysis/automation.actions';
import JobSummariesGrid from './automation/jobSummariesGrid';

class Status extends Component {
    static propTypes = {
        userKey: PropTypes.string,
        currentWorkspace: PropTypes.object,
        workspaces: PropTypes.array,
        dispatch: PropTypes.func,
        params: PropTypes.object,
        longRunningOperationsStatus: PropTypes.array,
        getLongRunningOperationsStatus: PropTypes.func,
        clearLongRunningOperationsStatus: PropTypes.func,
        statuses: PropTypes.array,
        cancelBatch: PropTypes.func,
        height: PropTypes.string,
        tableHeight: PropTypes.string,
        openedCommandStatus: PropTypes.string,
        fetchCommandStatuses: PropTypes.func,
    };
    constructor(props) {
        super(props);
        let startDate = new Date();
        startDate.setDate(startDate.getDate()-30); // days in the past
        this.state = {
            startDate,
            endDate: new Date(),
            projects: [],
            fetching: false,
            statusFilter: [],
            cancelledJobs: [],
            statuses: ['Failed', 'Completed', 'Pending', 'Executing', 'Canceled'],
            triggeringTimestamp: new Date()
        };
        this.changeWorkspaceHandler = this.changeWorkspaceHandler.bind(this);
        this.getCancelIcon = this.getCancelIcon.bind(this);
        this.handleStatusFilterChange = this.handleStatusFilterChange.bind(this);
        this.handleDateChange = this.handleDateChange.bind(this);
        this.refresh = this.refresh.bind(this);
        this.navToProjects = this.navToProjects.bind(this);
        this.navToExtract = this.navToExtract.bind(this);
    }

    UNSAFE_componentWillMount() {
        const {
            currentWorkspace,
            userKey,
            dispatch,
            params,
        } = this.props;

        if (params.databaseId && !currentWorkspace) {
            dispatch(fetchWorkspace(userKey, params.databaseId));
        }
    }

    handleDateChange(e) {
        const { startDate:oldStartDate, endDate: oldEndDate } = this.state;
        const { startDate, endDate } = e;
        if (!startDate || !endDate) {
            this.setState({startDate: new Date(oldStartDate), endDate: new Date(oldEndDate)});
            return;
        }
        this.setState({startDate, endDate});
    }

    handleStatusFilterChange(e){
        this.setState({statusFilter: e.value});
    }

    refresh(){
        this.setState({triggeringTimestamp: new Date()})
    }

    getCancelIcon(job) {
        const { userKey, currentWorkspace } = this.props;

        const { status } = job;
        let message = 'Cancel Batch Process',
        icon = <span></span>;

        let disabled = status === 'Canceling' || status === 'Canceled';

        if (job.isBackendReport)
        {
            return icon;  // DO NOT show cancel for reports.  its not implemented yet.
        }
        
        //    if (job.status === 1 || job.status === 2) {
        if (status === 'Pending' || status === 'Executing') { icon = (
                <Button
                    mode={disabled ? 'cancel-disabled' : 'cancel'}
                    onClick={() => {
                        if (!disabled) {
                            this.cancelBatchProcess({ userKey, databaseId: currentWorkspace.id, batchJobId: job.groupId })
                    }}}
                    toolTip={message}
                    placement='left'
                />
            );
       } 
       
        return icon;
    }

    cancelBatchProcess({ userKey, databaseId, batchJobId }) {
        const { cancelledJobs } = this.state;
        cancelledJobs.push(batchJobId);
        this.props.cancelBatch(userKey, databaseId, batchJobId, this.refresh);
        this.setState({ cancelledJobs });
    }

    changeWorkspaceHandler(workspaceId) {
        const {
            dispatch,
            userKey,
            clearLongRunningOperationsStatus,
            currentWorkspace,
            browserHistory,
        } = this.props;
        if (currentWorkspace) {
            clearLongRunningOperationsStatus();
        }
        dispatch(fetchWorkspace(userKey, workspaceId));
        dispatch(clearScripts());
        browserHistory.push(`/arius/automation/${workspaceId}/status`);
    }

    navToProjects() {
        const { browserHistory } = this.props;
        browserHistory.push(`/arius/analysis`);
    }
    
    navToExtract() {
        this.props.browserHistory.push('/arius/reporting');
    }

    getGrid() {
        const { userKey, currentWorkspace, secureDataManagerFactory } = this.props;
        const { startDate, endDate, statusFilter, triggeringTimestamp } = this.state;

        return <JobSummariesGrid 
            userKey={userKey} 
            currentWorkspace={currentWorkspace}
            startDate={startDate}
            endDate={endDate}
            statuses={statusFilter}
            getCancelIcon={this.getCancelIcon}
            trigger={triggeringTimestamp}
            secureDataManagerFactory={secureDataManagerFactory}
        />;
    }

    render() {
        const { startDate, endDate } = this.state,
        {
            currentWorkspace,
            workspaces,
            dispatch,
        } = this.props;
        let renderTable = (
            <div
            style={{ width: '85vw', margin: '5vh auto' }}>
            <PlaceHolderMessage message={'Please select a database'} />
            </div>
        );
        if (currentWorkspace) {
            renderTable = this.getGrid();
        }

        let projectLink = <span className='clickable project-link' onClick={this.navToProjects}>See projects</span>;
        let extractLink = <span className='clickable project-link' onClick={this.navToExtract}>See extract tables</span>;

        return  (
            <div className="list-container-arius">
                <AnalysisToolbar
                    currentWorkspace={currentWorkspace}
                    workspaces={workspaces}
                    dispatch={dispatch}
                    changeWorkspaceHandler={this.changeWorkspaceHandler}
                />
                <div className="list-header-arius" style={{justifyContent: 'flex-start'}}>
                    <span style={{display:'flex', marginRight: 10}}>
                        <h4>Status Reports</h4>
                    </span>
                    <div style={{marginRight: 10}}>
                        <DateRangePickerComponent
                            startDate={startDate}
                            endDate={endDate}
                            change={this.handleDateChange}
                            showClearButton={false}
                            strictMode={true}
                            width='210px'
                        />
                    </div>
                    <div style={{marginRight: 10}}>
                        <MultiSelectComponent id="checkssbox"
                            dataSource={this.state.statuses}
                            change={this.handleStatusFilterChange}
                            fields={this.state.statusFilter} 
                            placeholder="Filter by statuses" 
                            mode="CheckBox" 
                            showSelectAll={true}
                            showDropDownIcon={true} 
                            filterBarPlaceholder="Search statuses" 
                            popupHeight="350px"
                            width='360'>
                            <Inject services={[CheckBoxSelection]} />
                        </MultiSelectComponent>
                    </div>
                    <div style={{marginRight: 10}}>
                        <Button onClick={this.refresh} width='30' variant='outline-secondary'>
                            <i className="fa fa-refresh" aria-hidden="true"/>
                        </Button>
                    </div>
                    {projectLink}
                    {extractLink}
                </div>
                {renderTable}
        </div>);
    }
}

export default Status;
