import React, { Component } from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import {
    getInfoDescription,
} from '@app/utilities/longRunningOperationsDescriptionHelper';
import { Grid } from '@arius';
import StatusTaskDetail from './statusTaskDetail';

class JobDetailsGrid extends Component {
    constructor(props) {
        super(props);
        this.state = {};
        
        // NOTE:  for whatever reason, complex grid properties need to be set at the component level
        //          before they can be used in the grid...  (per syncfusion "guidance", put in quotes)
        this.pageOptions = {
            pageSize: 10, pageSizes: [5,10,50,100]
        };
        this.getDetailTemplate = this.getDetailTemplate.bind(this);
    }

    getDetailTemplate(r) {
        const { userKey, currentWorkspace, project } = this.props;
        return <StatusTaskDetail
            job={r}
            project={project}
            userKey={userKey}
            currentWorkspace={currentWorkspace}
        />
    }

    shouldComponentUpdate(nextProps, nextState){
        return false;
    }

    getTemplateJob(r){
        return <div 
            style={{
                margin: 8,
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
        }}>
            <div>{r.isBackendReport ? r.operationCode : r.projectName}</div>
            { r.isBackendReport ? '' : (
                <div>
                    <span style={{ color: 'black', fontWeight: 'bold' }}>id:</span>
                    &nbsp;
                    {r.projectId}
                </div> 
            )}
        </div>
    }

    getTemplateStatusCode(r){
        // return <span>
        //             <OverlayTrigger
        //                 placement="top"
        //                 focus
        //                 overlay={
        //                     <Tooltip id="info-tip">Version:&nbsp;{r.statusParams.version ? r.statusParams.version : "unknown"}</Tooltip>
        //                 }
        //             >
        //             <span>{r.statusCode}</span>
        //             </OverlayTrigger>
        //         </span>
        return <span>{r.statusCode}</span>
    }

    getTemplateInfoDescription(r){
        return  <span>
            {getInfoDescription(r.statusCode, r.statusParams)}
        </span>
    }

    getGrid() {
        const {
            currentWorkspace,
            isExtract,
            isBackendReport,
            jobId,
            secureDataManagerFactory
        } = this.props;
        
        let dm = secureDataManagerFactory.getDataManager(`workspaces/${currentWorkspace.id}/jobs/${jobId}`,{});
        let columns = [
            { headerText: 'Job',  template: this.getTemplateJob},
            { headerText: 'Last Submitted By', field: 'requestor'},
            { headerText: 'Last Submitted', field: 'requestTime'},
            { headerText: 'Status', field: 'statusCode', width: 80, template: this.getTemplateStatusCode, enableTooltips: false},
            { headerText: 'Started', field: 'startTime', type:'datetime'},
            { headerText: 'Info', width: '80px', template: this.getTemplateInfoDescription},
            { headerText: 'Last Updated', field: 'lastReportTime'},
        ];
        let grid = <Grid 
            columns={columns} 
            data={dm}     
            height='300'
            detailTemplate={isExtract || isBackendReport ? null : this.getDetailTemplate}
            allowPaging={false}
            allowResizing={false}
            initialRefresh={false}
        />

        return <span className="task-grid">{grid}</span>
    }

    render() {
        return this.getGrid();
    }
}

export default JobDetailsGrid;
