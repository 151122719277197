import React, { Component } from 'react';
import { Grid, Button } from '@arius';
import Loader from '@app/shared/presentational/loader';
import Modal from './modalEditDatabase';

class DatabasesView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showModal: false,
            selected: {},
            timerId: null,
        }
        this.getGrid = this.getGrid.bind(this);
        this.getActionItems = this.getActionItems.bind(this);
        this.handleClose = this.handleClose.bind(this);
    }

    getActionItems(row) {
        return  (
            <Button 
                iconName="edit" 
                onClick={() => this.setState({showModal: true, selected: row})}
                toolTip='Edit'/>
        )
    }

    getGrid() {
        const { info } = this.props;
        let data = info && info.databaseServer ? 
        info.databaseServer.databases : [];

        let columns = [
            { field: 'name', headerText: 'Name'},
            { field: 'type', headerText: 'Type'},
            { field: 'edition', headerText: 'Edition'},
            { field: 'serviceLevel', headerText: 'Service Level'},
            { field: 'name', headerText: 'Actions', template: this.getActionItems},
        ];
        
        return <Grid 
            columns={columns}
            data={data}
            height='calc(100vh - 200px)' 
            allowPaging={false}/>
    }

    handleClose() {
        this.setState({showModal: false});
    }

    render() {
        const { isFetching, jobStatus, lastCompletion, canEdit, levelsWarningMessage } = this.props;
        const { showModal, selected } = this.state;
        return (  
            <div className="list-container-arius" style={{ padding: 10 }}>
                <div className="list-header-arius" style={{ marginBottom: 10 }}>
                    <h4>Individually Managed Databases (outside SQL elastic pools)</h4>
                    {jobStatus && jobStatus.isBusy === true ? <div>Adjusting performance...</div>: ('')}

                    {lastCompletion === false ? <div style={{color: 'red'}}>Adjustment Failed</div>:
                     lastCompletion === true ? <div style={{color: 'green'}}>Adjustment Completed Successfully</div>:
                     ('')
                    }
                </div>
                <span style={isFetching ? {}: {display: 'none'}}>
                    <Loader loadingItem='Performance Info' />
                </span>
                <span style={isFetching ? {display: 'none'} : {}}>
                    {this.getGrid()}
                </span>
                <Modal
                    show={showModal}
                    canEdit={canEdit}
                    data={selected}
                    levelsWarningMessage={levelsWarningMessage}
                    closeCallback={this.handleClose}
                >
                </Modal>
            </div>
        );
    }
}

export default DatabasesView;
